import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Icon, Segment, Header } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// Import actions
import { locationSelected } from '../../redux/actions/locationActions';

// Import Components
import LocationCard from './LocationCard';
import LocationForm from '../Forms/LocationForm';
import withSlidingPanel from '../Shared/withSlidingPanel';
import OrganizationQRCode from './OrganizationQRCode';

const SLIDER_WIDTH = '80%';

const SlidingLocationFormAdd = withSlidingPanel(LocationForm, {
  title: 'Add A Location',
  width: SLIDER_WIDTH,
});

const SlidingLocationFormEdit = withSlidingPanel(LocationForm, {
  title: 'Edit Location',
  width: SLIDER_WIDTH,
});

class LocationsList extends Component {
  state = {
    isAddOpen: false,
    isEditOpen: false,
  };

  renderPageTitle = () => {
    const {
      locations: { list },
      t,
    } = this.props;

    if (list) {
      return (
        <Segment basic clearing>
          <OrganizationQRCode floated="right" />

          <Header
            as="h1"
            floated="left"
            content={t('Locations ({{locationsCounter}})', {
              locationsCounter: list.length,
            })}
          />
        </Segment>
      );
    }

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left">
          {t('Locations')}
        </Header>
      </Segment>
    );
  };

  renderLocationList = () => {
    const {
      locations: { list },
      itemsPerRow,
      // currentOrganization,
      currentOrganization: { programType },
      t,
    } = this.props;

    return (
      <Card.Group stackable itemsPerRow={itemsPerRow || 4}>
        {programType && programType === 'familyChildCare' ? null : (
          <Card
            className="add-new"
            onClick={() => this.setState({ isAddOpen: true })}
          >
            <Card.Content>
              <div className={'center'}>
                <Card.Header as={'h4'}>
                  <Icon name="plus" />
                </Card.Header>
                <Card.Description>{t('Add a new location')}</Card.Description>
              </div>
            </Card.Content>
          </Card>
        )}

        {list.map((location) => (
          <LocationCard
            key={location.id}
            location={location}
            onClick={() => {
              this.props.locationSelected(location);
              this.setState({ isEditOpen: true });
            }}
          />
        ))}
      </Card.Group>
    );
  };

  renderSlidingForms = () => {
    return (
      <div>
        <SlidingLocationFormAdd
          isOpen={this.state.isAddOpen}
          onClose={() => this.setState({ isAddOpen: false })}
        />
        <SlidingLocationFormEdit
          isOpen={this.state.isEditOpen}
          onClose={() => {
            this.setState({ isEditOpen: false });
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderPageTitle()}
        {this.renderLocationList()}
        {this.renderSlidingForms()}
      </div>
    );
  }
}

LocationsList.defaultProps = {
  itemsPerRow: 4,
};

LocationsList.propTypes = {
  locations: PropTypes.shape({
    selectedLocation: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  locationSelected: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  locations: state.locations,
  currentOrganization: state.organizations.currentOrganization,
});

export default withTranslation()(
  connect(mapStateToProps, { locationSelected })(LocationsList)
);
