import React, { useState, useEffect } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import PayInvoice from './PayInvoice';

import './assets/style.scss';

const STRIPE_API_PUBLIC_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_live_PvuSWmRdLNKxUwJyb8doKRuY';

const Stripe = () => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    setStripe(window.Stripe(STRIPE_API_PUBLIC_KEY));
  }, []);

  return (
    <StripeProvider stripe={stripe}>
      <PayInvoice />
    </StripeProvider>
  );
};

export default Stripe;
