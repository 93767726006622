import { firestore } from '.';

export const updateSetupStatus = async (organizationId, entityName = null) => {
  try {
    if (!entityName) throw new Error('field name is require');
    const organizationDocument = await firestore()
      .doc(`organizations/${organizationId}`)
      .get();

    if (organizationDocument.exists) {
      const organizationData = organizationDocument.data();
      if (!organizationData.setup[entityName]) {
        const organizationRef = organizationDocument.ref;

        // Check if all steps except current step are complete and set setupComplete.
        const setupComplete = Object.keys(organizationData.setup).every(
          (key) => {
            return key === entityName
              ? true
              : organizationData.setup[key] === true;
          }
        );

        // Set entity flag to true.
        organizationRef.set(
          {
            setup: { [entityName]: true },
            setupComplete,
          },
          { merge: true }
        );
      }
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message || 'Unable to update setup status');
  }
};
