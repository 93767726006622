import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import useCombinedAuthState from '../hooks/useCombinedAuthState';
import { isActive, stopSession } from '../firebase/session';
import { UserStatus } from '../utils/UserStatus';
import { logWarning } from '../../rollbar';
/* eslint-disable no-unused-vars */
// use for types in comments
import firebase from 'firebase/app';
/* eslint-enable no-unused-vars */
/**
 * @typedef {import('../index').WSAuth} WSAuth instance
 */

const authDefaults = {
  /**
   * @type {boolean} whether state is still settling, for both auth and user
   */
  isLoading: true,
  /**
   * @type {boolean} whether state has settled successfully
   */
  isReady: false,
  /**
   * @type {boolean} whether initialization went wrong
   */
  isFailed: false,
  /**
   * @type {boolean} whether there's a user still waiting to be loaded
   */
  isUserLoading: false,
  /**
   * @type {boolean} whether there's a usable user found (not disabled, etc) along with all the other user objects like session and claims
   */
  isAuthenticated: false,
  /**
   * @type {Error?} error occurred during initialization
   */
  authError: undefined,
  /**
   * @type {firebase.User?} signed in user, if exists
   */
  authUser: undefined,
  /**
   * @type {Object?} user session, if exists
   */
  authSession: undefined,
  /**
   * @type {Object?} claims for the user, if exists
   */
  userClaims: undefined,
  /**
   * @type {string?} idToken for the user, if exists
   */
  idToken: undefined,
  /**
   * @type {UserStatus} derived and simplified user status enum
   */
  userStatus: UserStatus.NO_USER,
  /**
   * @type {boolean} whether the found user's account is disabled
   */
  isDisabledUser: false,
  /**
   * @type {boolean} whether the found user needs verification
   */
  needsVerification: false,
  /**
   * @type {boolean} whether the found user should be signed out for security reasons
   */
  needsSignout: false,
  /**
   * use this to force an update for certain updates that don't fire triggers, like custom claims modifications
   */
  forceUserRefresh: () => undefined,
};
const WSAuthContext = createContext(authDefaults);
/**
 * Provides the context for the authenticated user and session.
 * If underlying auth management systems request a redirect to SSO app, this
 * provider will honor it.
 * @param {{
 *  WSA: WSAuth,
 *  children: React.ReactChild
 * }}
 */
const WSAuthProvider = ({ WSA, children }) => {
  if (!WSA) {
    throw new Error('forgot to set WSA in provider');
  }
  const {
    isLoading,
    isFailed,
    isReady,
    isUserLoading,
    isAuthenticated,
    authUser,
    authSession,
    userClaims,
    idToken,
    authError,
    forceUserRefresh,
    sessionNeedsSignout,
    cleanupSignout,
  } = useCombinedAuthState(WSA);
  const [userStatus, setUserStatus] = useState(UserStatus.NOT_READY);
  const [signingOut, setSigningOut] = useState(false);

  useEffect(() => {
    let userStatus = UserStatus.NOT_READY;
    if (!isReady) {
      userStatus = UserStatus.NOT_READY;
    } else if (!authUser) {
      userStatus = UserStatus.NO_USER;
    } else if (authUser.disabled) {
      userStatus = UserStatus.DISABLED;
    } else if (!authUser?.emailVerified && !userClaims?.email_verified) {
      userStatus = UserStatus.NOT_VERIFIED;
    } else if (!isActive(authSession)) {
      userStatus = UserStatus.SESSION_INVALID;
    } else {
      userStatus = UserStatus.AUTHENTICATED;
    }
    setUserStatus(userStatus);
  }, [authSession, authUser, isReady, userClaims]);

  const doSignOut = useCallback(async () => {
    let result;
    setSigningOut(true);
    try {
      if (authSession) {
        result = !!(await stopSession(
          authSession?.userId,
          authSession?.sessionId
        ));
      } else {
        result = true; // no session, skip to
      }
      if (result) {
        result = !!(await WSA.auth.signoutLocal());
      }
      cleanupSignout();
      WSA.auth.signoutRedirect();
    } catch (err) {
      // ignore error
      logWarning(`error stopping session: ${err.message}`, { err });
    } finally {
      cleanupSignout();
      setSigningOut(false);
    }
    return result; // if undefined, failed
  }, [WSA.auth, authSession, cleanupSignout]);

  useEffect(() => {
    if (sessionNeedsSignout && !signingOut) {
      doSignOut();
    }
  }, [doSignOut, sessionNeedsSignout, signingOut]);

  return (
    <WSAuthContext.Provider
      value={{
        /**
         * @type {boolean} whether state is still settling, for auth, user, and session
         */
        isLoading,
        /**
         * @type {boolean} whether state has settled successfully
         */
        isReady,
        /**
         * @type {boolean} whether initialization went wrong
         */
        isFailed,
        /**
         * @type {boolean} whether there's a user still waiting to be loaded
         */
        isUserLoading,
        /**
         * @type {boolean} whether the user and session have been successfully loaded and the user is still valid
         */
        isAuthenticated:
          isAuthenticated &&
          (userStatus === UserStatus.NOT_READY || // this can happen in transition states
            userStatus === UserStatus.AUTHENTICATED ||
            userStatus === UserStatus.NOT_VERIFIED),
        /**
         * @type {Error?} error occurred during initialization
         */
        authError: authError,
        /**
         * @type {firebase.User?} signed in user, if exists
         */
        authUser: authUser,
        /**
         * @type {Object?} user session, if exists
         */
        authSession: authSession,
        /**
         * @type {Object?} claims for the user, if exists
         */
        userClaims: userClaims,
        /**
         * @type {string?} idToken for the user, if exists
         */
        idToken: idToken,
        /**
         * @type {UserStatus} derived and simplified user status enum
         */
        userStatus: userStatus,
        /**
         * @type {boolean} whether the found user's account is disabled
         */
        isDisabled: !!authUser && userStatus === UserStatus.DISABLED,
        /**
         * @type {boolean} whether the found user needs verification
         */
        needsVerification: userStatus === UserStatus.NOT_VERIFIED,
        /**
         * @type {boolean} whether the found user should be signed out for security reasons
         */
        needsSignout: userStatus === UserStatus.SESSION_INVALID || !!authError,
        /**
         * @type {() => undefined} updates user, claims, and token. use this after email verification and claims updates
         */
        forceUserRefresh,
      }}
    >
      {children}
    </WSAuthContext.Provider>
  );
};

/**
 * Makes the WSAuth and Firebase contexts available with the useWSAuth hook
 * Useful for functional components
 * @param {WSAuth} WSA
 * @param {React.Component} WrappedComponent
 * @returns {(WrappedComponent: React.Component) => React.ComponentType}
 */
const makeWithWSAuth = (WSA) => (WrappedComponent) => (props) => {
  return (
    <WSAuthProvider WSA={WSA}>
      <WrappedComponent {...props} />
    </WSAuthProvider>
  );
};
/**
 * Makes the WSAuth and Firebase contexts available in the component props
 * Useful for class components
 * @param {(Object) => Object} mapAuthContextToProps
 * @param {React.ComponentType} WrappedComponent
 * @returns {React.Component}
 * @example
 * const MyWrappedComponent = WSA.mapAuthContextToProps((ctx) => {user: ctx.user}, MyComponent)
 */
const mapAuthContextToProps =
  (mapAuthContextToProps = () => ({}), WrappedComponent) =>
  (props) => {
    return (
      <WrappedComponent {...mapAuthContextToProps(useWSAuth())} {...props} />
    );
  };
/**
 * hook that returns the WSAuthContext object
 */
const useWSAuth = () => useContext(WSAuthContext);

export {
  makeWithWSAuth,
  mapAuthContextToProps,
  useWSAuth,
  //
};
