
import { ORGANIZATION_USERS_FETCHED } from './types';

import { organizationUsersOnListen } from '../../api/firebase/users';

export const organizationUsersFetched = (users) => ({
  type: ORGANIZATION_USERS_FETCHED,
  users,
});

export const startOrganizationUsersListener =
  (organizationId) => (dispatch) => {
    return organizationUsersOnListen(
      organizationId,
      (users) => {
        if (users) dispatch(organizationUsersFetched(users));
      },
      (error) => console.log(error.message)
    );
  };
