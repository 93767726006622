import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { switchOrganization } from '../redux/actions/organizationActions';

export default function useOrganizationImpersonation() {
  const {
    user,
    organizations: { currentOrganization },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const isImpersonatingOrganization = useMemo(() => {
    if (!user.impersonatedOrganization) return false;
    return user.impersonatedOrganization !== user.defaultOrganization;
  }, [user?.impersonatedOrganization, user?.defaultOrganization]);

  const isOwnOrganization = useCallback(
    (id) => {
      if (!id) return false;
      return user.defaultOrganization === id;
    },
    [user?.defaultOrganization]
  );

  // If organization id is not supplied, clears the impersonation.
  const switchToOrganization = useCallback(
    async (organization = null) => {
      if (!user.uid) return;

      await dispatch(switchOrganization(organization, user.uid));

      // Force reload of the app to restart organization listeners.
      // window.location.href = window.location.origin; can also be used to reload and land on root page '/'.
      window.location.reload();
    },
    [dispatch, user?.uid]
  );

  return {
    isImpersonatingOrganization,
    impersonatedOrganization: user.impersonatedOrganization,
    activeOrganization: currentOrganization,
    isOwnOrganization,
    switchToOrganization,
  };
}
