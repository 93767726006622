import React from 'react';
import PropTypes from 'prop-types';
import { Form, Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import InvoiceItemEditor from './InvoiceItemEditor';
import InvoiceTotal from './InvoiceTotal';

import './InvoiceItemsEditor.scss';

const InvoiceItemsEditor = (props) => {
  const { t } = useTranslation();

  let { onAddInvoiceItem, invoiceItemList, total, validation } = props;

  return (
    <>
      <Form.Group widths={'equal'} className={'grid-header'}>
        <Form.Field width={4} required label={t('Category')} />
        <Form.Field width={4} required label={t('Description')} />
        <Form.Field width={5} label={t('Notes')} />
        <Form.Field width={2} required label={t('Amount')} />
        <Form.Field width={1}>&nbsp;</Form.Field>
      </Form.Group>
      {invoiceItemList &&
        invoiceItemList.map((invoiceItem, index) => {
          return (
            <InvoiceItemEditor
              categoryError={
                validation[index] && validation.length > 0
                  ? validation[index].categoryError
                  : null
              }
              itemError={null}
              key={`invoiceRow${index}`}
              onAddition={props.onAddition}
              onAdditionItemOptions={props.onAdditionItemOptions}
              onChangeItemOptions={props.onChangeItemOptions}
              onChange={props.onChange}
              onAddDiscount={props.onAddDiscount}
              onDeleteDiscount={props.onDeleteDiscount}
              onChangeDiscount={props.onChangeDiscount}
              onDeleteInvoiceItem={props.onDeleteInvoiceItem}
              categoryOptions={props.categoryOptions}
              discountOptions={props.discountOptions}
              index={index}
              {...invoiceItem}
            />
          );
        })}
      <Divider />
      <Form.Group>
        <Form.Field width={4}>
          <a
            href="#add-item"
            onClick={(e) => {
              e.preventDefault();
              console.log('e');
              onAddInvoiceItem();
            }}
          >
            {t('Add Another Item')}
          </a>
        </Form.Field>
        <Form.Field width={12}>
          <InvoiceTotal total={total} />
        </Form.Field>
      </Form.Group>
    </>
  );
};

InvoiceItemsEditor.propTypes = {
  categoryOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.array,
  onChangeDiscount: PropTypes.func.isRequired,
  onAddition: PropTypes.func.isRequired,
  onAdditionItemOptions: PropTypes.func.isRequired,
  onChangeItemOptions: PropTypes.func.isRequired,
  onAddDiscount: PropTypes.func.isRequired,
  onDeleteDiscount: PropTypes.func.isRequired,
  onAddInvoiceItem: PropTypes.func.isRequired,
  onDeleteInvoiceItem: PropTypes.func.isRequired,
  invoiceItemList: PropTypes.array,
};

export default InvoiceItemsEditor;
