import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { usePrevious } from '@reactivers/use-previous';
import { isActive, getRemaining, refreshSession } from '../firebase/session';

const ONE_HOUR = 1000 * 60 * 60;

/**
 * ensures the active session, if provided, is kept refreshed
 * @param {Object?} maybeAuthSession session if available
 * @returns {{
 *  expires: number?,
 *  nextRefresh: number?,
 * }}
 *  - expires: epoch millis until session expiration, undefined if no session
 *  - nextRefresh: epoch millis until next refresh, undefined if no session
 */
const useSessionRefresher = (maybeAuthSession) => {
  const prevSession = usePrevious(maybeAuthSession);
  const [timer, setTimer] = useState();
  // exists and is active
  const isGoodSession = maybeAuthSession && isActive(maybeAuthSession);
  const didSessionUpdate =
    maybeAuthSession && prevSession && !isEqual(maybeAuthSession, prevSession);
  const noTimer = !timer;
  const needsNewTimer = isGoodSession && (didSessionUpdate || noTimer);
  const needsClearTimer = !noTimer && !isGoodSession;

  let expiresAt, nextRefresh;
  if (isGoodSession) {
    const maybeExpiresAt = getRemaining(maybeAuthSession);
    if (!maybeExpiresAt) {
      throw new Error('could not get expiration from session');
    }
    expiresAt = maybeExpiresAt;
    // either in half the expiration window or now, if the expiration time is soon.
    nextRefresh = Math.round(expiresAt / 2);
    if (expiresAt < ONE_HOUR) {
      nextRefresh = 0;
    }
  }

  // ****************************************************************
  // Entry Point
  // manages timer lifecycle
  // ****************************************************************
  useEffect(() => {
    if (needsNewTimer && nextRefresh !== undefined) {
      clearTimeout(timer); // clear original
      setTimer(
        setTimeout(() => {
          // refresh (async) the session, triggering session listener updates
          refreshSession(maybeAuthSession);
        }, nextRefresh)
      );
      // cleanup
      return () => {
        clearTimeout(timer);
      };
    } else if (needsClearTimer) {
      clearTimeout(timer);
    }
  }, [maybeAuthSession, needsClearTimer, needsNewTimer, nextRefresh, timer]);

  return {
    /** @type {number?} epoch millis until session expiration, undefined if no session */
    expiresAt,
    /** @type {number?} epoch millis until next refresh, undefined if no session */
    nextRefresh,
  };
};

export default useSessionRefresher;
