import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import { firebaseConfig } from './config';
import { isTest } from '../../config/env';

// Only initialize if it isn't already initialized.
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (isTest() || process.env.REACT_APP_FIREBASE_EMULATOR === 'ACTIVE') {
    /**
     * To use firebase emulator
     * start firebase emulator: firebase emulators:start
     * start react app: yarn start:with-emulator
     */
    // Auth emulator
    firebase.auth().useEmulator('http://localhost:9099', {
      disableWarnings: isTest(),
    });
    // Firestore emulator
    firebase.firestore().useEmulator('localhost', 8080);
    // Functions emulator
    firebase.functions().useEmulator('localhost', 5001);
    // Storage emulator
    firebase.storage().useEmulator('localhost', 9199);
  }
}

// Export firebase stuff.
export const { auth, firestore, storage, functions } = firebase;
export default firebase;
