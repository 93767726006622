import _ from 'lodash';
import store from '../redux/store';
export const randomPictures = [
  'https://www.moxit.com/assets/images/placeholders/profile-01.png',
  'https://www.moxit.com/assets/images/placeholders/profile-02.png',
  'https://www.moxit.com/assets/images/placeholders/profile-03.png',
  'https://www.moxit.com/assets/images/placeholders/profile-04.png',
  'https://www.moxit.com/assets/images/placeholders/profile-05.png',
  'https://www.moxit.com/assets/images/placeholders/profile-06.png',
  'https://www.moxit.com/assets/images/placeholders/profile-07.png',
  'https://www.moxit.com/assets/images/placeholders/profile-08.png',
  'https://www.moxit.com/assets/images/placeholders/profile-09.png',
  'https://www.moxit.com/assets/images/placeholders/profile-10.png',
  'https://www.moxit.com/assets/images/placeholders/profile-11.png',
];

const PARENT_NOTES = 'Parent or Guardian';
const CONTACT_NOTES = 'Other';

export const RelationshipTypes = {
  PARENT: 'parent',
  GUARDIAN: 'guardian',
  CONTACT: 'emergencyContact', //
};

export const RelationshipTypeOptions = [
  {
    key: RelationshipTypes.PARENT,
    text: PARENT_NOTES,
    value: RelationshipTypes.PARENT,
  },
  {
    key: RelationshipTypes.CONTACT,
    text: CONTACT_NOTES,
    value: RelationshipTypes.CONTACT,
  },
];

export const CONTACT_TYPES = {
  user: 'user',
  contact: 'contact',
  invitation: 'invitation',
};

/* We use the various ids to help decide what type of familyMember this is:
   uid:  User
   uuid: Contact from the students.contacts array
   id:   Invitation from the /invitations collection
 */
export const getContactType = (familyMember) => {
  // alias the vario
  const {
    uid: userId,
    id: invitationId,
    uuid: contactId,
    allowParentLogin,
  } = familyMember;

  // The order of these checks is important.
  if (userId && !invitationId) return CONTACT_TYPES.user;
  else if (invitationId && allowParentLogin) return CONTACT_TYPES.invitation;
  else if (contactId) return CONTACT_TYPES.contact;

  return null;
};
export const getRandomStudentPicture = () => {
  return (
    randomPictures[_.random(0, randomPictures.length - 1)] || randomPictures[0]
  );
};

export const getPrimaryLocation = (locations) => {
  if (!locations?.length) return null;
  return locations.find(location => location.primary) || locations[0];
}

const findLocationIdFromRoom = (student) => {
  const { students, rooms } = store.getState();
  const studentState = students.list?.find((s) => s.id === student.id);

  if (!studentState || !studentState.rooms || studentState.rooms.length <= 0)
    return null;

  const roomId = studentState.rooms[0];
  const roomState = rooms.list.find((r) => r.id === roomId);

  if (!roomState) return null;

  return roomState.location;
}

export const findPrimaryLocationId = () => {
  const { locations } = store.getState();
  return getPrimaryLocation(locations?.list || [])?.id ?? null;
}

export const findLocationIdForStudent = (student) => 
  findLocationIdFromRoom(student) || findPrimaryLocationId();

export function addOrUpdateContact(student, familyMember) {
  if (!student.contacts) {
    student.contacts = [familyMember];
    return;
  }
  let isUpdate = false;
  student.contacts.forEach((contact, index) => {
    if (
      contact.id === familyMember.id ||
      contact.uid === familyMember.uid ||
      contact.uuid === familyMember.uuid ||
      contact.invitationId === familyMember.invitationId
    ) {
      student.contacts[index] = familyMember;
      isUpdate = true;
    }
  });
  if (!isUpdate) {
    student.contacts.push(familyMember);
  }
}

// Take the original relationship types and munge them into a newer format
// Does NOT mutate the original object
export function transformRelationship(data, t) {
  let { relationship = null, relationshipNotes = null, ...rest } = data ?? {};

  // eliminate the 'guardian' relationship
  if (relationship === RelationshipTypes.GUARDIAN)
    relationship = RelationshipTypes.CONTACT;

  return { ...rest, relationship, relationshipNotes };
}

export function getOrCreateRelationshipNotes(data, t) {
  const { relationship = null, relationshipNotes = null } = data ?? {};

  if (relationshipNotes) return relationshipNotes;

  if (relationship === RelationshipTypes.PARENT) {
    return t(PARENT_NOTES);
  } else {
    return t(CONTACT_NOTES);
  }
}

export function createArrayOfFamilies(familyMembers) {
  if (!familyMembers) return [];

  return Object.keys(familyMembers).map((key) => ({ ...familyMembers[key] }));
}
/**
 * Returns true if student matches searchText. Tries to match with displayName as
 * well as names in families.
 */
export const doesQueryMatchStudent = (searchText) => (student) => {
  const { displayName, combinedFamily } = student;

  const searchTextLower = searchText.toLowerCase();

  if (displayName.toLowerCase().includes(searchTextLower)) {
    return true;
  }

  if (combinedFamily) {
    const famKeys = Object.keys(combinedFamily);

    return famKeys.some((key) => {
      if (
        combinedFamily[key].displayName?.toLowerCase().includes(searchTextLower)
      ) {
        return true;
      }
      // some older data may not have this property, therefore, we need to create a string
      // and then check against it
      if (!combinedFamily[key].hasOwnProperty('displayName')) {
        const displayName =
          combinedFamily[key].firstName + ' ' + combinedFamily[key].lastName;
        return displayName.toLowerCase().includes(searchTextLower);
      }

      return false;
    });
  }

  return false;
};

/**
 * Returns the subarray of students that match searchText. Tries to match with displayName as
 * well as names in families.
 */
export const searchStudents = (searchText, students) =>
  students.filter(doesQueryMatchStudent(searchText));

export const computeEnforceAutomaticPayment = (student, organization) => {
  const studentModifiedDate = student.automaticPayment?.modifiedDate ?? 0;
  const organizationModifiedDate =
    organization.automaticPayment?.modifiedDate ?? 0;

  return studentModifiedDate > organizationModifiedDate
    ? !!student.automaticPayment?.enforce
    : !!organization.automaticPayment?.enforce;
};
