import React from 'react';

import { Image, Modal } from 'semantic-ui-react';

export default function ImageModal({
  imageURL,
  onClose,
  open,
}) {
  if (!onClose)
    throw new Error('onClose is required');

  return (
    <Modal
      onClose={onClose}
      open={open}
      closeIcon
      closeOnDimmerClick
      closeOnDocumentClick
    >
      <Modal.Content image>
        <Image src={imageURL} centered/>
      </Modal.Content>
    </Modal>
  )
}
