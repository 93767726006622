import { firestore, functions } from '.';
import {
  getDocument,
  getDocuments,
  getDocumentsAsArray,
  setDocument,
  startCollectionListener
 } from './firestore';

 const paths = {
  user: (organizationId, userId) => `organizations/${organizationId}/users/${userId}`,
  users: (organizationId) => `organizations/${organizationId}/users`,
  rootUser: (userId) => `users/${userId}`,
  rootUsers: () => 'users',
}

export const getUserProfiles = ({
  conditions = [],
  orderBy = [],
  limit = null,
  startAfter = null,
}) =>
  getDocuments({
    path: paths.rootUsers(),
    conditions,
    orderBy,
    limit,
    startAfter,
  });

export const getUserProfile = async (uid) => {
  if (!uid)
    throw Error('User ID must be specified');

  return getDocument({
    path: paths.rootUser (uid),
  });
};

export const userOnListen = (uid, next, error, complete) =>
  firestore()
    .collection(`users`)
    .doc(uid)
    .onSnapshot(
      (doc) => next(doc ? { uid: doc.id, ...doc.data() } : {}),
      error,
      complete
    );
  export const getOrganizationUserByEmail = async (organizationId, email) => {
    const users = await getDocumentsAsArray ({
      path: paths.users(organizationId),
      conditions: [
        {
          field: "email",
          operation: "==",
          value: email
        }
      ],
      limit: 1
    })

    if (!users.count) return null;
    return users.list[0];
  };

export const updateOrganizationUser = async (organizationId, userId, data) => {
  if (!organizationId || !userId || !data)
    throw new Error('Missing parameters');

  return setDocument({
    path: paths.user(organizationId, userId),
    data,
  });
};

export const updateOrganizationUserPin = (
  organizationId,
  userId,
  type,
  pin
) => {
  if (!organizationId || !userId || !type || !pin) return null;
  return setDocument({
    path: paths.user(organizationId, userId),
    data: { [type]: pin },
  });
};

export const updateUserProfile = async (userId, data) => {
  return setDocument({
    path: paths.rootUser(userId),
    data,
    merge: true,
  });
};

export const organizationUsersOnListen = (organizationId, next, error, complete) => {
  return startCollectionListener ({
    path: paths.users(organizationId),
    conditions: [
  //    {field: 'enabled', operation: '==', value: true}
  //    {field: "allowParentLogin", operation: "==", value: true}
    ],
    orderBy: [{field: 'email', direction:'asc'}],
  },
  next, error, complete);
}


// Checks the root user and auth user to see if the email is already in use,
// but does not check the organization users
// The shape of the data returned is:
// {
//   isInUse: <boolean>,             // hasAuthUser || hasRootUser
//   hasAuthUser: <boolean>,         // if the email is a Firebase auth user
//   hasRootUser: <boolean>,         // if the email has a root Users document
//   hasOrganizationUser: <boolean>, // if the email has a user in the organization
//   uid: <string>,                  // the uid of the user if isInUse is true
// }
export const fetchEmailUsage = async (email, organizationId) => {
  const params = { email }
  if (organizationId)
    params.organizationId = organizationId;

  const { data } = await functions().httpsCallable(
    'callables-users-emailUsage'
  )(params);

  return data;
}
