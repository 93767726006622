import React,{ useState } from 'react';
import { Modal, Form, Button, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { functions } from '../../../api/firebase';

import { formatFullName } from '../../../helpers/utils';

import { updateUserProfile } from '../../../api/firebase/users';
import UserRolesDropdown from './UserRolesDropdown';
import {
  USER_MAX_BALANCE,
  USER_MAX_TRANSACTION_AMOUNT,
  getOrSetFastpay,
} from  '../../../helpers/fastpay';

import { areStripePaymentsEnabledForUser } from '../../../helpers/stripe';

import FastpaySetup from  '../FastpaySetup';

function UsersDetail ({ user, onClose }) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [userRoles, setUserRoles] = useState([]);
  const [fastpayData, setFastpayData] = useState(null);
  const [paymentsEnabled, setPaymentsEnabled] = useState(areStripePaymentsEnabledForUser(user));

  if (!user)
    return null;

  const { id, uid, email } = user;

  initializeState ();

  return (
    <Modal closeIcon onClose={onClose} open={true} closeOnDimmerClick={false} closeOnEscape={false}>
      <Modal.Header>
        {user.displayName ?? formatFullName(user, true)}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description></Modal.Description>
        <Modal.Description>
          {renderForm()}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )

  function renderForm () {
    return (
      <Form onSubmit={onSubmit}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("User's ID")}</label>
            <div>{uid || id}</div>
          </Form.Field>

          <Form.Field>
            <label>{t('Email')}</label>
            <div>{email}</div>
          </Form.Field>
        </Form.Group>

        <UserRolesDropdown
          user={user}
          userRoles={userRoles}
          onUserRolesChange={_userRoles=>setUserRoles (_userRoles)}/>

        <Form.Radio
          toggle
          id="paymentsEnabled"
          name="paymentsEnabled"
          label={t('Allow Stripe Payments')}
          checked={paymentsEnabled}
          onChange={onPaymentsEnabledChange}
          control={Checkbox}
        />

        <FastpaySetup
          fastpayData={fastpayData}
          onFastpayDataChange={onFastpayDataChange}
          maxBalanceDefault={USER_MAX_BALANCE}
          maxTransactionAmountDefault={USER_MAX_TRANSACTION_AMOUNT}  />

        <Button primary type="submit" loading={loading} content={t('Submit')} />
        <Button
          basic
          loading={loading}
          content={t('Cancel')}
          onClick={onCancel}
        />
      </Form>
    );
  }

  function onPaymentsEnabledChange (e, { checked }){
    setPaymentsEnabled(checked);
  }

  function onFastpayDataChange (fastpayData) {
    setFastpayData (fastpayData);
  }

  function initializeState () {
    if (!fastpayData && user) {
      setFastpayData ({fastpay:getOrSetFastpay(user)});
    }
  }
  function onCancel ()  {
    onClose();
  }

  async function onSubmit (e) {
    e.preventDefault();

    try {
      setLoading(true);

      if (userRoles?.length > 0) {
        await functions().httpsCallable('callables-users-grantRoles')({
          uid: uid ||id,
          email,
          roles: userRoles,
        });
      }
      const stripeData ={
        stripe: {
          paymentsEnabled:paymentsEnabled
        }}
      const data = {...fastpayData, ...stripeData};
      await updateUserProfile (uid || id, data);

      Object.assign (user, data);
      onCancel()
    } catch (error) {
      console.error(error);

    } finally {
      setLoading(false);
    }
  };
}
export default UsersDetail;
