import { Button, Form, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function GeoDataEditForm ({geoData, onChange, onDelete, onAdd}) {
  const { t } = useTranslation();

  return (
    <>
      <Form.Group columns={2}>
        <Form.Field width={14}>
          <Button
            basic
            color="blue"
            size="mini"
            compact
            floated="right"
            onClick={(e) => {
              e.preventDefault();
              if (onAdd) onAdd();
            }}
          >
            <Icon name="add" /> {t('Add Geo Data')}
          </Button>
          <label>{t('Geo Data')}</label>
        </Form.Field>
      </Form.Group>
      {geoData.map ((geo, index) => (
        <Form.Group columns={2} key={index}>
          <Form.Field width={14}>
            <Form.Input
              type="text"
              id="geoData"
              name="geoData"
              value={geo}
              onChange={(e, {value})=>{
                e.preventDefault();
                if (onChange) onChange (index, value)
              }}
              placeholder={t('Enter Geo Data')}
            />
          </Form.Field>
          <Form.Button
            width={2}
            basic
            compact
            id="delete"
            icon="trash"
            onClick={(e) => {
              e.preventDefault();
              if (onDelete) onDelete (index)
            }}
          />
        </Form.Group>
      ))}
    </>

  )
}