import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Menu, Icon, Image, Modal } from 'semantic-ui-react';

// Import Logo
import wsLogo from '../../styles/img/wonderschool/logo-base.png';

// Import components.
import TopNavigation from './TopNavigation';

import WSA from '../../WSA';

const MobileNavigation = ({ user, permissions, handleToggle }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isReady, isAuthenticated } = WSA.components.useWSAuth();

  if (!(isReady && isAuthenticated)) return null;

  const renderMobileHamburger = () => {
    return (
      <Modal
        closeIcon
        centered={false}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
        trigger={
          <Menu.Item className="hamburger">
            <Icon name="sidebar" />
          </Menu.Item>
        }
      >
        <Modal.Header>
          <Image
            src={wsLogo}
            style={{
              width: '80%',
              paddingTop: '0.5em',
              paddingBottom: '0.5em',
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <TopNavigation
              viewport="mobile"
              handleShowMenu={() => setMenuOpen(false)}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <Menu secondary size="large">
      {renderMobileHamburger()}
      <Menu.Item as="a">
        <Image size="medium" src={wsLogo} />
      </Menu.Item>
    </Menu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  permissions: state.permissions,
});

export default withRouter(connect(mapStateToProps)(MobileNavigation));
