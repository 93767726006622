/*****************************/
/* REDUCED from SSO-ACCOUNTS */
/*****************************/
import { writeMergeDoc } from './firestore';

export const ACTION_TYPES = {
  RECOVER_EMAIL: 'recoverEmail',
  RESET_PASSWORD: 'resetPassword',
  VERIFY_EMAIL: 'verifyEmail',
};

/**
 * For each action type, we can save one state record
 * for any device to use when it consumes the action
 */
const paths = {
  /**
   * @param {string} userId firebase id
   * @param {'recoverEmail' | 'resetPassword' | 'verifyEmail'} actionType type of action
   * @returns {string} doc path
   */
  actionReturn: (userId, actionType) =>
    `authSession/${userId}/actionReturn/${actionType}`,
};
/**
 * make json payload for signup data
 * @param {string} userId firebase id
 * @param {'recoverEmail' | 'resetPassword' | 'verifyEmail'} actionType type of action
 * @param {Object} returnData data to be saved for later use
 * @returns {Object} action return data, sanitized
 */
const makeActionReturnData = (userId, actionType, returnData = {}) => ({
  userId,
  actionType,
  ...returnData,
});
/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * Tries to persist actionReturn data. If successful, the (slightly different) written data is returned.
 * @param {string} userId firebase id
 * @param {string} actionType type of action
 * @param {Object} data data to be saved
 * @returns { Object } saved data
 * @throws { Error } if userId is not provided
 * @throws { Error } if actionType is not provided
 * @throws { Error } record fails to write
 */
export const saveActionReturn = async (userId, actionType, data) => {
  if (!userId) {
    throw new Error('userId required');
  }
  if (!actionType) {
    throw new Error('actionType required');
  }
  const actionReturnData = makeActionReturnData(userId, actionType, data);
  const didWrite = await writeMergeDoc(
    paths.actionReturn(userId, actionType),
    actionReturnData
  );
  if (didWrite) {
    return actionReturnData;
  } else {
    throw new Error('Could not save action return data');
  }
};
/**
 * Checks if provided string value is one of the allowed values (whitelist).
 * @param {string} actionType
 * @returns {boolean} if the actionType is supported and valid
 */
export const isValidActionType = (actionType) => {
  return Object.values(ACTION_TYPES).indexOf(actionType) >= 0;
};
