import { useSelector } from 'react-redux';

import { useUser } from './useUser';

import { startOrganizationInvoicesListener } from '../redux/actions/invoiceActions';
import { startOrganizationLocationsListener } from '../redux/actions/locationActions';
import { startOrganizationPermissionsListener } from '../redux/actions/permissionActions';
import { startOrganizationRolesListener } from '../redux/actions/roleActions';
import { startOrganizationStaffListener } from '../redux/actions/staffActions';
import { startOrganizationStatsListener } from '../redux/actions/statsActions';
import { startOrganizationStudentsListener } from '../students/studentsRedux';
import { startOrganizationUsersListener } from '../redux/actions/usersActions';

import { useListenerWithRedux } from './useListenerWithRedux';

export {
  useOrganization,
  useOrganizationInvoicesListener,
  useOrganizationLocationsListener,
  useOrganizationPermissionsListener,
  useOrganizationRolesListener,
  useOrganizationStaffListener,
  useOrganizationStatsListener,
  useOrganizationStudentsListener,
  useOrganizationUsersListener,
}
function useOrganization() {
  return useSelector(state => state.organizations.currentOrganization);
}

function useOrganizationInvoicesListener(organizationId) {
  const dependencyArray = [organizationId];
  const { isLocationAdminHighestClaim, locations } = useUser()
  // if locationAdmin is highest claim - pass in locations
  const _locations = isLocationAdminHighestClaim ? locations : undefined
  const startListenerAction = startOrganizationInvoicesListener(organizationId, _locations)
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationLocationsListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationLocationsListener(organizationId, firestoreOptions)
  return useListenerWithRedux(startListenerAction, dependencyArray);
}
function useOrganizationPermissionsListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationPermissionsListener(organizationId, firestoreOptions)
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationRolesListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationRolesListener(organizationId, firestoreOptions)
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationStaffListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationStaffListener(organizationId, firestoreOptions)
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationStatsListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationStatsListener(organizationId, firestoreOptions)
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationStudentsListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationStudentsListener(organizationId, firestoreOptions)
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationUsersListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationUsersListener(organizationId, firestoreOptions)
  return useListenerWithRedux(startListenerAction, dependencyArray);
}
