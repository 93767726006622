import { formatFullName } from './utils';
import uuidGenerate from 'uuid/v4';

// users can be Staff, User, Student, Parent...
export function createMissingFieldsAndMergeLists (usersList, invitationsList) {
    if (!invitationsList?.length && !usersList?.length)
      return [];

    const mergedList = [];

    // make sure displayName is defined for each user
    usersList.forEach((user) => {
      createMissingFieldsAndAddToList (mergedList, user);
    });

    // make sure displayName is defined for each invitation,
    // and set an 'invited' flag to distinguish it from a user (maybe using uid would be better?)
    invitationsList.forEach((invitation) => {
      invitation.invited = true;
      createMissingFieldsAndAddToList (mergedList, invitation);
    })

    mergedList.sort ((a, b) => {
      return a.displayName < b.displayName
        ? -1
        : a.displayName > b.displayName ? 1 : 0;
    })
    return mergedList;
  }

  // create missing fields 'key' and 'displayName' and then add to mergedList
  function createMissingFieldsAndAddToList (mergedList, data) {
    createDisplayNameIfMissing (data);
    createKeyIfMissing (data);
    mergedList.push (data);
  }
  function createKeyIfMissing (data) {
    if (!data.key)
      data.key = data.uid || data.id || data.uuid || uuidGenerate()
  }
  function createDisplayNameIfMissing(data) {
    if (!data.displayName)
      data.displayName = formatFullName (data, true);
  }
