import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '../../../../Shared/Dropdown';
import { FormInput } from '../../../../Shared/FormInput';
import DiscountRowEditor from './DiscountRowEditor';
import { CurrencyInput } from '../../../../Shared/NumericInput';

export default function InvoiceItemEditor({
  category,
  itemOptions,
  item,
  notes,
  amount,
  discounts,
  index,
  onAddition,
  onChange,
  onAddDiscount,
  onChangeDiscount,
  onDeleteDiscount,
  onAdditionItemOptions,
  onChangeItemOptions,
  onDeleteInvoiceItem,
  categoryOptions,
  discountOptions,
  categoryError,
  itemError,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Form.Group>
        <Form.Field width={4} error={categoryError}>
          <Dropdown
            allowAdditions
            search
            clearable
            required
            placeholder={'Select or type new'}
            name={'category'}
            value={category}
            onAddItem={(e, data) => onAddition(e, data, index)}
            onChange={(e, data) => {
              onChange(e, data, index);
            }}
            translator={t}
            options={categoryOptions}
          />
        </Form.Field>
        <Form.Field width={4} error={itemError}>
          {itemOptions && (
            <Dropdown
              allowAdditions
              clearable
              search
              required
              placeholder={
                itemOptions.length
                  ? t('Select or type new')
                  : t('Select a category first')
              }
              name={'item'}
              value={item}
              onAddItem={(e, data) => onAdditionItemOptions(e, data, index)}
              onChange={(e, data) => onChangeItemOptions(e, data, index)}
              options={itemOptions}
            />
          )}
        </Form.Field>
        <Form.Field width={5}>
          <FormInput
            value={notes}
            onChange={(e, data) => onChange(e, data, index)}
            placeholder={t('Add description (optional)')}
            name={'notes'}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Form.Input
            required
            type={'text'}
            value={amount}
            onBlur={(e) => onChange(e, {name:e.target.name, value:e.target.value}, index)}
            control={CurrencyInput}
            placeholder={t('Amount')}
            name={'amount'}
          />
        </Form.Field>
        <Form.Field width={1} className="text-right">
          <Button
            basic
            index={index}
            href="#delete-row"
            icon="trash"
            onClick={(e) => {
              e.preventDefault();
              onDeleteInvoiceItem(index);
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <a
            index={index}
            href="#add-discount"
            onClick={(e) => {
              e.preventDefault();
              onAddDiscount(index);
            }}
            className="margin right"
          >
            {t('Add Discount or Subsidy')}
          </a>
        </Form.Field>
      </Form.Group>

      {discounts.length > 0 &&
        discounts.map((discount, discountIndex) => (
          <DiscountRowEditor
            discountIndex={discountIndex}
            key={`discount${index}${discountIndex}`}
            discountOptions={discountOptions}
            onChange={onChangeDiscount}
            onDeleteDiscount={onDeleteDiscount}
            index={index}
            {...discount}
          />
        ))}
      <br />
    </div>
  );
}
