import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image, List, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import components
import EmployeesList from '../../SelectEmployees/EmployeesList/EmployeesList';

// Import style
import './EmployeeWidget.scss';

const EmployeeWidget = ({ selectedEmployees, onRemove }) => {
  const { t } = useTranslation();

  return (
    <Card fluid>
      <Card.Content className={'employee-summary-card-header'}>
        <Card.Header textAlign="center">
          {t('{{selectedStudentsCount}} students to be invoiced', {
            selectedStudentsCount: selectedEmployees.length,
          })}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <List
          selection
          verticalAlign="middle"
          className={'employee-summary-list'}
        >
          {selectedEmployees.map((student, index) => (
            <List.Item key={student.id}>
              <Image avatar src={student.picture} />
              <List.Content>
                <List.Header>{student.displayName}</List.Header>
              </List.Content>
              <List.Content floated="right">
                <Icon name="remove" onClick={() => onRemove(index)} />
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  );
};

EmployeesList.propTypes = {
  selectedEmployees: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
};

export default EmployeeWidget;
