import React, { useState } from 'react';
import { Container, Segment } from 'semantic-ui-react';

import {
  useOrganization,
  useOrganizationInvoicesListener,
} from '../hooks/useOrganizations';
import { useSegmentPage, PAGE_NAMES } from '../segment';

import PageHeader from '../Components/Shared/PageHeader';
import ShowErrors from '../Components/Messages/ShowError';
import SetupPayoutRibbon from '../Components/Setup/SetupPayoutRibbon';

// Import components
import { AgingContainer } from '../Components/Billing/Reports/Aging';

export default function InvoiceReports() {
  const [errorMessage, setErrorMessage] = useState(null);
  const organization = useOrganization();

  useSegmentPage(PAGE_NAMES.invoiceReports);
  useOrganizationInvoicesListener(organization.id);

  return (
    <Container className="no-padding no-margin">
      <PageHeader pageName={'Reports'} classes="billing" />
      {errorMessage && <ShowErrors content={errorMessage} />}
      <SetupPayoutRibbon onError={(err) => setErrorMessage(err)} />

      <Segment basic>
        <AgingContainer />
      </Segment>
    </Container>
  );
}
