import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectInfiniteHits, Highlight } from 'react-instantsearch-dom';
import { Button, Header, Icon, Table } from 'semantic-ui-react';
import { getBalance, isEnabled } from '../../../helpers/fastpay';
import { formatCurrency } from '../../../helpers/utils';
import { renderDate } from '../../../utils/date';
import { FastpayStatus } from '../../Billing/Fastpay';
import OrganizationDetail from '../../Organizations/OrganizationDetail';
import withPermission from '../../Shared/withPermission';
import ImpersonationButton from '../Impersonation';

const HitRow = ({ hit, onSelect }) => {
  const { t } = useTranslation();
  const fastpayEnabled = isEnabled(hit);
  const onBoardingStatusIconColor = hit.setupComplete ? 'green' : 'orange';

  return (
    <Table.Row key={hit.id} verticalAlign="top">
      <Table.Cell>
        <Header as="h5">
          <Header.Content>
            <a
              href={`#${hit.id}`}
              onClick={(e) => {
                e.preventDefault();
                if (onSelect) onSelect(hit);
              }}
            >
              <Highlight hit={hit} attribute="name" />
            </a>
            <Header.Subheader>
              <Highlight hit={hit} attribute="id" />
            </Header.Subheader>

            <Header.Subheader>
              Created:&nbsp;
              {renderDate(hit?.createdAt, 'MM/DD/YYYY h:mm A')}
            </Header.Subheader>

            <Header.Subheader>
              <Icon.Group size="small">
                <Icon name="circle" color={onBoardingStatusIconColor} />
              </Icon.Group>
              {t(hit.onboardingStatusFilter)}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell>
        <Header as="h5">
          <Header.Content>
            {hit.primaryOwner.displayName}
            <Header.Subheader>{hit.primaryOwner.email}</Header.Subheader>
            <Header.Subheader>{hit.primaryOwner.uid}</Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell>
        <Header as="h5">
          <Header.Content>
            <Header.Subheader>
              <FastpayStatus enabled={fastpayEnabled} />
            </Header.Subheader>
            {fastpayEnabled && (
              <Header.Subheader>
                {`${t('Current balance')}: ${formatCurrency(getBalance(hit))}`}
              </Header.Subheader>
            )}
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell textAlign="right">
        <ImpersonationButton organization={hit} />
      </Table.Cell>
    </Table.Row>
  );
};

const OrganizationList = connectInfiniteHits(
  ({ hits, hasMore, refineNext }) => {
    const { t } = useTranslation();
    const [selectedOrganization, setSelectedOrganization] = useState(null);

    return (
      <>
        <Table stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="4">{t('Name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Primary Owner')}</Table.HeaderCell>
              <Table.HeaderCell>{t('FastPay')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {hits.map((hit) => (
              <HitRow
                key={hit.id}
                hit={hit}
                onSelect={() => setSelectedOrganization(hit)}
              />
            ))}
          </Table.Body>
        </Table>

        {!!selectedOrganization && (
          <OrganizationDetail
            organization={selectedOrganization}
            isOpen={!!selectedOrganization}
            onClose={() => setSelectedOrganization(null)}
          />
        )}
        {hasMore && (
          <Button primary size="big" disabled={!hasMore} onClick={refineNext}>
            {t('Show more')}
          </Button>
        )}
      </>
    );
  }
);

export default withPermission(OrganizationList, 'can_list_moxit_organizations');
