import { useEffect } from 'react';
import useStateRef from 'react-usestateref';

const TIMEOUT = 500;
const useRouteDebounce = (stringKey) => {
  const [isDebounced, setDebounced, isDebouncedRef] = useStateRef(false);

  /**
   * Because a lot of changes happen all at once when loading a route and the ordering
   * is not so easy to control as the app changes, we rely on a debounce effect to stabilize
   * the validation checks.
   */
  useEffect(() => {
    let timer;
    if (isDebouncedRef.current !== true) {
      timer = setTimeout(() => {
        if (isDebouncedRef.current !== true) {
          setDebounced(true);
        }
      }, TIMEOUT);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [isDebouncedRef, setDebounced, stringKey]);

  return { isDebounced: isDebounced === true };
};

export default useRouteDebounce;
