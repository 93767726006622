import { firestore, functions } from '.';
import {
  getDocumentsAsArray,
  startCollectionListener,
} from './firestore';

const paths = {
  location: (organizationId, locationId) =>
    `organizations/${organizationId}/locations/${locationId}`,
  locations: (organizationId) => `organizations/${organizationId}/locations`,
};

export const addOrganizationLocation = (
  organizationId,
  locationData,
  programType = null
) => {
  if (programType && programType === 'familyChildCare') {
    const locationId = `${organizationId}-primaryLocation`;

    return firestore()
      .collection(paths.locations(organizationId))
      .doc(locationId)
      .set(locationData);
  }

  return firestore()
    .collection(paths.locations(organizationId))
    .add(locationData);
};

export const updateOrganizationLocation = (
  organizationId,
  { id, ...locationData }
) => {
  return firestore()
    .doc(paths.location(organizationId, id))
    .update(locationData);
};

export function organizationLocationsOnListen(
  organizationId,
  next,
  error,
  complete
) {
  return startCollectionListener(
    {
      path: paths.locations(organizationId),
      orderBy: [{ field: 'name', direction: 'asc' }],
    },
    next,
    error,
    complete
  );
}

export const fetchOrganizationLocations = (organizationId, conditions=[]) => {
  return getDocumentsAsArray({
    path: paths.locations(organizationId),
    conditions,
    orderBy: [{ field: 'name', direction: 'asc' }],
  });
};
export const getPrimaryLocationDoc = async (organizationId) => {
  let querySnapshot = await firestore()
  .collection(paths.locations(organizationId))
  .where('primary', '==', true)
  .limit (1)
  .get();

  // If no primary location is found, return the first location
  if (querySnapshot.empty) {
    querySnapshot = await firestore()
      .collection(paths.locations(organizationId))
      .where('active', '==', true)
      .limit(1)
      .get();
  }
  return (!querySnapshot.empty ? querySnapshot.docs[0] : null);
}
export const getPrimaryLocation = async (organizationId) => {
  const locationDoc = await getPrimaryLocationDoc (organizationId);
  if (!locationDoc?.exists) return null;
  return  { id: locationDoc.id, ...locationDoc.data() };
}

export const updateOrganizationLocations = async (organizationId, locations) => {
  if (!locations?.length)
    return null;

  const locationsDocRef = firestore()
    .collection(paths.locations(organizationId))
    .doc();

  const batch = firestore().batch();

  for (const location of locations) {
    const {id, ...locationData} = location;
    if (id) {
      const locationDocRef = firestore().doc (paths.location(organizationId, id));
      batch.set (locationDocRef, locationData);
    } else {
      batch.set(locationsDocRef, locationData);
    }
  }
  batch.commit();
}


export async function removeDuplicates() {
  await functions().httpsCallable('callables-locations-removeDuplicates')();
}
