import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { DiscountAmountType } from './invoices';
import moment from 'moment';
import { isEmpty, pull } from 'lodash';
import accounting from 'accounting';
import { timezoneOptions } from '../config';
import fetchTimezoneWithGoogleApi from '../api/fetchTimezoneWithGoogleApi';

const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * Returns the capitalized version fo string
 * @param {string} string String to be capitalized
 * @returns Capitalized version of string e.g.: dog => Dog
 */
export const capitalize = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * TODO: Document your code!
 * @constructor
 * @param {string} phoneNumber - The phone number.
 * @param {string} code - The country code.
 */
export const phoneNumberFormat = (phoneNumber, code = 'US') =>
  phoneNumber
    ? phoneUtil.format(
      phoneUtil.parseAndKeepRawInput(phoneNumber, code),
      PhoneNumberFormat.E164
    )
    : '';

/**
 * TODO: Document your code!
 * @constructor
 * @param {string} phoneNumber - The phone number.
 * @param {string} code - The country code.
 */
export const phoneNumberParse = (phoneNumber, code = 'US') =>
  phoneNumber
    ? phoneUtil.format(
      phoneUtil.parseAndKeepRawInput(phoneNumber, code),
      PhoneNumberFormat.NATIONAL
    )
    : '';

/**
 * Represents a book.
 * @constructor
 * @param {string} bytes - The data url of the book.
 */
export const humanReadableFileSize = (bytes) => {
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  );
};

/**
 * TODO: Document your code!
 * @constructor
 * @param {string} dataUrl - The data url of the book.
 */
export const dataURLtoBlob = (dataUrl) => {
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

/**
 * TODO: Document your code!
 * @constructor
 * @param {blob} blob - The blob to convert.
 * @param {function} callback - The callback function.
 */
export const blobToDataURL = (blob, callback) => {
  var a = new FileReader();
  a.onload = function (e) {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
};

const currencyOptions = {
  symbol: '$',
  decimal: '.',
  thousand: ',',
  precision: 2,
};

/**
 * TODO: Document your code!
 * @constructor
 * @param {number} number - The number to format as currency.
 */
export const currencyFormatter = (number, options = {}) => {
  return number
    ? accounting.formatMoney(number, { ...currencyOptions, ...options })
    : accounting.formatMoney(0, { ...currencyOptions, ...options });
};

/**
 * TODO: Document your code!
 * @constructor
 * @param {string} invoices - The invoices.
 * @param {boolean} invoiceMode - The invoice.
 */
export const selectInvoices = (invoices, invoiceMode = true) =>
  Object.keys(invoices)
    .filter((key) => invoices[key].isInvoice === invoiceMode)
    .map((id) => {
      return {
        id: id,
        ...invoices[id],
      };
    });

/**
 * TODO: Document your code!
 * @constructor
 * @param {string} students - The students list.
 */

const totalShape = {
  first: 0,
  second: 0,
  third: 0,
  fourth: 0,
  fifth: 0,
  total: 0,
};

export const selectTotalByEmployee = (students) => {
  let result = {};
  let totalSummary = { ...totalShape };

  if (!isEmpty(students)) {
    for (let studentId in students) {
      const studentInvoices = students[studentId];

      if (result[studentId] === undefined) {
        result[studentId] = { ...totalShape, name: null };
      }

      for (let j in studentInvoices) {
        const targetDate = studentInvoices[j]?.dateDue;
        if (!targetDate) continue;

        const difference = moment().diff(moment(targetDate), 'days');

        if (difference < 7) {
          result[studentId].first += studentInvoices[j].total;
        } else if (difference >= 7 && difference < 14) {
          result[studentId].second += studentInvoices[j].total;
        } else if (difference >= 14 && difference <= 20) {
          result[studentId].third += studentInvoices[j].total;
        } else if (difference > 20 && difference <= 28) {
          result[studentId].fourth += studentInvoices[j].total;
        } else if (difference > 28) {
          result[studentId].fifth += studentInvoices[j].total;
        }

        result[studentId].total += studentInvoices[j].total;

        if (!result[studentId].displayName) {
          result[studentId].displayName =
            studentInvoices[j].student.displayName;
        }
      }

      totalSummary.first += result[studentId].first;
      totalSummary.second += result[studentId].second;
      totalSummary.third += result[studentId].third;
      totalSummary.fourth += result[studentId].fourth;
      totalSummary.fifth += result[studentId].fifth;
      totalSummary.total += result[studentId].total;
    }

    return {
      totalByEmployee: Object.keys(result).map((key) => {
        return {
          id: key,
          ...result[key],
        };
      }),
      totalSummary,
    };
  }
};

const defaultCurrencyOptions = {
  symbol: '$',
  decimal: '.',
  thousand: ',',
  precision: 2,
};

export const formatCurrency = (amount, options = {}) => {
  let formatted = '$0.00';
  const currencyOptions = { ...defaultCurrencyOptions, ...options };

  if (amount) {
    // accounting pkg formnats negative numbers like $ -XX.XX so we have to override
    if (Math.sign(amount) === -1) {
      formatted = `-${currencyOptions.symbol}${accounting.formatNumber(
        Math.abs(amount),
        options
      )}`;
    } else {
      formatted = accounting.formatMoney(amount, currencyOptions);
    }
  }
  return formatted;
};

export const parseCurrency = (currency) => {
  return accounting.parse(currency);
};

export const formatPercent = (amount, precision = 1) => {
  if (!amount) return '0%';

  const intAmount = Math.floor(amount);
  if (amount === intAmount) return `${intAmount}%`;
  else return amount.toFixed(precision) + '%';
};

export const getFormatter = (amountType) => {
  if (amountType === DiscountAmountType.PERCENT) return formatPercent;
  return formatCurrency;
};

/**
 * This function toggles array. If the value exists in an array
 * it removes it, if not, it adds it.
 * @constructor
 * @param {array} arr - An list.
 * @param {string} val - Value that needs to be checked.
 */

export const toggleArray = (arr, val) => {
  if (arr.length === pull(arr, val).length) {
    arr.push(val);
  }

  return arr;
};

/**
 * This function combines name fields into a single string. It assumes but doesn't require that the
 * object have some combination of firstName, middleName, and lastName.
 *
 * If all fields are empty, it'll return an empty string and warn in the console.
 *
 * @param {Object} person - person-like object
 * @param {string=} [person.firstName=''] - Person's first name
 * @param {string=} [person.middleName=''] - Person's middle name
 * @param {string=} [person.lastName=''] - Person's last name
 * @param {boolean=} [skipMiddle=false] - Ignore middleName
 */
export const formatFullName = (person, skipMiddle = false) => {
  const { firstName = '', middleName = '', lastName = '' } = person;
  const startingArr = skipMiddle
    ? [firstName, lastName]
    : [firstName, middleName, lastName];

  const nonEmpty = startingArr.filter((x) => x.length > 0);

  if (nonEmpty.length === 0) {
    console.warn('Tried to extract empty name from person: ', person);
    return '';
  }

  return nonEmpty.join(' ');
};

/**
 * Proper case function.
 *
 * @param {string} str - The string to convert.
 */
export const toProperCase = (str) => {
  if (isEmpty(str)) return '';

  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isObject = function (objectMaybe) {
  return (
    objectMaybe === Object(objectMaybe) &&
    !Array.isArray(objectMaybe) &&
    typeof objectMaybe !== 'function'
  );
};

export const toCamelCase = (inputString) => {
  return inputString.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const objectKeysToCamelCase = (object) => {
  if (isObject(object)) {
    const newObject = {};

    Object.keys(object).forEach((key) => {
      newObject[toCamelCase(key)] = objectKeysToCamelCase(object[key]);
    });

    return newObject;
  } else if (Array.isArray(object)) {
    return object.map((key) => objectKeysToCamelCase(key));
  }

  return object;
};

// see: https://stackoverflow.com/a/10600491
const pow = Math.pow,
  floor = Math.floor,
  abs = Math.abs,
  log = Math.log;
const abbrev = 'KMB';

const round = (n, precision) => {
  const prec = Math.pow(10, precision);
  return Math.round(n * prec) / prec;
};

export const getAgeFromBirthday = (birthday) => {
  if (birthday) {
    const totalMonths = moment().diff(birthday, 'months');
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    return { months, years};
  }
  return null;
};

export const formatNumberShorthand = (n) => {
  let base = floor(log(abs(n)) / log(1000));
  const suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? round(n / pow(1000, base), 2) + suffix : '' + n;
};

export const formatLocationPlaceObject = async (place) => {
  let stNumber;
  let stName;
  let city;
  let state;
  let zipcode;

  place.address_components.forEach(address_comp => {
    address_comp.types.forEach(type => {
      if (type === 'street_number') {
        stNumber = address_comp.long_name
      }

      if (type === 'route') {
        stName = address_comp.long_name
      }

      if (type === 'locality') {
        city = address_comp.long_name
      }

      if (type === 'administrative_area_level_1') {
        state = address_comp.short_name
      }

      if (type === 'postal_code') {
        zipcode = address_comp.long_name
      }
    })
  })

  const googleTzApiResponse = await fetchTimezoneWithGoogleApi({
    lat: place.geometry.location.lat(),
    long: place.geometry.location.lng(),
  })

  const reshaped = {
    formatted: {
      address1: stNumber + ' ' + stName,
      city: city,
      state: state,
      zipcode: zipcode,
      timezone: timezoneOptions.find(tz => tz.value === googleTzApiResponse?.timeZoneId) || null,
      geometry: place.geometry,
    },
    raw: place
  }

  return reshaped
}
