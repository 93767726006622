import { getEnv } from '../config/env';
import { firebaseConfig } from '../api/firebase/config';
import { auth } from '../api/firebase';

const FUNCTIONS_PATH =
  getEnv() === 'local'
    ? `http://localhost:5001/moxit-wonderschool-dev/us-central1`
    : `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`;

const useGenerateReport = (callback) => {
  const showFile = (blob) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    var newBlob = new Blob([blob], { type: 'application/pdf' });

    const data = window.URL.createObjectURL(newBlob);
    window.open(data, '_blank');
  };

  const generateReportPDF = async (data, options) => {
    try {
      const userToken = await auth().currentUser.getIdToken();

      const response = await fetch(
        `${FUNCTIONS_PATH}/endpoints-reports/reports/pdf`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({ data, options }),
        }
      );
      if (response.ok) {
        const pdf = await response.blob();
        showFile(pdf);
      }
    } catch (error) {
      console.log(error);
    } finally {
      callback();
    }
  };

  return generateReportPDF;
};

export default useGenerateReport;
