import {  useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

export function useListenerWithRedux (listenerStarter, dependencyArray=[]) {
  const unsubscribeListenerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!unsubscribeListenerRef.current) {
      const _unsubscribe = dispatch (listenerStarter);
      unsubscribeListenerRef.current = _unsubscribe;
    }
    return () => {
      if (unsubscribeListenerRef.current) {
        unsubscribeListenerRef.current();
        unsubscribeListenerRef.current = null;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyArray);
  //
  return unsubscribeListenerRef.current;
}
