import { useEffect, useRef } from 'react';
import { useSegment } from 'react-segment-hooks';

import { useAuthUser } from '../../hooks/useUser';
import { useOrganization } from '../../hooks/useOrganizations';

import { UNKNOWN_TEXT } from '../types';
import { callSegmentIdentify } from '../api';

export default function useSegmentIdentify() {
  const { currentUser } = useAuthUser();
  const organization = useOrganization();
  const segmentClient = useSegment();
  const userIdRef = useRef (currentUser?.uid);

  useEffect(() => {
    if (!currentUser?.uid || !organization?.id || (userIdRef.current === currentUser.uid))
      return;

    userIdRef.current = currentUser.uid;
    const data = {
      userId: currentUser.uid,
      traits: {
        email: currentUser.email,
        displayName: currentUser.displayName || UNKNOWN_TEXT,
        organizationId: organization.id,
        organizationName: organization.name || UNKNOWN_TEXT,
      },
    }
    callSegmentIdentify (segmentClient, data);
  }, [segmentClient, currentUser, organization]);
}
