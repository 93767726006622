import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Fuse from 'fuse.js';
import { Form, Grid, Loader, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import { startOrganizationStudentsListener } from '../../../../students/studentsRedux';
import { updateSelectedEmployees } from '../../../../redux/actions/billingActions';

// Import components
import { routes } from '../../../../config/routes';
import PageHeader from '../../../Shared/PageHeader';
import PageTitle from '../../../Shared/PageTitle';
import { Steps } from '../Sidebar/Steps';
import SelectEmployees from './SelectEmployees';
import { EmployeeWidget } from '../Sidebar/EmployeeWidget';
import { DetailsWidget } from '../Sidebar/DetailsWidget';

class SelectEmployeesContainer extends React.Component {
  static propTypes = {
    paymentDetails: PropTypes.object,
    employeesList: PropTypes.array,

    currentOrganization: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    students: PropTypes.shape({
      selectedStudent: PropTypes.shape({
        id: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      ),
    }),
  };

  state = {
    employeesList: [],
    selectedEmployees: [],
    loaded: false,
    roomsList: [
      // { key: 'Room A', text: 'Room A', value: 'Room A' },
    ],
    room: '',
    searchTerm: '',
  };

  componentDidMount() {
    this.startListeners();

    let { paymentEmployees } = this.props;
    if (paymentEmployees) {
      this.setState({ selectedEmployees: paymentEmployees });
    }
    if (!this.state.employeesList && this.props.employeesList.length > 0) {
      this.setState({ employeesList: this.props.employeesList });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      students: { list },
    } = this.props;

    // if (!this.state.employeesList && this.props.employeesList) {
    //   this.setState({ employeesList: this.props.employeesList });
    // }

    if (!this.state.loaded && !isEmpty(list))
      this.setState({ employeesList: list, loaded: true });
  }

  renderPageHeader = () => (
    <PageHeader
      pageName={'Billing'}
      classes="billing"
    />
  );

  startListeners = () => {
    const {
      currentOrganization: { id },
    } = this.props;

    if (id) {
      this.unsubscribeListeners();
      this.unSubscribeOrganizationStudentsListener =
        this.props.startOrganizationStudentsListener(id);
    }
  };

  unsubscribeListeners = () => {
    if (this.unSubscribeOrganizationStudentsListener)
      this.unSubscribeOrganizationStudentsListener();
  };

  handleRoomChange = (e, { value }) => {
    this.setState({ room: value }, () => {
      // redo search with room
      this.handleSearch(null, {
        value: this.state.searchTerm,
      });
    });
  };

  handleSearch = (e, { value }) => {
    const searchTerm = value;
    let students = this.props.students.list;
    const room = this.state.room;
    if (room?.length) {
      students = students.filter((s) => {
        return (s.rooms || []).includes(room);
      });
    }
    const fuse = new Fuse(students, {
      includeScore: true,
      threshold: 0.35,
      keys: ['displayName', 'firstName', 'middleName', 'lastName', 'fullName'],
    });
    const results = searchTerm.length
      ? fuse.search(searchTerm).map((s) => s.item)
      : students;
    this.setState({
      searchTerm,
      employeesList: results,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.selectedEmployees.length > 0) {
      this.props.updateSelectedEmployees({
        selectedEmployees: this.state.selectedEmployees,
      });
      this.props.history.push(routes.billing.confirm);
    }
  };

  handleSelect = (employee, selectAll = false) => {
    if (!selectAll) {
      this.setState((state) => {
        let { selectedEmployees } = state;
        let index = selectedEmployees.map((e) => e.id).indexOf(employee.id);
        return {
          selectedEmployees:
            index === -1
              ? [...selectedEmployees, employee]
              : selectedEmployees.filter((_, i) => i !== index),
        };
      });
    } else {
      this.setState((state) => {
        const { selectedEmployees, employeesList } = state;
        const validStudents = employeesList.filter(({ preventInvoiceReason }) => !preventInvoiceReason);
        return {
          selectedEmployees:
            selectedEmployees.length !== validStudents.length
              ? validStudents
              : [],
        };
      });
    }
  };

  handleRemove = (index) => {
    this.setState((state) => ({
      selectedEmployees: state.selectedEmployees.filter((_, i) => i !== index),
    }));
  };

  render() {
    const { employeesList, room, roomsList, selectedEmployees, loaded } = this.state;
    const { paymentDetails, t } = this.props;

    if (!loaded) return <Loader active />;

    return (
      <Segment basic>
        {this.renderPageHeader()}

        <Form onSubmit={this.handleSubmit}>
          <Grid stackable reversed={'computer tablet'}>
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <PageTitle title={t('Select Students')} />
            </Grid.Column>

            <Grid.Row columns={2}>
              <Grid.Column computer={4} mobile={16} tablet={4}>
                <Steps />
                <EmployeeWidget
                  onRemove={this.handleRemove}
                  selectedEmployees={selectedEmployees}
                />
                {paymentDetails && (
                  <DetailsWidget
                    invoiceSummary={paymentDetails.invoiceSummary}
                  />
                )}
              </Grid.Column>
              <Grid.Column
                computer={12}
                mobile={16}
                tablet={12}
                className={'leftCol'}
              >
                {employeesList && (
                  <SelectEmployees
                    roomsList={roomsList}
                    room={room}
                    onRoomChange={this.handleRoomChange}
                    onSelect={this.handleSelect}
                    onSearch={this.handleSearch}
                    selectedEmployees={selectedEmployees}
                    employeesList={employeesList}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentDetails: state.paymentDetails,
    paymentEmployees: state.paymentEmployees,
    employeesList: state.employeesList,

    students: state.students,
    currentOrganization: state.organizations.currentOrganization,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateSelectedEmployees,

      startOrganizationStudentsListener,
    },
    dispatch
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SelectEmployeesContainer)
);
