import {
  organizationInvoicesOnListen,
  createOrganizationInvoice,
  fetchOrganizationInvoice,
  fetchOrganizationInvoices,
  fetchOrganizationInvoicePlans,
  removeOrganizationInvoice,
  setOrganizationInvoicePlan,
  createOrganizationInvoicePlan,
} from '../../api/firebase/invoices';

import {
  INVOICES_FETCHED,
  INVOICES_LISTENER_STARTED,
  INVOICE_REFRESHED,
  INVOICE_PLANS_FETCHED,
  INVOICE_SELECTION_CLEARED,
  INVOICE_SET,
  INVOICE_SELECTED,
  INVOICE_UPDATED,
  INVOICE_ADDED,
  INVOICE_ADDED_TO_LIST,
  INVOICE_REMOVE_FROM_LIST,
} from './types';

// Import billing actions
import { resetPaymentData as resetPaymentDataAction} from './billingActions';
import { userHasPermission } from '../../api/firebase/account';

export const invoicesListenerStarted = () => ({
  type: INVOICES_LISTENER_STARTED,
});

export const InvoiceType = {
  ONE_TIME: 'oneTime',
  RECURRING: 'recurring',
  ALL: 'all',
}
export const invoicesFetched = (invoices, invoiceType) => ({
  type: INVOICES_FETCHED,
  invoices,
  invoiceType,
});

export const invoicePlansFetched = (invoices) => ({
  type: INVOICE_PLANS_FETCHED,
  invoices,
  invoiceType: InvoiceType.RECURRING,
});

export const invoiceRefreshed = (invoice, invoiceType) => ({
  type: INVOICE_REFRESHED,
  invoice,
  invoiceType,
});

export const invoiceAdded = () => ({
  type: INVOICE_ADDED,
});

export const invoiceSet = () => ({
  type: INVOICE_SET,
});

export const invoiceUpdated = (invoice, invoiceType) => ({
  type: INVOICE_UPDATED,
  invoice,
  invoiceType,
});

export const invoiceSelected = (invoice, invoiceType) => {
  return {
    type: INVOICE_SELECTED,
    invoice,
    invoiceType,
  };
};

export const invoiceSelectionCleared = () => ({
  type: INVOICE_SELECTION_CLEARED,
});

export const invoiceAddedToList = (invoice) => ({
  type: INVOICE_ADDED_TO_LIST,
  invoice,
});

export const invoiceRemovedFromList = (id) => ({
  type: INVOICE_REMOVE_FROM_LIST,
  id,
});

export const getOrganizationInvoices = (
  organizationId,
  conditions,
  orderBy = [],
  limit,
  last,
  invoiceType
) => async (dispatch) => {
  try {
    const invoicesData = await fetchOrganizationInvoices(
      organizationId,
      conditions,
      orderBy,
      limit,
      last
    );

    // We are passing the invoice type is because we have three type of invoices
    // we want to fetch for: everything, one-off and recurring.
    return dispatch(invoicesFetched(invoicesData, invoiceType));
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const getOrganizationInvoicePlans = (
  organizationId,
  conditions,
  orderBy = [],
  limit,
  last
) => async (dispatch) => {
  try {
    const invoicesData = await fetchOrganizationInvoicePlans(
      organizationId,
      conditions,
      orderBy,
      limit,
      last
    );

    return dispatch(invoicePlansFetched(invoicesData));
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const startOrganizationInvoicesListener = (organizationId, locations) => (
  dispatch
) => {
  if (!userHasPermission ('can_list_billing'))
  return null;

  dispatch(invoicesListenerStarted());
  
  return organizationInvoicesOnListen(
    { organizationId, locations },
    (data) => {
      if (data) dispatch(invoicesFetched(data, "all"));
    },
    (error) => console.log(error.message)
  );
};

export const resetPaymentData = () => async (
  dispatch
) => {
  try {
    // Clear out the billing store
    return dispatch(resetPaymentDataAction());
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};
export const newOrganizationInvoice = (organizationId, payload) => async (
  dispatch
) => {
  try {
    // Save the data
    await createOrganizationInvoice(organizationId, payload);

    // Clear out the billing store
    dispatch(resetPaymentData());

    return dispatch(invoiceAdded());
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const saveOrganizationInvoicePlan = (organizationId, payload) => async (
  dispatch
) => {
  try {
    if (payload.id) {
      await setOrganizationInvoicePlan(organizationId, payload);
    } else {
      await createOrganizationInvoicePlan(organizationId, payload);
    }
    return dispatch(getOrganizationInvoicePlans(organizationId));
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const deleteOrganizationInvoice = (organizationId, invoiceId) => (
  dispatch
) => {
  try {
    // Immediately remove from Redux store.
    dispatch(invoiceRemovedFromList(invoiceId));
    return removeOrganizationInvoice(organizationId, invoiceId);
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const refreshOrganizationInvoice = (
  organizationId,
  invoiceId,
  invoiceType
) => async (dispatch) => {
  try {
    const invoice = await fetchOrganizationInvoice(
      organizationId,
      invoiceId
    );
    return dispatch(invoiceRefreshed(invoice, invoiceType));
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};
