import {
  setDocument,
  getDocuments,
  startCollectionListener,
} from './firestore';

import uuid from 'uuid/v4';

const paths = {
  charge: (organizationId, chargeId) =>
    `organizations/${organizationId}/charges/${chargeId}`,
  charges: (organizationId) => `organizations/${organizationId}/charges`,
};

export async function fetchCharges(
  organizationId,
  conditions,
  orderBy = [],
  limit = null,
  startAfter = null
) {
  return getDocuments({
    path: paths.charges(organizationId),
    conditions,
    orderBy: [{ field: 'name', direction: 'asc' }, ...orderBy],
    limit,
    startAfter,
  });
}

export function chargesOnListen(organizationId, next, error, complete) {
  return startCollectionListener(
    {
      path: paths.charges(organizationId),
      orderBy: [{ field: 'category', direction: 'asc' }],
    },
    next,
    error,
    complete
  );
}

export async function saveCharge(organizationId, charge) {
  if (!charge.id) charge.id = uuid();

  await setDocument({
    path: paths.charge(organizationId, charge.id),
    data: charge,
  });
  return charge;
}
