import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Accordion,
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Modal,
  Segment,
  Table,
} from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';

// Import config
import routeList from '../Routes';

// Import component
import { updateMoxitOrganization } from '../../redux/actions/organizationActions';
import {
  fetchOrganizationLocations,
  updateOrganizationLocations,
} from '../../api/firebase/locations';
import {
  getOrSetFastpay,
  ORGANIZATION_MAX_BALANCE,
  ORGANIZATION_MAX_TRANSACTION_AMOUNT,
} from '../../helpers/fastpay';

import { initOrganizationPreCheck } from '../../helpers/preCheck';
import { areStripePaymentsEnabledForOrganization } from '../../helpers/stripe';
import FastpaySetup from '../Admin/FastpaySetup';
import PreCheckSetup from '../Admin/PreCheckSetup';

import NetworkEditModal from './NetworkEditModal';

const OrganizationDetail = ({ organization, isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isNetworkModalOpen, setIsNetworkModalOpen] = useState(false);
  const [features, setFeatures] = useState([]);
  const [fastpayData, setFastpayData] = useState(null);
  const [preCheckData, setPreCheckData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const [paymentsEnabled, setPaymentsEnabled] = useState(null);
  const [locations, setLocations] = useState([]);
  const [locationsToSave, setLocationsToSave] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  initializeState();

  useEffect(() => {
    if (!organization) return;

    const _features = createFeatures(organization);
    setFeatures(_features);

    fetchOrganizationLocations(organization.id).then((locations) =>
      setLocations(locations)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  function createFeatures(organization) {
    const organizationFeatures = {};
    organization.features?.forEach((feature) => {
      organizationFeatures[feature.name] = feature.enabled;
    });

    const _features = [];
    routeList?.forEach(({ name, enabled, hasMenu, permission }) => {
      if (hasMenu && permission !== 'can_list_moxit_organizations') {
        if (organizationFeatures.hasOwnProperty(name)) {
          _features.push({ name, enabled: organizationFeatures[name] });
        } else {
          _features.push({ name, enabled });
        }
      }
    });
    return _features;
  }

  const onFeatureChange = (e, { name, checked }) => {
    const _features = features.map((f) => {
      if (f.name === name) return { name, enabled: checked };
      return f;
    });
    setFeatures(_features);
  };

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  const onPaymentsEnabledChange = (e, { checked }) => {
    setPaymentsEnabled(checked);
  };

  const renderForm = () => {
    const featureToggles =
      features &&
      features.map((feature, index) => (
        <Form.Radio
          toggle
          key={index}
          id={feature.name}
          name={feature.name}
          label={feature.name}
          checked={feature.enabled}
          onChange={onFeatureChange}
          control={Checkbox}
        />
      ));

    return (
      <>
        <Form onSubmit={onSubmit}>
          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={handleAccordionClick}
            >
              <Icon name="dropdown" />
              {t('Locations')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <LocationsNetworks />
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={handleAccordionClick}
            >
              <Icon name="dropdown" />
              {t('Features')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <Form.Field>{featureToggles}</Form.Field>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 3}
              index={3}
              onClick={handleAccordionClick}
            >
              <Icon name="dropdown" />
              {t('Pre-check Questionnaire')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              <PreCheckSetup
                preCheckData={preCheckData}
                onPreCheckDataChange={setPreCheckData}
              />
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 4}
              index={4}
              onClick={handleAccordionClick}
            >
              <Icon name="dropdown" />
              {t('Stripe Payments')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              <Form.Radio
                toggle
                id="paymentsEnabled"
                name="paymentsEnabled"
                label={t('Allow Stripe Payments')}
                checked={paymentsEnabled}
                onChange={onPaymentsEnabledChange}
                control={Checkbox}
              />
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 5}
              index={5}
              onClick={handleAccordionClick}
            >
              <Icon name="dropdown" />
              {t('FastPay')}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>
              <FastpaySetup
                fastpayData={fastpayData}
                onFastpayDataChange={setFastpayData}
                maxBalanceDefault={ORGANIZATION_MAX_BALANCE}
                maxTransactionAmountDefault={
                  ORGANIZATION_MAX_TRANSACTION_AMOUNT
                }
              />
            </Accordion.Content>
          </Accordion>
          <br />
          <Button primary type="submit" loading={loading} content={t('Save')} />
          <Button
            basic
            loading={loading}
            content={t('Cancel')}
            onClick={(e) => {
              e.preventDefault();
              if (onClose) onClose(e);
            }}
          />
        </Form>
      </>
    );
  };
  function LocationsNetworks() {
    if (!locations?.count) {
      return (
        <>
          <Header as="h4">{t('Locations')}</Header>
          <p>{t('No locations found')}</p>
        </>
      );
    }
    return (
      <>
        {locations?.list.map((location) => {
          return (
            <Segment basic key={location.id}>
              <Header as="h5">
                {location.name}
                <Button
                  basic
                  color="blue"
                  size="mini"
                  compact
                  floated="right"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickAddNetwork(location);
                  }}
                >
                  <Icon name="add" /> {t('Add Network')}
                </Button>
              </Header>
              {location.networks?.length ? (
                <NetworksTable location={location} />
              ) : null}
            </Segment>
          );
        })}
      </>
    );
  }

  function NetworksTable({ location }) {
    const { networks } = location;
    return (
      <Table compact basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={8}>{t('Network')}</Table.HeaderCell>
            <Table.HeaderCell width={6}>{t('Geo Area')}</Table.HeaderCell>
            <Table.HeaderCell width={2}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {networks.map((network) => {
            const geoArea = network.geoData?.join(', ') ?? '';
            return (
              <Table.Row key={network.id}>
                <Table.Cell>{network.name}</Table.Cell>
                <Table.Cell>{geoArea}</Table.Cell>

                <Table.Cell textAlign="right" singleLine>
                  <Button
                    basic
                    compact
                    key="#delete-row"
                    icon="trash"
                    onClick={(e) => {
                      e.preventDefault();
                      onClickDeleteNetwork(location, network);
                    }}
                  />

                  <Button
                    basic
                    compact
                    key="#edit-row"
                    icon="pencil"
                    onClick={(e) => {
                      e.preventDefault();
                      onClickEditNetwork(location, network);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  return (
    <>
      <Modal
        size="small"
        closeIcon
        centered={false}
        onClose={onCloseLocal}
        open={isOpen}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>{`${organization?.name} (${organization?.id})`}</Modal.Header>
        <Modal.Content>{renderForm()}</Modal.Content>
      </Modal>
      <NetworkEditModal
        organization={organization}
        location={selectedLocation}
        network={selectedNetwork}
        isOpen={isNetworkModalOpen}
        onClose={() => {
          setSelectedLocation(null);
          setSelectedNetwork(null);
          setIsNetworkModalOpen(false);
        }}
        onSave={onSaveNetwork}
      />
    </>
  );
  async function onSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const stripeData = organization.stripe ?? {};
      const organizationData = {
        id: organization.id,
        features,
        ...fastpayData,
        ...preCheckData,
        stripe: {
          ...stripeData,
          paymentsEnabled,
        },
      };
      await updateOrganizationLocations(
        organization.id,
        Object.values(locationsToSave)
      );
      await dispatch(updateMoxitOrganization(organizationData));
      Object.assign(organization, organizationData);

      if (onClose) onClose(e);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function onSaveNetwork(network) {
    setIsNetworkModalOpen(false);

    if (!selectedLocation) return;

    if (!selectedLocation.networks) selectedLocation.networks = [];

    const index = selectedLocation.networks.findIndex(
      (n) => n.id === network.id
    );
    if (index > -1) {
      selectedLocation.networks[index] = network;
    } else {
      selectedLocation.networks.push(network);
    }
    setLocationsToSave({
      ...locationsToSave,
      [selectedLocation.id]: selectedLocation,
    });
    setSelectedLocation(null);
    setSelectedNetwork(null);
    setLocations({ ...locations }); // force re-render
  }
  function onCloseLocal(e) {
    e.preventDefault();
    if (onClose) onClose(e);
  }

  function onClickAddNetwork(location) {
    setSelectedLocation(location);
    setIsNetworkModalOpen(true);
  }

  function onClickDeleteNetwork(location, network) {
    const index = location.networks?.findIndex((n) => n.id === network.id);
    if (index > -1) {
      location.networks.splice(index, 1);
      setLocations({ ...locations }); // force re-render
      setLocationsToSave({ ...locationsToSave, [location.id]: location });
    }
  }
  function onClickEditNetwork(location, network) {
    setSelectedLocation(location);
    setSelectedNetwork(network);
    setIsNetworkModalOpen(true);
  }
  function initializeState() {
    if (!fastpayData && organization) {
      setFastpayData({fastpay: getOrSetFastpay(organization)});
    }
    if (!preCheckData && organization) {
      setPreCheckData(initOrganizationPreCheck(organization));
    }
    if (paymentsEnabled === null && organization) {
      setPaymentsEnabled(areStripePaymentsEnabledForOrganization(organization));
    }
  }
};

export default OrganizationDetail;
