import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch } from 'react-redux';
import { Button, Form, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { storage } from '../../../api/firebase';
import { deleteFile, updateDocument } from '../../../api/firebase/firestore';
// Import utils
import { InlineError, ShowError } from '../../../Components/Messages';
import {
  formatStringAsUtcMillisOrNull,
  formatUtcMillisAsString,
} from '../../../helpers/dates';
import { humanReadableFileSize } from '../../../helpers/utils';
import { organizationUpdateStudent } from '../../studentsRedux';
// Import components
import { DatePicker } from '../../../Components/Shared/DatePicker';
// Import components.
import MimeIcon from '../../../Components/Shared/MimeIcon';

import { useTranslation } from 'react-i18next';
import { useOrganization } from '../../../hooks/useOrganizations';
import { useSelectedStudent } from '../../studentsHooks';

const StudentDocumentList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const selectedStudent = useSelectedStudent();
  const currentOrganization = useOrganization();
  const documents = useMemo(
    () => selectedStudent?.documents || [],
    [selectedStudent?.documents]
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [data, setData] = useState({
    id: '',
    name: '',
    expirationDate: null,
    tags: [],
  });
  const [rowData, setRowData] = useState({});
  const [errors, setErrors] = useState({});
  const [tagOptions, setTagOptions] = useState([]);

  const handleAddition = (e, { value }) => {
    setTagOptions((prev) => [{ text: value, value }, ...prev]);
  };

  const handleChange = (_e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleExpirationDate = (e, { name, value, valid }) => {
    setData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => (valid ? _.omit(prev, name) : prev));
  };

  const saveChanges = async () => {
    if (!currentOrganization?.id) return;

    const { documentId, name, expirationDate, tags } = data;

    if (selectedStudent?.id) {
      const studentData = {
        ...selectedStudent,
        documents: {
          ...selectedStudent.documents,
          [documentId]: {
            ...rowData,
            documentId,
            name,
            expirationDate: formatStringAsUtcMillisOrNull(expirationDate),
            tags,
          },
        },
      };

      // Dispatch is since this is an action creator.
      dispatch(organizationUpdateStudent(currentOrganization.id, studentData));

      setTagOptions([]);
      setData({
        id: '',
        name: '',
        expirationDate: null,
        tags: [],
      });
    }
  };

  const removeDocument = (refId) => {
    if (!currentOrganization?.id) return;

    if (selectedStudent?.id) {
      const updatedDocuments = _.omit(selectedStudent?.documents, [refId]);

      // TODO: Create action creator.
      return updateDocument({
        path: `organizations/${currentOrganization.id}/students/${selectedStudent.id}`,
        data: { documents: updatedDocuments },
      });
    }
  };

  const renderFileForm = () => {
    return (
      <Segment basic clearing>
        <ShowError errors={errors} />
        <Form>
          <Form.Input
            name="name"
            label={t('Name')}
            fluid
            value={data?.name}
            onChange={handleChange}
            error={!!errors.name}
          >
            {errors.name && <InlineError text={errors.name} />}
          </Form.Input>

          <Form.Field>
            <DatePicker
              id="expirationDate"
              name="expirationDate"
              value={data?.expirationDate}
              onChange={handleExpirationDate}
              label={t('Expiration Date')}
              placeholder={t('Select Expiration Date')}
              minDate={moment()}
              error={!!errors.expirationDate}
              closable
            >
              {errors.expirationDate && (
                <InlineError text={errors.expirationDate} />
              )}
            </DatePicker>
          </Form.Field>

          <Form.Dropdown
            name="tags"
            label={t('Tags')}
            search
            selection
            fluid
            multiple
            allowAdditions
            value={data?.tags || []}
            options={tagOptions}
            onAddItem={handleAddition}
            onChange={handleChange}
            error={!!errors.tags}
          >
            {errors.tags && <InlineError text={errors.tags} />}
          </Form.Dropdown>
        </Form>
      </Segment>
    );
  };

  const renderNoData = () => {
    return (
      <Segment basic clearing textAlign="center">
        <Header as={'h3'}>
          <Icon circular inverted name="info" color="grey" />
        </Header>
        <Header as={'h4'}>{t('No files found.')}</Header>
        <p className="text-muted">
          {t('You can upload files by clicking the upload button.')}
        </p>
      </Segment>
    );
  };

  const openFile = async (row) => {
    const { path = null, documentId = null } = row?.rowData;
    if (!path || !documentId) return;
    try {
      // Get a valid URL with token.
      const url = await storage().ref(`${path}/${documentId}`).getDownloadURL();

      // Open URL in a new tab.
      if (url?.length) window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  };

  const renderTable = () => {
    const columns = [
      {
        text: '',
        dataField: 'documentId',
        hidden: true,
      },
      {
        text: 'Name',
        dataField: 'name',
        sort: true,
        formatter: (cell, row, rowIndex) => (
          <div>
            <Header
              key={rowIndex}
              as="h4"
              className="no-margin"
              icon={row.type ? <MimeIcon mime={row.type} /> : null}
              content={
                <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    rel="noopener noreferrer"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openFile(row);
                    }}
                  >
                    {cell}
                  </a>
                  {row.size && (
                    <em className="text-muted">{` (${row.size})`}</em>
                  )}
                </>
              }
            />
            <div style={{ margin: '5px 0' }}>
              <div
                className="edited-by text-muted"
                style={{ fontSize: '12px' }}
              >
                {`Edited by: ${row.addedBy}`}
              </div>
              <div
                className="uploaded-date text-muted"
                style={{ fontSize: '12px' }}
              >
                {`Uploaded date: ${row.uploadedDate}`}
              </div>
            </div>
          </div>
        ),
        headerStyle: () => {
          return { width: '45%' };
        },
        style: { verticalAlign: 'middle' },
      },
      {
        text: 'Tags',
        dataField: 'tags',
        style: { verticalAlign: 'middle' },
        formatter: (cell) => cell.join(', '),
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        text: 'Expiration',
        dataField: 'expirationDate',
        style: { verticalAlign: 'middle' },
        formatter: (cell) => {
          return formatUtcMillisAsString(cell);
        },
      },
      {
        text: '',
        dataField: '',
        key: 'edit-button',
        formatter: (_cell, row) => (
          <div>
            <Button
              circular
              icon="edit outline"
              color="green"
              onClick={() => {
                if (documents && row?.documentId) {
                  const selectedDocument = documents[row.documentId];
                  setModalOpen(true);
                  setRowData(selectedDocument);
                  setTagOptions(
                    selectedDocument?.tags?.length
                      ? selectedDocument.tags.map((tag) => ({
                          text: tag,
                          value: tag,
                        }))
                      : []
                  );
                  setData({
                    documentId: selectedDocument.documentId,
                    name: selectedDocument.name,
                    tags: selectedDocument.tags,
                    expirationDate: formatUtcMillisAsString(
                      selectedDocument.expirationDate
                    ),
                  });
                }
              }}
            />
            <Button
              circular
              icon="trash alternate outline"
              color="red"
              onClick={() => {
                setOpenConfirmationDialog(true);
                setDocumentToDelete(row);
              }}
            />
          </div>
        ),
        style: { verticalAlign: 'middle' },
        align: 'right',
        headerStyle: () => {
          return { width: '20%' };
        },
      },
    ];

    const tableData = documents
      ? Object.keys(documents).map((key) => ({
          documentId: documents[key].documentId,
          name: documents[key].name,
          addedBy: documents[key]?.uploadedBy?.name || 'UNKNOWN',
          type: documents[key].type,
          uploadedDate: formatUtcMillisAsString(
            documents[key].uploadedDate,
            'YYYY-MM-DD - h:m A'
          ),
          expirationDate: documents[key].expirationDate || null,
          downloadUrl: documents[key].downloadUrl,
          path: documents[key].path,
          refId: documents[key]?.meta?.refId || '',
          size: humanReadableFileSize(documents[key].size) || '',
          tags: documents[key].tags || [],
          rowData: documents[key],
        }))
      : [];

    return tableData?.length ? (
      <div className="bootstrap-iso">
        <BootstrapTable
          classes="w-auto w-md-100"
          wrapperClasses="table-responsive"
          noDataIndication="no results to display"
          keyField="documentId"
          bordered={false}
          data={tableData}
          columns={columns}
        />
      </div>
    ) : (
      renderNoData()
    );
  };

  const renderConfirmationDialog = () => (
    <Modal
      size="mini"
      open={openConfirmationDialog}
      onClose={() => setOpenConfirmationDialog(false)}
    >
      <Modal.Header>{t('Delete Document')}</Modal.Header>
      <Modal.Content>
        <p>{t('Are you sure you want to delete this document?')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button>No</Button>
        <Button
          negative
          icon="checkmark"
          labelPosition="right"
          content={t('Yes')}
          loading={deleting}
          onClick={async () => {
            const { path, refId } = documentToDelete;
            if (path && refId) {
              try {
                setDeleting(true);
                await removeDocument(refId);
                await deleteFile({ path, refId });
                setDocumentToDelete({});
                setOpenConfirmationDialog(false);
                setDeleting(false);
              } catch (error) {
                console.log(error);
                setDocumentToDelete({});
                setOpenConfirmationDialog(false);
                setDeleting(false);
                setErrors((prev) => ({ ...prev, error }));
              }
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  );

  const renderEditModal = () => (
    <Modal
      style={{ overflow: 'visible' }}
      key="documents-modal"
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      header={t('Edit Student Document')}
      content={renderFileForm()}
      actions={[
        'Cancel',
        {
          key: 'done',
          content: 'Update',
          primary: true,
          onClick: () => {
            saveChanges();
          },
        },
      ]}
    />
  );

  return (
    <div>
      {renderEditModal()}
      {renderConfirmationDialog()}
      {renderTable()}
    </div>
  );
};

export default StudentDocumentList;
