import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// Import components
import { routes } from '../../../../config/routes';
import PageHeader from '../../../Shared/PageHeader';
import PageTitle from '../../../Shared/PageTitle';

import { InvoiceContainer } from './Invoice';
import { resetPaymentData } from '../../../../redux/actions/billingActions';
import { Steps } from '../Sidebar/Steps';

class DetailsContainer extends React.Component {
  static propTypes = {
    paymentDetails: PropTypes.object,
  };

  state = {
    isInvoice: true,
    fireSubmit: false,
  };

  componentDidMount() {
    if (this.props.paymentDetails) {
      this.setState({ ...this.props.paymentDetails });
    }
    if (this.props.location?.hash === routes.billing.detailsRecurringHash) {
      this.setState({
        isInvoice: false,
      });
    }
  }

  renderPageHeader = () => (
    <PageHeader
      pageName={'Billing'}
      classes="billing"
    />
  );


  handleSubmit = () => {
    this.setState({ fireSubmit: true }, () => {
      //reset submit state after passing down to child
      this.setState({ fireSubmit: false });
    });
  };
  getIsInvoice () {
    return this.props.isInvoice ?? this.state.isInvoice ?? this.props.paymentDetails?.isInvoice ?? true;
  }
  render() {
    const isInvoice = this.getIsInvoice();
    const { t } = this.props;

    return (
      <Segment basic>
        {this.renderPageHeader()}

        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <Grid stackable reversed={'computer tablet'}>
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <PageTitle
                title={isInvoice ? t('One-time invoice') : t('Recurring plan')}
              />
            </Grid.Column>

            <Grid.Row columns={2}>
              <Grid.Column computer={4} mobile={16} tablet={4}>
                <Steps isInvoice={isInvoice} />
              </Grid.Column>
              <Grid.Column
                computer={12}
                mobile={16}
                tablet={12}
                className={'leftCol'}
              >
                {isInvoice ? (
                  <InvoiceContainer fireSubmit={this.state.fireSubmit} />
                ) : (
                  <></>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentDetails: state.paymentDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetPaymentData,
    },
    dispatch
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DetailsContainer)
);
