import {
  useDownloadFiles,
  useUploadFile,
} from '@wonderschool/file-service-client';
import { useEffect, useState } from 'react';

// ui lib components
import { Grid, Menu } from 'semantic-ui-react';

// components
import DocumentUploader from '../../Components/Upload/DocumentUploader';
import FormsAndDocumentsActionButtons from './FormsAndDocumentsActionButtons';
import FormsAndDocumentsTable from './FormsAndDocumentsTable';
import FileShareModal from './modals/FileShareModal';
import RenameFileModal from './modals/RenameFileModal';
import UploadProgressModal from './modals/UploadProgressModal';

// hooks
import { useTranslation } from 'react-i18next';
import { useOrganization } from '../../hooks/useOrganizations';

// util stuff
import { showErrorToast } from '../../Components/Shared/showToast';
import {
  allowedMimeTypes,
  FILE_ACTIONS_CONSTS,
  UPLOAD_STATE_CONSTS,
} from '../dictionary';

const FormsAndDocumentsMain = () => {
  const { t } = useTranslation();
  const { uploadFile, error: uploadError } = useUploadFile();
  const { downloadFiles, error: downloadError } = useDownloadFiles();
  const currentOrganizationId = useOrganization();

  const [showArchived, setShowArchived] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [uploadState, setUploadState] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [openModalControl, setOpenModalControl] = useState(null);

  const handleModalStates = (modalKey, isOpen) => {
    setOpenModalControl(isOpen ? modalKey : null);
  };

  // upload stuff
  const handleFileSelectedForUpload = (file) => {
    setFileToUpload(file?.data);
  };

  // Show error toast if upload or download fails.
  useEffect(() => {
    if (!uploadError && !downloadError) return;

    const title = !!uploadError
      ? t('Error occurred while uploading file')
      : !!downloadError
      ? t('Error occurred while downloading file')
      : '';
    const message = !!uploadError
      ? uploadError.message
      : !!downloadError
      ? downloadError.message
      : 'Unknown error occurred';

    showErrorToast(title, message);
  }, [uploadError, downloadError, t]);

  const handleFileSubmitted = () => {
    // set the modal content
    setUploadState(UPLOAD_STATE_CONSTS.uploading);
    // open progress modal
    handleModalStates(FILE_ACTIONS_CONSTS.upload, true);

    try {
      // start the request
      uploadFile({
        file: fileToUpload,
        options: {
          fileAction: 'downloadable',
          uploadPath: `/organizations/${currentOrganizationId}`,
        },
      });

      // close the modal
      handleModalStates(FILE_ACTIONS_CONSTS.upload, false);
    } catch (error) {
      // show the error modal state
      setUploadState(UPLOAD_STATE_CONSTS.error);
    }

    return null;
  };

  // current docs stuff
  const handleSelectedDocuments = (selectedDocuments) => {
    setSelectedDocuments(selectedDocuments);
  };

  const handleFileActionClicked = (fileAction) => {
    handleModalStates(fileAction, true);

    // Triggers file download.
    if (fileAction === FILE_ACTIONS_CONSTS.download)
      downloadFiles(selectedDocuments.map((doc) => doc.id));
  };

  return (
    <>
      {/* modals */}
      <>
        {openModalControl === FILE_ACTIONS_CONSTS.rename && (
          <RenameFileModal
            isModalOpen={openModalControl === FILE_ACTIONS_CONSTS.rename}
            selectedFileId={selectedDocuments[0]}
            closeModal={() =>
              handleModalStates(FILE_ACTIONS_CONSTS.rename, false)
            }
          />
        )}
        {openModalControl === FILE_ACTIONS_CONSTS.share && (
          <FileShareModal
            isModalOpen={openModalControl === FILE_ACTIONS_CONSTS.share}
            selectedDocuments={selectedDocuments}
            closeModal={() =>
              handleModalStates(FILE_ACTIONS_CONSTS.share, false)
            }
          />
        )}
        {openModalControl === FILE_ACTIONS_CONSTS.upload && (
          <UploadProgressModal
            isModalOpen={openModalControl === FILE_ACTIONS_CONSTS.upload}
            uploadState={uploadState}
            filename={fileToUpload?.name}
            closeModal={() =>
              handleModalStates(FILE_ACTIONS_CONSTS.upload, false)
            }
          />
        )}
      </>

      <Grid stackable>
        {/* active / archived toggle */}
        <Grid.Row>
          <Grid.Column>
            <Menu pointing secondary>
              <Menu.Item
                name="documentsCurrent"
                active={!showArchived}
                onClick={() => setShowArchived(false)}
              >
                {t('My Documents')}
              </Menu.Item>

              <Menu.Item
                name="documentsArchived"
                active={showArchived}
                onClick={() => {
                  setShowArchived(true);
                }}
              >
                {t('Archived')}
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        {/* buttons row */}
        <Grid.Row>
          <Grid.Column>
            {/* UPLOADER */}
            {selectedDocuments.length === 0 && (
              <DocumentUploader
                floated="none"
                onFileSelected={handleFileSelectedForUpload}
                onSubmit={handleFileSubmitted}
                onError={() => null}
                shouldUseExternalStorageApi
                title={t('Uploading Files and Documents')}
                allowedFileTypes={allowedMimeTypes}
                maxNumberOfFiles={1}
              />
            )}
            {/* SELECTED FILE ACTIONS */}
            {selectedDocuments.length > 0 && (
              <FormsAndDocumentsActionButtons
                areMultipleSelected={selectedDocuments.length > 1}
                handleFileActionClicked={handleFileActionClicked}
              />
            )}
          </Grid.Column>
        </Grid.Row>

        {/* forms/docs table */}
        <Grid.Row>
          <Grid.Column>
            <FormsAndDocumentsTable
              showArchived={showArchived}
              onRowSelect={handleSelectedDocuments}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default FormsAndDocumentsMain;
