import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import { Card, Header, Label } from 'semantic-ui-react';
import Avatar from 'react-avatar';

// Import utils
import { formatFullName } from '../../helpers/utils';

import UserStatusLabel from '../Shared/UserStatusLabel';

import styles from './StaffEditCard.module.scss';
import cardStyles from '../Shared/CardHelpers/CardStyles.module.scss';

const getStaffEmail = (staff) =>
  staff.email && staff.email.indexOf('moxit_') !== -1 ? '' : staff.email;

const getStaffName = (staff) =>
  staff.displayName || formatFullName(staff, true);

const renderStaffPin = (staff) =>
  staff.staffPin ? (
    <Label content={`PIN: ${staff.staffPin}`} active />
  ) : (
    <Label content={'No Pin'} basic />
  );

const StaffEditCard = ({ staff, onClick, t }) => (
  <Card className="shadow" key={staff.id} onClick={onClick}>
    <Card.Content textAlign="center" className={styles.cardWidthFix}>
      <Card.Header>
        <Avatar
          name={getStaffName(staff)}
          email={getStaffEmail(staff)}
          round
          size={80}
          textSizeRatio={3}
        />
      </Card.Header>
    </Card.Content>
    <Card.Content
      textAlign="center"
      className={CN(styles.cardContentBorderless, styles.cardWidthFix)}
    >
      <Card.Header>
        <Header as="h3" className={cardStyles.textTruncate}>
          {getStaffName(staff)}
        </Header>
      </Card.Header>
      <Card.Meta className={cardStyles.textTruncate}>
        {getStaffEmail(staff)}
      </Card.Meta>
      <Card.Description>{renderStaffPin(staff)}</Card.Description>
    </Card.Content>
    <Card.Content
      className={CN(
        styles.cardContentBorderless,
        styles.cardWidthFix,
        styles.cardContentSpacer
      )}
    />
    <Card.Content extra textAlign="center" className={styles.cardWidthFix}>
      <UserStatusLabel user={staff} />
    </Card.Content>
  </Card>
);

StaffEditCard.propTypes = {
  staff: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StaffEditCard;
