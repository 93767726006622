import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Segment, Sidebar } from 'semantic-ui-react';

// Import Logo
// import moxitLogo from '../../styles/img/moxit-logo-white-100.png';

// Import component
// import TopNavigation from '../Navigation/TopNavigation';
import MobileNavigation from '../Navigation/MobileNavigation';
import SetupRibbon from './../Setup/SetupRibbon';
import FooterNavigation from '../Navigation/FooterNavigation';
// import MobileHamburgerNavigation from '../Navigation/MobileHamburgerNavigation';

class MobileContainer extends Component {
  state = {};

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };

  handleToggle = () =>
    this.setState({ sidebarOpened: !this.state.sidebarOpened });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher
          dimmed={sidebarOpened}
          onClick={this.handlePusherClick}
          className="mobile-container"
        >
          <Segment vertical className="navbar no-padding">
            <Container className="no-padding no-margin">
              <MobileNavigation handleToggle={this.handleToggle} />
            </Container>
          </Segment>

          <div id="content">
            <SetupRibbon />

            {children}
          </div>

          <FooterNavigation viewport="mobile" />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

export default MobileContainer;
