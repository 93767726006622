import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { SegmentProvider } from 'react-segment-hooks';
import { SemanticToastContainer } from 'react-semantic-toasts';

import App from './App';

import { isProd } from './config/env';
import { FeatureFlagsProvider } from './feature-flags';
import { RollbarLoggingProvider } from './rollbar';

// Import store
import store from './redux/store';

// translations
import './i18n';

// Import styles.
import 'semantic-ui-css/semantic.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import './styles/css/bootstrap-iso.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import 'instantsearch.css/themes/algolia.css';
import 'uppy/dist/uppy.min.css';

// Moxit specific style.
import './styles/scss/index.scss';

ReactDOM.render(
  <SegmentProvider
    apiKey={process.env.REACT_APP_SEGMENT_API_KEY || ''}
    debug={!isProd()}
  >
    <FeatureFlagsProvider>
      <BrowserRouter>
        <RollbarLoggingProvider>
          <Provider store={store}>
            <HelmetProvider>
              <Suspense fallback="Loading...">
                {/* This is required in order to show toast() in child components position and animation can be set here. */}
                <SemanticToastContainer />
                <App />
              </Suspense>
            </HelmetProvider>
          </Provider>
        </RollbarLoggingProvider>
      </BrowserRouter>
    </FeatureFlagsProvider>
  </SegmentProvider>,
  document.getElementById('root')
);
