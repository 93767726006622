import { cloneDeep } from 'lodash';

import {
  ORGANIZATION_SELECTED,
  ORGANIZATION_FETCHED,
  MOXIT_ORGANIZATIONS_FETCHED,
  ORGANIZATION_MISSING,
  ORGANIZATION_PROVISIONING_STARTED,
  ORGANIZATION_PROVISIONING_COMPLETE,
} from '../actions/types';

const initialState = {
  currentOrganization: {
    setup: {
      firstLocationCreated: false,
      firstRoomCreated: false,
      firstStaffCreated: false,
      firstStudentCreated: false,
    },
    fetching: undefined,
    programType: null,
    setupComplete: false,
  },
  list: {},
  last: {},
  count: 0,
};

/**
 * Organization Onboarding Process:
 * 1) Authenticated user is found and loaded
 * 2) UserProfile Listener (userActions:startUserProfileListener) checks the user profile for a defaultOrganization id
 * ((needsOrgOnboarding = true))
 *  2>A) Missing: will dispatch ORGANIZATION_MISSING (currentOrg.fetching = undefined -> false)
 *  2>B) App.js will redirect to CompleteSignup where the user will begin the org provision process.
 *    ORGANIZATION_PROVISIONING_STARTED,
 *    await document(s) creation,
 *    brief sleep
 *    USER_PROFILE_UPDATED (now contains defaultOrganization id)
 *    ORGANIZATION_PROVISIONING_COMPLETE (fetching: false -> undefined) (relinquish control)
 *  2>C) App's user profile listener will receive a update trigger and find ORGANIZATION_SELECTED
 *    which merges this branch into the next step >> (3)
 * 3) If found: ORGANIZATION_SELECTED (currentOrg.fetching = undefined -> true or (whatever previous value), id -> exists)
 * ((canStartOrgListeners = true))
 * 4) App.js will start org listeners which rely only on the org id
 * 5) organizationActions.startOrganizationListener will dispatch ORGANIZATION_FETCHED (currentOrg.fetching: true -> false)
 *  most up to date organization data will now be loaded.
 * ((isOrgLoaded = true))
 * 6) User will be routed or navigate to the Setup wizard and eventually create all their first entities (defined in currentOrg.setup)
 *  once the last of them are set to true, setupComplete will be set to true (api/firebase/setup:updateSetupStatus)
 *  which will trigger an update event (ORGANIZATION_SELECTED) that will update the latest.
 * ((isOrgComplete = true))
 */

export const getOrganizationSetupState = (state) => {
  const currentOrganization = state.currentOrganization;
  const {
    fetching,
    id: organizationId,
    programType,
    setupComplete,
  } = currentOrganization;

  const needsOrgOnboarding = fetching === false && !organizationId;
  const canStartOrgListeners = fetching === true && !!organizationId;
  const isOrgLoaded = canStartOrgListeners && !!programType;
  const isOrgComplete = isOrgLoaded && setupComplete;

  return {
    organizationId,
    needsOrgOnboarding,
    canStartOrgListeners,
    isOrgLoaded,
    isOrgComplete,
  };
};

const organizations = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_MISSING:
      return {
        ...state,
        currentOrganization: {
          ...cloneDeep(initialState.currentOrganization),
          fetching: false,
        },
      };
    case ORGANIZATION_PROVISIONING_STARTED:
      return {
        ...state,
      };
    case ORGANIZATION_PROVISIONING_COMPLETE:
      return {
        ...state,
        currentOrganization: {
          ...cloneDeep(state.currentOrganization),
          fetching: undefined,
        },
      };
    case ORGANIZATION_SELECTED:
      return {
        ...state,
        currentOrganization: {
          ...cloneDeep(state.currentOrganization),
          ...action.organization,
          // only trigger fetching:true here if nothing else is involved (undefined)
          // if something is involved, fetching might be false or some other defined value
          fetching: state.currentOrganization.fetching === undefined,
        },
      };
    case ORGANIZATION_FETCHED:
      return {
        ...state,
        currentOrganization: {
          ...cloneDeep(initialState.currentOrganization),
          ...action.organization,
          fetching: false,
        },
      };
    case MOXIT_ORGANIZATIONS_FETCHED: {
      const list = { ...state.list, ...action.organizations.data };
      return {
        ...state,
        list,
        last: action.organizations.lastDocument,
        count: Object.keys(list).length,
      };
    }

    default:
      return state;
  }
};

export default organizations;
