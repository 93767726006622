import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid, Label } from 'semantic-ui-react';
// Import utils
import { formatUtcMillisAsString } from '../../../helpers/dates';
import EnrollmentInvite from './InvitationForm';
import StudentRooms from '../student/StudentRooms';

const SHOW_STUDENT_ENROLLMENT_FEATURES = false;

const StudentEnrollment = ({
  selectedStudent: {
    id,
    enrollmentStatus,
    rooms,
    schedule,
    tourDate,
    enrollmentDate,
    unenrollmentDate,
    unenrollmentInitatedBy,
    unenrollmentReason,
  },
  currentOrganization: { programType },
}) => {
  const { t } = useTranslation();

  if (!id) return null;
  return (
    <>
      <Grid>
        {(programType === null ||
          (programType && programType !== 'familyChildCare')) && (
          <Grid.Row>
            <Grid.Column width={6}>{t('Homeroom')}</Grid.Column>
            <Grid.Column width={10}>
              <StudentRooms rooms={rooms} />
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row>
          <Grid.Column width={6}>{t('Schedule')}</Grid.Column>
          <Grid.Column width={10}>
            {schedule && schedule.length ? (
              schedule.map((s, key) => (
                <Label key={key} color="black" content={t(`Day-${s}`)} />
              ))
            ) : (
              <Label color="black" content={t('No schedule selected')} />
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={6}>{t('Tour Date')}</Grid.Column>
          <Grid.Column width={10}>
            <strong>
              {tourDate ? formatUtcMillisAsString(tourDate) : t('No tour date')}
            </strong>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={6}>{t('Enrollment Date')}</Grid.Column>
          <Grid.Column width={10}>
            <strong>
              {enrollmentDate
                ? formatUtcMillisAsString(enrollmentDate)
                : t('No enrollment date')}
            </strong>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={6}>{t('Enrollment Status')}</Grid.Column>
          <Grid.Column width={10}>
            <Label
              content={enrollmentStatus ? t('Enrolled') : t('Unenrolled')}
              color={enrollmentStatus ? 'green' : 'red'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {!enrollmentStatus && (
        <Grid style={{ background: '#FCD34D', borderRadius: 10 }}>
          <Grid.Row>
            <Grid.Column width={6}>{t('Unenrollment Date')}</Grid.Column>
            <Grid.Column width={10}>
              <strong>
                {unenrollmentDate
                  ? formatUtcMillisAsString(unenrollmentDate)
                  : t('No unenrollment date')}
              </strong>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              {t('Unenrollment Initiated By')}
            </Grid.Column>
            <Grid.Column width={10}>
              <strong>{unenrollmentInitatedBy}</strong>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>{t('Unenrollment Reason')}</Grid.Column>
            <Grid.Column width={10}>
              <strong>{unenrollmentReason}</strong>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {SHOW_STUDENT_ENROLLMENT_FEATURES && <EnrollmentInvite />}
    </>
  );
};

StudentEnrollment.propTypes = {
  selectedStudent: PropTypes.shape({
    id: PropTypes.string,
    enrollmentStatus: PropTypes.bool,
    rooms: PropTypes.arrayOf(PropTypes.string),
    tourDate: PropTypes.number,
    enrollmentDate: PropTypes.number,
    graduationDate: PropTypes.number,
    photosAllowed: PropTypes.bool,
  }),
};
const mapStateToProps = (state) => ({
  selectedStudent: state.students.selectedStudent,
  currentOrganization: state.organizations.currentOrganization,
});
export default connect(mapStateToProps)(StudentEnrollment);
