import React from 'react';
import { Card, Divider, Icon, Image, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../../helpers/utils';
import { calculateTotalFromInvoiceItems } from '../../../helpers/invoices';
import {
  useInvoiceEditItems,
} from '../../../hooks/useInvoiceEdit';

// Import style
import './Billing.scss';

export function StudentsListWidget({ students, onRemove, allowDelete = true }) {
  const { t } = useTranslation();
  if (!students?.length) return null;

  return (
    <Card fluid>
      <Card.Content className={'students-summary-card-header'}>
        <Card.Header textAlign="center">
          {t('{{selectedStudentsCount}} students to be invoiced', {
            selectedStudentsCount: students.length,
          })}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <List
          selection
          verticalAlign="middle"
          className={'students-summary-list'}
        >
          {students.map((student, index) => (
            <List.Item key={student.id}>
              <Image avatar src={student.picture} />
              <List.Content>
                <List.Header>{student.displayName}</List.Header>
              </List.Content>
              {allowDelete && (
                <List.Content floated="right">
                  <Icon name="remove" onClick={() => onRemove(student)} />
                </List.Content>
              )}
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  );
}

export function InvoicePlanWidget({ invoicePlan }) {
  const { t } = useTranslation();

  return (
    <Card fluid className={'details-widget'}>
      <Card.Content className={'details-widget-header'}>
        <Card.Header textAlign="center">{t('Invoice Details')}</Card.Header>
      </Card.Content>
      <Card.Content>
        <List>
          <Divider />
          <List.Item>
            <List.Content>
              <List.Header>
                <div>{t('Total')}</div>
                <div>{invoicePlan && formatCurrency(invoicePlan.total)}</div>
              </List.Header>
            </List.Content>
          </List.Item>
        </List>
      </Card.Content>
    </Card>
  );
}
export function InvoiceItemsWidget() {
  const { t } = useTranslation();
  const items = useInvoiceEditItems();

  return (
    <Card fluid className={'details-widget'}>
      <Card.Content className={'details-widget-header'}>
        <Card.Header textAlign="center">{t('Invoice Details')}</Card.Header>
      </Card.Content>
      <Card.Content>
        <List>
          {items?.map((item, index) => (
            <List.Item key={item.id}>
              <List.Content>
                <List.Header>
                  <div>{item.category}</div>
                  <div>{formatCurrency(item.amount)}</div>
                </List.Header>
                {item.discounts?.map((discount, index) => {
                  return (
                    <List.Description key={discount.id}>
                      <div>{discount.discountType}</div>
                      <div>-{formatCurrency(discount.amount)}</div>
                    </List.Description>
                  );
                })}
              </List.Content>
            </List.Item>
          ))}
          <Divider />
          <List.Item>
            <List.Content>
              <List.Header>
                <div>{t('Total')}</div>
                <div>{formatCurrency(calculateTotalFromInvoiceItems(items))}</div>
              </List.Header>
            </List.Content>
          </List.Item>
        </List>
      </Card.Content>
    </Card>
  );
}
