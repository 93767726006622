// This is a thin wrapper around the callables for initiating
// some of the scheduled pubsub functions.

import {functions} from '.';

export async function sendInvoicesIfDue () {
    return await functions().httpsCallable('callables-pubsub-sendInvoicesIfDue')();
}
export async function sendInvoicePlansIfDue () {
    return await functions().httpsCallable('callables-pubsub-sendInvoicePlansIfDue')();
}
export async function backfillInvoicePayoutStatus () {
    return await functions().httpsCallable('callables-backfill-invoicePayoutStatus')();
}
export async function backfillUserStripeAndFastpay () {
    return await functions().httpsCallable('callables-backfill-userStripeAndFastpay')();
}
export async function syncRootUserWithOrgUser () {
    return await functions().httpsCallable('callables-fixes-syncRootUserWithOrgUser')();
}
export async function backfillStudentPreventInvoiceReason () {
    return await functions().httpsCallable('callables-backfill-studentPreventInvoiceReason')();
}
export async function backfillTransactionsForPaidInvoices () {
    return await functions().httpsCallable('callables-backfill-createTransactionsForInvoices')();
}
export async function sendSinceFeature () {
    return await functions().httpsCallable('callables-invoices-sendSinceFeature')();
}

export async function setFastpayForOrganizations(fastpayEnabled) {
  return await functions().httpsCallable(
    'callables-fastpay-setFastpayForOrganizations'
  )({ fastpayEnabled });
}

export async function setFastpayForUsers(fastpayEnabled) {
  return await functions().httpsCallable(
    'callables-fastpay-setFastpayForUsers'
  )({ fastpayEnabled });
}

export async function resetFastpayBalanceForOrganizations() {
  return await functions().httpsCallable(
    'callables-fastpay-resetFastpayBalanceForOrganizations'
  )();
}

export async function resetFastpayBalanceForUsers() {
  return await functions().httpsCallable(
    'callables-fastpay-resetFastpayBalanceForUsers'
  )();
}
