import React, { useState, useEffect } from 'react';
import { Feed, Icon } from 'semantic-ui-react';
import { firestore } from '../../api/firebase';
import { connect } from 'react-redux';
import { isEmpty, find } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { activitiesConfig } from '../../config';

const startTime = moment().startOf('day').utc().valueOf();
const endTime = moment().endOf('day').utc().valueOf();

const ActivityFeedList = ({ activities }) => {
  const { t } = useTranslation();

  if (isEmpty(activities))
    return (
      <Feed.Event>
        <Feed.Content>
          <Feed.Summary>
            {t('There are no activities added today.')}
          </Feed.Summary>
        </Feed.Content>
      </Feed.Event>
    );

  const activitiesArr = [];

  for (let key in activities) {
    if (activities.hasOwnProperty(key)) {
      activitiesArr.push(activities[key]);
    }
  }

  const activitiesList = activitiesArr.map((activity, index) => {
    if (isEmpty(activity)) return null;

    const activityConfig = find(activitiesConfig, ['type', activity.type]);

    const activityTime = moment(activity.activityTime).fromNow();
    const activityDescription = `${activity?.addedBy?.fullName} added a ${activityConfig?.name} information for ${activity?.student?.fullName}.`;

    return (
      <Feed.Event key={index}>
        <Feed.Label>
          <Icon
            circular
            name={activityConfig?.icon}
            style={{
              backgroundColor: activityConfig?.color,
              boxShadow: 'none',
              color: '#fff',
            }}
          />
        </Feed.Label>
        <Feed.Content>
          <Feed.Date content={t(activityTime)} />
          <Feed.Summary content={t(activityDescription)} />
        </Feed.Content>
      </Feed.Event>
    );
  });

  return <>{activitiesList}</>;
};

const ActivityFeed = ({ currentOrganization: { id = null } }) => {
  const [activities, setActivities] = useState();

  useEffect(() => {
    if (!id) return null;

    const unsubscribe = firestore()
      .collection(`organizations/${id}/activities`)
      .orderBy('activityTime', 'desc')
      .where('activityTime', '>=', startTime)
      .where('activityTime', '<=', endTime)
      .limit(15)
      .onSnapshot(
        (snapshot) => {
          if (!snapshot.empty) {
            const allActivities = {};

            snapshot.docs.forEach((doc) => {
              if (doc.exists) allActivities[doc.id] = doc.data();
            });

            setActivities(allActivities);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    // Stop listener.
    return () => unsubscribe;
  }, [id]);

  return (
    <Feed>
      <ActivityFeedList activities={activities} />
    </Feed>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms.list,
});

export default connect(mapStateToProps)(ActivityFeed);
