import React, { useState, useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import { firestore } from '../../api/firebase';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
// import moment from 'moment';

// Import components
import NoDataMessage from '../../Components/Shared/NoDataMessage';

// const startTime = moment().subtract(7, 'days').startOf('day').utc().valueOf();
// const endTime = moment().endOf('day').utc().valueOf();

const PhotoGalleryList = ({ activities }) => {
  const { t } = useTranslation();

  if (isEmpty(activities))
    return (
      <NoDataMessage
        // image="https://cdn.dribbble.com/users/3205235/screenshots/6387825/crying-face.gif"
        message={t(
          'Oh no! There have not been any photos added in the last week.'
        )}
        sub={t('Adding photos is the #1 way to engage parents.')}
      />
    );

  const activitiesArr = [];

  for (let key in activities) {
    if (activities.hasOwnProperty(key)) {
      activitiesArr.push(activities[key]);
    }
  }

  const activitiesList = activitiesArr.map((activity, index) => {
    if (isEmpty(activity)) return null;

    const photoUri = activity.photo?.data?.base64 
      ? `data:image/gif;base64,${activity.photo?.data?.base64}`
      : activity.photo?.data?.uri;

    if (isEmpty(photoUri)) return null;

    return (
      <Image key={index} rounded src={photoUri} />
    );
  });

  return <>{activitiesList}</>;
};

const PhotoGallery = ({ currentOrganization: { id = null } }) => {
  const [activities, setActivities] = useState();

  useEffect(() => {
    if (!id) return null;

    const unsubscribe = firestore()
      .collection(`organizations/${id}/activities`)
      .orderBy('activityTime', 'desc')
      .where('type', '==', 'photo')
      // .where('activityTime', '>=', startTime)
      // .where('activityTime', '<=', endTime)
      .limit(14)
      .onSnapshot(
        (snapshot) => {
          if (!snapshot.empty) {
            const allActivities = {};

            snapshot.docs.forEach((doc) => {
              if (doc.exists) allActivities[doc.id] = doc.data();
            });

            setActivities(allActivities);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    // Stop listener.
    return () => unsubscribe;
  }, [id]);

  return (
    <Image.Group size="small">
      <PhotoGalleryList activities={activities} />
    </Image.Group>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms.list,
});

export default connect(mapStateToProps)(PhotoGallery);
