import { useTranslation } from "react-i18next"
import { Button, Grid, Icon, Modal, Segment } from "semantic-ui-react"
import { UPLOAD_STATE_CONSTS } from "../../dictionary"

const UploadProgressModal = ({
  close,
  uploadState,
  filename,
  isModalOpen,
  closeModal,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      open={isModalOpen}
      onClose={() => closeModal()}
      {...rest}
    >
      {uploadState === UPLOAD_STATE_CONSTS.uploading && (
        <>
          <Modal.Header>
            <Grid verticalAlign='middle'>
              <Grid.Column verticalAlign='middle' width='1'>
                <Icon.Group size='large'>
                  {/* include the empty icon to ensure proper positioning */}
                  <Icon />
                  <Icon size='small' name='circle outline' color='blue' />
                  <Icon size='tiny' name='info' color='blue' aria-label='info' />
                </Icon.Group>
              </Grid.Column>
              <Grid.Column width='15'>
                {t('Hang tight, we\'re processing your file!')}
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            <Segment basic>

              <Grid>
                <Grid.Row>
                  <strong>{filename}</strong>
                </Grid.Row>
                <Grid.Row>
                  {t('You will be able to access this document once it is done uploading.')}
                </Grid.Row>
                <Grid.Row>
                  <Button primary onClick={closeModal}>{t('Done')}</Button>
                </Grid.Row>
              </Grid>
            </Segment>
          </Modal.Content>
        </>
      )}
      {uploadState === UPLOAD_STATE_CONSTS.error && (
        <>
          <Modal.Header>
            <Grid verticalAlign='middle'>
              <Grid.Column verticalAlign='middle' width='1'>
                <Icon.Group size='large'>
                  {/* include the empty icon to ensure proper positioning */}
                  <Icon />
                  <Icon size='small' name='circle outline' color='red' />
                  <Icon size='tiny' name='info' color='red' aria-label='upload-error' />
                </Icon.Group>
              </Grid.Column>
              <Grid.Column width='15'>
                {t('Error uploading your file')}
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            <Segment basic>
              <Grid>
                <Grid.Row>
                  {t('An error occurred when uploading the file:')}
                </Grid.Row>
                <Grid.Row>
                  <strong>{filename}</strong>
                </Grid.Row>
                <Grid.Row>
                  {t('Please try uploading again or uploading a different file.')}
                </Grid.Row>
                <Grid.Row>
                  <Button primary onClick={closeModal}>{t('Done')}</Button>
                </Grid.Row>
              </Grid>
            </Segment>
          </Modal.Content>
        </>
      )
      }
    </Modal >
  )
}

export default UploadProgressModal
