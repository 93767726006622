import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Segment,
  Grid,
  Header,
  Label,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  InvoicePlanStatus,
  invoicePlanStatusFormatter,
  getUpdatedBillingDates,
} from '../../../../helpers/invoicePlans';
import { routes } from '../../../../config/routes';

import { setOrganizationInvoicePlan } from '../../../../api/firebase/invoices';
import { userHasPermission } from '../../../../api/firebase/account';
import { invoiceEditSelected } from '../../../../redux/actions/billingActions';

import InvoiceItemList from '../InvoiceItemList';
import { useLoadedStudents } from '../../../../students/studentsHooks';
import {
  createArrayOfFamilies,
} from '../../../../students/studentsUtils';
import { createMissingFieldsAndMergeLists } from '../../../../helpers/invitations';
import { useInvitations, usePendingParentInvitationsListener } from '../../../../hooks/useInvitations';

// Import style

import './InvoiceDetail.scss';

function InvoicePlanDetailsContainer({
  invoicePlan,
  showActionButtons = true,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const invoicePlanFromStore = useSelector(
    (state) => state.invoices?.recurring?.selected
  );
  const { id: organizationId } = useSelector(
    (state) => state.organizations.currentOrganization
  );
  const [sendingInvoicePlan, setSendingInvoicePlan] = useState(false);
  const [editInvoicePlan, setEditInvoicePlan] = useState(false);

  usePendingParentInvitationsListener(organizationId);

  const selectedInvoicePlan = invoicePlan || invoicePlanFromStore;
  const invitations = useInvitations();

  const students = useLoadedStudents((selectedInvoicePlan.students && selectedInvoicePlan.students.map(student => student.id)) || []);
  const responsibleForBilling = students.map(student => {
    if(student.responsibleForBilling) return student.responsibleForBilling;

    const families = createArrayOfFamilies(student?.family);
    const familiesAndContacts = families.concat(student?.contacts ?? []);
    const familiesAndContactsAndInvitations = createMissingFieldsAndMergeLists(
      familiesAndContacts,
      invitations.list.filter((user) => 
        (user.student && user.student.id === student.id) || 
        (user.studentIds && user.studentIds.includes(student.id))),
    );

    if(familiesAndContactsAndInvitations.length <= 0) return null;

    const familyMember = familiesAndContactsAndInvitations.find((parent) => !!parent.email);

    if(!familyMember) return null;

    const data = {
      displayName: familyMember.displayName,
      email: familyMember.email,
    }
    return data;
  }).filter(responsible => !!responsible);
  
  if(responsibleForBilling.length <= 0) responsibleForBilling.push({ displayName: 'No parent found :(' , email: 'No email found :(' });

  useEffect(() => {
    const doSendInvoicePlan = async () => {
      if (sendingInvoicePlan) {
        await setOrganizationInvoicePlan(organizationId, selectedInvoicePlan);
        setSendingInvoicePlan(false);
      }
    };
    doSendInvoicePlan();
  }, [sendingInvoicePlan, selectedInvoicePlan, organizationId]);

  useEffect(() => {
    if (editInvoicePlan) {
      history.push(routes.billing.plans.edit);
    }
  }, [editInvoicePlan, history]);

  return (
    <Container fluid className="no-padding no-margin">
      <Grid stackable columns="equal">
        <Grid.Column>
          {renderSummary(selectedInvoicePlan)}
          {renderDetails(selectedInvoicePlan)}
          {
            false &&
              renderSentInvoices(
                selectedInvoicePlan
              ) /*Dont render for now, but lint will complain*/
          }
        </Grid.Column>
      </Grid>
    </Container>
  );

  function renderSummary(selectedInvoicePlan) {
    return (
      <Segment clearing>
        {renderPageTitle(selectedInvoicePlan)}

        <Segment className="no-shadow no-border top-border">
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Table compact basic="very" className="billing-summary">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{t('Billed to')}</Table.Cell>
                      <Table.Cell>{responsibleForBilling.map(responsible => responsible.displayName).join(', ')}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>

              <Grid.Column>
                <Table
                  singleLine
                  compact
                  basic="very"
                  className="billing-summary"
                >
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{t('Recurring Plan number')}</Table.Cell>
                      <Table.Cell>
                        {selectedInvoicePlan && selectedInvoicePlan.id}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <strong>{t('Next invoice date')}</strong>
                      </Table.Cell>
                      <Table.Cell>
                        <strong>
                          {selectedInvoicePlan.dateSend &&
                            moment(selectedInvoicePlan.dateSend).format(
                              'MM/DD/YYYY'
                            )}
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <strong>{t('Next due date')}</strong>
                      </Table.Cell>
                      <Table.Cell>
                        <strong>
                          {selectedInvoicePlan.dateDue &&
                            moment(selectedInvoicePlan.dateDue).format(
                              'MM/DD/YYYY'
                            )}
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <strong>{t('Billing interval')}</strong>
                      </Table.Cell>
                      <Table.Cell>
                        <strong>{selectedInvoicePlan.billingInterval}</strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                {!!selectedInvoicePlan?.invoiceItemList && (
                  <InvoiceItemList
                    invoiceItemList={selectedInvoicePlan?.invoiceItemList}
                    isEditable={false}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment>
    );
  }

  function renderDetails(selectedInvoicePlan) {

    return (
      <Segment clearing>
        <Segment basic clearing className="no-padding">
          <Header as="h2" content={t('Details')} />
        </Segment>

        <Segment className="no-shadow no-border no-padding no-margin top-border">
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell>{t('Id')}</Table.Cell>
                <Table.Cell>
                  {selectedInvoicePlan && selectedInvoicePlan.id}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('Created')}</Table.Cell>
                <Table.Cell>
                  {selectedInvoicePlan &&
                    selectedInvoicePlan.createdAt &&
                    moment(selectedInvoicePlan.createdAt).format(
                      'MM/DD/YYYY HH:MM A'
                    )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('Finalized')}</Table.Cell>
                <Table.Cell>
                  {selectedInvoicePlan &&
                    selectedInvoicePlan.createdAt &&
                    moment(selectedInvoicePlan.createdAt).format(
                      'MM/DD/YYYY HH:MM A'
                    )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('Emails invoice sent to')}</Table.Cell>
                <Table.Cell>{responsibleForBilling.map(responsible => responsible.email).join(', ')}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }
  function renderSentInvoices(selectedInvoicePlan) {
    const invoices = selectedInvoicePlan.invoices?.map((invoice) => {
      return (
        <Table.Row key={invoice.id}>
          <Table.Cell>{invoice.id}</Table.Cell>
          <Table.Cell>{invoice.status}</Table.Cell>
          <Table.Cell>
            {invoice.createdAt &&
              moment(invoice.createdAt).format('MM/DD/YYYY')}
          </Table.Cell>
          <Table.Cell>{invoice.total}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Segment clearing>
        <Segment basic clearing className="no-padding">
          <Header as="h2" content={t('Invoices')} />
        </Segment>

        <Segment className="no-shadow no-border top-border">
          <Table singleLine basic="very">
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell>{t('Invoice ID')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Status')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Created On')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Total')}</Table.HeaderCell>
              </Table.Row>

              {invoices ? (
                invoices
              ) : (
                <Table.Row key={'noInvoices'} textAlign="center">
                  <Table.Cell colSpan={4}>
                    {t('No invoices created yet')}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }
  function renderPageTitle() {
    return (
      <Segment basic clearing className="no-padding no-border no-margin">
        {showActionButtons &&
          userHasPermission('can_edit_billing') &&
          renderActionButtons()}

        <Header as="h2" floated="left">
          {t('Summary')} {renderStatus()}
        </Header>
      </Segment>
    );
  }

  function renderActionButtons() {
    const isActive = selectedInvoicePlan.status === InvoicePlanStatus.ACTIVE;
    const isPaused = selectedInvoicePlan.status === InvoicePlanStatus.PAUSED;
    const isScheduled =
      selectedInvoicePlan.status === InvoicePlanStatus.SCHEDULED;

    return (
      <>
        {isPaused && (
          <Button
            primary
            floated="right"
            loading={sendingInvoicePlan}
            onClick={() => {
              const dates = getUpdatedBillingDates(selectedInvoicePlan);
              selectedInvoicePlan.dateDue = dates.dateDue;
              selectedInvoicePlan.dateSend = dates.dateSend;
              processStatusChange(InvoicePlanStatus.ACTIVE);
            }}
          >
            {t('Resume Invoice Plan')}
          </Button>
        )}

        {isActive && (
          <Button
            primary
            floated="right"
            loading={sendingInvoicePlan}
            onClick={() => processStatusChange(InvoicePlanStatus.PAUSED)}
          >
            {t('Pause Invoice Plan')}
          </Button>
        )}

        {isActive && !isScheduled && (
          <Button
            primary
            floated="right"
            loading={sendingInvoicePlan}
            onClick={() => processStatusChange(InvoicePlanStatus.SCHEDULED)}
          >
            {t('Send Invoices Now')}
          </Button>
        )}

        <Button
          primary
          floated="right"
          loading={sendingInvoicePlan}
          onClick={onClickEdit}
        >
          {t('Edit')}
        </Button>
      </>
    );
  }
  function onClickEdit(e) {
    e.preventDefault();
    dispatch(invoiceEditSelected(selectedInvoicePlan));
    setEditInvoicePlan(true);
  }
  function processStatusChange(newStatus) {
    selectedInvoicePlan.status = newStatus;
    setSendingInvoicePlan(true);
  }
  function renderStatus() {
    const { text, color } = invoicePlanStatusFormatter(selectedInvoicePlan);
    if (!text || !color) return null;
    return <Label color={color} content={t(text)} />;
  }
}

export default InvoicePlanDetailsContainer;
