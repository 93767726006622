import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Segment, Checkbox, Confirm } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

// Import components
import { DatePicker } from '../../../Components/Shared/DatePicker';
import InlineError from '../../../Components/Messages/InlineError';
import ShowError from '../../../Components/Messages/ShowError';
import RoomPicker from '../../../Components/Rooms/RoomPicker';
import { SchedulePicker } from '../../../Components/Shared/SchedulePicker';
import SectionCard from '../../../Components/Shared/SectionCard';

import {
  unenrollmentInitatedByOptions,
  unenrollmentReasonOptions,
} from '../../../config';

// Import utils
import {
  formatStringAsUtcMillisOrNull,
  formatUtcMillisAsString,
} from '../../../helpers/dates';

// Import actions
import {
  organizationAddStudent,
  organizationUpdateStudent,
  studentSelectionCleared,
} from '../../studentsRedux';

class StudentEnrollmentForm extends Component {
  state = {
    data: {
      id: '',
      enrollmentStatus: true,
      rooms: [],
      schedule: ['M', 'T', 'W', 'Th', 'F'],
      tourDate: null,
      enrollmentDate: null,
      unenrollmentDate: null,
      unenrollmentInitatedBy: '',
      unenrollmentReason: '',
    },
    loading: false,
    errors: {},
    minDateNow: moment(),
    showUnenrollConfirm: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      students: { selectedStudent },
    } = nextProps;

    if (
      !_.isEmpty(selectedStudent.id) &&
      prevState.data.id !== selectedStudent.id
    ) {
      const { tourDate, enrollmentDate, unenrollmentDate, ...rest } =
        selectedStudent;

      return {
        data: {
          tourDate: formatUtcMillisAsString(tourDate),
          enrollmentDate: formatUtcMillisAsString(enrollmentDate),
          unenrollmentDate: formatUtcMillisAsString(unenrollmentDate),
          ...rest,
        },
      };
    }
    return prevState;
  }

  componentDidMount() {
    document?.body.classList.add('scrollable')
  }
  componentWillUnmount() {
    document?.body.classList.remove('scrollable')
    if (!this.props.clearOnUnmount) return;
    // Clear selection from store.
    this.props.studentSelectionCleared();
  }

  onChange = (e, { name, value, checked }) => {
    // FIXME: TEMPORARY SOLUTION TO SET SINGLE STUDENT ROOM.
    // [CCMS-174] empty array instead of empty string to match starting empty state.
    if (name === 'rooms') value = value === '' ? [] : [value];

    const {
      students: { selectedStudent },
    } = this.props;

    // If enrollment status is set to true after
    // being set false reset the unenrollment fields.
    if (name === 'enrollmentStatus' && checked === true) {
      this.setState({
        data: {
          ...this.state.data,
          enrollmentStatus: true,
          unenrollmentDate: null,
          unenrollmentInitatedBy: '',
          unenrollmentReason: '',
        },
      });
    // If the student is enrolled and status is set to unenroll, show a confirm modal
    } else if (name === 'enrollmentStatus' && checked === false && selectedStudent?.enrollmentStatus === true) {
      this.setState({ showUnenrollConfirm: true });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          [name]: !_.isUndefined(checked) ? checked : value,
        },
        errors: _.omit(this.state.errors, name),
      });
    }
  };

  handleUnenrollConfirm = () => {
    this.setState({
      showUnenrollConfirm: false,
      data: {
        ...this.state.data,
        enrollmentStatus: false,
        errors: _.omit(this.state.errors, 'enrollmentStatus'),
      }
    });
  };

  handleScheduleChange = (schedule) => {
    this.setState({
      data: {
        ...this.state.data,
        schedule,
      },
    });
  };

  handleDateChange = (e, { name, value, valid }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
      errors: valid ? _.omit(this.state.errors, name) : this.state.errors,
    });
  };

  validate = (data) => {
    const errors = {};

    if (!data.rooms || !data.rooms.length)
      errors.rooms = 'Homeroom is required';
    if (!data.enrollmentStatus) {
      if (!data.unenrollmentDate)
        errors.unenrollmentDate =
          'Unenrollment date is required when a student is unenrolled.';
      if (!data.unenrollmentInitatedBy)
        errors.unenrollmentInitatedBy =
          'Unenrollment initiated by is required when a student is unenrolled.';
      if (!data.unenrollmentReason)
        errors.unenrollmentReason =
          'Unenrollment reason is required when a student is unenrolled.';
    }

    return errors;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const {
        data: { id, tourDate, unenrollmentDate, enrollmentDate, ...rest },
      } = this.state;
      const { currentOrganization } = this.props;

      this.setState({ loading: true });

      const formData = {
        tourDate: formatStringAsUtcMillisOrNull(tourDate),
        unenrollmentDate: formatStringAsUtcMillisOrNull(unenrollmentDate),
        enrollmentDate: formatStringAsUtcMillisOrNull(enrollmentDate),
        ...rest,
      };

      if (currentOrganization && currentOrganization.id)
        if (id) {
          // Update.
          this.props
            .organizationUpdateStudent(currentOrganization.id, {
              id,
              ...formData,
              organization: currentOrganization.id,
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              this.props.onClose('updated');
              // return;

              this.setState({ loading: false });
              if (this.props.done) this.props.done();
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { 'Unable to Update': error.message },
              })
            );
        } else {
          // New entry.
          this.props
            .organizationAddStudent(currentOrganization.id, {
              ...formData,
              organization: currentOrganization.id,
            })
            .then(() => {
              this.setState({ loading: false }, () => {
                // Used to close wrapper HOC. e.g Sliding panel, modal...
                this.props.onClose('added');
              });
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { 'Unable to Add': error.message },
              })
            );
        }
    }
  };

  render() {
    const { data, loading, errors, showUnenrollConfirm } = this.state;
    const {
      currentOrganization: { programType },
      t,
    } = this.props;

    return (
      <Segment basic textAlign="left">
        <Confirm
          open={showUnenrollConfirm}
          content={t('unenrollConfirm')}
          onConfirm={this.handleUnenrollConfirm}
          onCancel={() => this.setState({ showUnenrollConfirm: false })}
        />
        <ShowError errors={errors} />
        <Form
          id="student-enrollment-information-form"
          onSubmit={this.onSubmit}
          loading={loading}
          noValidate
        >
          {(programType === null ||
            (programType && programType !== 'familyChildCare')) && (
            <Form.Field error={!!errors.rooms}>
              <RoomPicker
                error={!!errors.rooms}
                id="rooms"
                name="rooms"
                label={t('Homeroom')}
                placeholder={t('Homeroom')}
                value={data?.rooms[0] ?? ''}
                selection
                search
                required
                onChange={this.onChange}
              />
              {errors.rooms && <InlineError text={errors.rooms} />}
            </Form.Field>
          )}

          <SchedulePicker
            error={!!errors.schedule}
            id="schedule"
            name="schedule"
            label={t('Schedule')}
            value={data?.schedule ?? []}
            onScheduleChange={this.handleScheduleChange}
          />

          <Form.Field>
            <DatePicker
              id="tourDate"
              name="tourDate"
              value={data.tourDate}
              onChange={this.handleDateChange}
              label={t('Tour Date')}
              placeholder={t('Select a tour date')}
              minDate={this.state.minDateNow}
              error={!!errors.tourDate}
              closable
            >
              {errors.tourDate && <InlineError text={errors.tourDate} />}
            </DatePicker>
          </Form.Field>

          <Form.Field>
            <DatePicker
              id="enrollmentDate"
              name="enrollmentDate"
              value={data.enrollmentDate}
              onChange={this.handleDateChange}
              label={t('Enrollment Date')}
              placeholder={t('Select an enrollment date')}
              error={!!errors.enrollmentDate}
              closable
            >
              {errors.enrollmentDate && (
                <InlineError text={errors.enrollmentDate} />
              )}
            </DatePicker>
          </Form.Field>

          <Form.Field error={!!errors.enrollmentStatus}>
            <label>{t('Enrollment Status')}</label>
            <Form.Radio
              toggle
              id="enrollmentStatus"
              name="enrollmentStatus"
              onClick={this.onChange}
              checked={data.enrollmentStatus}
              control={Checkbox}
            />
            {errors.enrollmentStatus && (
              <InlineError text={errors.enrollmentStatus} />
            )}
          </Form.Field>

          {!data.enrollmentStatus && (
            <SectionCard header={t('Unenrollment Details')}>
              <Form.Field>
                <DatePicker
                  id="unenrollmentDate"
                  name="unenrollmentDate"
                  value={data.unenrollmentDate}
                  onChange={this.handleDateChange}
                  label={t('Unenrollment Date')}
                  placeholder={t('Select unenrollment date')}
                  error={!!errors.unenrollmentDate}
                  closable
                >
                  {errors.unenrollmentDate && (
                    <InlineError text={errors.unenrollmentDate} />
                  )}
                </DatePicker>
              </Form.Field>

              <Form.Field error={!!errors.unenrollmentInitatedBy}>
                <Form.Dropdown
                  clearable
                  required={!data.enrollmentStatus}
                  id="unenrollmentInitatedBy"
                  name="unenrollmentInitatedBy"
                  label={t('Unenrollment Initiated By')}
                  placeholder={t('Unenrollment initiated by')}
                  selection
                  selectOnBlur={false}
                  value={data.unenrollmentInitatedBy}
                  onChange={this.onChange}
                  options={unenrollmentInitatedByOptions}
                />
              </Form.Field>

              <Form.Field error={!!errors.unenrollmentReason}>
                <Form.Dropdown
                  clearable
                  required={!data.enrollmentStatus}
                  id="unenrollmentReason"
                  name="unenrollmentReason"
                  label={t('Why is this family unenrolling?')}
                  placeholder={t('Why is this family unenrolling?')}
                  selection
                  selectOnBlur={false}
                  value={data.unenrollmentReason}
                  onChange={this.onChange}
                  options={unenrollmentReasonOptions}
                />
              </Form.Field>
            </SectionCard>
          )}

          <br />
          <br />

          <Form.Group>
            <Form.Button primary content={data.id ? t('Update') : t('Save')} />
            {!this.props.hideCancel && (
              <Form.Button
                basic
                content={t('Cancel')}
                onClick={(e) => {
                  if (e) e.preventDefault();
                  this.props.onClose();
                }}
              />
            )}

            <Form.Button
              basic
              content={t('Cancel')}
              onClick={(e) => {
                if (e) e.preventDefault();
                if (this.props.done) this.props.done();
              }}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

StudentEnrollmentForm.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  students: PropTypes.shape({
    selectedStudent: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  organizationAddStudent: PropTypes.func,
  organizationUpdateStudent: PropTypes.func,
  studentSelectionCleared: PropTypes.func,
  onClose: PropTypes.func,
};

StudentEnrollmentForm.defaultProps = {
  onClose: () => {},
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  students: state.students,
});

export default withTranslation()(
  connect(mapStateToProps, {
    organizationAddStudent,
    organizationUpdateStudent,
    studentSelectionCleared,
  })(StudentEnrollmentForm)
);
