import { firestore, storage } from '.';
import { buildObjectsTree, buildObject } from './helpers/firestore';

export const newDocument = ({ path }) => {
  try {
    if (!path) throw Error('Path must be specified');
    return firestore().collection(path).doc();
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const getDocument = async ({ path }) => {
  try {
    if (!path) throw Error('Path must be specified');
    let ref = firestore().doc(path);
    const doc = await ref.get();
    return buildObject(doc);
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const startDocumentListener = ({ path }, next, error, complete) => {
  if (!path) throw Error('Path must be specified');
  try {
    let ref = firestore().doc(path);
    return ref.onSnapshot(
      (doc) =>
        next(
          doc
            ? {
                uid: doc.id,
                id: doc.id,
                ...doc.data(),
              }
            : {}
        ),
      error,
      complete
    );
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const startCollectionListener = (
  { path, conditions = [], orderBy = [], limit = null, startAfter = null },
  next,
  error,
  complete
) => {
  try {
    if (!path) throw Error('Path must be specified');
    let ref = firestore().collection(path);

    if (startAfter && (!limit || !orderBy))
      throw Error('OrderBy and limit are required during paginated calls');

    orderBy.forEach((order) => {
      const { field, direction = 'asc' } = order;
      if (field && direction) ref = ref.orderBy(field, direction);
    });

    conditions.forEach((condition) => {
      const { field, operation, value } = condition;
      if (field && operation && value) ref = ref.where(field, operation, value);
    });

    if (limit && typeof limit === 'number') ref = ref.limit(limit);

    if (startAfter && orderBy && startAfter.id)
      ref = ref.startAfter(startAfter);

    return ref.onSnapshot(
      (querySnapshot) =>
        next(
          querySnapshot
            ? {
                count: querySnapshot.size,
                list: querySnapshot.docs.map((doc) => ({
                  uid: doc.id,
                  id: doc.id,
                  ...doc.data(),
                })),
                last: querySnapshot.docs[querySnapshot.docs.length - 1],
              }
            : {}
        ),
      error,
      complete
    );
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const getDocuments = async ({
  path,
  conditions = [],
  orderBy = [],
  limit = null,
  startAfter = null,
}) => {
  try {
    const snapshot = await createQuerySnapshot(
      path,
      conditions,
      orderBy,
      limit,
      startAfter
    );
    const lastDocument = snapshot.docs[snapshot.docs.length - 1];

    return {
      data: buildObjectsTree(snapshot.docs) || {},
      lastDocument: lastDocument || null,
    };
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const getDocumentsAsArray = async ({
  path,
  conditions = [],
  orderBy = [],
  limit = null,
  startAfter = null,
}) => {
  try {
    const querySnapshot = await createQuerySnapshot(
      path,
      conditions,
      orderBy,
      limit,
      startAfter
    );
    const lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];

    return {
      count: querySnapshot.size,
      list: querySnapshot.docs.map((doc) => ({
        uid: doc.id,
        id: doc.id,
        ...doc.data(),
      })),
      lastDocument: lastDocument || null,
      hasMoreData: querySnapshot.size === limit,
    };
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

// Take 2 objects that where returned from fetchDocumentsAsArray and merge them into a new object
// with source.lastDocument as the lastDocument (used for pagination)
export const mergeDocumentsAsArray = (target, source) => {
  if (!target) return source;

  const list = [...target.list, ...source.list];
  return {
    list,
    lastDocument: source.lastDocument,
    count: list.length,
    hasMoreData: source.hasMoreData,
  };
};

async function createQuerySnapshot(
  path,
  conditions,
  orderBy,
  limit,
  startAfter
) {
  let docRef = firestore().collection(path);

  if (startAfter && (!limit || !orderBy))
    throw Error('OrderBy and limit are required during paginated calls');

  orderBy.forEach((order) => {
    const { field, direction } = order;
    if (field) docRef = docRef.orderBy(field, direction || 'asc');
  });

  conditions.forEach((condition) => {
    const { field, operation, value } = condition;
    if (
      field &&
      operation &&
      (value !== null || value !== undefined || value !== '')
    )
      docRef = docRef.where(field, operation, value);
  });

  if (limit && typeof limit === 'number') docRef = docRef.limit(limit);

  if (startAfter && orderBy && startAfter.id) {
    docRef = docRef.startAfter(startAfter);
  }
  return await docRef.get();
}

export const addDocument = async ({ path, data }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!data) throw Error('Data cannot be empty');

    let ref = firestore().collection(path);
    return ref.add(data);
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const updateDocument = async ({ path, data }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!data) throw Error('Data cannot be empty');
    let ref = firestore().doc(path);
    return ref.update(data);
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const deleteDocument = async ({ path }) => {
  try {
    if (!path) throw Error('Path must be specified');
    let ref = firestore().doc(path);
    return ref.delete();
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const setDocument = async ({ path, data, merge = true }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!data) throw Error('Data cannot be empty');
    let ref = firestore().doc(path);
    return ref.set(data, { merge });
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const downloadFile = async ({ path, refId }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!refId) throw Error('refId cannot be empty');
    const storageRef = storage().ref(path).child(refId);
    const downloadUrl = await storageRef.getDownloadURL();
    return downloadUrl;
  } catch (error) {
    console.log(error);
    switch (error.code) {
      // File doesn't exist
      case 'storage/object-not-found':
        return error.message;

      // User doesn't have permission to access the object
      case 'storage/unauthorized':
        return error.message;

      // User canceled the upload
      case 'storage/canceled':
        return error.message;

      // Unknown error occurred, inspect the server response
      case 'storage/unknown':
        return error.message;
      default:
        return;
    }
  }
};

export const deleteFile = async ({ path, refId }) => {
  try {
    if (!path) throw Error('Path must be specified');
    if (!refId) throw Error('refId cannot be empty');
    const storageRef = storage().ref(path).child(refId);
    return storageRef.delete();
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};
