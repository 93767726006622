import { getFirebaseConfig } from './env';
import { getFirebase } from '../firebase';
/* eslint-disable no-unused-vars */
// use for types in comments
import firebase from 'firebase/app';
/* eslint-enable no-unused-vars */
/**
 *
 * @param {'local' | 'dev' | 'prod'} env
 * @returns {firebase.app.App} firebase app
 */
const initFirebase = (env) => {
  const config = getFirebaseConfig(env);
  return getFirebase(config);
};

export { initFirebase };
