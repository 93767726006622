import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { addError } from '../../../helpers/errors';
import { useInvoiceEditItems } from '../../../hooks/useInvoiceEdit';

import SectionCard from '../../Shared/SectionCard';
import ShowError from '../../Messages/ShowError';

import { StepsContainer } from './Steps';
import InvoiceItemList from './InvoiceItemList';
import './Billing.scss';

export default function StepInvoiceItemList({ onSave, isInvoice = true }) {
  const { t } = useTranslation();
  const _invoiceItems = useInvoiceEditItems();

  const invoiceItemListRef = useRef(_invoiceItems ?? []);
  const [errors, setErrors] = useState();

  return (
    <>
      <StepsContainer
        isInvoice={isInvoice}
        CurrentForm={InvoiceItemsForm}
        onNext={onSaveLocal}
      />
    </>
  );

  function InvoiceItemsForm() {
    return (
      <SectionCard header={t('Enter charges')}>
        <ShowError errors={errors} />

        <InvoiceItemList
          invoiceItemList={invoiceItemListRef.current}
          onChange={onChangeInvoiceItemList}
        />
      </SectionCard>
    );
  }

  function onSaveLocal(doValidate = true) {
    const errors = doValidate ? validate() : false;

    if (errors) {
      setErrors(errors);
      return false;
    } else if (onSave) {
      onSave(invoiceItemListRef.current);
      return true;
    }
  }

  function validate() {
    let errors = null;
    if (!invoiceItemListRef.current?.length)
      errors = addError(
        errors,
        'invoiceItems',
        'Invoice must have at least one item'
      );

    return errors;
  }

  function onChangeInvoiceItemList(invoiceItemList) {
    invoiceItemListRef.current = invoiceItemList;
  }
}
