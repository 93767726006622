/*****************************/
/* REDUCED from SSO-ACCOUNTS */
/*****************************/
// CODE MIRRORED IN src/api/firebase/firestore.js (v9)
// This is using Firebase v8 syntax
// :: TODO :: share code, standardize on v9

import { getFirestore } from './index';
import { logError } from '../../rollbar';

/* eslint-disable no-unused-vars */
// use for types in comments
import firebase from 'firebase/app';
/* eslint-enable no-unused-vars */

/**
 * @param {string} path
 * @returns {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>}
 */
const getDocRef = (path, firestoreCtx = getFirestore()) =>
  firestoreCtx.doc(path);
/**
 * ### Async / Promise
 * Write new data to a document using a merge strategy.
 * Accepts a batch parameter.
 * @param {string} docPath document path
 * @param {Object} data data to be written
 * @return {true | undefined}
 * if undefined, an error occurred. otherwise, it returns true
 */
const writeMergeDoc = async (docPath, data, firestoreCtx = getFirestore()) => {
  try {
    const docRef = getDocRef(docPath, firestoreCtx);
    await docRef.set(data, { merge: true });
    return true;
  } catch (err) {
    logError(`Failed to write doc: ${err.message}`, err, {
      err,
      docPath,
      data,
    });
    return; // signals failure to write
  }
};
/**
 * ### Async / Promise
 * Gets document as json object
 * @param {string} docPath
 * @returns {firebase.firestore.DocumentData | undefined | null}
 * if undefined, an error occurred during reading.
 * if null, the document was not found.
 * otherwise, returns the document data as a json object
 */
const getDocument = async (docPath, firestoreCtx = getFirestore()) => {
  try {
    const docRef = getDocRef(docPath, firestoreCtx);
    const snapshot = await docRef.get();
    // snapshot.data() would be undefined if !snapshot.exists()
    return snapshot.data() || null; // subtly distinguish between failure case
  } catch (err) {
    logError(`Failed to get doc: ${err.message}`, err, { err, docPath });
    return; // signals failure to read.
  }
};
/**
 * listens for document updates, callback triggers at least once
 * if document doesn't exist, nextFn will be passed undefined
 * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} docRef
 * @param {(data: firebase.firestore.DocumentData | undefined, snapshot: DocumentSnapshot<firebase.firestore.DocumentData>) => void} nextFn
 * @param {(error: firebase.firestore.FirestoreError) => void} errorFn
 * @param {() => void} completeFn
 * @returns {() => void} unsub function
 */
const docOnListen = (docRef, nextFn, errorFn, completeFn) => {
  return docRef.onSnapshot(
    (snap) => nextFn(snap.data(), snap),
    errorFn,
    completeFn
  );
};

export {
  getDocRef,
  writeMergeDoc,
  getDocument,
  docOnListen,
  //
};
