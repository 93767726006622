import React from 'react';
import PropTypes from 'prop-types';

const defaultStyle = {
  color: '#ae5856',
  textAlign: 'left',
  minWidth: '100%',
};

const InlineError = ({ text, style }) => {
  return <div style={{ ...defaultStyle, ...style }}>{text}</div>;
};

InlineError.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InlineError;
