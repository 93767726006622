import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import RoomsList from '../Components/Rooms/RoomsList';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';

const Rooms = (props) => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.rooms);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Rooms') })}
      </title>
      <body class="rooms" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        <RoomsList />
      </Segment>
    </Container>
  );
};

export default Rooms;
