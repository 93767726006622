import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import InlineError from '../Messages/InlineError';
import { isEnabled, getUri } from '../../helpers/preCheck';

const preCheckRowStyle = {
  minHeight: '5em',
};

//preCheckData is an object with the following shape: { preCheck: preCheck }
export default function PreCheckSetup({
  onPreCheckDataChange,
  preCheckData,
  uriDefault = 'https://forms.wonderschool.com/health-check/',
  errors = null,
}) {
  const { t } = useTranslation();

  const { preCheck } = preCheckData;
  const isPreCheckEnabled = isEnabled(preCheckData);
  const uri = getUri(preCheckData);

  const onUriChange = (e, { name, value }) => {
    onPreCheckDataChange({
      preCheck: {
        ...preCheck,
        uri: value,
      },
    });
  };

  const onPreCheckEnabledChange = (e, { checked }) => {
    onPreCheckDataChange({
      preCheck: {
        ...preCheck,
        enabled: checked,
      },
    });
  };

  return (
    <>
      <Form.Radio
        toggle
        id="preCheckEnabled"
        name="preCheckEnabled"
        label={t('Enable PreCheck')}
        checked={isPreCheckEnabled}
        onChange={onPreCheckEnabledChange}
        control={Checkbox}
      />
      {isPreCheckEnabled && (
        <Form.Group widths="equal" style={preCheckRowStyle}>
          {uriDefault && (
            <Form.Field error={!!errors?.uri}>
              <Form.Input
                type="text"
                id="uri"
                name="uri"
                value={uri}
                onChange={onUriChange}
                label={t('Form URL')}
                placeholder={uriDefault}
              />
              {errors?.uri && <InlineError text={errors.uri} />}
            </Form.Field>
          )}
        </Form.Group>
      )}
    </>
  );
}
