import React from 'react';
import PropTypes from 'prop-types';
import CN from 'classnames';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Grid, Card, Popup, Icon } from 'semantic-ui-react';

// Import utils
import { formatFullName } from '../../../../../helpers/utils';

// Import styles
import './EmployeesList.scss';
import cardStyles from '../../../../Shared/CardHelpers/CardStyles.module.scss';


const EmployeesList = ({ employeesList, selectedEmployees, onSelect }) => {
  const { t } = useTranslation();

  return (
    <Grid.Row>
      <Grid.Column>
        <Card.Group stackable itemsPerRow={6}>
          {employeesList.map((student) => {
            const isActive =
              selectedEmployees.map((e) => e.id).indexOf(student.id) >= 0;
            const studentIsDisabled = Boolean(student.preventInvoiceReason);
            const disablePopup = !studentIsDisabled;
            return (
              <Popup
                key={student.id}
                on='click'
                disabled={disablePopup}
                wide
                trigger={
                  <Card
                    centered
                    className={`${isActive ? 'student selected' : 'student'} ${studentIsDisabled ? 'disabled' : ''}`}
                    key={student.id}
                    onClick={studentIsDisabled ? null : () => onSelect(student)}
                  >
                    <Card.Content className="center" textAlign="center">
                      <Avatar
                        name={formatFullName(student, true)}
                        round
                        size={44}
                        src={student.picture}
                      />
                    </Card.Content>

                    <Card.Content
                      textAlign="center"
                      className={CN(
                        cardStyles.textTruncate,
                        cardStyles.truncateWidthFix
                      )}
                    >
                      {formatFullName(student)}
                    </Card.Content>
                  </Card>}
              >
                {studentIsDisabled && <Popup.Content>
                  <Icon name="warning circle" color="red" />
                  <span>{t(student.preventInvoiceReason)}</span>
                </Popup.Content>}
              </Popup>
            );
          })}
        </Card.Group>
      </Grid.Column>
    </Grid.Row>
  );
};

EmployeesList.propTypes = {
  employeesList: PropTypes.array.isRequired,
  selectedEmployees: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default EmployeesList;
