import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [
  { key: 0, text: '12:00 AM', value: -62167201438000 },
  { key: 15, text: '12:15 AM', value: -62167200538000 },
  { key: 30, text: '12:30 AM', value: -62167199638000 },
  { key: 45, text: '12:45 AM', value: -62167198738000 },
  { key: 60, text: '1:00 AM', value: -62167197838000 },
  { key: 75, text: '1:15 AM', value: -62167196938000 },
  { key: 90, text: '1:30 AM', value: -62167196038000 },
  { key: 105, text: '1:45 AM', value: -62167195138000 },
  { key: 120, text: '2:00 AM', value: -62167194238000 },
  { key: 135, text: '2:15 AM', value: -62167193338000 },
  { key: 150, text: '2:30 AM', value: -62167192438000 },
  { key: 165, text: '2:45 AM', value: -62167191538000 },
  { key: 180, text: '3:00 AM', value: -62167190638000 },
  { key: 195, text: '3:15 AM', value: -62167189738000 },
  { key: 210, text: '3:30 AM', value: -62167188838000 },
  { key: 225, text: '3:45 AM', value: -62167187938000 },
  { key: 240, text: '4:00 AM', value: -62167187038000 },
  { key: 255, text: '4:15 AM', value: -62167186138000 },
  { key: 270, text: '4:30 AM', value: -62167185238000 },
  { key: 285, text: '4:45 AM', value: -62167184338000 },
  { key: 300, text: '5:00 AM', value: -62167183438000 },
  { key: 315, text: '5:15 AM', value: -62167182538000 },
  { key: 330, text: '5:30 AM', value: -62167181638000 },
  { key: 345, text: '5:45 AM', value: -62167180738000 },
  { key: 360, text: '6:00 AM', value: -62167179838000 },
  { key: 375, text: '6:15 AM', value: -62167178938000 },
  { key: 390, text: '6:30 AM', value: -62167178038000 },
  { key: 405, text: '6:45 AM', value: -62167177138000 },
  { key: 420, text: '7:00 AM', value: -62167176238000 },
  { key: 435, text: '7:15 AM', value: -62167175338000 },
  { key: 450, text: '7:30 AM', value: -62167174438000 },
  { key: 465, text: '7:45 AM', value: -62167173538000 },
  { key: 480, text: '8:00 AM', value: -62167172638000 },
  { key: 495, text: '8:15 AM', value: -62167171738000 },
  { key: 510, text: '8:30 AM', value: -62167170838000 },
  { key: 525, text: '8:45 AM', value: -62167169938000 },
  { key: 540, text: '9:00 AM', value: -62167169038000 },
  { key: 555, text: '9:15 AM', value: -62167168138000 },
  { key: 570, text: '9:30 AM', value: -62167167238000 },
  { key: 585, text: '9:45 AM', value: -62167166338000 },
  { key: 600, text: '10:00 AM', value: -62167165438000 },
  { key: 615, text: '10:15 AM', value: -62167164538000 },
  { key: 630, text: '10:30 AM', value: -62167163638000 },
  { key: 645, text: '10:45 AM', value: -62167162738000 },
  { key: 660, text: '11:00 AM', value: -62167161838000 },
  { key: 675, text: '11:15 AM', value: -62167160938000 },
  { key: 690, text: '11:30 AM', value: -62167160038000 },
  { key: 705, text: '11:45 AM', value: -62167159138000 },
  { key: 720, text: '12:00 PM', value: -62167158238000 },
  { key: 735, text: '12:15 PM', value: -62167157338000 },
  { key: 750, text: '12:30 PM', value: -62167156438000 },
  { key: 765, text: '12:45 PM', value: -62167155538000 },
  { key: 780, text: '1:00 PM', value: -62167154638000 },
  { key: 795, text: '1:15 PM', value: -62167153738000 },
  { key: 810, text: '1:30 PM', value: -62167152838000 },
  { key: 825, text: '1:45 PM', value: -62167151938000 },
  { key: 840, text: '2:00 PM', value: -62167151038000 },
  { key: 855, text: '2:15 PM', value: -62167150138000 },
  { key: 870, text: '2:30 PM', value: -62167149238000 },
  { key: 885, text: '2:45 PM', value: -62167148338000 },
  { key: 900, text: '3:00 PM', value: -62167147438000 },
  { key: 915, text: '3:15 PM', value: -62167146538000 },
  { key: 930, text: '3:30 PM', value: -62167145638000 },
  { key: 945, text: '3:45 PM', value: -62167144738000 },
  { key: 960, text: '4:00 PM', value: -62167143838000 },
  { key: 975, text: '4:15 PM', value: -62167142938000 },
  { key: 990, text: '4:30 PM', value: -62167142038000 },
  { key: 1005, text: '4:45 PM', value: -62167141138000 },
  { key: 1020, text: '5:00 PM', value: -62167140238000 },
  { key: 1035, text: '5:15 PM', value: -62167139338000 },
  { key: 1050, text: '5:30 PM', value: -62167138438000 },
  { key: 1065, text: '5:45 PM', value: -62167137538000 },
  { key: 1080, text: '6:00 PM', value: -62167136638000 },
  { key: 1095, text: '6:15 PM', value: -62167135738000 },
  { key: 1110, text: '6:30 PM', value: -62167134838000 },
  { key: 1125, text: '6:45 PM', value: -62167133938000 },
  { key: 1140, text: '7:00 PM', value: -62167133038000 },
  { key: 1155, text: '7:15 PM', value: -62167132138000 },
  { key: 1170, text: '7:30 PM', value: -62167131238000 },
  { key: 1185, text: '7:45 PM', value: -62167130338000 },
  { key: 1200, text: '8:00 PM', value: -62167129438000 },
  { key: 1215, text: '8:15 PM', value: -62167128538000 },
  { key: 1230, text: '8:30 PM', value: -62167127638000 },
  { key: 1245, text: '8:45 PM', value: -62167126738000 },
  { key: 1260, text: '9:00 PM', value: -62167125838000 },
  { key: 1275, text: '9:15 PM', value: -62167124938000 },
  { key: 1290, text: '9:30 PM', value: -62167124038000 },
  { key: 1305, text: '9:45 PM', value: -62167123138000 },
  { key: 1320, text: '10:00 PM', value: -62167122238000 },
  { key: 1335, text: '10:15 PM', value: -62167121338000 },
  { key: 1350, text: '10:30 PM', value: -62167120438000 },
  { key: 1365, text: '10:45 PM', value: -62167119538000 },
  { key: 1380, text: '11:00 PM', value: -62167118638000 },
  { key: 1395, text: '11:15 PM', value: -62167117738000 },
  { key: 1410, text: '11:30 PM', value: -62167116838000 },
  { key: 1425, text: '11:45 PM', value: -62167115938000 },
];

const HourPicker = ({ staticContext, control, children, ...rest }) => (
  <Dropdown {...rest} options={options} selectOnBlur={false} clearable />
);

export default HourPicker;
