import { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function ExistingUserConfirmationDialog({existingUser, onYes, onClose }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  if (!existingUser)
    return null;

  if (!onYes || !onClose)
    throw new Error ('onYes and onClose are required');

  const message = t('{{firstName}} {{lastName}} is already a member of this organization.',
    {firstName: existingUser.firstName, lastName: existingUser.lastName});

  return (
    <Modal
      open={!!existingUser}
      onClose={onClose}
      size="tiny"
    >
      <Modal.Header>{t('Existing User Found')}</Modal.Header>
      <Modal.Content>
        <p>{message}</p>
        <p>{t('Would you like to add them to this student? If not, you must use a different email address.')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose} loading={isLoading}>
          {t('No')}
        </Button>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content={t('Yes')}
          onClick={onYesLocal}
          loading={isLoading}
        />
      </Modal.Actions>
    </Modal>
  );

  async function onYesLocal (e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      await onYes (e);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      onClose (e);
    }
    onYes(e);
  }
}
