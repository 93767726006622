import { useSelector } from 'react-redux';

import { startChargesListener } from '../redux/actions/billingActions';
import { useListenerWithRedux } from './useListenerWithRedux';

export function useChargesListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startChargesListener(organizationId);
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

export function useCharges() {
  return useSelector((state) => {
    return state?.charges?.list ?? [];
  });
}
