import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

// Import component
import { formatCurrency, getFormatter } from '../../../helpers/utils';

const InvoiceItem = ({ item }) => (
  <>
    <List.Item>
      <List.Content>
        <List.Header>
          <div>{item.label}</div>
          <div>{formatCurrency(item.amount)}</div>
        </List.Header>
        {item.discounts.length > 0 &&
          item.discounts.map((discount, index) => (
            <List.Description key={`invoiceItemDiscount${index}`}>
              <div>{discount.label}</div>
              <div>-{getFormatter(discount.amountType)(discount.amount)}</div>
            </List.Description>
          ))}
      </List.Content>
    </List.Item>
    {/*<Divider/>*/}
  </>
);
InvoiceItem.propTypes = {
  item: PropTypes.object,
};

export default InvoiceItem;
