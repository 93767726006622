import { firestore, functions } from '.';
import {
  setDocument,
  getDocuments,
  getDocumentsAsArray,
  addDocument,
  updateDocument,
} from './firestore';

export const organizationOnListen = (organizationId, next, error, complete) =>
  firestore()
    .collection(`organizations`)
    .doc(organizationId)
    .onSnapshot(
      (doc) => next(doc ? { id: doc.id, ...doc.data() } : {}),
      error,
      complete
    );

export const provisionOrganizationUserAccount = async (data) => {
  try {
    if (!data) throw Error('user data is missing');
    if (!data.uid) throw Error('UID is required');

    const { uid, organizationName, programType, networkId, ...userData } = data;

    // Create new organization.
    const organization = firestore().collection('organizations').doc();
    const createdAt = firestore.FieldValue.serverTimestamp();

    // Create user
    await setDocument({
      path: `users/${uid}`,
      data: {
        id: uid,
        uid,
        ...userData,
        enabled: true,
        defaultOrganization: organization.id,
        organizations: { [organization.id]: true },
        createdAt,
      },
      merge: true,
    });

    // Prepare user data to be saved.
    let userProfile = {
      uid,
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      displayName: userData.displayName || '',
      email: userData.email || '',
      phoneNumber: userData.phoneNumber || '',
      photoURL: userData.photoURL || '',
      defaultOrganization: organization.id,
      organizations: { [organization.id]: true },
      staffType: ['organizationAdmin'],
      allowStaffLogin: true,
      staff: true,
      staffPin: userData.staffPin || '',
      enabled: true,
      language: userData.language || '',
    };

    // Modify the payload if it's FCC.
    if (programType && programType === 'familyChildCare') {
      const locationId = `${organization.id}-primaryLocation`;
      const roomId = `${organization.id}-primaryRoom`;

      userProfile = {
        ...userProfile,
        currentRoom: roomId,
        defaultRoom: roomId,
        defaultLocation: locationId,
        locations: {
          [locationId]: true,
        },
        rooms: {
          [roomId]: true,
        },
      };
    }

    const batch = firestore().batch();

    batch.set(organization, {
      name: organizationName || `${userData.firstName} Program`,
      programType: programType || '',
      networks: networkId ? [networkId] : [],
      setup: {
        firstLocationCreated: false,
        firstRoomCreated: false,
        firstStaffCreated: true,
        firstStudentCreated: false,
      },
      setupComplete: false,
      active: true,
      createdBy: uid,
      createdAt,
    });

    // Add signing up user to users sub-collection.
    batch.set(
      firestore().collection(`organizations/${organization.id}/users`).doc(uid),
      userProfile
    );

    await batch.commit();

    return userProfile;
  } catch (error) {
    console.error(error);
    throw Error(error.message || 'Unable to complete provisioning');
  }
};

export const createOrganization = (organizationData) => {
  const data = {
    ...organizationData,
    setup: {
      firstLocationCreated: false,
      firstRoomCreated: false,
      firstStaffCreated: false,
      firstStudentCreated: false,
      tabletPinsGenerated: false,
      permissionsGenerated: false,
      rolesGenerated: false,
    },
    setupComplete: false,
    active: true,
    createdAt: Date.now(),
  };

  return addDocument({ path: 'organizations', data });
};

export const updateOrganization = (organizationId, organizationData) => {
  return updateDocument({
    path: `organizations/${organizationId}`,
    data: organizationData,
  });
};

export const getAllOrganizations = (limit = null, startAfter = null) =>
  getDocuments({
    path: 'organizations',
    orderBy: [{ field: 'createdAt', direction: 'asc' }],
    limit,
    startAfter,
  });

export const getAllOrganizationsAsArray = (limit = null, startAfter = null) =>
  getDocumentsAsArray({
    path: 'organizations',
    orderBy: [{ field: 'createdAt', direction: 'asc' }],
    limit,
    startAfter,
  });

export const getSetupPayoutUri = async (organizationId) => {
  const { data } = await functions().httpsCallable(
    'callables-stripe-setupPayout'
  )({ organizationId });

  const queryString = new URLSearchParams(data.queryParameters).toString();
  return decodeURI(`${data.authorizeUri}?${queryString}`);
};

export const enableFastpayInStripe = async (organizationId, enable) => {
  await functions().httpsCallable('callables-stripe-enableFastpay')({
    enable,
    organizationId,
  });
};
