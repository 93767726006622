import React from 'react';
import PropTypes from 'prop-types';
import { Header, Input, Grid, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { EmployeesList } from './EmployeesList';
import RoomPicker from '../../../Rooms/RoomPicker';

const SelectEmployees = ({
  employeesList,
  selectedEmployees,
  room,
  onRoomChange,
  onSearch,
  onSelect,
}) => {
  const { t } = useTranslation();

  const validStudentsLength = employeesList.filter(({ preventInvoiceReason }) => !preventInvoiceReason).length;

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={12}>
          <Input onChange={onSearch} placeholder={t('Search...')} fluid />
        </Grid.Column>
        <Grid.Column width={4}>
          <RoomPicker
            selection
            search
            onChange={onRoomChange}
            placeholder={t('Room')}
            name={'room'}
            value={room}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          <Header as="h2">
            {t('{{studentsCount}} Students', {
              studentsCount: employeesList.length,
            })}
          </Header>
        </Grid.Column>
        <Grid.Column width={4} textAlign="right">
          {validStudentsLength > 0 && <Button type="button" onClick={() => onSelect(null, true)}>
            {selectedEmployees.length !== validStudentsLength
              ? t('Select All')
              : t('Unselect All')}
          </Button>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <EmployeesList
            selectedEmployees={selectedEmployees}
            onSelect={onSelect}
            employeesList={employeesList}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SelectEmployees.propTypes = {
  employeesList: PropTypes.array.isRequired,
  selectedEmployees: PropTypes.array.isRequired,
  roomsList: PropTypes.array.isRequired,
  room: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onRoomChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectEmployees;
