import React, { useState } from 'react';
import { Container, Segment } from 'semantic-ui-react';

import { useSegmentPage, PAGE_NAMES } from '../segment';

import PageHeader from '../Components/Shared/PageHeader';
import ShowErrors from '../Components/Messages/ShowError';
import SetupPayoutRibbon from '../Components/Setup/SetupPayoutRibbon';
import InvoicePlansList from '../Components/Forms/Billing/InvoicePlansList';

export default function InvoicePlans() {
  const [errorMessage, setErrorMessage] = useState(null);
  useSegmentPage(PAGE_NAMES.invoicePlans);

  return (
    <Container className="no-padding no-margin">
      <PageHeader pageName={'Billing'} classes="billing" />
      {errorMessage && <ShowErrors content={errorMessage} />}
      <SetupPayoutRibbon onError={(err) => setErrorMessage(err)} />

      <Segment basic>
        <InvoicePlansList />
      </Segment>
    </Container>
  );
}
