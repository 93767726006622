import { useSelector } from 'react-redux';

/**
 * Grabs rooms data from redux store.
 * @returns {Object}
 */
const useRooms = () => {
  const rooms = useSelector((state) => state?.rooms?.list ?? []);

  return rooms;
};

export default useRooms;
