import React, { useState } from 'react';

import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../helpers/utils';
import { dateFormatter } from '../../helpers/dates';
import NoDataMessage from '../../Components/Shared/NoDataMessage';

import {
  useTransactions,
  useTotalTransactionsCount,
} from '../transactionsHooks';

import ImageModal from './ImageModal';
import TransactionModal from './TransactionModal';
import TransactionsSearchBar from './TransactionsSearchBar';

import TransactionsStatsBar from './TransactionsStatsBar';

import { TransactionType, isExpenseTransactionType } from '../types';

import styles from '../Transactions.module.scss';

import noTransactionsImage from '../../styles/img/wonderfolks/transactions-list-is-empty.png';

export default function TransactionsList({ organization }) {
  const { t } = useTranslation();

  const [transactionTypeForModal, setTransactionTypeForModal] = useState(null);

  const [searchConditions, setSearchConditions] = useState(null);
  const [startAfter, setStartAfter] = useState(null);
  const [extraFetchProps, setExtraFetchProps] = useState(null);

  const [transaction, setTransaction] = useState(null);
  const [receiptURL, setReceiptURL] = useState(false);

  const totalTransactionsCount = useTotalTransactionsCount();

  const { loading, transactions } = useTransactions(
    organization.id,
    searchConditions,
    startAfter,
    extraFetchProps
  );

  const transactionList = transactions?.list ?? [];

  return (
    <>
      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={transactionList}
        columns={getTableColumns(t)}
        exportCSV={{
          fileName: 'transactions.csv',
          ignoreHeader: false,
        }}
      >
        {({ baseProps, csvProps }) => {
          return (
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header as="h1">{t('Posted Transactions')}</Header>
                  {t(
                    'See all transactions in your program. You can filter by specific transaction type, date range, or search for key words.'
                  )}
                  {/* Leave this here for when PM gets un the page to link to
                  <Link style={{ marginLeft: '0.5em' }} to="/transactions/learn More">
                    {t('Learn more')}
                  </Link>
                  */}
                </Grid.Column>
                {renderCommandsColumn(csvProps, t)}
              </Grid.Row>

              {renderPrivacyRow(t)}
              {renderTransactionsRow(baseProps, t)}
              {renderNoTransactionsRow(t)}
            </Grid>
          );
        }}
      </ToolkitProvider>
      <TransactionModal
        organization={organization}
        transaction={transaction}
        transactionType={transactionTypeForModal}
        onClose={onCloseTransactionModal}
        onSave={onSaveTransaction}
        open={!!transactionTypeForModal}
      />

      <ImageModal
        imageURL={receiptURL}
        open={!!receiptURL}
        onClose={onCloseImageModal}
      />
    </>
  );

  function renderCommandsColumn(csvProps, t) {
    if (!transactionsDoExist()) return null;

    return (
      <Grid.Column textAlign="right">
        <Link
          to="#"
          onClick={(e) => csvProps.onExport()}
          style={{ marginRight: '0.5em' }}
        >
          {t('Export to CSV')}
        </Link>
        {renderAddTransactionButton(t)}
      </Grid.Column>
    );
  }

  function renderAddTransactionButton(t) {
    return (
      <Dropdown
        text={t('Add transaction')}
        value=""
        className="button primary"
        options={[
          {
            key: 'expense',
            text: t('Money out (expense)'),
            value: 'expense',
          },
          {
            key: 'revenue',
            text: t('Money in (revenue)'),
            value: 'revenue',
          },
        ]}
        onChange={(e, { value }) => {
          e.stopPropagation();
          value === 'revenue' ? onAddRevenue() : onAddExpense();
        }}
      />
    );
  }

  function renderTransactionsRow(baseProps, t) {
    return (
      <>
        {transactionsDoExist() && (
          <Grid.Row>
            <Grid.Column>
              <TransactionsSearchBar onSearch={onSearch} loading={loading} />
            </Grid.Column>
          </Grid.Row>
        )}

        {transactionList.length > 0 && (
          <Grid.Row>
            <Grid.Column textAlign="center">
              <TransactionsStatsBar
                count={transactions.count}
                totalCount={totalTransactionsCount}
              />
              {renderTransactionsList(baseProps, t)}
              {transactions?.hasMoreData && (
                <Button loading={loading} onClick={onLoadMore}>
                  {t('Load More Transactions')}
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        )}
      </>
    );
  }

  function renderPrivacyRow(t) {
    if (!transactionsDoExist()) return null;

    return (
      <Grid.Row>
        <Grid.Column>{renderPrivacySegment(t)}</Grid.Column>
      </Grid.Row>
    );
  }
  function renderPrivacySegment(t) {
    return (
      <Segment>
        <Icon name="lock" color="grey" />
        {t('Your payment information and data are private.')}
        {/*
        <Link style={{ marginLeft: '0.5em' }} to="/transactions/learnMore">
          {t('Learn more')}
        </Link>
        */}
      </Segment>
    );
  }
  function renderTransactionsList(baseProps, t) {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (onSelectTransaction) onSelectTransaction(e, row);
      },
    };
    return (
      <div className="bootstrap-iso">
        <BootstrapTable
          classes="w-auto w-md-100"
          wrapperClasses="table-responsive"
          headerClasses={styles.transactionsListHeader}
          bordered={false}
          hover={true}
          rowClasses={styles.transactionsListRow}
          rowEvents={rowEvents}
          {...baseProps}
        />
      </div>
    );
  }

  function renderNoTransactionsRow(t) {
    return (
      <Grid.Row>
        <Grid.Column>
          {!transactionsDoExist() && renderNoTransactionsExistSegment(t)}
          {transactionsNotFound() && renderNoTransactionsFoundSegment(t)}
        </Grid.Column>
      </Grid.Row>
    );
  }

  function renderNoTransactionsExistSegment(t) {
    return (
      <Segment>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={12}>
              <Header as="h1">
                {t('Start tracking your expenses and revenue')}
              </Header>
              {t(
                'You have not posted any transactions yet. You can add a transaction by clicking the button below.'
              )}
              {renderPrivacySegment(t)}
              {renderAddTransactionButton(t)}
            </Grid.Column>
            <Grid.Column width={4}>
              <Image src={noTransactionsImage} size="medium" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  function renderNoTransactionsFoundSegment(t) {
    return (
      <Segment>
        <NoDataMessage
          message={t(
            'No transactions found that match your search criteria. Clear your search and try again.'
          )}
          image={noTransactionsImage}
          CallToActionComponent={() => (
            <Button primary size="large" onClick={forceTransactionsReload}>
              {t('Clear Search Criteria')}
            </Button>
          )}
        />
      </Segment>
    );
  }

  function onViewReceipt(e, _receiptURL) {
    e.preventDefault();
    e.stopPropagation();
    setReceiptURL(_receiptURL);
  }

  function onSelectTransaction(e, _transaction) {
    e.preventDefault();
    e.stopPropagation();
    setTransaction(_transaction);
    setTransactionTypeForModal(_transaction.transactionType);
  }

  function onAddRevenue() {
    setTransactionTypeForModal(TransactionType.REVENUE);
  }
  function onAddExpense() {
    setTransactionTypeForModal(TransactionType.EXPENSE);
  }
  function onCloseImageModal() {
    setReceiptURL(null);
  }
  function onCloseTransactionModal() {
    setTransaction(null);
    setTransactionTypeForModal(null);
  }
  function onSaveTransaction(_transaction, wasAdded) {
    if (wasAdded) {
      forceTransactionsReload();
    } else {
      const index = transactions.list?.findIndex(
        (t) => t.id === _transaction.id
      );
      if (index >= 0) {
        transactions.list[index] = _transaction;
      }
    }
  }
  function onSearch(_searchConditions) {
    setSearchConditions(_searchConditions);
    setStartAfter(null);
  }
  function onLoadMore() {
    setStartAfter(transactions?.lastDocument);
  }
  function forceTransactionsReload() {
    setStartAfter(null);
    setSearchConditions(null);
    setExtraFetchProps({ reload: true });
  }
  // if searchConditions is not set, and there were is no searchConditions, then no transactions exist in the db

  function transactionsDoExist() {
    return totalTransactionsCount > 0;
  }
  // There are transactions in the db, but none were found by the search or filter conditions
  function transactionsNotFound() {
    return (
      transactionsDoExist() && transactions?.count === 0 // we did a search, but none were found
    );
  }
  function getTableColumns(t) {
    return [
      {
        dataField: 'date',
        text: t('Date'),
        sort: true,
        formatter: (cell) => {
          return dateFormatter(cell, 'MM/DD/YYYY');
        },
        csvFormatter: (cell) => {
          return dateFormatter(cell, 'MM/DD/YYYY');
        },
      },
      {
        dataField: 'payee',
        text: t('Payee'),
        sort: true,
      },
      {
        dataField: 'payer',
        text: t('Payer'),
        sort: true,
      },
      {
        dataField: 'category',
        text: t('Category'),
        sort: true,
        formatter: (cell) => {
          return Array.isArray(cell) ? cell.join(', ') : t(cell) ?? '';
        },
      },
      {
        dataField: 'notes',
        text: t('Notes'),
        sort: true,
        formatter: (cell, row) => {
          return (
            <>
              {cell && <div>{cell}</div>}
              {row.receiptURL && (
                <div>
                  <Link
                    to="#"
                    onClick={(e) => onViewReceipt(e, row.receiptURL)}
                  >
                    {t('View receipt')}
                  </Link>
                </div>
              )}
            </>
          );
        },
        csvFormatter: (cell, row) => {
          const value = `"${cell}","${row.receiptURL}"`;
          return value;
        },
        csvText: t('Notes') + '","' + t('Receipt'),
        csvType: 'Number', // So BootstrapTable doesn't wrap the value in quotes
      },
      {
        dataField: 'amount',
        text: t('Amount'),
        sort: true,
        sortValue: (cell, row) => {
          return isExpenseTransactionType(row.transactionType) ? -cell : cell;
        },
        csvFormatter: (cell, row) => {
          return isExpenseTransactionType(row.transactionType) ? -cell : cell;
        },
        csvType: 'Number', // So BootstrapTable doesn't wrap the value in quotes
        formatter: (cell, row) => {
          let text = formatCurrency(cell, { precision: 2 });
          let color = 'green';
          if (isExpenseTransactionType(row.transactionType)) {
            text = '-' + text;
            color = 'red';
          }
          return (
            <Label
              color={color}
              content={t(text)}
              className={styles.transactionAmount}
            />
          );
        },
      },
      {
        dataField: 'actions',
        text: '',
        isDummyField: true,
        csvExport: false,
        formatter: (cell, row) => (
          <Link
            to="#"
            onClick={(e) => onSelectTransaction(e, row)}
            className={styles.transactionViewLink}
          >
            {t('View/Edit')}
          </Link>
        ),
        headerStyle: { width: '100px' },
      },
    ];
  }
}
