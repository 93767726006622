import { firestore } from '.';
import { getDocuments } from './firestore';
import { buildObjectsTree } from './helpers/firestore';

export const organizationRolesOnListen = (
  organizationId,
  next,
  error,
  complete
) =>
  firestore()
    .collection(`organizations/${organizationId}/_roles`)
    .orderBy('name', 'asc')
    .where('enabled', '==', true)
    .onSnapshot(
      querySnapshot =>
        next(querySnapshot ? buildObjectsTree(querySnapshot.docs) : {}),
      error,
      complete
    );

export const getRole = (organizationId, role) =>
  firestore()
    .doc(`organizations/${organizationId}/_roles/${role}`)
    .get()
    .then(doc => {
      if (doc.exists && doc.data().enabled)
        return { id: doc.id, ...doc.data() };
      return null;
    });

export const getAllRoles = (organizationId, staffRole, parentRole) => {
  return getDocuments({
    path: `organizations/${organizationId}/_roles`,
    orderBy: [{ field: 'name', direction: 'asc' }],
  });
};

export const getRoles = (organizationId, staffRole, parentRole) => {
  return getDocuments({
    path: `organizations/${organizationId}/_roles`,
    conditions: [
      { field: 'enabled', operation: '==', value: true },
      { field: 'staffRole', operation: '==', value: staffRole },
      { field: 'parentRole', operation: '==', value: parentRole },
    ],
    orderBy: [{ field: 'name', direction: 'asc' }],
  });
};
