import { storage } from '../api/firebase';
import FirebaseCloudStorage from '../Components/Upload/FirebaseCloudStorage';

import Uppy from '@uppy/core';
import uuid from 'uuid/v4';

export { initializeUppy, resetUppy, createUploadPath };

function initializeUppy(uploadPath, onUploaded, onAdded, uppyOptions = {}) {
  const storageRef = storage().ref().child(uploadPath);

  const uppy = new Uppy({
    id: 'uppy1',
    autoProceed: false,
    restrictions: {
      maxFileSize: 5000000,
      allowedFileTypes: ['image/*'],
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
    },
    ...uppyOptions,
  })
    .use(FirebaseCloudStorage, {
      storageRef,
      refId: 'profile',
      rename: true,
    })
    .on('upload-success', (file, body, uploadURL) => {}) // When individual files are uploaded to remote storage.
    .on('file-added', (result) => {
      if (onAdded) onAdded(result);
    })
    .on('complete', (result) => {
      if (onUploaded) onUploaded(result.successful[0]);
    });
  return uppy;
}

function resetUppy(uppy) {
  if (!uppy) return;

  uppy.reset();

  const currentFiles = uppy.getFiles();
  currentFiles.forEach((currentFile) => uppy.removeFile(currentFile.id));
}

function createUploadPath(rootPath, document) {
  const id = document?.id ?? document?.uid ?? uuid();
  return `${rootPath}/${id}`;
}
