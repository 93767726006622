import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {
  getFirebaseConfig,
  shouldEmulateFirebase,
  isTest,
} from '../config/env';

/**
 * @type {firebase.app.App}
 */
let wsaFirebase; // memoize the shared firebase app
const FIREBASE_SDK_APP_NAME = 'wsaFirebaseApp';

/**
 * initializes and memoizes the sdk firebase app
 * @param {object?} config firebase config json object
 * @returns {firebase.app.App} firebase app
 */
export const getFirebase = (config = getFirebaseConfig()) => {
  if (!wsaFirebase) {
    if (!config) {
      throw new Error('config required for initialization');
    }
    wsaFirebase = firebase.initializeApp(config, FIREBASE_SDK_APP_NAME);
    getFirestore().settings({
      merge: shouldEmulateFirebase(),
      // https://firebase.google.com/docs/reference/js/firestore_.firestoresettings.md#firestoresettings_interface
      ignoreUndefinedProperties: true,
    });

    if (shouldEmulateFirebase()) {
      // Auth emulator
      getAuth().useEmulator('http://localhost:9099', {
        disableWarnings: isTest(),
      });
      // Firestore emulator
      getFirestore().useEmulator('localhost', 8080);
    }
  }
  return wsaFirebase;
};

/**
 * Returns the Firebase Auth instance for the sdk
 * @returns {firebase.auth.Auth}
 */
export const getAuth = () => {
  const firebase = getFirebase();
  if (!firebase) {
    throw new Error('Firebase not yet initialized in Auth SDK');
  }
  return firebase.auth();
};

/**
 * Returns Firebase Firestore instance for the sdk
 * @returns {firebase.firestore.Firestore}
 */
export const getFirestore = () => {
  const firebase = getFirebase();
  if (!firebase) {
    throw new Error('Firebase not yet initialized in Auth SDK');
  }
  return firebase.firestore();
};
