import { getCurrentEnv, getWSConfig } from './env';
import { encodeBase64UrlJson } from '../utils/HashParams';

export const RETURN_URL_KEY = 'ret';
export const RETURN_STATE_KEY = 'rts';
export const NO_SIGN_UP_KEY = 'nos';
export const MOBILE_RETURN_KEY = 'mre';
export const INSTANT_RETURN_KEY = 'ire';
export const FULL_SIGN_OUT_KEY = 'fso';
export const USE_ID_JWT_KEY = 'uij';
export const FORM_STATE_KEY = 'frm';

export const JWT_KEY = 'jwt';
export const EXTRA_DATA_KEY = 'ext';
export const RETURN_SIGNIN_KEY = 'signin';
export const RETURN_SIGNUP_KEY = 'signup';
export const RETURN_SESSION_ID_KEY = 'ses';
export const RETURN_LANGUAGE_KEY = 'lang';

/**
 * Domain names to use depending on environment
 */
const getAuthDomain = (env = getCurrentEnv()) => getWSConfig(env).authDomain;
/**
 * Supported SSO endpoints
 */
const AuthEndpoints = {
  Signin: '/signin',
  Signup: '/signup',
  Signout: '/signout',
  Invited: '/invited',
};
/**
 * Joins domain name and endpoints in SSO app
 * @param {string} env environment name
 * @param {string} maybeEndpoint one of two kinds of strings,
 *  either the key to use for `AuthEndpoints` or the specific endpoint value
 * @returns {string} full fledged url for sso app (no payload)
 */
const makeAuthRoute = (env = getCurrentEnv(), maybeEndpoint) => {
  const domain = getAuthDomain(env);
  if (!domain) {
    throw new Error('Invalid env variable provided');
  }

  const endpoint = AuthEndpoints[maybeEndpoint] || maybeEndpoint;
  if (!endpoint) {
    throw new Error('Invalid endpoint key provided');
  }

  return domain + endpoint;
};
/**
 * @param {*} destination SSO endpoint
 * @param {*} env current environment
 * @returns { (Object?) => string } a generator function that can attach a payload to a sso url route via hash param
 */
const makeRouteMaker =
  (destination, env = getCurrentEnv()) =>
  (optState) => {
    const stateHash = optState ? `#${encodeBase64UrlJson(optState)}` : '';
    return makeAuthRoute(env, destination) + stateHash;
  };
/**
 * predefined SSO route maker functions
 */
const AuthRouteMakers = {
  Signin: makeRouteMaker(AuthEndpoints.Signin),
  Signup: makeRouteMaker(AuthEndpoints.Signup),
  Signout: makeRouteMaker(AuthEndpoints.Signout),
  Invited: makeRouteMaker(AuthEndpoints.Invited),
};
/**
 * @param {{
 *  mobileReturnMode: boolean,
 *  returnUrl: string,
 *  instantReturnMode: boolean,
 *  lang: string,
 * }} config
 *  - mobileReturnMode: whether to use the return url to a web based app or use a mobile-oriented sso-hosted page
 *  - returnUrl: client web resource location that sso should return to after flow
 *  - lang: i18n shortcode ('en')
 * @param {Object?} optState optional additional configuation options
 * @param {(Object) => {extState: Object, restState: Object}} customPayloadMaker
 *  Certain data properties are special cased. There is a default function that will ensure those are extracted for their
 *  desired uses. But some routes may want to provide their own. Any special root-level properties should be set in the
 *  returned `extState` and the rest in `restState`, which will be attached to the returned payload.
 * @returns {Object} return payload
 */

const makeReturnData = (
  {
    mobileReturnMode = false,
    returnUrl,
    instantReturnMode = false,
    lang,
    //
  },
  optState = {},
  customPayloadMaker = (state) => {
    return {
      extState: {},
      restState: state,
    };
  }
) => {
  let {
    // extract mobile return, instant return, lang setting from optState
    [MOBILE_RETURN_KEY]: isMobileReturn,
    [INSTANT_RETURN_KEY]: isInstantReturn,
    [RETURN_LANGUAGE_KEY]: langReturn,
    ...restOptState
  } = optState;
  // and from config
  isMobileReturn = isMobileReturn || mobileReturnMode;
  isInstantReturn = isInstantReturn || instantReturnMode;
  langReturn = langReturn || lang;

  if (!returnUrl && !isMobileReturn) {
    throw new Error('Auth Redirect requires returnUrl or mobile return');
  }
  const { extState, restState } = customPayloadMaker(restOptState);
  return {
    // stuff returnUrl if not mobile
    [RETURN_URL_KEY]: isMobileReturn ? '' : returnUrl,
    // stuff return state
    [RETURN_STATE_KEY]: { ...restState },
    // stuff external options at root level
    ...extState,
    // stuff mobile return, instant return, lang state
    ...(isMobileReturn ? { [MOBILE_RETURN_KEY]: returnUrl } : {}),
    ...(isInstantReturn ? { [INSTANT_RETURN_KEY]: true } : {}),
    ...(lang ? { [RETURN_LANGUAGE_KEY]: langReturn } : {}),
  };
};
/**
 * @param {{
 *  mobileReturnMode: boolean,
 *  returnUrl: string,
 *  instantReturnMode: boolean,
 *  lang: string,
 * }} config
 *  - mobileReturnMode: whether to use the return url to a web based app or use a mobile-oriented sso-hosted page
 *  - returnUrl: client web resource location that sso should return to after flow
 *  - lang: i18n shortcode ('en')
 * @param {(Object?) => string} routeMaker generator function that can attach a payload to a sso url route via hash param
 * @param {Object?} optState optional additional configuation options
 * @param {(Object) => {extState: Object, restState: Object}} customPayloadMaker
 *  Certain data properties are special cased. There is a default function that will ensure those are extracted for their
 *  desired uses. But some routes may want to provide their own. Any special root-level properties should be set in the
 *  returned `extState` and the rest in `restState`, which will be attached to the returned payload.
 * @returns {string} web resource location in the sso app that will conduct the signin action
 */
const makeReturnBase = (
  {
    mobileReturnMode = false,
    returnUrl,
    instantReturnMode = false,
    lang,
    //
  },
  routeMaker,
  optState = {},
  customPayloadMaker = (state) => {
    return {
      extState: {},
      restState: state,
    };
  }
) => {
  return routeMaker(
    makeReturnData(
      {
        mobileReturnMode,
        returnUrl,
        instantReturnMode,
        lang,
      },
      optState,
      customPayloadMaker
    )
  );
};
/**
 * @param {{
 *  noSignupMode: boolean,
 *  idTokenJwtMode: boolean,
 *  mobileReturnMode: boolean,
 *  returnUrl: string,
 *  lang: string,
 * }} config
 *  - noSignupMode: whether the sso app should forbid sign up (useful for mobile)
 *  - idTokenJwtMode: whether to return an id token jwt instead of the custom token jwt (useful for legacy apps)
 *  - mobileReturnMode: whether to use the return url to a web based app or use a mobile-oriented sso-hosted page
 *  - returnUrl: client web resource location that sso should return to after flow
 *  - lang: i18n shortcode ('en')
 * @param {*} optState optional additional configuation options
 * @returns {string} web resource location in the sso app that will conduct the signin action
 */
const getSigninUrl = (
  {
    noSignupMode = false,
    idTokenJwtMode = false,
    mobileReturnMode = false,
    returnUrl,
    lang,
  },
  optState = {}
) => {
  return makeReturnBase(
    {
      mobileReturnMode,
      returnUrl,
      lang,
    },
    AuthRouteMakers.Signin,
    optState,
    (state) => {
      let {
        // extract noSignup and idTokenJwtMode settings from state
        [NO_SIGN_UP_KEY]: isNoSignup,
        [USE_ID_JWT_KEY]: isUseIdJwt,
        [FORM_STATE_KEY]: formState,
        ...restState
      } = state;
      // and from config
      isNoSignup = isNoSignup || noSignupMode;
      isUseIdJwt = isUseIdJwt || idTokenJwtMode;
      return {
        // these are set at root level
        extState: {
          ...(isNoSignup ? { [NO_SIGN_UP_KEY]: true } : {}),
          ...(isUseIdJwt ? { [USE_ID_JWT_KEY]: true } : {}),
          ...(!!formState ? { [FORM_STATE_KEY]: formState } : {}),
        },
        restState,
      };
    }
  );
};
/**
 * @param {{
 *  idTokenJwtMode: boolean,
 *  mobileReturnMode: boolean,
 *  returnUrl: string,
 *  lang: string,
 * }} config
 *  - idTokenJwtMode: whether to return an id token jwt instead of the custom token jwt (useful for legacy apps)
 *  - mobileReturnMode: whether to use the return url to a web based app or use a mobile-oriented sso-hosted page
 *  - returnUrl: client web resource location that sso should return to after flow
 *  - lang: i18n shortcode ('en')
 * @param {*} optState optional additional configuation options
 * @returns {string} web resource location in the sso app that will conduct the signup action
 */
const getSignupUrl = (
  {
    idTokenJwtMode = false,
    mobileReturnMode = false,
    returnUrl,
    lang,
    //
  },
  optState = {}
) => {
  return makeReturnBase(
    {
      mobileReturnMode,
      returnUrl,
      lang,
    },
    AuthRouteMakers.Signup,
    optState,
    (state) => {
      let {
        // extract idTokenJwtMode settings from state
        [USE_ID_JWT_KEY]: isUseIdJwt,
        [FORM_STATE_KEY]: formState,
        ...restState
      } = state;
      // and from config
      isUseIdJwt = isUseIdJwt || idTokenJwtMode;
      return {
        // these are set at root level
        extState: {
          ...(isUseIdJwt ? { [USE_ID_JWT_KEY]: true } : {}),
          ...(!!formState ? { [FORM_STATE_KEY]: formState } : {}),
        },
        restState,
      };
    }
  );
};
/**
 * @param {{
 *  fullSignoutMode: boolean,
 *  mobileReturnMode: boolean,
 *  returnUrl: string
 *  instantReturnMode: boolean,
 *  lang: string,
 * }} config
 *  - fullSignoutMode: whether or not to use a total/all device signout
 *  - mobileReturnMode: whether to use the return url to a web based app or use a mobile-oriented sso-hosted page
 *  - returnUrl: client web resource location that sso should return to after flow
 *  - lang: i18n shortcode ('en')
 * @param {*} optState optional additional configuation options
 * @returns {string} web resource location in the sso app that will conduct the signout action
 */
const makeSignoutBase = (
  {
    fullSignoutMode = false,
    mobileReturnMode = false,
    returnUrl,
    instantReturnMode = false,
    lang,
  },
  optState = {}
) => {
  return makeReturnBase(
    {
      mobileReturnMode,
      returnUrl,
      instantReturnMode,
      lang,
    },
    AuthRouteMakers.Signout,
    optState,
    (state) => {
      let {
        // extract full sign out setting from state
        [FULL_SIGN_OUT_KEY]: isFullSignout,
        ...restState
      } = state;
      // and config
      isFullSignout = isFullSignout || fullSignoutMode;
      return {
        extState: {
          ...(isFullSignout ? { [FULL_SIGN_OUT_KEY]: true } : {}),
        },
        restState,
      };
    }
  );
};
/**
 * @param {{
 *  mobileReturnMode: boolean,
 *  returnUrl: string,
 *  instantReturnMode: boolean,
 *  lang: string,
 * }} config
 *  - mobileReturnMode: whether to use the return url to a web based app or use a mobile-oriented sso-hosted page
 *  - returnUrl: client web resource location that sso should return to after flow
 *  - lang: i18n shortcode ('en')
 * @param {*} optState optional additional configuation options
 * @returns {string} web resource location in the sso app that will conduct a normal (just this browser context) sign out
 */
const getSignoutUrl = (
  {
    mobileReturnMode = false,
    returnUrl,
    instantReturnMode = false,
    lang,
    //
  },
  optState = {}
) => {
  return makeSignoutBase(
    {
      fullSignoutMode: false,
      mobileReturnMode,
      returnUrl,
      instantReturnMode,
      lang,
    },
    optState
  );
};
/**
 * @param {{
 *  mobileReturnMode: boolean,
 *  returnUrl: string,
 *  instantReturnMode: boolean,
 *  lang: string,
 * }} config
 *  - mobileReturnMode: whether to use the return url to a web based app or use a mobile-oriented sso-hosted page
 *  - returnUrl: client web resource location that sso should return to after flow
 *  - lang: i18n shortcode ('en')
 * @param {*} optState optional additional configuation options
 * @returns {string} web resource location in the sso app that will conduct a full sign out action
 */
const getFullSignoutUrl = (
  {
    mobileReturnMode = false,
    returnUrl,
    instantReturnMode = false,
    lang,
    //
  },
  optState = {}
) => {
  return makeSignoutBase(
    {
      fullSignoutMode: true,
      mobileReturnMode,
      returnUrl,
      instantReturnMode,
      lang,
    },
    optState
  );
};
const makeInvitedUrl = () => {
  return AuthRouteMakers.Invited();
};
/**
 * Extract simpler data payload from hash param generated in sign in flow
 * @param {Object?} decoded decoded json from hash param
 * @returns {{
 *  jwt: string,
 *  sessionId: string,
 *  isSignin: boolean,
 *  signinData: Object?,
 *  isSignup: boolean,
 *  signupData: Object?
 *  selectedLanguage: string?,
 * } | {}} if empty object, there was no (usable) decoded data
 */
const extractReturnData = (decoded) => {
  if (decoded) {
    const {
      [JWT_KEY]: jwt,
      [EXTRA_DATA_KEY]: {
        [RETURN_SESSION_ID_KEY]: sessionId,
        [RETURN_SIGNIN_KEY]: signinData,
        [RETURN_SIGNUP_KEY]: signupData,
        [RETURN_LANGUAGE_KEY]: selectedLanguage,
      },
      [RETURN_STATE_KEY]: returnState,
    } = decoded;
    const isSignin = !!signinData;
    const isSignup = !!signupData;
    return {
      jwt,
      sessionId,
      isSignin,
      signinData,
      isSignup,
      signupData,
      selectedLanguage,
      returnState,
    };
  } else {
    return {};
  }
};

export {
  getSigninUrl,
  getSignupUrl,
  getSignoutUrl,
  getFullSignoutUrl,
  makeInvitedUrl,
  makeReturnData,
  extractReturnData,
};
