import React from 'react';
import { Icon, Segment, Header } from 'semantic-ui-react';
import moment from 'moment';

import { formatCurrency } from '../../helpers/utils';

const formatMessage = (amount, date, currency) =>
  (amount !== null || amount !== undefined) && date
    ? `${formatCurrency(amount)} ${currency.toUpperCase() || ''} ${date}`
    : '';

const InvoicePaidConfirmation = ({ invoice, paid }) => {
  if (!invoice.alreadyPaid && paid && paid.amount) {
    return (
      <Segment basic textAlign="center">
        <Icon name="check circle" color="green" size="big" />
        <div style={{ marginTop: '10px' }}>INVOICE PAID</div>
        <Header>
          {formatMessage(
            paid.amount,
            moment.unix(paid.date).format('LL'),
            paid.currency
          )}
        </Header>
      </Segment>
    );
  }

  if (invoice.alreadyPaid)
    return (
      <Segment basic textAlign="center">
        <Icon name="check circle" color="green" size="big" />
        <div style={{ marginTop: '10px' }}>INVOICE PAID</div>
        <Header>
          {formatMessage(
            invoice.paidAmount,
            moment(invoice.dateFinalized).format('LL'),
            'usd'
          )}
        </Header>
      </Segment>
    );

  return null;
};

export default InvoicePaidConfirmation;
