import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../../../../helpers/utils';

const InvoiceTotal = ({ total }) => {
  const { t } = useTranslation();

  return (
    <div className={'invoice-total'}>
      <div className={'inner'}>
        <span>{t('Total')}:</span> <strong>{formatCurrency(total)}</strong>
      </div>
    </div>
  );
};

InvoiceTotal.propTypes = {
  total: PropTypes.number.isRequired,
};

export default InvoiceTotal;
