import { useEffect, useState, useCallback } from 'react';
import useStateRef from 'react-usestateref';

import useAuthUser from './useAuthUser';
import useAuthSession from './useAuthSession';
import useSavedSessionId from './useSavedSessionId';
import useSessionRefresher from './useSessionRefresher';

import { updateSession, isActive, isRecent } from '../firebase/session';

const TIMEOUT = 7500;

/**
 * a hook that manages the one time initialization of the auth user listener and the dependent state
 * management functions (sessions, local storage, transition state, etc).
 * returns various lifecycle state, the user/session objects, captures any unrecoverable errors.
 * provides a method to register state during pre-sign-in to be used post-sign-in.
 * state will listen for user and session updates.
 * also provides session management hooks.
 * - setTransitionState: during sign up/in, you can attach transitionary state to be saved to the (eventual) user session.
 *    this will be consumed and erased by the end of a successful authentication flow.
 */
const useCombinedAuthState = (WSA) => {
  const {
    isLoading: isAuthLoading,
    isFailed: isAuthFailed,
    isReady: isAuthReady,
    hasUser,
    authUser,
    authError,
    hasClaimsAndToken,
    userClaims,
    idToken,
    forceRefresh,
  } = useAuthUser(WSA);
  const userId = authUser?.uid;
  const [savedSessionId, setSessionId] = useSavedSessionId(); // from local storage
  const [setMakingSession, makingSessionRef] = useStateRef(false).slice(1);
  const getMakingSession = useCallback(
    () => makingSessionRef.current,
    [makingSessionRef]
  );
  const {
    isFailed: isSessionFailed,
    hasSession,
    authSession,
    sessionError,
    isInvalidSession,
  } = useAuthSession(userId, savedSessionId);
  const [stateError, setStateError] = useState();
  const hasError = !!authError || !!sessionError || !!stateError;

  const sessionNeedsRefresh =
    !hasError &&
    hasUser &&
    hasSession &&
    isActive(authSession) &&
    !isRecent(authSession);
  // the check for savedSessionId is unique to clients which rely on SSO to provide a savedSessionId
  const sessionNeedsSignout =
    (hasUser && !savedSessionId) || (hasSession && !isActive(authSession));
  const { expiresAt: sessionExpires, nextRefresh: sessionNextRefresh } =
    useSessionRefresher(authSession);

  // reset the saved session id if it isn't usable
  useEffect(() => {
    if (isInvalidSession && !getMakingSession()) {
      setSessionId(undefined);
    }
  }, [getMakingSession, isInvalidSession, setSessionId]);

  // if session exists, check if it needs updating
  useEffect(() => {
    const maybeRefreshSession = async () => {
      if (sessionNeedsRefresh && !getMakingSession()) {
        try {
          setMakingSession(true);
          await updateSession(userId, authSession.sessionId, {
            ...authSession,
          });
        } catch (err) {
          setStateError(err);
        } finally {
          setMakingSession(false);
        }
      }
    };
    maybeRefreshSession();
    return () => {
      setMakingSession(false);
    }; // cancellation flag
  }, [
    authSession,
    getMakingSession,
    sessionNeedsRefresh,
    setMakingSession,
    userId,
  ]);

  const isSessionInProgress =
    hasSession && (sessionNeedsRefresh || getMakingSession());
  const isAuthenticated =
    hasUser && hasSession && hasClaimsAndToken && !isSessionInProgress;
  const isUserLoading = isAuthReady && hasUser && !isAuthenticated;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isUserLoading) {
        setStateError(new Error('Timed out looking for user'));
      }
    }, TIMEOUT);
    return () => clearTimeout(timer);
  });

  return {
    isLoading: isAuthLoading,
    isFailed: isAuthFailed || isSessionFailed || hasError,
    isReady: isAuthReady,
    isUserLoading,
    isAuthenticated,
    authUser,
    authSession,
    userClaims,
    idToken,
    authError: authError || sessionError || stateError,
    sessionNeedsSignout,
    /**
     * @type {number?} epoch millis until session expires
     */
    sessionExpires: sessionExpires,
    /**
     * @type {number?} epoch millis until session will be refreshed
     */
    sessionNextRefresh: sessionNextRefresh,
    /**
     * Turn off updates during signout
     */
    /**
     * perform cleanup operations during signout
     */
    cleanupSignout: () => {
      setSessionId(undefined);
    },
    forceUserRefresh: forceRefresh,
  };
};

export default useCombinedAuthState;
