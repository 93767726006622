import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Message, Segment } from 'semantic-ui-react';

// Import helpers.
import { routes } from '../../config/routes';
import WSA from '../../WSA';
import withPermission from '../Shared/withPermission';

const SetupRibbon = ({ currentOrganization, location: { pathname } }) => {
  const { setupComplete, fetching } = currentOrganization;
  const { isReady, isAuthenticated } = WSA.components.useWSAuth();
  const { t } = useTranslation();

  // Hide on unauthenticated pages.
  // Hide if the organization is still being fetched.
  // Hide if the setup steps are completed after fetching.
  if (
    !(isReady && isAuthenticated) ||
    fetching === true ||
    (fetching === false && setupComplete === true)
  ) {
    return null;
  }

  // Remove/hide ribbon if they are on the
  // setup page, verify, action or invoice.
  if (
    pathname &&
    (pathname.startsWith(routes.setup) ||
      pathname.startsWith(routes.verify) ||
      pathname.startsWith(routes.invoice) ||
      pathname.startsWith(routes.staff) ||
      pathname.startsWith(routes.profile) ||
      pathname.startsWith(routes.r401))
  ) {
    return null;
  }

  return (
    <Segment basic textAlign="center" className="setup-ribbon">
      <Message warning className="major">
        {t('You have not yet completed setting up your account')}&nbsp;
        <Link to="/setup">{t('Continue the setup')} &#8594;</Link>
      </Message>
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

export default withRouter(
  connect(mapStateToProps)(
    withPermission(SetupRibbon, 'can_setup_organization')
  )
);
