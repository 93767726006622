import React, { useState } from 'react';
import { Button, Grid, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid/v4';

import { formatCurrency, formatPercent } from '../../../helpers/utils';
import { calculateDiscountAmounts } from '../../../helpers/invoices';
import { useCharges } from '../../../hooks/useCharges';

import { Dropdown } from '../../Shared/Dropdown';

import InvoiceItemEditor from './InvoiceItemEditor';
import './Billing.scss';

export default function InvoiceItemList({
  invoiceItemList = [],
  onChange,
  isEditable = true,
}) {
  const { t } = useTranslation();
  const charges = useCharges();

  const [invoiceItem, setInvoiceItem] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([...invoiceItemList]);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  initializeInvoiceItemIds();

  return (
    <>
      {!isEditFormOpen && (
        <Table stackable compact="very" basic="very">
          <Table.Header className={'grid-header'}>
            <Table.Row verticalAlign="middle">
              <Table.HeaderCell width={4}>{t('Category')}</Table.HeaderCell>
              <Table.HeaderCell width={4}>{t('Description')}</Table.HeaderCell>
              <Table.HeaderCell width={4}>{t('Notes')}</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="right">
                {t('Amount')}
              </Table.HeaderCell>
              {isEditable && <Table.HeaderCell width={2} />}
            </Table.Row>
          </Table.Header>
          <Table.Body>{renderInvoiceItems(invoiceItems)}</Table.Body>
        </Table>
      )}
      {isEditFormOpen && renderInvoiceItemEditor()}
      {!isEditFormOpen && isEditable && renderAddChargeButtons()}
    </>
  );

  function renderInvoiceItems() {
    if (!invoiceItems?.length) return null;

    let total = 0;
    return (
      <>
        {invoiceItems.map((invoiceItem, index) => {
          total += Number(invoiceItem.amount);
          return (
            <>
              <Table.Row key={invoiceItem.id} verticalAlign="middle">
                <Table.Cell>{invoiceItem.category}</Table.Cell>
                <Table.Cell>{invoiceItem.item}</Table.Cell>
                <Table.Cell>{invoiceItem.notes}</Table.Cell>
                <Table.Cell />
                <Table.Cell textAlign="right">
                  {formatCurrency(invoiceItem.amount)}
                </Table.Cell>
                {isEditable && renderEditButtons(invoiceItem)}
              </Table.Row>
              {invoiceItem.discounts?.map((discount, j) => {
                const { percentAmount, currencyAmount } =
                  calculateDiscountAmounts(discount, invoiceItem.amount);
                total -= currencyAmount;
                return (
                  <Table.Row key={discount.id} disabled verticalAlign="middle">
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell>{discount.discountType}</Table.Cell>
                    <Table.Cell disabled textAlign="right">
                      {formatPercent(percentAmount)}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      -{formatCurrency(currencyAmount)}
                    </Table.Cell>
                    {isEditable && <Table.Cell />}
                  </Table.Row>
                );
              })}
            </>
          );
        })}
        <Table.Row key={'total'} verticalAlign="middle">
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell textAlign="right">{t('Total')}</Table.Cell>
          <Table.Cell textAlign="right">{formatCurrency(total)}</Table.Cell>
          {isEditable && <Table.Cell />}
        </Table.Row>
      </>
    );
  }
  function renderInvoiceItemEditor() {
    if (isEditFormOpen)
      return (
        <InvoiceItemEditor
          invoiceItem={invoiceItem}
          onSave={onSaveInvoiceItem}
          onCancel={onCancelInvoiceItem}
        />
      );
  }

  function renderAddChargeButtons() {
    return !!charges?.length ? (
      <Grid stackable>
        <Grid.Column width={8} textAlign="right">
          {renderSavedChargesDropdown()}
        </Grid.Column>
        <Grid.Column width={1} textAlign="center" verticalAlign="middle">
          <strong>{t('or')}</strong>
        </Grid.Column>
        <Grid.Column width={7} textAlign="left" verticalAlign="middle">
          {renderCreateChargeLink()}
        </Grid.Column>
      </Grid>
    ) : (
      <div className="add-charge-wrapper">{renderCreateChargeLink()}</div>
    );
  }

  function renderSavedChargesDropdown() {
    const options = charges.map((charge) => ({
      key: charge.id,
      text: charge.category + ' - ' + charge.item,
      value: charge.id,
    }));
    return (
      <Dropdown
        basic
        fluid={false}
        value={''}
        placeholder={t('Select a saved charge')}
        name={'savedCharge'}
        onChange={onChangeSavedCharge}
        options={options}
      />
    );
  }
  function renderCreateChargeLink() {
    return (
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          setInvoiceItem(null);
          setIsEditFormOpen(true);
        }}
      >
        <strong>+</strong>
        {t('Create a new charge')}
      </Link>
    );
  }

  function renderEditButtons(invoiceItem) {
    return (
      <Table.Cell textAlign="right" singleLine>
        <Button
          basic
          key="#delete-row"
          icon="trash"
          onClick={(e) => {
            e.preventDefault();
            onDeleteInvoiceItem(invoiceItem);
          }}
        />

        <Button
          basic
          key="#edit-row"
          icon="pencil"
          onClick={(e) => {
            e.preventDefault();
            onEditInvoiceItem(invoiceItem);
          }}
        />
      </Table.Cell>
    );
  }

  // Invoice Items are an array, not a document, so we need to generate IDs for them with uuid
  function initializeInvoiceItemIds() {
    invoiceItems?.forEach((invoiceItem, index) => {
      if (!invoiceItem.id) invoiceItem.id = uuid();

      invoiceItem.discounts?.forEach((discount, j) => {
        if (!discount.id) discount.id = uuid();
      });
    });
  }

  function onSaveInvoiceItem(invoiceItem) {
    setIsEditFormOpen(false);
    const index = invoiceItems.findIndex((item) => item.id === invoiceItem.id);

    let _invoiceItems;
    if (index >= 0) {
      _invoiceItems = [
        ...invoiceItems.slice(0, index),
        invoiceItem,
        ...invoiceItems.slice(index + 1),
      ];
    } else {
      // new invoice item
      _invoiceItems = [...invoiceItems, invoiceItem];
    }
    onChangeInvoiceItems(_invoiceItems);
  }
  function onCancelInvoiceItem() {
    setInvoiceItem(null);
    setIsEditFormOpen(false);
  }

  function onEditInvoiceItem(invoiceItem) {
    setInvoiceItem(invoiceItem);
    setIsEditFormOpen(true);
  }

  function onDeleteInvoiceItem(invoiceItem) {
    const newInvoiceItems = invoiceItems.filter((i) => i !== invoiceItem);
    onChangeInvoiceItems(newInvoiceItems);
  }
  function onChangeInvoiceItems(_invoiceItems) {
    setInvoiceItems(_invoiceItems);
    if (onChange) onChange(_invoiceItems);
  }

  function onChangeSavedCharge(e, { name, value }) {
    const charge = charges.find((charge) => charge.id === value);
    setInvoiceItem({ ...charge, id: uuid() });
    setIsEditFormOpen(true);
  }
}
