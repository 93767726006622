import { useEffect, useState } from 'react';
import { Icon, Segment, Header, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { formatCurrency } from '../../helpers/utils';
import { getBalance, isEnabled } from '../../helpers/fastpay';
import { getAllOrganizationsAsArray } from '../../api/firebase/organizations';
import { getUserProfile } from '../../api/firebase/users';

// Import components
import { FastpayStatus } from '../../Components/Billing/Fastpay';
import ImpersonationButton from '../Admin/Impersonation';
import OrganizationDetail from './OrganizationDetail';

export default function AllOrganizationsList () {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect ((props) => {
    const doFetch = async () => {
      try {
        setIsLoading(true);
        const _organizations = await getAllOrganizationsAsArray();
        setOrganizations(_organizations);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    doFetch();
  }, []);

  const renderOrganizationsList = () => {
    if (!organizations)
      return null;

    const { list } = organizations;
    return (
      <>
        <Table stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4} computer={4}>
                {t('Name')}
              </Table.HeaderCell>
              <Table.HeaderCell>{t('Primary Owner')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Created Date')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Fastpay')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {list.map((organization) => {
              return (
                <OrganizationItem
                  organization={organization}
                  onSelect={() => setSelectedOrganization(organization)}
                  key={organization.id}
                />
              );
            })}
          </Table.Body>
        </Table>
        {!!selectedOrganization && (
          <OrganizationDetail
            organization={selectedOrganization}
            isOpen={!!selectedOrganization}
            onClose={() => setSelectedOrganization(null)}
          />
        )}
      </>
    );
  };

  return (
    <Segment basic loading={isLoading}>
      <PageTitle organizations={organizations} t={t} />
      {renderOrganizationsList()}
    </Segment>
  );
}

function PageTitle ({organizations, t}) {
  if (!organizations)
    return null;

  const { count } = organizations;

  return (
    <Segment basic clearing>
      <Header
        as="h1"
        content={t('Providers ({{providersCount}})', {
          providersCount: count ?? 0,
        })}
      />
    </Segment>
  );
};

function OrganizationItem  ({ organization, onSelect }) {
  const [accountOwner, setAccountOwner] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (organization.createdBy)
      getUserProfile (organization.createdBy)
        .then((user) => setAccountOwner (user));

  }, [organization.createdBy]);

  const fastpayEnabled = isEnabled(organization);
  return (
    <Table.Row key={organization.id}>
      <Table.Cell width={4} verticalAlign="top">
        <Header as="h5">
          <Header.Content>
          <a href={`#${organization.id}`}
            onClick={(e) => {
              e.preventDefault();
              if (onSelect) onSelect (organization);
            }}
          >
            {organization.name}
          </a>
            <Header.Subheader>{organization.id ?? ''}</Header.Subheader>
            <Header.Subheader>
              <SetupStatus organization={organization} t={t}/>
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell>
        <Header as="h5">
          <Header.Content>
            {accountOwner?.displayName ?? ''}
            <Header.Subheader>{accountOwner?.email ?? ''}</Header.Subheader>
            <Header.Subheader>{accountOwner?.uid ?? ''}</Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell>
        {moment(organization?.createdAt).format('MM/DD/YYYY h:mm A') ?? ''}
      </Table.Cell>
      <Table.Cell>
        <Header as="h5">
          <Header.Content>
            <Header.Subheader>
              <FastpayStatus enabled={fastpayEnabled} />
            </Header.Subheader>
            {fastpayEnabled && (
              <Header.Subheader>
                {`${t('Current balance')}: ${formatCurrency(
                  getBalance(organization)
                )}`}
              </Header.Subheader>
            )}
          </Header.Content>
        </Header>
      </Table.Cell>

      <Table.Cell textAlign="right">
        <ImpersonationButton organization={organization} />
      </Table.Cell>
    </Table.Row>
  );
};

function SetupStatus ({organization, t}) {
  return organization.setupComplete ? (
    <>
      <Icon.Group size="small">
        <Icon name="circle" color="green" />
      </Icon.Group>
      {t('Fully onboarded')}
    </>
  ) : (
    <>
      <Icon.Group size="small">
        <Icon name="circle" color="orange" />
      </Icon.Group>
      {t('Not onboarded')}
    </>
  );
}