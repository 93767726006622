import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, Dropdown, Header, Segment } from 'semantic-ui-react';

import { useStudents } from '../../studentsHooks';

import RoomPicker from '../../../Components/Rooms/RoomPicker';
import withSlidingPanel from '../../../Components/Shared/withSlidingPanel';

import StudentAddForm from '../student/StudentAddForm';
import StudentDetail from '../student/StudentDetail';
import StudentCard from './StudentCard';

const SLIDER_WIDTH = '85%';

const SlidingStudentDetail = withSlidingPanel(StudentDetail, {
  width: SLIDER_WIDTH,
});

const PageTitle = ({ state, setState }) => {
  const { t } = useTranslation();
  const { students, count } = useStudents(state);
  const currentOrganization = useSelector(
    (state) => state.organizations?.currentOrganization
  );

  if (!students?.length && !state.filters) {
    return (
      <Segment basic clearing>
        <Header as="h1" floated="left" content={t('Students')} />
      </Segment>
    );
  }

  return (
    <Segment basic clearing>
      <Header as="h4" floated="right">
        <Button.Group className="blue">
          <StudentAddForm icon />
          <Dropdown className="button icon" floating trigger={<></>}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/import/students`}
                text={t('Import students')}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>
      </Header>

      {/* Should not be visible for familyChildCare program types. */}
      {currentOrganization?.programType !== 'familyChildCare' && (
        <Header as="h5" floated="right">
          <RoomPicker
            selection
            search
            clearable
            value={state.filters?.room || ''}
            placeholder={t('Filter by room')}
            onChange={(_, { value }) => {
              setState((prev) => ({
                ...prev,
                filters: { ...prev.filters, room: value },
              }));
            }}
            customOptions={[
              {
                key: 'no-room',
                text: t('No room'),
                value: 'no-room',
              },
            ]}
          />
        </Header>
      )}

      <Header as="h1" floated="left">
        <Dropdown
          floating
          inline
          options={[
            {
              value: 'all',
              text: t('All Students ({{studentsCount}})', {
                studentsCount: count,
              }),
              content: t('All Students', {
                studentsCount: count,
              }),
            },
            {
              value: 'active',
              text: t('Active Students ({{studentsCount}})', {
                studentsCount: count,
              }),
              content: t('Active Students', {
                studentsCount: count,
              }),
            },
            {
              value: 'unenrolled',
              text: t('Unenrolled Students ({{studentsCount}})', {
                studentsCount: count,
              }),
              content: t('Unenrolled Students', {
                studentsCount: count,
              }),
            },
          ]}
          value={state.filters?.enrollmentStatus}
          onChange={(_, { value }) => {
            setState((prev) => ({
              ...prev,
              filters: { ...prev.filters, enrollmentStatus: value },
            }));
          }}
        />
      </Header>
    </Segment>
  );
};

const StudentCards = ({ itemsPerRow, state, setState }) => {
  const { list: students } = useStudents(state);
  const dispatch = useDispatch();

  return (
    <Card.Group stackable itemsPerRow={itemsPerRow}>
      {students.map((student) => (
        <StudentCard
          key={student.id}
          student={student}
          onClick={() => {
            dispatch({ type: 'STUDENT_SELECTED', student });
            setState((prev) => ({ ...prev, isEditOpen: true }));
          }}
        />
      ))}
    </Card.Group>
  );
};

const SlidingForms = ({ state, setState }) => {
  const { selectedStudent } = useStudents(state);

  let studentName = selectedStudent?.displayName
    ? selectedStudent.displayName
    : `${selectedStudent?.firstName} ${selectedStudent?.lastName}`;

  // Concatenate nickname if it exists.
  if (selectedStudent?.nickName?.length)
    studentName += ` (${selectedStudent?.nickName})`;

  return (
    <div>
      <SlidingStudentDetail
        title={studentName}
        image={selectedStudent?.picture}
        isOpen={state.isEditOpen}
        onClose={() => {
          setState((prev) => ({ ...prev, isEditOpen: false }));
        }}
      />
    </div>
  );
};

const StudentsGallery = ({ itemsPerRow }) => {
  const [state, setState] = useState({
    isAddOpen: false,
    isEditOpen: false,
    filters: {
      room: '',
      enrollmentStatus: 'all',
    },
  });

  return (
    <>
      <PageTitle state={state} setState={setState} />
      <StudentCards
        itemsPerRow={itemsPerRow}
        state={state}
        setState={setState}
      />
      <SlidingForms state={state} setState={setState} />
    </>
  );
};

StudentsGallery.defaultProps = {
  itemsPerRow: 4,
};

StudentsGallery.propTypes = {
  itemsPerRow: PropTypes.number,
};

export default StudentsGallery;
