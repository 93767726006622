import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import WSA from '../WSA';
import { routes } from '../config/routes';

class TokenAuth extends Component {
  componentDidUpdate = async (prevProps, prevState) => {
    const {
      history,
      match: { params },
      isReady,
      isAuthenticated,
    } = this.props;

    if (isReady && isAuthenticated) {
      history.replace(routes.billing.index);
      return;
    } else if (isReady && !isAuthenticated && params.jwt) {
      // Sign in if with custom token.
      try {
        await WSA.auth.manualCustomTokenSignin(params.jwt);
        history.replace(routes.billing.index);
      } catch (error) {
        history.replace(routes.signin);
      }
    }
  };

  render() {
    return (
      <Dimmer page active inverted>
        <Loader inline="centered">Authenticating ...</Loader>
      </Dimmer>
    );
  }
}

const mapStateToProps = (state) => ({
  //
});
const mapAuthContextToProps = ({
  isReady,
  isAuthenticated,
  //
}) => {
  return {
    isReady,
    isAuthenticated,
  };
};

export default WSA.components.mapAuthContextToProps(
  mapAuthContextToProps,
  connect(mapStateToProps)(TokenAuth)
);
