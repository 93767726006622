import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Dropdown } from './Dropdown';
import { timezoneOptions as options } from '../../config';

const TimezonePicker = ({ staticContext, control, children, ...rest }) => {
  const [dst, setDst] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  useEffect(() => {
    //checks if it is currently Daylight Savings to show correct UTC offsets
    setDst(moment().isDST());
  }, []);

  useEffect(() => {
    //generates dropdown item labels to show UTC offset based on DST
    const selectOptions = options.map((zone) => {
      const tagObj = dst ? zone.dstTag : zone.tag;
      return {
        key: zone.key,
        value: zone.value,
        text: zone.text,
        content: (
          <>
            {zone.text} {`(${tagObj.abbr}, UTC${tagObj.utc})`}
          </>
        ),
      };
    });
    setTimezoneOptions(selectOptions);
  }, [dst]);

  return (
    <Dropdown
      {...rest}
      selectOnBlur={false}
      clearable
      options={timezoneOptions}
    ></Dropdown>
  );
};

export default TimezonePicker;
