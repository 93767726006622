import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { Area51Container } from '../Components/Admin/Area51';
// Import Admin Components
import OrganizationAdmin from '../Components/Admin/Providers';
import { UsersListContainer } from '../Components/Admin/Users';
// Import components
import PageHeader from '../Components/Shared/PageHeader';
import { NetworksAdminContainer } from '../networks';

class Admin extends Component {
  renderPageHeader = () => <PageHeader pageName={'Admin'} classes="admin" />;

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  sectionTitle = (title, attached = false) =>
    title && <Header as="h2" attached={attached} content={title} />;

  renderContent = () => {
    const {
      location: { pathname },
    } = this.props;

    let content = '';

    switch (pathname) {
      case '/admin/users':
        content = <UsersListContainer />;
        break;

      case '/admin/networks':
        content = <NetworksAdminContainer />;
        break;

      case '/admin/area-51':
        content = <Area51Container />;
        break;

      case '/admin/providers':
        content = <OrganizationAdmin />;
        break;

      default:
        content = null;
        break;
    }

    return <>{content}</>;
  };

  render() {
    return (
      <Segment basic>
        {this.renderPageHeader()}

        <Grid stackable>
          <Grid.Column computer={16} mobile={16} tablet={16}>
            {this.renderContent()}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

export default withTranslation()(Admin);
