import { STATS_FETCHED } from './types';
import { organizationStatsOnListen } from '../../api/firebase/stats';

export const statsFetched = (stats) => ({
  type: STATS_FETCHED,
  stats,
});

export const startOrganizationStatsListener = (organizationId) => (
  dispatch
) => {
  return organizationStatsOnListen(
    organizationId,
    (data) => {
      if (data) dispatch(statsFetched(data));
    },
    (error) => console.log(error.message)
  );
};
