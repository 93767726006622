import { useEffect, useState } from 'react';
import WSA from '../WSA';
import Loading from './Loading';
import ExternalRedirect from './ExternalRedirect';

const Signout = ({ fullSignoutMode = false }) => {
  const { isReady } = WSA.components.useWSAuth();
  const [didSignOut, setDidSignOut] = useState(false);
  // sometimes you get into a bad state during sign out
  // so isAuthenticated isn't always useful here.
  // so we take the approach of trying to force sign out even if unnecessary
  const isReadyForSignout = isReady && !didSignOut;

  useEffect(() => {
    let isStillValid = true;
    (async () => {
      if (isStillValid && isReadyForSignout) {
        const result = await WSA.auth.signoutLocal();
        if (!!result && isStillValid) {
          setDidSignOut(true);
        } else {
          console.log('Could not complete signout');
        }
      }
      // and then page will redirect to SSO app
      return () => {
        isStillValid = false;
      };
    })();
  }, [isReadyForSignout]);

  if (didSignOut) {
    const signoutUrl = fullSignoutMode
      ? WSA.urls.getFullSignout()
      : WSA.urls.getSignout();
    return <ExternalRedirect externalUrl={signoutUrl} shouldUseReplace />;
  } else {
    return <Loading />;
  }
};

export default Signout;
