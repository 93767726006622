import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import { withRouter } from 'react-router-dom';
import { functions } from '../../../api/firebase';
import { Button } from 'semantic-ui-react';
import { formatCurrency } from '../../../helpers/utils';

class _BankTransfer extends Component {
  state = {
    processing: false,
    loading: false,
    data: {
      name: '',
      amount: null,
      address_city: '',
      address_country: '',
      address_line1: '',
      address_line1_check: '',
      address_line2: '',
      address_state: '',
    },
  };

  componentDidMount() {
    const {
      invoice: { total },
    } = this.props;
    this.setState({ data: { ...this.state.data, amount: total } });
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();

    if (this.props.stripe) {
      const {
        invoice: { invoiceId },
      } = this.props;

      try {
        const tokenResponse = await this.props.stripe.createSource({
          type: 'ach_credit_transfer',
          currency: 'usd',
          owner: {
            name: ev.target.name.value,
            email: ev.target.email.value,
          },
          mandate: {
            notification_method: 'email',
          },
        });

        console.log(tokenResponse);

        if (!tokenResponse || !tokenResponse.token) {
          console.log('No Token');
          return;
        }

        const settlePayment = functions().httpsCallable(
          'callables-stripe-settlePayment'
        );

        const response = await settlePayment({
          token: tokenResponse.token,
          amount: this.props.invoice.total,
          invoiceId,
          description: `Payment for invoice - ${invoiceId}`,
        });

        console.log(response);
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  handleChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  render() {
    const { invoice } = this.props;
    const { loading } = this.state;
    return invoice && invoice.total > 0 ? (
      <form onSubmit={this.handleSubmit} noValidate>
        <label>
          Name on bank account
          <input
            name="name"
            type="text"
            placeholder="Jane Doe"
            required
            style={{ width: '100%' }}
          />
        </label>
        <label>
          Routing number
          <input
            name="routingNumber"
            type="text"
            required
            style={{ width: '100%' }}
          />
        </label>
        <label>
          Account number
          <input
            name="accountNumber"
            type="text"
            required
            style={{ width: '100%' }}
          />
        </label>

        <label>
          Amount
          <NumberFormat
            value={this.state.data.amount}
            thousandSeparator={true}
            prefix={'$'}
            style={{ width: '100%' }}
            decimalScale={2}
            fixedDecimalScale
            onValueChange={({ floatValue }) =>
              this.setState({
                data: { ...this.state.data, amount: floatValue },
              })
            }
          />
        </label>

        <Button
          loading={loading}
          disabled={loading}
          fluid
          positive
          content={`Pay  ${formatCurrency(this.state.data.amount)}`}
        />
      </form>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  //
});

const BankTransfer = injectStripe(
  withRouter(connect(mapStateToProps)(_BankTransfer))
);

export default BankTransfer;
