import {
  sendResetPasswordEmail,
  userHasPermission,
} from '../api/firebase/account';

const useAdminActions = () => {
  const resetUserPassword = (user) => {
    if (!user.uid || !user.email) throw new Error('Invalid user object');

    if (!userHasPermission('can_manage_users'))
      throw new Error('You do not have permission to send password reset link');

    return sendResetPasswordEmail(user.email);
  };

  // TODO: Add more admin actions here.

  return {
    resetUserPassword,
  };
};

export default useAdminActions;
