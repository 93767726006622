import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';

import { Grid, Image, Header, Modal } from 'semantic-ui-react';

import { StudentRooms } from '../../students/components';

import { formatFullName } from '../../helpers/utils';
import { formatUtcMillisAsString } from '../../helpers/dates';


export function AttendanceModal({
  activity,
  onClose,
  open,
}) {
  const { t } = useTranslation();
  const students = useSelector((state) => state.students?.list) || [];

  if (!activity) return null;

  const student = students.find(student => student.id === activity.student.id);

  const renderDropOff = (activity) => {
    const preCheckUrl = activity.dropoff.data.preCheck && new URL(activity.dropoff.data.preCheck);
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">{t('Checked In')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            {t('Date & Time')}
          </Grid.Column>
          <Grid.Column>
            {formatUtcMillisAsString(activity.activityTime, 'MM/DD/YYYY h:mma')}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            {t('Signee')}
          </Grid.Column>
          <Grid.Column>
            {activity.addedBy.fullName}
          </Grid.Column>
        </Grid.Row>
        {preCheckUrl && (
          <>
            <Grid.Row columns={2}>
              <Grid.Column>
                {t('Health check - symptoms')}
              </Grid.Column>
              <Grid.Column>
                {preCheckUrl.searchParams.getAll('symptoms').join(', ')}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>{t('Health check - notes')}</Grid.Column>
              <Grid.Column>{preCheckUrl.searchParams.get('notes')}</Grid.Column>
            </Grid.Row>
          </>
        )}
        <Grid.Row columns={2}>
          <Grid.Column>
            {t('Signature')}
          </Grid.Column>
          <Grid.Column>
            <Image height={150} style={{objectFit: 'scale-down'}} src={`data:image/png;base64,${activity.dropoff.data.signature}`} alt='Check In Signature' />
          </Grid.Column>
        </Grid.Row>
      </>
    );
  }

  const renderPickUp = (activity) => (
    <>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3">{t('Checked Out')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          {t('Date & Time')}
        </Grid.Column>
        <Grid.Column>
          {formatUtcMillisAsString(activity.activityTime, 'MM/DD/YYYY h:mma')}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          {t('Signee')}
        </Grid.Column>
        <Grid.Column>
          {activity.addedBy.fullName}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          {t('Signature')}
        </Grid.Column>
        <Grid.Column>
          <Image height={150} style={{objectFit: 'scale-down'}} src={`data:image/png;base64,${activity.pickup.data.signature}`} alt='Check Out Signature' />
        </Grid.Column>
      </Grid.Row>
    </>
  )

  const renderAbsent = (activity) => (
    <>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3">{t('Absent')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          {t('Date & Time')}
        </Grid.Column>
        <Grid.Column>
          {formatUtcMillisAsString(activity.activityTime, 'MM/DD/YYYY h:mma')}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          {t('Signee')}
        </Grid.Column>
        <Grid.Column>
          {activity.addedBy.fullName}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          {t('Signature')}
        </Grid.Column>
        <Grid.Column>
          <Image height={150} style={{objectFit: 'scale-down'}} src={`data:image/png;base64,${activity.absent.data.signature}`} alt='Absent Signature' />
        </Grid.Column>
      </Grid.Row>
    </>
  )


  return (
    <Modal
      onClose={onClose}
      open={open}
      closeIcon
      size="small"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Header as="h2" icon textAlign='center'>
                <Avatar
                  name={formatFullName(student, true)}
                  round
                  size={100}
                  src={student.picture}
                />
                <Header.Content>
                {`${formatFullName(student)} ${student.nickName ? `(${student.nickName})` : ''}`}
                </Header.Content>
              </Header>
              <p className="ui text muted">{activity.date}</p>
              <StudentRooms rooms={student?.rooms} />
            </Grid.Column>
          </Grid.Row>
          {(activity.type === 'dropoff') && renderDropOff(activity)}
          {(activity.type === 'pickup') && renderPickUp(activity)}
          {(activity.type === 'absent') && renderAbsent(activity)}
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

export default AttendanceModal;
