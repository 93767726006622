import {
  ORGANIZATION_LISTENER_STARTED,
  ORGANIZATION_FETCHED,
  MOXIT_ORGANIZATIONS_FETCHED,
  ORGANIZATION_SWITCHED,
  ORGANIZATION_CREATED,
  ORGANIZATION_UPDATED,
} from './types';

// Import API.
import {
  organizationOnListen,
  getAllOrganizations,
  createOrganization,
  updateOrganization,
} from '../../api/firebase/organizations';
import { updateUserProfile } from '../../api/firebase/users';
import { getOrganizationSetupState } from '../reducers/organizations';

import { isEnabled } from '../../helpers/fastpay';

import { enableFastpayInStripe } from '../../api/firebase/organizations';

export const organizationListenerStarted = () => ({
  type: ORGANIZATION_LISTENER_STARTED,
});

export const organizationFetched = (organization) => ({
  type: ORGANIZATION_FETCHED,
  organization,
});

export const moxitOrganizationsFetched = (organizations) => ({
  type: MOXIT_ORGANIZATIONS_FETCHED,
  organizations,
});

export const organizationSwitched = () => ({
  type: ORGANIZATION_SWITCHED,
});

export const organizationCreated = () => ({
  type: ORGANIZATION_CREATED,
});

export const organizationUpdated = () => ({
  type: ORGANIZATION_UPDATED,
});

export { getOrganizationSetupState };

export const startOrganizationListener = (organizationId) => (dispatch) => {
  dispatch(organizationListenerStarted());

  return organizationOnListen(
    organizationId,
    (organization) => {
      if (organization) dispatch(organizationFetched(organization));
    },
    (error) => console.log(error.message)
  );
};

export const getMoxitOrganizations = (limit, last) => async (dispatch) => {
  try {
    const organizations = await getAllOrganizations(limit, last);
    return dispatch(moxitOrganizationsFetched(organizations));
  } catch (error) {
    throw Error(error);
  }
};

export const createMoxitOrganization = (data) => async (dispatch) => {
  try {
    await createOrganization(data);
    dispatch(getMoxitOrganizations());
    return dispatch(organizationCreated());
  } catch (error) {
    throw Error(error);
  }
};

export const updateMoxitOrganization = (data) => async (dispatch) => {
  try {
    await updateOrganization(data.id, data);
    await enableFastpayInStripe(data.id, isEnabled(data));
    dispatch(getMoxitOrganizations());
    return dispatch(organizationUpdated());
  } catch (error) {
    throw Error(error);
  }
};

export const switchOrganization =
  (newOrganizationId, uid) => async (dispatch) => {
    try {
      if (!uid) throw Error('User uid is required');

      await updateUserProfile(uid, {
        impersonatedOrganization: newOrganizationId,
      });

      // TODO: Remove this once we are sure this logic doesn't break anything.
      // await updateOrganizationUser(currentOrganizationId, uid, {
      //   impersonatedOrganization: newOrganizationId,
      // });

      return dispatch(organizationSwitched());
    } catch (error) {
      throw Error(error);
    }
  };
