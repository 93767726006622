import { useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import mitt from 'mitt';
import LocalStorageKey from '../utils/LocalStorageKey';

const emitter = mitt();
const SESSION_PERSISTENCE_KEY = 'SessionId';
const SESSION_EVENT_KEY = 'SessionIdEventUnique';
const LS = new LocalStorageKey(SESSION_PERSISTENCE_KEY);
/**
 * Saves the session id to local storage w/o using hooks
 * @param {string} sessionId
 * @returns {boolean} whether successful
 */
export const saveSessionId = (sessionId) => {
  LS.set(sessionId);
  emitter.emit(SESSION_EVENT_KEY, { sessionId });
};
/**
 * hook to persist a session id into local storage
 * @returns {[
 *  savedSessionId: string,
 *  setSessionId: (string) => void
 * ]}
 */
const useSavedSessionId = () => {
  const [savedSessionId, setSessionId] = useLocalStorage(
    SESSION_PERSISTENCE_KEY,
    undefined, // rely on presisted value to initialize
    {
      logger: (err) => {
        // this is too noisy on empty json so we're silencing it
      },
    }
  );
  useEffect(() => {
    const onUpdate = ({ sessionId }) => {
      setSessionId(sessionId);
    };
    emitter.on(SESSION_EVENT_KEY, onUpdate);
    return () => {
      emitter.off(SESSION_EVENT_KEY, onUpdate);
    };
  }, [setSessionId]);

  return [savedSessionId, setSessionId];
};

export default useSavedSessionId;
