import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { auth } from '../api/firebase';

export const useAuthUser = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (!auth()?.currentUser) return;
    auth().currentUser?.getIdToken(true).then(setToken);
  }, []);

  return { currentUser: auth().currentUser, token };
};

export const useUser = () => {
  const user = useSelector((state) => state.user ?? {});

  const isParent = useMemo (() => {
    const claims = user?.claims ?? {};
    return !!claims.parent;
  }, [user]);

  const isLocationAdminHighestClaim = useMemo (() => {
    const claims = user?.claims ?? {};
    return !claims.moxitAdmin && !claims.organizationAdmin && !!claims.locationAdmin
  }, [user]);

  return { ...user, isParent, isLocationAdminHighestClaim };
};

export const useUsers = () => {
  const users = useSelector(state => state.users ?? {count:0, list:[]});

  const getParents = useCallback (() => {
    const list = users?.list.filter (user => !user.device && !user.staff && user.allowParentLogin);
    const count = list?.length ?? 0;
    return {list, count}
  }, [users]);

  const getStaff = useCallback (() => {
    const list = users?.list.filter (user => user.device && !user.allow);
    const count = list?.length ?? 0;
    return {list, count}
  }, [users]);

  return { users, getParents, getStaff }
}
