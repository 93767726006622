import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  startPendingStaffInvitationsListener,
  startPendingParentInvitationsListener,
  startPendingParentInvitationsByStudentIdsListener,
} from '../redux/actions/invitationActions';
import { useListenerWithRedux } from './useListenerWithRedux';

export {
  useInvitations,
  useParentInvitations,
  useStaffInvitations,
  usePendingStaffInvitationsListener,
  usePendingParentInvitationsListener,
  usePendingParentInvitationsByStudentIdsListener,
}

function useInvitations () {
  return useSelector (state => state.invitations ?? {});
}

function useParentInvitations (studentId) {
  const invitations = useInvitations ();
  if (!studentId || !invitations?.count)
    return invitations;
  const list = invitations.list.filter (invitation => {
    return (invitation.student?.id === studentId || !!invitation.studentIds?.includes (studentId))
  })
  return {count: list.length, list}
}

function useStaffInvitations () {
  const invitations = useInvitations ();
  const list = useMemo (() => {
    return invitations.list.filter (invitation => invitation.type === 'staff');
  }, [invitations])
  return {count: list.length, list}
}

function usePendingStaffInvitationsListener (organizationId) {
  const startListenerAction = startPendingStaffInvitationsListener (organizationId)
  return useListenerWithRedux (startListenerAction, [organizationId]);
}
function usePendingParentInvitationsListener (organizationId, studentId=null) {
  const startListenerAction = startPendingParentInvitationsListener (organizationId, studentId)
  return useListenerWithRedux (startListenerAction, [organizationId, studentId]);
}

function usePendingParentInvitationsByStudentIdsListener (organizationId, studentIds) {
  const startListenerAction = startPendingParentInvitationsByStudentIdsListener(organizationId, studentIds);
  return useListenerWithRedux(startListenerAction, [organizationId]);
}
