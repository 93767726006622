import moment from 'moment';

import {
  InvoiceStatus,
  isInvoicePaid,
  isInvoiceProcessing,
} from '../helpers/invoices';
import { doesQueryMatchStudent } from '../students/studentsUtils';

export const filterInvoiceStatus = (invoiceList, invoiceStatusFilter) =>
  invoiceList.filter((invoice) => {
    const { status, manuallyPaid } = invoice;

    if (invoiceStatusFilter === `${InvoiceStatus.PAID}(O)`) 
      return Boolean(manuallyPaid) && status === InvoiceStatus.PAID;

    if (invoiceStatusFilter === InvoiceStatus.PAID)
      return isInvoicePaid(invoice);

    if (invoiceStatusFilter === InvoiceStatus.PROCESSING)
      return isInvoiceProcessing(invoice);

    return status === invoiceStatusFilter;
  })

export const filterDescriptions = (invoiceList, descriptionSearch) =>
  invoiceList.filter(({ invoiceItemList }) => {
    const description = invoiceItemList.reduce(
      (acc, { item }) => acc + item,
      ''
    );

    return description.toLowerCase().includes(descriptionSearch.toLowerCase());
  });

export const filterAmounts = (invoiceList, amountSearch) =>
  invoiceList.filter(({ total }) =>
    total.toFixed(2).toString().includes(amountSearch)
  );

export const filterBalanceDues = (invoiceList, balanceDueSearch) =>
  invoiceList.filter(({ total, paidAmount = 0 }) => {
    const balanceDue = total - paidAmount;

    // Special case to make it easier to search for 0 balances
    if (parseFloat(balanceDueSearch) === 0) return balanceDue === 0;

    return balanceDue.toFixed(2).toString().includes(balanceDueSearch);
  });

export const filterBillingInterval = (invoicePlans, billingInterval) =>
  invoicePlans.filter(
    (invoicePlan) => invoicePlan.billingInterval === billingInterval
  );

export const filterStudents = (invoiceList, studentSearch) =>
  invoiceList.filter(({ student }) =>
    doesQueryMatchStudent(studentSearch)(student)
  );

export const filterStudentsAndParents = (invoicePlanList, studentSearch) =>
  invoicePlanList.filter(({ students }) => {
    return students.some((student) =>
      doesQueryMatchStudent(studentSearch)(student)
    );
  });

export const filterDates = (invoicePlanList, fieldName, dateRange) =>
  invoicePlanList.filter((invoicePlan) => {
    const dateToCompare = invoicePlan[fieldName];

    const [displayedStartDate, displayedEndDate] = dateRange.split('-');

    const startDateTimestamp = moment(displayedStartDate).valueOf();

    if (
      !displayedEndDate?.trim() &&
      moment(dateToCompare).isSame(startDateTimestamp, 'day')
    )
      return true;

    const startDate = moment(displayedStartDate).startOf('day').valueOf();
    const endDate = moment(displayedEndDate).endOf('day').valueOf();

    if (dateToCompare >= startDate && dateToCompare <= endDate) return true;

    return false;
  });
