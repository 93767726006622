import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '../../../../Shared/Dropdown';
import { CurrencyInput, PercentInput } from '../../../../Shared/NumericInput';
import { DiscountAmountType } from '../../../../../helpers/invoices';
import './InvoiceItemsEditor.scss';

const getInputType = (amountType) => {
  switch (amountType) {
    case DiscountAmountType.PERCENT:
      return PercentInput;
    default:
      return CurrencyInput;
  }
};

const DiscountRowEditor = ({
  amount,
  amountType,
  discountType,
  index,
  discountIndex,
  onAddition,
  onChange,
  onDeleteDiscount,
  discountOptions,
  error,
}) => {
  const { t } = useTranslation();
  const isCurrency = amountType === DiscountAmountType.CURRENCY;
  const isPercent = amountType === DiscountAmountType.PERCENT;

  const handleAmountTypeChange = (e, type) => {
    e.preventDefault();
    onChange(e, { name: 'amountType', value: type }, index, discountIndex);
  };

  return (
    <>
      <Form.Group className={'discount-row'}>
        <Form.Field width={8}>
          <label>{t('Discount or Subsidy')}</label>
          <Dropdown
            search
            placeholder={t('Select or type new')}
            name={'discountType'}
            value={discountType}
            onChange={(e, data) => onChange(e, data, index, discountIndex)}
            options={discountOptions}
          />
        </Form.Field>

        <Form.Field width={5}>
          <label>{t('Amount')}</label>
          <Form.Input
            type="text"
            required
            name={'amount'}
            onBlur={(e) => onChange(e, {name:e.target.name, value:e.target.value}, index, discountIndex)}
            control={getInputType(amountType)}
            value={amount}
            placeholder={t('Amount')}
          />
        </Form.Field>

        <Form.Field width={2}>
          <label>&nbsp;</label>
          <Button.Group>
            <Button
              icon
              className={isCurrency ? 'active' : ''}
              onClick={(e) =>
                handleAmountTypeChange(e, DiscountAmountType.CURRENCY)
              }
            >
              <Icon name="dollar" size="small" />
            </Button>
            <Button
              icon
              className={isPercent ? 'active' : ''}
              onClick={(e) =>
                handleAmountTypeChange(e, DiscountAmountType.PERCENT)
              }
            >
              <Icon name="percent" size="small" />
            </Button>
          </Button.Group>
        </Form.Field>

        <Form.Field width={1} className={'discount-delete-button'}>
          <label>&nbsp;</label>
          <Button
            basic
            icon="trash"
            onClick={() => onDeleteDiscount(index, discountIndex)}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

DiscountRowEditor.propTypes = {
  discountOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onDeleteDiscount: PropTypes.func.isRequired,
};

export default DiscountRowEditor;
