import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message, Modal } from 'semantic-ui-react';

const InvitationConfirmationDialog = ({ open, loading, onClose, onYes }) => {
  const { t } = useTranslation();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleClose = useCallback(() => {
    setShowSuccessMessage(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!showSuccessMessage) return;
    const timeout = setTimeout(() => {
      handleClose();
    }, 3000);
    return () => clearTimeout(timeout);
  }, [handleClose, showSuccessMessage]);

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>
        {showSuccessMessage ? t('Success') : t('Are you sure?')}
      </Modal.Header>
      <Modal.Content>
        {showSuccessMessage ? (
          <Message color="green">{t('The invitation has been sent')}</Message>
        ) : (
          <p>{t('Are you sure you want to send the enrollment forms?')}</p>
        )}
      </Modal.Content>
      <Modal.Actions>
        {showSuccessMessage ? (
          <Button positive onClick={handleClose}>
            {t('Close')}
          </Button>
        ) : (
          <>
            <Button negative onClick={onClose}>
              {t('No')}
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content={t('Yes')}
              loading={loading}
              onClick={async () => {
                try {
                  await onYes();
                  setShowSuccessMessage(true);
                } catch (error) {
                  console.error(error);
                }
              }}
            />
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default InvitationConfirmationDialog;
