import * as devices from '../../api/firebase/devices';
import {
  DEVICE_REFRESHED,
  DEVICES_FETCHED,
  DEVICE_SELECTION_CLEARED,
  DEVICE_SET,
  DEVICE_SELECTED,
  DEVICE_UPDATED,
  DEVICE_ADDED,
  DEVICE_ADDED_TO_LIST,
  DEVICE_REMOVE_FROM_LIST,
} from './types';

export const devicesFetched = devices => ({
  type: DEVICES_FETCHED,
  devices,
});

export const deviceRefreshed = device => ({
  type: DEVICE_REFRESHED,
  device,
});

export const deviceAdded = () => ({
  type: DEVICE_ADDED,
});

export const deviceSet = () => ({
  type: DEVICE_SET,
});

export const deviceUpdated = () => ({
  type: DEVICE_UPDATED,
});

export const deviceSelected = device => ({
  type: DEVICE_SELECTED,
  device,
});

export const deviceSelectionCleared = () => ({
  type: DEVICE_SELECTION_CLEARED,
});

export const deviceAddedToList = device => ({
  type: DEVICE_ADDED_TO_LIST,
  device,
});

export const deviceRemovedFromList = id => ({
  type: DEVICE_REMOVE_FROM_LIST,
  id,
});

export const getOrganizationDevices = (
  organizationId,
  orderBy = [],
  limit,
  last
) => async dispatch => {
  try {
    const devicesData = await devices.getOrganizationDevices(
      organizationId,
      orderBy,
      limit,
      last
    );
    return dispatch(devicesFetched(devicesData));
  } catch (error) {
    throw Error(error);
  }
};

export const newOrganizationDevice = organizationId => dispatch => {
  try {
    const device = devices.newOrganizationDevice(organizationId);
    // Preselect device.
    return dispatch(deviceSelected({ id: device.id }));
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const setOrganizationDevice = (
  organizationId,
  data
) => async dispatch => {
  try {
    await devices.setOrganizationDevice(organizationId, data);
    return dispatch(deviceSet());
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const deleteOrganizationDevice = (
  organizationId,
  deviceId
) => dispatch => {
  try {
    // Immediately remove from Redux store.
    dispatch(deviceRemovedFromList(deviceId));
    return devices.deleteOrganizationDevice(organizationId, deviceId);
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const refreshOrganizationDevice = (
  organizationId,
  deviceId
) => async dispatch => {
  try {
    const device = await devices.getOrganizationDevice(
      organizationId,
      deviceId
    );
    return dispatch(deviceRefreshed(device));
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};
