import { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { routes } from '../../config/routes';
import { logWarning } from '../../rollbar';

const DefaultRoute = ({ defaultPath }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== routes.featureFlags) {
      const str = `Default Route activated for ${location.pathname}`;
      logWarning(str, { location });
    }
  }, [location]);
  return <Route default render={() => <Redirect to={defaultPath} />} />;
};
export default DefaultRoute;
