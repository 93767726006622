import {
  ORGANIZATION_USERS_FETCHED,
} from '../actions/types';

const initialState = {
  count: 0,
  list: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_USERS_FETCHED:
      return {
        ...state,
        count: action.users.count,
        list: action.users.list
      };
    default:
      return state;
  }
};

export default users;
