import { combineReducers } from 'redux';
// Import action.
import { USER_SIGNED_OUT } from '../actions/types';

// Import reducers
import user from './user';
import users from './users';
import permissions from './permissions';
import roles from './roles';
import organizations from './organizations';
import locations from './locations';
import rooms from './rooms';
import staff from './staff';
import layout from './layout';
import devices from './devices';
import stats from './stats';
import { networksReducer as networks } from '../../networks/networksRedux';
import { studentsReducer as students } from '../../students/studentsRedux';
import { transactionsReducer as transactions } from '../../transactions/transactionsRedux';
import invitations from './invitations';
import { segmentReducer as segment } from '../../segment/segmentRedux';

// Billing
import {
  ajaxCallsInProgress,
  employeesList,
  paymentDetails,
  paymentEmployees,
  invoices,
  charges,
  invoiceEdit,
} from './billing';

const combinedReducers = combineReducers({
  user,
  users,
  permissions,
  roles,
  students,
  organizations,
  locations,
  rooms,
  staff,
  layout,
  devices,
  networks,
  transactions,
  segment,
  stats,
  invitations,

  ajaxCallsInProgress,
  employeesList,
  paymentDetails,
  paymentEmployees,
  invoices,
  charges,
  invoiceEdit,
});

// Handy trick to clear store upon user sign out.
const rootReducer = (state, action) =>
  action.type === USER_SIGNED_OUT
    ? combinedReducers({}, action)
    : combinedReducers(state, action);

export default rootReducer;
