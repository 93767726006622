import React from 'react';
import Avatar from 'react-avatar';
import { Modal, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { formatFullName } from '../../helpers/utils';

import styles from './CheckedInStudentsModal.module.scss';

export function CheckedInStudentsModal({
  roomName,
  students,
  onClose,
  open,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onClose}
      open={open}
      closeIcon
      size="small"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {roomName}
        <span className={styles.subtitle}>
          {t("Students")}
        </span>
      </Modal.Header>
      <Modal.Content>
        <List selection verticalAlign='middle'>
          {
            students.map((student, i) => (
              <List.Item>
                <Avatar
                  key={i}
                  name={formatFullName(student, true)}
                  round
                  size={28}
                  alt={formatFullName(student, true)}
                  src={student.picture}
                  className={styles.avatar}
                />
                <List.Content className={styles.listContent}>
                  <List.Header>{formatFullName(student, true)}</List.Header>
                </List.Content>
              </List.Item>
            ))
          }
        </List>
      </Modal.Content>
    </Modal>
  );
}

export default CheckedInStudentsModal;
