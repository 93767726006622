import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import SectionCard from '../Shared/SectionCard';
import withPermission from '../Shared/withPermission';
import PhotoGallery from '../Stats/PhotoGallery';

const RecentPhotos = () => {
  const { t } = useTranslation();
  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <SectionCard header={t('Recent Photos')} component={PhotoGallery} />
      </Grid.Column>
    </Grid.Row>
  );
};

export default withPermission(RecentPhotos, 'can_view_recent_photos');
