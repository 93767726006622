import React, { Component } from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { functions } from '../../../api/firebase';
import { formatCurrency } from '../../../helpers/utils';

import NumberFormat from 'react-number-format';

const handleBlur = () => {
  // console.log('[blur]');
};
const handleChange = change => {
  // console.log('[change]', change);
};
// const handleClick = () => {
//   // console.log('[click]');
// };
const handleFocus = () => {
  // console.log('[focus]');
};
const handleReady = () => {
  // console.log('[ready]');
};

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        color: '#303238',
        fontSize: '16px',
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
  };
};

class _CreditCard extends Component {
  state = {
    processing: false,
    data: {
      name: '',
      amount: null,
      address_city: '',
      address_country: '',
      address_line1: '',
      address_line1_check: '',
      address_line2: '',
      address_state: '',
    },
  };

  componentDidMount() {
    const {
      invoice: { total },
    } = this.props;
    this.setState({ data: { ...this.state.data, amount: total } });
  }

  handleSubmit = async ev => {
    ev.preventDefault();

    if (this.props.stripe) {
      const {
        data: { amount, ...invoiceData },
      } = this.state;

      const {
        invoice: { invoiceId, organizationId, billToId },
      } = this.props;

      try {
        this.setState({ loading: true });
        const tokenResponse = await this.props.stripe.createToken(invoiceData);

        if (tokenResponse.error || !tokenResponse || !tokenResponse.token) {
          this.setState({ loading: false });
          return;
        }

        const settlePayment = functions().httpsCallable(
          'callables-stripe-settlePayment'
        );

        const response = await settlePayment({
          token: tokenResponse.token,
          amount,
          billToId,
          invoiceId,
          organizationId,
          description: `Payment for invoice - ${invoiceId}`,
        });

        this.setState({ loading: false });
        if (this.props.onSuccess && response.data && response.data.paid) {
          this.props.onSuccess({
            amount: response.data.amount ? response.data.amount / 100 : 0, // from cent value.
            date: response.data.created,
            currency: response.data.currency,
          });
        }
      } catch (error) {
        console.log(JSON.stringify(error));
        this.setState({ loading: false });
        if (this.props.onError)
          this.props.onError('Problem processing payment');
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  handleChange = e =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  render() {
    const { loading } = this.state;
    const { invoice } = this.props;

    return invoice && invoice.total > 0 ? (
      <form onSubmit={this.handleSubmit} noValidate>
        <label>
          Name on card
          <input
            id="name"
            name="name"
            required
            type="text"
            placeholder="John Doe"
            value={this.state.data.name}
            onChange={this.handleChange}
            style={{ width: '100%' }}
          />
        </label>

        <label>
          Card details
          <CardElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(this.props.fontSize)}
          />
        </label>

        <label>
          Amount
          <NumberFormat
            value={this.state.data.amount}
            thousandSeparator={true}
            prefix={'$'}
            style={{ width: '100%' }}
            decimalScale={2}
            fixedDecimalScale
            onValueChange={({ floatValue }) =>
              this.setState({
                data: { ...this.state.data, amount: floatValue },
              })
            }
          />
        </label>
        <Button
          loading={loading}
          disabled={loading}
          fluid
          positive
          content={`Pay  ${formatCurrency(this.state.data.amount)}`}
        />
      </form>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  //
});

const CreditCard = injectStripe(
  withRouter(connect(mapStateToProps)(_CreditCard))
);

export default CreditCard;
