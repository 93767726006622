import { useSelector } from "react-redux"

import { INVOICE_TYPE } from "../Components/Billing/Invoices/InvoicesList"

import {
  filterAmounts,
  filterBalanceDues,
  filterDates,
  filterDescriptions,
  filterInvoiceStatus,
  filterStudents
} from "../helpers/filters";

/**
 * Grabs invoice data from redux store and applies filters/search.
 */
export const useInvoices = (filters) => {
  const invoices = useSelector(state => state?.invoices)

  const invoiceType = invoices.invoiceType || INVOICE_TYPE;
  const { list: invoiceList } = invoices[invoiceType];

  const { invoiceStatus, descriptionSearch, dueDateRange, amountSearch, balanceDueSearch, studentSearch } = filters ?? {}

  const filteredStatuses = Boolean(invoiceStatus) ? filterInvoiceStatus(invoiceList, invoiceStatus) : invoiceList

  const filteredDescriptions = Boolean(descriptionSearch) ? filterDescriptions(filteredStatuses, descriptionSearch) : filteredStatuses

  const filteredDates = Boolean(dueDateRange) ? filterDates(filteredDescriptions, 'dateDue', dueDateRange) : filteredDescriptions

  const filteredAmounts = Boolean(amountSearch) ? filterAmounts(filteredDates, amountSearch) : filteredDates

  const filteredBalanceDues = Boolean(balanceDueSearch) ? filterBalanceDues(filteredAmounts, balanceDueSearch) : filteredAmounts

  const filteredInvoices = Boolean(studentSearch) ? filterStudents(filteredBalanceDues, studentSearch) : filteredBalanceDues

  return {
    ...invoices,
    [invoiceType]: {
      ...invoices[invoiceType],
      list: filteredInvoices,
    }
  }
}
