import React, { useCallback, useState } from 'react';
import { Button, Grid, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';

import useGenerateReport from '../../hooks/useGenerateReport';

// Import components.
import AttendanceList from '../../Components/Reports/AttendanceList';
import AttendanceModal from '../../Components/Reports/AttendanceModal';
import PageHeader from '../../Components/Shared/PageHeader';
import PageTitle from '../../Components/Shared/PageTitle';
import moment from 'moment-timezone';

const Attendance = () => {
  const { t } = useTranslation();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState(null);
  const organization = useSelector(
    (state) => state.organizations.currentOrganization
  );
  const rooms = useSelector((state) => state.rooms?.list);
  const locations = useSelector((state) => state.locations);

  const location = locations.list[0];
  const generateReportPDF = useGenerateReport(() => setLoading(false));

  const [filters, setFilters] = useState({
    timePeriod: {
      from: moment().startOf('month'),
      to: moment().subtract(1, 'day').endOf('day'),
    },
    room: null,
    student: null,
    location: location?.id,
  });

  const handleSelectActivity = (activity) => {
    setActivity(activity);
  };

  const handlePrintPDFClick = useCallback(() => {
    setLoading(true);
    generateReportPDF(getPDFData(), {
      padding: 5, // {Number} default: 0
      columnSpacing: 10, // {Number} default: 5
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, filters, activities]);

  const selectedLocation = locations.list.find(
    (location) => location.id === filters.location
  );

  const getDuration = (activity, activities) => {
    if (activity.type === 'dropoff' || activity.type === 'absent') return '';

    const activityIndex = activities.indexOf(activity);

    const previousActivity = activities.find((_activity, index) => {
      if (index < activityIndex) return false;
      if (
        _activity.student.id === activity.student.id &&
        _activity.type !== activity.type
      )
        return true;

      return false;
    });

    if (!previousActivity) return '';

    const diff = moment.utc(
      moment(activity.activityTime).diff(previousActivity.activityTime)
    );

    const duration = moment.duration(diff);

    const days = duration.get('days').toString().padStart(2, '0');
    const hours = duration.get('hours').toString().padStart(2, '0');
    const minutes = duration.get('minutes').toString().padStart(2, '0');

    return `${days}:${hours}:${minutes}`;
  };

  const typeFormatter = (cell) => {
    switch (cell) {
      case 'pickup':
        return 'Checked Out';
      case 'dropoff':
        return 'Checked In';
      case 'absent':
        return 'Absent';
      default:
        console.error(`Cannot process activity type: ${cell}`);
        return 'Unknown';
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const durationFormatter = (_cell, row, _index, formatExtraData) =>
    getDuration(row, formatExtraData);

  const getPDFData = () => {
    const data = {
      title: `Attendance Report: ${filters.timePeriod.from.format(
        'MM/DD/YYYY'
      )} - ${filters.timePeriod.to.format('MM/DD/YYYY')}`,
      subtitle: `${organization.name} - ${selectedLocation.name}\n${
        selectedLocation.address1
      }${selectedLocation.address2 && ' ' + selectedLocation.address2}, ${
        selectedLocation.city
      } ${selectedLocation.state}, ${selectedLocation.zipcode}\n${
        selectedLocation.phone
      }\n\nName  ____________________________        Signature  ____________________________       Date  ____________________________\n\n`,
      headers: [
        { label: 'Date', property: 'date', width: 110, renderer: null },
        { label: 'Type', property: 'type', width: 80, renderer: null },
        { label: 'Student', property: 'student', width: 110, renderer: null },
        { label: 'Signee', property: 'signee', width: 110, renderer: null },
        {
          label: 'Duration (days:hrs:min)',
          property: 'duration',
          width: 80,
          renderer: null,
        },
        { label: 'Room', property: 'room', width: 80, renderer: null },
        {
          label: 'Health Check',
          property: 'healthCheck',
          width: 100,
          renderer: null,
        },
        {
          label: 'Signature',
          property: 'signature',
          width: 60,
          renderer: `(value, index, i, row, rectRow, rectCell) => {
            this.image('data:image/png;base64,' + row.signature.signature, rectCell.x , rectCell.y, { fit: [32, 32] });
            return '';
          }`,
        },
      ],
      datas: activities.map((activity) => {
        const getHealthCheck = ({ type, dropoff }) => {
          if (type !== 'dropoff') return 'N/A';

          if (!dropoff.data?.preCheck) return 'N/A';

          const preCheck = new URL(
            dropoff.data.preCheck.replace(/\/\?(.*)\/\?/, '?')
          );
          const hasFamilySymptoms = preCheck.searchParams.get('familySymptoms');

          const symptoms = preCheck.searchParams.getAll('symptoms');
          const notes = preCheck.searchParams.get('notes');

          return hasFamilySymptoms === 'yes'
            ? `Symptoms: ${symptoms.join(', ')}\nNotes: ${notes}`
            : 'No issues';
        };

        return {
          date: moment
            .tz(activity.activityTime, activity.timezone)
            .format('MM/DD/YYYY h:mma'),
          type: typeFormatter(activity.type),
          student: activity.student.fullName,
          room: rooms.find((room) => room.id === activity.room)?.name,
          signee: activity.addedBy.fullName,
          duration: getDuration(activity, activities) || '',
          healthCheck: getHealthCheck(activity),
          signature: { signature: activity[activity.type].data.signature },
        };
      }),
    };

    return data;
  };

  const getTableColumns = useCallback(() => {
    const tableColumns = [
      {
        dataField: 'activityTime',
        text: t('Date'),
        sort: true,
        formatter: (cell, row) =>
          moment.tz(cell, row.timezone).format('MM/DD/YYYY h:mma'),
        csvFormatter: (cell, row) =>
          moment.tz(cell, row.timezone).format('MM/DD/YYYY h:mma'),
      },
      {
        dataField: 'type',
        text: t('Type'),
        sort: true,
        formatter: typeFormatter,
        csvFormatter: typeFormatter,
      },
      {
        dataField: 'student',
        text: t('Student'),
        sort: true,
        formatter: (cell) => cell.fullName,
        csvFormatter: (cell) => cell.fullName,
      },
      {
        dataField: 'addedBy',
        text: t('Signee'),
        formatter: (cell) => cell.fullName,
        csvFormatter: (cell) => cell.fullName,
      },
      {
        dataField: 'duration',
        text: t('Duration (days:hrs:min)'),
        isDummyField: true,
        formatExtraData: activities,
        formatter: durationFormatter,
        csvFormatter: durationFormatter,
      },
      {
        dataField: 'room',
        text: t('Room'),
        sort: true,
        formatter: (cell) => rooms.find((room) => room.id === cell)?.name,
        csvFormatter: (cell) => rooms.find((room) => room.id === cell)?.name,
      },
    ];

    if (organization?.preCheck?.enabled) {
      tableColumns.push({
        dataField: 'dropoff',
        text: t('Health check'),
        formatter: (dropoff, { type }) => {
          if (type !== 'dropoff') return 'N/A';

          if (!dropoff.data?.preCheck) return 'N/A';

          const preCheck = new URL(
            dropoff.data.preCheck.replace(/\/\?(.*)\/\?/, '?')
          );
          const hasFamilySymptoms = preCheck.searchParams.get('familySymptoms');

          return hasFamilySymptoms === 'yes' ? 'Flagged' : 'No issues';
        },
        csvFormatter: (dropoff, { type }) => {
          if (type !== 'dropoff') return 'N/A';

          if (!dropoff.data?.preCheck) return 'N/A';

          const preCheck = new URL(
            dropoff.data.preCheck.replace(/\/\?(.*)\/\?/, '?')
          );
          const hasFamilySymptoms = preCheck.searchParams.get('familySymptoms');

          const symptoms = preCheck.searchParams.getAll('symptoms');
          const notes = preCheck.searchParams.get('notes');

          return hasFamilySymptoms === 'yes'
            ? symptoms.join('; ') + (notes && `; ${notes}`)
            : 'No issues';
        },
      });
    }

    tableColumns.push({
      dataField: 'actions',
      text: '',
      isDummyField: true,
      csvExport: false,
      formatter: (_, row) => (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            handleSelectActivity(row);
          }}
        >
          {t('View')}
        </Link>
      ),
      headerStyle: { width: '100px' },
    });

    return tableColumns;
  }, [
    organization?.preCheck?.enabled,
    rooms,
    activities,
    durationFormatter,
    t,
  ]);

  return (
    <Segment basic>
      <PageHeader pageName={'Attendance'} classes="attendance" />

      <ToolkitProvider
        bootstrap4
        keyField="activityTime"
        data={activities}
        columns={getTableColumns()}
        exportCSV={{
          fileName: `Attendance ${organization.name} - ${
            selectedLocation.name
          } (${filters.timePeriod.from.format(
            'MM/DD/YYYY'
          )} - ${filters.timePeriod.to.format('MM/DD/YYYY')}).csv`,
          ignoreHeader: false,
        }}
      >
        {({ baseProps, csvProps }) => (
          <Grid stackable>
            <Grid.Row>
              <Grid.Column computer={16} mobile={16} tablet={16}>
                <PageTitle
                  title={t('Attendance')}
                  RightComponent={() => (
                    <>
                      <Button
                        onClick={handlePrintPDFClick}
                        secondary
                        floated="left"
                        disabled={activities.length === 0 || loading}
                      >
                        {loading ? t('Loading...') : t('Print PDF')}
                      </Button>
                      <Button
                        onClick={(e) => csvProps.onExport()}
                        primary
                        floated="right"
                        disabled={activities.length === 0}
                      >
                        {t('Export to CSV')}
                      </Button>
                    </>
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <AttendanceList
              setActivities={setActivities}
              tableProps={baseProps}
              onSelectRow={handleSelectActivity}
              filters={filters}
              setFilters={setFilters}
            />
          </Grid>
        )}
      </ToolkitProvider>
      <AttendanceModal
        organization={organization}
        activity={activity}
        onClose={() => setActivity(null)}
        open={!!activity}
      />
    </Segment>
  );
};

export default Attendance;
