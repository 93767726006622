import React from 'react';
import { connect } from 'react-redux';

import {
  userSignedIn,
  userSignedOut,
  startUserProfileListener,
} from './redux/actions/userActions';
import {
  getOrganizationSetupState,
  startOrganizationListener,
} from './redux/actions/organizationActions';
import { startOrganizationPermissionsListener } from './redux/actions/permissionActions';
import { startOrganizationRolesListener } from './redux/actions/roleActions';
import { startOrganizationRoomsListener } from './redux/actions/roomActions';
import { startOrganizationLocationsListener } from './redux/actions/locationActions';
import { startOrganizationStudentsListener } from './students/studentsRedux';
import { startOrganizationStatsListener } from './redux/actions/statsActions';

import { isLocal } from './config/env';
import AppRouter from './AppRouter';
import WSA from './WSA';

interface State {
  hasPrevUser: boolean
  prevOrgId: string | undefined
  prevClaims: any | undefined
}

// TODO: move this to src/WSAuth/index.js and convert that file to tsx
interface AuthContextProps {
  isReady: boolean
  isAuthenticated: boolean
  needsVerification: boolean
  authUser: any,
  authSession: any,
  userClaims: {
    moxitAdmin?: boolean
    organizationAdmin?: boolean
    organizationDevice?: boolean
    locationAdmin?: boolean
    supportStaff?: boolean
    teacher?: boolean
    parent?: boolean
    billingAccount?: boolean
  }
  idToken: string
  userStatus: string
  forceUserRefresh: () => void
}

// TODO: move this to src/WSAuth/index.js and convert that file to tsx
interface Props extends AuthContextProps {
  currentOrganization: {
    id: string
    setup: {
      firstLocationCreated: boolean
      firstRoomCreated: boolean
      firstStaffCreated: boolean
      firstStudentCreated: boolean
    }
    fetching: boolean
    programType: string
    setupComplete: boolean
    stripeId: string
  }
  organizationId: string
  needsOrgOnboarding: boolean
  canStartOrgListeners: boolean
  // wsauth
  userId: string
  // actions
  userSignedIn: (x: any, y: any) => any
  userSignedOut: () => any
  startUserProfileListener: (x: string) => any
  startOrganizationListener: (x: string) => any
  startOrganizationPermissionsListener: (x: string) => any
  startOrganizationRolesListener: (x: string) => any
  startOrganizationLocationsListener: (x: string) => any
  startOrganizationRoomsListener: (x: string) => any
  startOrganizationStatsListener: (x: string) => any
  startOrganizationStudentsListener: (x: string) => any
}

type UnsubscribeType = () => any

class App extends React.Component<Props, State> {
  state: State = {
    hasPrevUser: false,
    prevOrgId: undefined,
    prevClaims: undefined,
  };
  componentWillUnmount = () => {
    this.unsubscribeOrganizationListeners();
    this.unsubscribeUserListeners();
  };

  componentDidUpdate = () => {
    this.checkForUserUpdate();
    this.checkForOrgUpdate();
  };

  unsubscribeUserProfileListener: UnsubscribeType | null = null
  unsubscribeOrganizationListener: UnsubscribeType | null = null
  unsubscribeOrganizationPermissionsListener: UnsubscribeType | null = null
  unsubscribeOrganizationRoleListener: UnsubscribeType | null = null
  unSubscribeOrganizationLocationsListener: UnsubscribeType | null = null
  unSubscribeOrganizationRoomsListener: UnsubscribeType | null = null
  unSubscribeOrganizationStudentsListener: UnsubscribeType | null = null
  unSubscribeOrganizationStatsListener: UnsubscribeType | null = null

  checkForUserUpdate = () => {
    const { hasPrevUser, prevClaims } = this.state;
    const {
      isReady,
      isAuthenticated,
      authUser,
      userId,
      authSession,
      idToken,
      userStatus,
      userClaims,
      userSignedIn,
      userSignedOut,
    }: Props = this.props;

    if (isReady && isAuthenticated) {
      if (JSON.stringify(prevClaims) !== JSON.stringify(userClaims)) {
        this.setState({ prevClaims: userClaims });
        userSignedIn(authUser, userClaims);
      }
    }

    if (!hasPrevUser && isReady && isAuthenticated) {
      this.setState({ hasPrevUser: true });

      if (isLocal()) {
        console.log('++++++++++++++++ Wonderschool Dev ******************');
        console.log('User Found: ', {
          authUser,
          idToken,
          userStatus,
          userSession: authSession,
          moxitAdmin: !!userClaims.moxitAdmin,
          organizationAdmin: !!userClaims.organizationAdmin,
          organizationDevice: !!userClaims.organizationDevice,
          locationAdmin: !!userClaims.locationAdmin,
          supportStaff: !!userClaims.supportStaff,
          teacher: !!userClaims.teacher,
          parent: !!userClaims.parent,
          billingAccount: !!userClaims.billingAccount,
        });
        console.log('++++++++++++++++ Wonderschool Dev ******************');
      }

      // this will check if there is an org setup for the user already
      // and determines `needsOrgOnboarding`
      this.startProfileListener(userId);
    } else if (hasPrevUser && isReady && !isAuthenticated) {
      // signed out detected
      if (isLocal()) {
        console.log('++++++++++++++++ Wonderschool Dev ******************');
        console.log('🛑 User Disconnected 🛑');
        console.log('++++++++++++++++ Wonderschool Dev ******************');
      }

      this.unsubscribeUserListeners();
      userSignedOut();
      this.setState({ hasPrevUser: false });
    }
  };

  checkForOrgUpdate = () => {
    const { prevOrgId } = this.state;
    const hasPrevOrg = !!prevOrgId;
    const {
      isAuthenticated,
      organizationId,
      needsOrgOnboarding,
      canStartOrgListeners,
    } = this.props;

    if (isAuthenticated && !needsOrgOnboarding) {
      // user with good org
      const needsFirstOrg = !hasPrevOrg && !!organizationId;
      const hasDifferentOrg = hasPrevOrg && prevOrgId !== organizationId;
      const needsOrgListeners =
        canStartOrgListeners && (needsFirstOrg || hasDifferentOrg);

      if (needsOrgListeners) {
        this.props.forceUserRefresh();
        this.setState({ prevOrgId: organizationId });
        // Unsubscribe active listeners if any
        this.unsubscribeOrganizationListeners();
        // start new ones
        this.startOrganizationListener(organizationId);
        this.startOrganizationPermissionsListener(organizationId);
        this.startOrganizationRolesListener(organizationId);
        this.startOrganizationLocationsListener(organizationId);
        this.startOrganizationRoomsListener(organizationId);
        this.startOrganizationStudentsListener(organizationId);
        this.startOrganizationStatsListener(organizationId);
      }
    } else if (hasPrevOrg && (!isAuthenticated || !organizationId)) {
      // signed out
      this.unsubscribeOrganizationListeners();
      this.setState({ prevOrgId: undefined });
    }
  };

  startProfileListener = (uid: string) => {
    this.unsubscribeUserProfileListener =
      this.props.startUserProfileListener(uid);
  };
  startOrganizationListener = (id: string) => {
    this.unsubscribeOrganizationListener =
      this.props.startOrganizationListener(id);
  };
  startOrganizationPermissionsListener = (id: string) => {
    this.unsubscribeOrganizationPermissionsListener =
      this.props.startOrganizationPermissionsListener(id);
  };
  startOrganizationRolesListener = (id: string) => {
    this.unsubscribeOrganizationRoleListener =
      this.props.startOrganizationRolesListener(id);
  };
  startOrganizationLocationsListener = (id: string) => {
    this.unSubscribeOrganizationLocationsListener =
      this.props.startOrganizationLocationsListener(id);
  };
  startOrganizationRoomsListener = (id: string) => {
    this.unSubscribeOrganizationRoomsListener =
      this.props.startOrganizationRoomsListener(id);
  };
  startOrganizationStudentsListener = (id: string) => {
    this.unSubscribeOrganizationStudentsListener =
      this.props.startOrganizationStudentsListener(id);
  };
  startOrganizationStatsListener = (id: string) => {
    this.unSubscribeOrganizationStatsListener =
      this.props.startOrganizationStatsListener(id);
  };

  // User listeners
  unsubscribeUserListeners = () => {
    if (this.unsubscribeUserProfileListener)
      this.unsubscribeUserProfileListener();
  };

  // Organization Listeners
  unsubscribeOrganizationListeners = () => {
    if (this.unsubscribeOrganizationListener)
      this.unsubscribeOrganizationListener();
    if (this.unsubscribeOrganizationPermissionsListener)
      this.unsubscribeOrganizationPermissionsListener();
    if (this.unsubscribeOrganizationRoleListener)
      this.unsubscribeOrganizationRoleListener();
    if (this.unSubscribeOrganizationLocationsListener)
      this.unSubscribeOrganizationLocationsListener();
    if (this.unSubscribeOrganizationRoomsListener)
      this.unSubscribeOrganizationRoomsListener();
    if (this.unSubscribeOrganizationStudentsListener)
      this.unSubscribeOrganizationStudentsListener();
    if (this.unSubscribeOrganizationStatsListener)
      this.unSubscribeOrganizationStatsListener();
  };

  render() {
    return <AppRouter />;
  }
}

const mapStateToProps = (state: any) => {
  const currentOrganization = state.organizations.currentOrganization;
  const { organizationId, needsOrgOnboarding, canStartOrgListeners } =
    getOrganizationSetupState(state.organizations);
  return {
    currentOrganization,
    organizationId,
    needsOrgOnboarding,
    canStartOrgListeners,
  };
};

const mapAuthContextToProps = ({
  isReady,
  isAuthenticated,
  needsVerification,
  authUser,
  authSession,
  userClaims,
  idToken,
  userStatus,
  forceUserRefresh,
}: AuthContextProps): any => {
  return {
    isReady,
    isAuthenticated,
    needsVerification,
    authUser,
    userId: authUser?.uid,
    authSession,
    userClaims,
    idToken,
    userStatus,
    forceUserRefresh,
  };
};

export default WSA.components.withWSAuth(
  WSA.components.mapAuthContextToProps(
    mapAuthContextToProps,
    connect<any,any,any>(mapStateToProps, {
      userSignedIn,
      userSignedOut,
      startUserProfileListener,
      startOrganizationListener,
      startOrganizationPermissionsListener,
      startOrganizationRolesListener,
      startOrganizationLocationsListener,
      startOrganizationRoomsListener,
      startOrganizationStudentsListener,
      startOrganizationStatsListener,
    })(App)
  )
);
