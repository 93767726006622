import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const ShowSuccess = ({ iconName = 'check', header, content, onDismiss, hideHeader=false }) => {
  const { t } = useTranslation();
  const _header = !hideHeader ? header || t('Success!') : null;

  return !_.isEmpty(content) ? (
    <Message positive icon={iconName} header={_header} content={content} onDismiss={onDismiss} />
  ) : null;
}

ShowSuccess.propTypes = {
  icon: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.oneOfType([ PropTypes.string, PropTypes.element,])
};

export default ShowSuccess;
