import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';

const InvoiceResponsibleConfirmationDialog = ({
  open,
  onClose,
  onYes,
  isResponsibleForBilling,
  parentCount,
}) => {
  const { t } = useTranslation();

  let message = `billingResponsibleConfirmationDialogIsNotResponsibleDescription`;

  if(isResponsibleForBilling && parentCount <= 1) {
    message = `billingResponsibleConfirmationDialogIsOnlyResponsibleDescription`;
  } else if (isResponsibleForBilling && parentCount >= 2) {
    message = `billingResponsibleConfirmationDialogIsResponsibleDescription`;
  }

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>{t('Responsible for Billing')}</Modal.Header>
      <Modal.Content>
        <p>
          {t(message)}
        </p>
      </Modal.Content>
      <Modal.Actions>
          {isResponsibleForBilling
            ? <Button
                  positive
                  icon="checkmark"
                  labelPosition="right"
                  content={t('Ok')}
                  onClick={() => onClose()}
              />
            : <>
                  <Button negative onClick={onClose}>
                      {t('Cancel')}
                  </Button>
                  <Button
                      positive
                      icon="checkmark"
                      labelPosition="right"
                      content={t('billingResponsibleConfirmationDialogYes')}
                      onClick={() => onYes()}
                  />
              </>
          }
      </Modal.Actions>
    </Modal>
  );
};

export default InvoiceResponsibleConfirmationDialog;
