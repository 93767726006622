import {
    filterAmounts,
    filterBillingInterval,
    filterDescriptions,
    filterInvoiceStatus,
    filterStudentsAndParents,
    filterDates
} from "../../../helpers/filters";

export function filterInvoicePlans(invoicePlansList, filters) {
  const {
    invoicePlanStatus,
    billingInterval,
    descriptionSearch,
    amountSearch,
    studentSearch,
    startDateRange,
    nextInvoiceDateRange,
    dueDateRange,
  } = filters;

  const filteredByPlanStatus = Boolean(invoicePlanStatus)
    ? filterInvoiceStatus(invoicePlansList, invoicePlanStatus)
    : invoicePlansList;

  const filteredByBillingInterval = Boolean(billingInterval)
    ? filterBillingInterval(filteredByPlanStatus, billingInterval)
    : filteredByPlanStatus;

  const filteredByDescription = Boolean(descriptionSearch)
    ? filterDescriptions(filteredByBillingInterval, descriptionSearch)
    : filteredByBillingInterval;

  const filteredByAmount = Boolean(amountSearch)
    ? filterAmounts(filteredByDescription, amountSearch)
    : filteredByDescription;

  const filteredByNames = Boolean(studentSearch)
    ? filterStudentsAndParents(filteredByAmount, studentSearch)
    : filteredByAmount;

  const filteredByStartDate = Boolean(startDateRange)
    ? filterDates(filteredByNames, 'dateStart', startDateRange)
    : filteredByNames;

  const filteredByNextInvoiceDate = Boolean(nextInvoiceDateRange)
    ? filterDates(filteredByStartDate, 'dateSend', nextInvoiceDateRange)
    : filteredByStartDate;

  return Boolean(dueDateRange)
    ? filterDates(filteredByNextInvoiceDate, 'dateDue', dueDateRange)
    : filteredByNextInvoiceDate;
}

export function isCurrentlyFiltered(filters) {
  return Object.keys(filters).some((field) => Boolean(filters[field]));
}
