import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';

import RoomPicker from '../Rooms/RoomPicker';
import LocationPicker from '../Locations/LocationPicker';
import { DatePicker } from '../Shared/DatePicker';

import {
  dateFormatter,
  DefaultDateFormat,
  formatStringAsUtcMillisOrNull,
} from '../../helpers/dates';
import { getAttendanceReports } from '../../api/firebase/reports';

import styles from './AttendanceList.module.scss';

const THIS_MONTH = 'this_month';
const THIS_WEEK = 'this_week';
const CUSTOM_PERIOD = 'custom_period';

// Hook useful to get the list of filtered students, selected student and students count.
// This could potentially be merged with hooks/useStudents once the student list redesign is complete.
// TODO: Work on merging into hooks as it is the 3rd place where it's used.

const useStudentList = ({ filters }) => {
  let students = useSelector((state) => state.students?.list) || [];

  // Filter students by room.
  if (filters.room) {
    students = students.filter((student) =>
      student?.rooms?.includes(filters.room)
    );
  }

  return { students };
};

const Filters = ({ filters, setFilters }) => {
  const [showCustomPeriod, setShowCustomPeriod] = useState(false);
  const { students } = useStudentList({ filters });
  const { t } = useTranslation();

  const timePeriodOptions = [
    { text: 'This month', value: THIS_MONTH },
    { text: 'This week', value: THIS_WEEK },
    { text: 'Custom period', value: CUSTOM_PERIOD },
  ];

  const handleTimePeriodChange = (_, { value }) => {
    setShowCustomPeriod(false);
    switch (value) {
      case THIS_MONTH:
        return setFilters((prev) => ({
          ...prev,
          timePeriod: {
            from: moment().startOf('month'),
            to: moment().subtract(1, 'day'),
          },
        }));
      case THIS_WEEK:
        return setFilters((prev) => ({
          ...prev,
          timePeriod: {
            from: moment().startOf('week'),
            to: moment().subtract(1, 'day'),
          },
        }));
      case CUSTOM_PERIOD: {
        return setShowCustomPeriod(true);
      }
      default:
        return null;
    }
  };

  const from = dateFormatter(filters.timePeriod.from);
  const to = dateFormatter(filters.timePeriod.to);

  return (
    <Grid stackable columns={6}>
      <Grid.Row>
        <Grid.Column>
          <Form.Field>
            <Form.Select
              fluid
              placeholder={'Select time period'}
              name={'timePeriod'}
              label={'Time period'}
              options={timePeriodOptions}
              onChange={handleTimePeriodChange}
              defaultValue={THIS_MONTH}
            />
          </Form.Field>
        </Grid.Column>
        {showCustomPeriod && (
          <>
            <Grid.Column>
              <Form.Field>
                <DatePicker
                  fluid
                  id="from"
                  name="from"
                  value={from}
                  onChange={(_, { value }) =>
                    setFilters((prev) => ({
                      ...prev,
                      timePeriod: {
                        ...prev.timePeriod,
                        from: moment(value, DefaultDateFormat).startOf('day'),
                      },
                    }))
                  }
                  label={'From'}
                  placeholder={'From'}
                  maxDate={filters.timePeriod.to.clone().subtract(1, 'day')}
                  closable
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <DatePicker
                  fluid
                  id="to"
                  name="to"
                  value={to}
                  onChange={(_, { value }) =>
                    setFilters((prev) => ({
                      ...prev,
                      timePeriod: {
                        ...prev.timePeriod,
                        to: moment(value, DefaultDateFormat).endOf('day'),
                      },
                    }))
                  }
                  label={'To'}
                  placeholder={'To'}
                  minDate={filters.timePeriod.from}
                  maxDate={moment().subtract(1, 'day')}
                  closable
                />
              </Form.Field>
            </Grid.Column>
          </>
        )}
        <Grid.Column>
          <Form.Field>
            <LocationPicker
              id="location"
              name="location"
              label={t('Location')}
              placeholder={t('Select location')}
              value={filters.location}
              clearable={false}
              selection
              search
              required
              fluid
              onChange={(_, { value }) =>
                setFilters((prev) => ({ ...prev, location: value }))
              }
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <RoomPicker
              fluid
              selection
              search
              clearable
              label={'Room'}
              value={filters.room}
              location={filters.location}
              placeholder="Select room"
              onChange={(_, { value }) =>
                setFilters((prev) => ({ ...prev, room: value }))
              }
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Form.Select
              fluid
              clearable
              placeholder={'All students'}
              name={'student'}
              label={'Student'}
              options={students.map((student) => ({
                text: student.displayName,
                value: student.uid,
              }))}
              onChange={(_, { value }) =>
                setFilters((prev) => ({ ...prev, student: value }))
              }
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const AttendanceList = ({
  setActivities,
  tableProps,
  filters,
  setFilters,
  onSelectRow,
}) => {
  const organization = useSelector(
    (state) => state.organizations.currentOrganization
  );

  useEffect(() => {
    const _getAttendanceReports = async () => {
      try {
        const conditions = [
          {
            field: 'type',
            operation: 'in',
            value: ['dropoff', 'pickup', 'absent'],
          },
          {
            field: 'activityTime',
            operation: '>=',
            value: formatStringAsUtcMillisOrNull(filters.timePeriod.from),
          },
          {
            field: 'activityTime',
            operation: '<=',
            value: formatStringAsUtcMillisOrNull(filters.timePeriod.to),
          },
        ];

        if (filters.location)
          conditions.push({
            field: 'location',
            operation: '==',
            value: filters.location,
          });

        if (filters.room)
          conditions.push({
            field: 'room',
            operation: '==',
            value: filters.room,
          });

        if (filters.student)
          conditions.push({
            field: 'student.id',
            operation: '==',
            value: filters.student,
          });

        const { data } = await getAttendanceReports({
          organizationId: organization.id,
          conditions,
        });
        setActivities(data);
      } catch (err) {
        console.error('Error while getting attendance report data: ', err);
      }
    };
    _getAttendanceReports();
  }, [filters, organization, setActivities]);

  return (
    <>
      <Grid.Row>
        <Filters filters={filters} setFilters={setFilters} />
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div className="bootstrap-iso">
            <BootstrapTable
              classes="w-auto w-md-100"
              wrapperClasses="table-responsive"
              bordered={false}
              hover={true}
              rowEvents={{
                onClick: (_, row) => {
                  if (onSelectRow) onSelectRow(row);
                },
              }}
              headerClasses={styles.attendanceTableHeader}
              {...tableProps}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default AttendanceList;
