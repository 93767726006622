import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { FILE_ACTIONS_CONSTS } from '../dictionary';

const FormsAndDocumentsActionButtons = ({
  areMultipleSelected = false,
  handleFileActionClicked,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        basic
        color="blue"
        onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.download)}
      >
        <Icon name="download" />
        {t('Download')}
      </Button>
      <Button
        basic
        color="blue"
        disabled={areMultipleSelected}
        onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.rename)}
      >
        <Icon name="pencil" />
        {t('Rename')}
      </Button>
      <Button
        basic
        color="blue"
        disabled={areMultipleSelected}
        onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.archive)}
      >
        <Icon name="archive" />
        {t('Archive')}
      </Button>
      <Button
        primary
        onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.share)}
      >
        <Icon name="users" />
        {t('Share')}
      </Button>
    </>
  );
};

export default FormsAndDocumentsActionButtons;
