import React from 'react';
import { Segment, Header } from 'semantic-ui-react';

const PageTitle = ({ list, count, title, RightComponent }) => {
  const counter = count ? `(${count})` : '';

  return (
    <Segment id="page-title" clearing basic className="no-padding">
      <Header as="h4" floated="right" className="no-margin">
        {RightComponent && <RightComponent />}
      </Header>
      <Header as="h1" floated="left">
        {`${title} ${counter}`}
      </Header>
    </Segment>
  );
};

export default PageTitle;
