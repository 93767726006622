import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Message, Segment } from 'semantic-ui-react';
import useOrganizationImpersonation from '../../hooks/useOrganizationImpersonation';

const NotificationBanner = () => {
  const {
    isImpersonatingOrganization,
    activeOrganization,
    switchToOrganization,
  } = useOrganizationImpersonation();

  if (!isImpersonatingOrganization || !activeOrganization.name) return null;

  return (
    <Segment basic textAlign="center">
      <Message warning className="major negative">
        {`You are impersonating ${activeOrganization?.name}.`}&nbsp;&nbsp;
        <Link
          to=""
          onClick={async (e) => {
            e.preventDefault();
            await switchToOrganization();
          }}
          style={{ color: '#fff' }}
        >
          Stop Impersonating &#8594;
        </Link>
      </Message>
    </Segment>
  );
};

export const ImpersonateBanner = NotificationBanner;

const ImpersonationButton = ({ organization }) => {
  const { isOwnOrganization, impersonatedOrganization, switchToOrganization } =
    useOrganizationImpersonation();

  const { t } = useTranslation();

  // If organization id is not supplied, don't render button.
  if (!organization.id) return null;

  return (
    <Button
      basic
      primary
      disabled={isOwnOrganization(organization.id)}
      size="mini"
      content={
        impersonatedOrganization === organization.id
          ? t('Stop impersonating')
          : t('Impersonate')
      }
      onClick={() => {
        let id = organization.id;

        // If impersonating, switch back to own organization (Stop impersonating).
        if (impersonatedOrganization === id) {
          id = null;
        }

        if (!isOwnOrganization(id)) {
          switchToOrganization(id);
        }
      }}
    />
  );
};

export default ImpersonationButton;
