import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Divider,
  Form,
  Icon,
  Input,
  Label,
  Message,
  Popup,
} from 'semantic-ui-react';
import moment from 'moment';

import { addError } from '../../../helpers/errors';
import { useInvoiceEditDetails } from '../../../hooks/useInvoiceEdit';

import InlineError from '../../Messages/InlineError';
import { DatePicker } from '../../Shared/DatePicker';
import { Dropdown } from '../../Shared/Dropdown';
import SectionCard from '../../Shared/SectionCard';

import { StepsContainer } from './Steps';
import {
  BILLING_INTERVAL_OPTIONS,
  SENDING_FREQUENCY_OPTIONS,
} from './dropdownOptions';
import './Billing.scss';

export default function StepInvoicePlanDetails({ onSave, isEdit }) {
  const { t } = useTranslation();

  const details = useInvoiceEditDetails();

  const [billingInterval, setBillingInterval] = useState(
    details?.billingInterval
  );
  const [dateStart, setDateStart] = useState(details?.dateStart);
  const [dateDue] = useState(details?.dateDue);
  const [dateEnd, setDateEnd] = useState(details?.dateEnd);
  const [notificationDays, setNotificationDays] = useState(
    details?.notificationDays ?? 1
  );
  const [autoAddDates, setAutoAddDates] = useState(!!details?.autoAddDates);
  const [sendingFrequency, setSendingFrequency] = useState(
    details?.sendingFrequency ?? t('Previous')
  );

  const [errors, setErrors] = useState();

  return (
    <>
      <StepsContainer
        isInvoice={false}
        CurrentForm={DetailsCard}
        onNext={onSaveLocal}
      />
    </>
  );
  function DetailsCard() {
    return (
      <SectionCard header={t('Recurring Plan Details')}>
        <DetailsForm />
      </SectionCard>
    );
  }
  function DetailsForm() {
    return (
      <>
        <Form.Group>
          <Form.Field width={4} error={!!errors?.billingInterval}>
            <Label basic className="label-required" style={{ border: 'none' }}>
              {t('Billing Interval')}
            </Label>
            <Dropdown
              clearable
              required
              onChange={onChangeBillingInterval}
              placeholder={'Billing frequency'}
              name={'billingInterval'}
              value={billingInterval}
              options={BILLING_INTERVAL_OPTIONS}
              translator={t}
            />
            {errors?.billingInterval && (
              <InlineError text={errors.billingInterval} />
            )}
          </Form.Field>
          <Form.Field width={4}>
            <Label basic className="label-required" style={{ border: 'none' }}>
              {t('Plan Start Date')}
            </Label>
            <DatePicker
              required
              disabled={isEdit}
              minDate={moment()}
              name={'dateStart'}
              value={dateStart}
              onChange={onChangeDateStart}
            />
            {errors?.dateStart && <InlineError text={errors.dateStart} />}
          </Form.Field>
          <Form.Field width={4}>
            <label className={'plan-wrapper'}>
              {t('Plan End Date')}
              <Popup
                trigger={
                  <Icon
                    size="small"
                    color="grey"
                    className={'plan-info'}
                    name="question"
                    circular
                  />
                }
                content={t('The date where the recurring plan ends.')}
              />
            </label>
            <DatePicker
              name={'dateEnd'}
              value={dateEnd}
              onChange={onChangeDateEnd}
              minDate={dateStart}
              disabled={dateStart ? false : true}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field inline>
            <label>{t('Notify parents')}</label>
            <Input
              maxLength={2}
              className="notifications-days"
              name={'notificationDays'}
              value={notificationDays}
              placeholder="0"
              onChange={onChangeNotificationDays}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <label className="label-after">
              {t('days before due date')}
              <Popup
                trigger={
                  <Icon
                    size="small"
                    color="grey"
                    className={'plan-info'}
                    name="question"
                    circular
                  />
                }
                content={t(
                  'Set this date if you want to start the recurring plan earlier than the start date.'
                )}
              />
            </label>
          </Form.Field>
        </Form.Group>

        {dateStart && (
          <Message info>
            <Icon name="info circle" size="big" />
            {t('Your first invoice will be due on {{dateStart}}.', {
              dateStart,
            })}
          </Message>
        )}
        <Divider />

        <Form.Group>
          <Form.Field>
            <Checkbox
              label={t('Automatically add dates of service to each invoice')}
              checked={autoAddDates}
              name={'autoAddDates'}
              onChange={onChangeAutoAddDates}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field inline className={'sending-frequency-dropdown-wrapper'}>
            <label>{t('Invoices are sent for the')}</label>
            <Dropdown
              onChange={onChangeSendingFrequency}
              name={'sendingFrequency'}
              value={sendingFrequency}
              options={SENDING_FREQUENCY_OPTIONS}
              translator={t}
            />
            <label className="label-after">{t('billing interval')}</label>
          </Form.Field>
        </Form.Group>
      </>
    );
  }

  function validate() {
    let errors = null;
    if (!billingInterval)
      errors = addError(
        errors,
        'billingInterval',
        'Billing Interval is required'
      );
    if (!dateStart)
      errors = addError(errors, 'dateStart', 'Start Date is required');

    return errors;
  }
  function onSaveLocal(doValidate = true) {
    const errors = doValidate ? validate() : false;

    if (errors) {
      setErrors(errors);
      return false;
    } else if (onSave) {
      onSave({
        billingInterval,
        dateStart,
        dateEnd,
        dateDue,
        notificationDays,
        autoAddDates,
        sendingFrequency,
      });
      return true;
    }
  }

  function onChangeBillingInterval(e, { value }) {
    setBillingInterval(value);
    setErrors(null);
  }
  function onChangeDateStart(e, { value }) {
    setDateStart(value);
    setDateEnd(null);
    setErrors(null);
  }
  function onChangeDateEnd(e, { value }) {
    setDateEnd(value);
    setErrors(null);
  }
  function onChangeNotificationDays(e, { value }) {
    setNotificationDays(Number(value ?? 0));
    setErrors(null);
  }
  function onChangeSendingFrequency(e, { value }) {
    setSendingFrequency(value);
    setErrors(null);
  }
  function onChangeAutoAddDates(e, { checked }) {
    setAutoAddDates(checked);
    setErrors(null);
  }
}
