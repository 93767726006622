/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';

// Hook borrowed from https://mantine.dev/hooks/use-did-update
// When component mounts useEffect hook is called. This is fine in most cases,
// but if you need to track value changes you will need to implement something like this to prevent useEffect call on mount:

export function useDidUpdate(fn, dependencies) {
  const mounted = useRef(false);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  useEffect(() => {
    if (mounted.current) {
      return fn();
    }

    mounted.current = true;
    return undefined;
  }, dependencies);
}
