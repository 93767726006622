export const activitiesConfig = [
  {
    name: 'food',
    icon: 'utensils',
    color: '#F9532E',
    type: 'feeding',
  },
  {
    name: 'potty',
    icon: 'poo',
    color: '#00e3b3',
    type: 'diapering',
  },
  {
    name: 'nap',
    icon: 'bed',
    color: '#ffbf01',
    type: 'sleeping',
  },
  {
    name: 'note',
    icon: 'comment',
    color: '#ff7701',
    type: 'note',
  },
  {
    name: 'Photo',
    icon: 'camera',
    color: '#961dc0',
    type: 'photo',
  },
  {
    name: 'drop off',
    icon: 'sign in alternate',
    color: '#ff8661',
    type: 'dropoff',
  },
  {
    name: 'pick up',
    icon: 'sign out alternate',
    color: '#00edf9',
    type: 'pickup',
  },
];

export const centerOverviewConfig = [
  {
    name: 'checkedIn',
    description: 'Checked-in Students',
  },
  {
    name: 'rooms',
    description: 'Rooms',
  },
  {
    name: 'staff',
    description: 'Staff',
  },
  {
    name: 'students',
    description: 'Total Students',
  },
];

export const scheduleDates = [
  {
    name: 'M',
    description: 'Monday',
  },
  {
    name: 'T',
    description: 'Tuesday',
  },
  {
    name: 'W',
    description: 'Wednesday',
  },
  {
    name: 'Th',
    description: 'Thursday',
  },
  {
    name: 'F',
    description: 'Friday',
  },
  {
    name: 'S',
    description: 'Saturday',
  },
  {
    name: 'Su',
    description: 'Sunday',
  },
];

export const ageRangeMonths = [
  { key: 0, text: '0 Months', value: 0 },
  { key: 1, text: '1 Month', value: 1 },
  { key: 2, text: '2 Months', value: 2 },
  { key: 3, text: '3 Months', value: 3 },
  { key: 4, text: '4 Months', value: 4 },
  { key: 5, text: '5 Months', value: 5 },
  { key: 6, text: '6 Months', value: 6 },
  { key: 7, text: '7 Months', value: 7 },
  { key: 8, text: '8 Months', value: 8 },
  { key: 9, text: '9 Months', value: 9 },
  { key: 10, text: '10 Months', value: 10 },
  { key: 11, text: '11 Months', value: 11 },
  { key: 12, text: '12 Months', value: 12 },
];

export const ageRangeYears = [
  { key: 0, text: '0 Years', value: 0 },
  { key: 1, text: '1 Year', value: 1 },
  { key: 2, text: '2 Years', value: 2 },
  { key: 3, text: '3 Years', value: 3 },
  { key: 4, text: '4 Years', value: 4 },
  { key: 5, text: '5 Years', value: 5 },
  { key: 6, text: '6 Years', value: 6 },
  { key: 7, text: '7 Years', value: 7 },
  { key: 8, text: '8 Years', value: 8 },
  { key: 9, text: '9 Years', value: 9 },
  { key: 10, text: '10 Years', value: 10 },
  { key: 11, text: '11 Years', value: 11 },
  { key: 12, text: '12 Years', value: 12 },
  { key: 13, text: '12+ Years', value: 13 },
];

export const raceOptions = [
  {
    text: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native',
  },
  {
    text: 'American Indian from South or Central America',
    value: 'American Indian from South or Central America',
  },
  { text: 'Asian', value: 'Asian' },
  {
    text: 'Black or African American',
    value: 'Black or African American',
  },
  {
    text: 'Middle Eastern or North African',
    value: 'Middle Eastern or North African',
  },
  {
    text: 'Native Hawaiian or Pacific Islander',
    value: 'Native Hawaiian or Pacific Islander',
  },
  { text: 'White or Caucasian', value: 'White or Caucasian' },
  { text: 'Other', value: 'Other' },
  { text: 'Unknown', value: 'Unknown' },
  { text: 'Prefer not to disclose', value: 'Prefer not to disclose' },
];

export const ethnicityOptions = [
  {
    text: 'Hispanic or Latino/a',
    value: 'Hispanic or Latino/a',
  },
  { text: 'Non Hispanic or Latino/a', value: 'Non Hispanic or Latino/a' },
  {
    text: 'Unknown',
    value: 'Unknown',
  },
  { text: 'Prefer not to disclose', value: 'Prefer not to disclose' },
];

export const ageOptions = [
  {
    text: '20-30',
    value: '20-30',
  },
  {
    text: '31-40',
    value: '31-40',
  },
  {
    text: '41-50',
    value: '41-50',
  },
  {
    text: '51-60',
    value: '51-60',
  },
  {
    text: '61-70',
    value: '61-70',
  },
  {
    text: '70+',
    value: '70+',
  },
  { text: 'Prefer not to disclose', value: 'Prefer not to disclose' },
];

export const unenrollmentInitatedByOptions = [
  {
    text: 'Parent requested unenrollment',
    value: 'Parent requested unenrollment',
  },
  {
    text: 'Provider initiated unenrollment',
    value: 'Provider initiated unenrollment',
  },
];

export const unenrollmentReasonOptions = [
  {
    text: 'Family is moving',
    value: 'Family is moving',
  },
  {
    text: 'Child has aged out of the program',
    value: 'Child has aged out of the program',
  },
  {
    text: 'Child transferring to another early learning/child care program',
    value: 'Child transferring to another early learning/child care program',
  },
  { text: 'Cost', value: 'Cost' },
  {
    text: 'Schedule/availability issue',
    value: 'Schedule/availability issue',
  },
  {
    text: 'Program does not have the resources, skills, or support to support this child’s needs',
    value:
      'Program does not have the resources, skills, or support to support this child’s needs',
  },
  {
    text: 'Parents were dissatisfied with the program',
    value: 'Parents were dissatisfied with the program',
  },
  {
    text: 'My program is closing',
    value: 'My program is closing',
  },
  {
    text: 'Short-term enrollment (drop in, camp, etc.)',
    value: 'Short-term enrollment (drop in, camp, etc.)',
  },
  { text: 'Other', value: 'Other' },
];

export const networkOptions = [
  {
    key: 'wonderschool',
    text: 'Wonderschool',
    value: 'wonderschool',
    active: true,
  },
  {
    key: 'e82f2738-de19-44bf-a7aa-70f39f6a7bc5',
    text: 'Candelen (Arizona)',
    value: 'e82f2738-de19-44bf-a7aa-70f39f6a7bc5',
    active: true,
  },
  {
    key: '9b1ab3ee-cc1c-439a-83d8-4fb2b53fcd77',
    text: 'CC Group Dallas (Texas)',
    value: '9b1ab3ee-cc1c-439a-83d8-4fb2b53fcd77',
    active: true,
  },
  {
    key: '16ecff84-f832-471a-a7c2-ab63ef2611d7',
    text: 'Child & Family Resources (New Jersey)',
    value: '16ecff84-f832-471a-a7c2-ab63ef2611d7',
    active: true,
  },
  {
    key: '3aa3e465-21a0-408f-baf7-605ee6be3759',
    text: "Children's Council of San Francisco (California)",
    value: '3aa3e465-21a0-408f-baf7-605ee6be3759',
    active: true,
  },
  {
    key: 'c757530d-f064-403c-957b-79cecf1d33f0',
    text: 'Educational First Steps (Texas)',
    value: 'c757530d-f064-403c-957b-79cecf1d33f0',
    active: true,
  },
  {
    key: 'f186f5c4-1835-401e-99a0-d57829476f90',
    text: 'Early Learning Resource Center - Region 1 (Erie, Pennsylvania)',
    value: 'f186f5c4-1835-401e-99a0-d57829476f90',
    active: true,
  },
  {
    key: 'c08d0ab8-cea7-4eea-9c2b-554fb657bcf4',
    text: 'Elevate New Mexico Child Care (New Mexico)',
    value: 'c08d0ab8-cea7-4eea-9c2b-554fb657bcf4',
    active: true,
  },
  {
    key: '54df5823-8153-4b1a-88d1-fe3a4d16954c',
    text: 'Family Child Care Alliance of Maryland (Maryland)',
    value: '54df5823-8153-4b1a-88d1-fe3a4d16954c',
    active: true,
  },
  {
    key: '7bdbe708-6257-428f-91ba-69c4cce87e6a',
    text: 'Idaho AEYC (Idaho)',
    value: '7bdbe708-6257-428f-91ba-69c4cce87e6a',
    active: true,
  },
  {
    key: '8689e1c0-392b-47d7-9091-8459eccc6306',
    text: "Let's Grow Kids (Vermont)",
    value: '8689e1c0-392b-47d7-9091-8459eccc6306',
    active: true,
  },
  {
    key: '938f0d8f-6cfb-49ce-a726-ccc348d5899d',
    text: 'Maryland Child Care Boost (Maryland)',
    value: '938f0d8f-6cfb-49ce-a726-ccc348d5899d',
    active: true,
  },
  {
    key: '690bfc07-afbb-4d93-bd5e-d7d79f7d3f62',
    text: 'Monroe Smart Start/CFBMC (Bloomington & Monroe Counties, Indiana)',
    value: '690bfc07-afbb-4d93-bd5e-d7d79f7d3f62',
    active: true,
  },
  {
    key: '5e86e874-7bd4-4478-b7cf-03fdfcc062f6',
    text: 'NCPC/Smart Start (North Carolina)',
    value: '5e86e874-7bd4-4478-b7cf-03fdfcc062f6',
    active: true,
  },
  {
    key: '4cf55bfb-c04b-4fe1-81c0-f7321807cc70',
    text: 'Nebraska Early Childhood Collaborative (Nebraska)',
    value: '4cf55bfb-c04b-4fe1-81c0-f7321807cc70',
    active: true,
  },
  {
    key: 'f297b80e-af30-4072-8631-0fb7d760d0c5',
    text: 'NEXT Memphis (Tennessee)',
    value: 'f297b80e-af30-4072-8631-0fb7d760d0c5',
    active: true,
  },
  {
    key: '66e6ab1e-bce6-41e0-b434-a6bb8b5a14dd',
    text: 'NW CCR&R (Oregon)',
    value: '66e6ab1e-bce6-41e0-b434-a6bb8b5a14dd',
    active: true,
  },
  {
    key: '866c438b-964e-4bd3-b481-d8ddec7d6f66',
    text: 'Oregon Childcare Alliance (Oregon)',
    value: '866c438b-964e-4bd3-b481-d8ddec7d6f66',
    active: true,
  },
  {
    key: '87459e8f-daf2-42b9-b5f3-ce6a50797472',
    text: 'Programs for Parents (New Jersey)',
    value: '87459e8f-daf2-42b9-b5f3-ce6a50797472',
    active: true,
  },
  {
    key: '84428a61-5a7a-4226-97af-5ce957542ca7',
    text: 'Porter Leath (Tennessee)',
    value: '84428a61-5a7a-4226-97af-5ce957542ca7',
    active: true,
  },
  {
    key: '4e6d0006-cddb-4cda-a569-fe1b743413bc',
    text: 'Quality Care for Children (Georgia)',
    value: '4e6d0006-cddb-4cda-a569-fe1b743413bc',
    active: true,
  },
  {
    key: '45e77d33-940b-4997-a02f-ec8c9502b295',
    text: 'South Coast Shared Services Alliance (Coos & Curry Counties, OR)',
    value: '45e77d33-940b-4997-a02f-ec8c9502b295',
    active: true,
  },
  {
    key: '2b7fb568-e6e6-405c-9ee4-e54d3b0429b7',
    text: 'TIFFAN (Nannies/CA)',
    value: '2b7fb568-e6e6-405c-9ee4-e54d3b0429b7',
    active: true,
  },
  {
    key: '5b257606-0ecb-409b-8ce6-fb9cfc26387e',
    text: 'Virginia Early Childhood Foundation/Ready Network (Virginia)',
    value: '5b257606-0ecb-409b-8ce6-fb9cfc26387e',
    active: true,
  },
  {
    key: '5854c5e2-607d-4019-bd22-2b54096283fa',
    text: 'West Central Initiative (Minnesota)',
    value: '5854c5e2-607d-4019-bd22-2b54096283fa',
    active: true,
  },
  {
    key: '685c1a59-db6f-47f1-860e-9d4b42ad8178',
    text: 'Wisconsin Early Childhood Association (Wisconsin)',
    value: '685c1a59-db6f-47f1-860e-9d4b42ad8178',
    active: true,
  },
];

export const timezoneOptions = [
  {
    key: 'America/New_York',
    value: 'America/New_York',
    text: 'America/New_York',
    tag: { abbr: 'EST', utc: '-05:00' },
    dstTag: { abbr: 'EDT', utc: '-04:00' },
  },
  {
    key: 'America/Chicago',
    value: 'America/Chicago',
    text: 'America/Chicago',
    tag: { abbr: 'CST', utc: '-06:00' },
    dstTag: { abbr: 'CDT', utc: '-05:00' },
  },
  {
    key: 'America/Denver',
    value: 'America/Denver',
    text: 'America/Denver',
    tag: { abbr: 'MST', utc: '-07:00' },
    dstTag: { abbr: 'MDT', utc: '-06:00' },
  },
  {
    key: 'America/Phoenix',
    value: 'America/Phoenix',
    text: 'America/Phoenix',
    tag: { abbr: 'MST', utc: '-07:00' },
    dstTag: { abbr: 'MST', utc: '-07:00' },
  },
  {
    key: 'America/Los_Angeles',
    value: 'America/Los_Angeles',
    text: 'America/Los_Angeles',
    tag: { abbr: 'PST', utc: '-08:00' },
    dstTag: { abbr: 'PDT', utc: '-07:00' },
  },
  {
    key: 'America/Anchorage',
    value: 'America/Anchorage',
    text: 'America/Anchorage',
    tag: { abbr: 'AKST', utc: '-09:00' },
    dstTag: { abbr: 'AKDT', utc: '-08:00' },
  },
  {
    key: 'Pacific/Honolulu',
    value: 'Pacific/Honolulu',
    text: 'Pacific/Honolulu',
    tag: { abbr: 'HST', utc: '-10:00' },
    dstTag: { abbr: 'HST', utc: '-10:00' },
  },
];