import { isEmpty } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from '../firebase';
import { getUserClaims, getIdToken } from '../firebase/currentUser';
import { logWarning, trackUser, trackUserSignout } from '../../rollbar';

const CHECK_INTERVAL = 5000;

/**
 * Simple hook that monitors for user state updates.
 */
const useAuthUser = (WSA) => {
  // unlike authSession, isLoading starts true (instead of false with sessions)
  // which means is ready will always start false and transition one way to true
  const [authUser, isLoading, authError] = useAuthState(getAuth());
  const [userClaims, setUserClaims] = useState();
  const [idToken, setIdToken] = useState();
  const [subError, setSubError] = useState();

  const hasUser = !!authUser;
  const hasClaimsAndToken = !!userClaims && !isEmpty(userClaims) && !!idToken;
  const isReady = !isLoading && (!hasUser || hasClaimsAndToken);
  const isFailed = !!authError || !!subError;
  const isEmailVerified = authUser?.emailVerified;

  useEffect(() => {
    if (authUser) {
      trackUser(authUser);
    } else {
      trackUserSignout();
    }
  }, [authUser]);

  // firebase DOES NOT update the emailVerified field on its own :(
  // long polling here to compensate
  // https://stackoverflow.com/questions/41541887/firebase-how-to-detect-observe-when-firebase-user-verified-their-email-like-on#comment122566144_41550149
  useEffect(() => {
    let timer;
    const checkVerified = async () => {
      clearTimeout(timer);
      if (!isEmailVerified) {
        timer = setInterval(async () => {
          if (!isEmailVerified && authUser) {
            const claims = await getUserClaims(true); // force the user refresh
            if (claims?.email_verified) {
              const token = await getIdToken(true);
              setUserClaims(claims);
              setIdToken(token);
              WSA.auth.forceTokenRefreshOnApps();
              clearTimeout(timer);
            }
          } else if (authUser) {
            clearTimeout(timer);
          }
        }, CHECK_INTERVAL);
      }
    };
    checkVerified();
    return () => clearTimeout(timer);
  }, [WSA.auth, authUser, isEmailVerified]);

  // keeps the user claims and id token in sync
  const getClaims = useCallback(
    ({ isStillValid, forceRefresh }) => {
      const doAsync = async () => {
        if (hasUser && (forceRefresh || !hasClaimsAndToken)) {
          try {
            const [idToken, claims] = await Promise.all([
              getIdToken(true),
              getUserClaims(true),
            ]);
            if (isStillValid || forceRefresh) {
              setUserClaims(claims);
              setIdToken(idToken);
              WSA.auth.forceTokenRefreshOnApps();
            }
          } catch (err) {
            if (isStillValid) {
              logWarning(`err getting user claims: ${err.message}`, {
                err,
                hasUser,
                hasClaimsAndToken,
                forceRefresh,
              });
              setSubError(err);
            }
          }
        } else if (!hasUser) {
          setUserClaims(undefined);
          setIdToken(undefined);
        }
      };
      doAsync();
    },
    [WSA.auth, hasClaimsAndToken, hasUser]
  );
  useEffect(() => {
    let isStillValid = true;
    getClaims({ isStillValid });
    return () => (isStillValid = false);
  }, [getClaims]);

  return {
    isLoading, // whether firebase is initialized and the authentication listener is setup
    isFailed, // whether there was an error trying to load the user
    isReady, // whether initialization has completed
    hasUser, // whether a user has been found
    authUser, // latest value for authUser. `null` if not present, `undefined` if not yet loaded
    authError: authError || subError,
    hasClaimsAndToken, // whether or not the sub elements are found
    userClaims, // json object
    idToken, // encoded string
    forceRefresh: () => getClaims({ forceRefresh: true }), // use this to force an update for certain updates that don't fire triggers, like custom claims modifications
  };
};

export default useAuthUser;
