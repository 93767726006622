import React from 'react';
import { Container } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

// Import components.
import StudentsTable from '../students/components/StudentsTable';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';

const Students = (props) => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.students);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Students') })}
      </title>
      <body class="students" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}
      <StudentsTable />
    </Container>
  );
};

export default Students;
