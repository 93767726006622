import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoadingRoute from './LoadingRoute';
import LoadingComponent from '../Shared/LoadingComponent';
import useRouteDebounce from './useRouteDebounce';
import { userHasPermission } from '../../api/firebase/account';
import { getOrganizationSetupState } from '../../redux/actions/organizationActions';
import WSA from '../../WSA';
import { routes } from '../../config/routes';

const RestrictedRoute = ({
  staticContext,
  permissions,
  routePermission,
  path,
  component: UserComponent,
  needsOrgOnboarding,
  ...rest
}) => {
  const { isAuthenticated, needsVerification, userClaims } =
    WSA.components.useWSAuth();
  const hasClaims = !isEmpty(userClaims);
  const hasPermissions = !isEmpty(permissions);
  const hasClaimsAndPermissions = hasClaims && hasPermissions;
  const hasRoutePermission =
    routePermission === '*' || userHasPermission(routePermission);
  // special routes where the user state is fluid but we want them to continue
  const isSetupRoute = path === routes.setup;
  const isCompleteSignupRoute = path === routes.completeSignup;
  const isHomeRoute = path === routes.home;
  const canViewRoute = hasRoutePermission || isSetupRoute || isHomeRoute;
  const { isDebounced } = useRouteDebounce(
    JSON.stringify({
      isAuthenticated,
      needsVerification,
      isCompleteSignupRoute,
      needsOrgOnboarding,
      hasClaimsAndPermissions,
      canViewRoute,
      isHomeRoute,
    })
  );

  return (
    <LoadingRoute
      {...rest}
      component={(props) => {
        if (!isDebounced) {
          return <LoadingComponent />;
        } else if (isAuthenticated) {
          if (needsVerification) {
            // engage verify user flow
            return (
              <Redirect
                to={{
                  ...props,
                  pathname: routes.verify,
                  state: { from: props.location },
                }}
              />
            );
          } else if (isCompleteSignupRoute) {
            // special case for completing signup
            // the user and might not have claims/permissions yet
            return <UserComponent {...props} />;
            // this check must be before Redirect below to prevent looping (completeSignup route)
          } else if (needsOrgOnboarding) {
            // switch to org setup, complete signup route
            return (
              <Redirect
                to={{
                  ...props,
                  pathname: routes.completeSignup,
                  state: { from: props.location },
                }}
              />
            );
          } else if (canViewRoute) {
            // normal flow for authenticated users
            return <UserComponent {...props} />;
            // this check must be before Redirect below to prevent looping (home route)
          } else {
            // the user can't view the route, send them somewhere safe
            return <Redirect to={routes.home} />;
          }
        } else {
          // not authenticated, go to signin
          return (
            <Redirect
              push={true}
              to={{
                ...props,
                pathname: routes.signin,
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  needsOrgOnboarding: getOrganizationSetupState(state.organizations)
    .needsOrgOnboarding,
});

export default connect(mapStateToProps)(RestrictedRoute);
