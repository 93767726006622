import React from 'react';
import { Icon } from 'semantic-ui-react';

// Import helpers

import mimeTypes from '../../helpers/mimeTypes';
/**
 * @param {string} mime
 * Reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 */
const pickName = mime => {
  switch (mime) {
    case mimeTypes.PNG:
      return 'file image';
    case mimeTypes.JPEG:
      return 'file image';
    case mimeTypes.PDF:
      return 'file pdf';
    case mimeTypes.DOC:
      return 'file word';
    case mimeTypes.DOCX:
      return 'file word';
    case mimeTypes.XLS:
      return 'file excel';
    case mimeTypes.XLSX:
      return 'file excel';
    default:
      return 'file text';
  }
};

const MimeIcon = ({ mime, name = null, ...rest }) =>
  mime && <Icon name={name || pickName(mime)} {...rest} />;

MimeIcon.propTypes = {};

export default MimeIcon;
