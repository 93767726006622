import {
  MalwareStatus,
  useGetFiles,
  useDownloadFiles,
} from '@wonderschool/file-service-client';
import { capitalize } from 'lodash';
import { useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Header, Message } from 'semantic-ui-react';

import { renderDate } from '../../utils/date';
import { humanizeBytes } from '../../utils/file';

import { useDidUpdate } from '../../hooks/useDidUpdate';

const DEFAULT_FILTER = {
  limit: 10,
  orderBy: [
    {
      field: 'updatedAt',
      direction: 'desc',
    },
  ],
};

const FormsAndDocumentsTable = ({
  showArchived = false,
  onRowSelect = () => {},
}) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(DEFAULT_FILTER);
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    data,
    isLoading,
    isError: isGetFilesError,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetFiles(filters);
  const { downloadFiles, error: downloadError } = useDownloadFiles();

  const getRowClasses = (row) => {
    if (row.fileStatus !== MalwareStatus.CLEAN) return 'disabled';
    return null;
  };

  const handleTableChange = (type, { sortField, sortOrder }) => {
    // FIXME:  **Sort**, react-bootstrap-table2 does not support sorting by multiple fields
    // Therefore we replace the current orderBy with the new one.
    // https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/basic-sort.html
    if (type === 'sort') {
      setFilters((prev) => ({
        ...prev,
        orderBy: [{ field: sortField, direction: sortOrder }],
      }));
    }
  };

  // This hook behaves like useEffect, but only runs when the value of the dependencies change.
  // Does not run on the initial render.
  useDidUpdate(() => {
    setFilters((prev) => ({
      ...prev,
      includeArchived: showArchived,
    }));
  }, [showArchived]);

  const tableData = useMemo(
    () => data?.pages?.flatMap((page) => page.documents) ?? [],
    [data]
  );

  const columns = useMemo(
    () => [
      {
        dataField: 'id',
        text: 'ID',
        hidden: true,
      },
      {
        dataField: 'name',
        text: t('Name'),
        sort: true,
        formatter: (cell, row) => {
          const fileStatus = row.fileStatus;
          if (fileStatus !== MalwareStatus.CLEAN) return cell;

          return (
            <a
              href={`#${row.id}`}
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                downloadFiles([row.id]);
              }}
            >
              {cell}
            </a>
          );
        },
      },
      {
        dataField: 'updatedAt',
        text: t('Updated'),
        sort: true,
        formatter: (_cell, row) => {
          const fileStatus = row.fileStatus;
          const date = renderDate(row.updatedAt, 'MM/DD/YYYY');
          const updatedBy = row.updatedBy?.displayName;

          return (
            <Header as="h5">
              <Header.Content>
                {date} by {updatedBy}
                <Header.Subheader>
                  Status: {capitalize(fileStatus)}
                </Header.Subheader>
              </Header.Content>
            </Header>
          );
        },
      },
      {
        dataField: 'file.size',
        text: t('Size'),
        sort: true,
        formatter: (cell) => humanizeBytes(cell, 2),
      },
      {
        dataField: 'fileAction',
        text: t('File Type'),
        formatter: (cell) => {
          return capitalize(cell);
        },
      },
    ],
    [downloadFiles, t]
  );

  const selectRow = useMemo(
    () => ({
      mode: 'checkbox',
      clickToSelect: true,
      selectionHeaderRenderer: ({ mode, rowKey, rowIndex, ...rest }) => (
        <Checkbox {...rest} />
      ),
      selectionRenderer: ({ mode, rowKey, rowIndex, ...rest }) => (
        <Checkbox {...rest} />
      ),
      onSelect: (row, isSelected) => {
        const selected = isSelected
          ? [...selectedRows, row]
          : selectedRows.filter((c) => c.id !== row.id);

        setSelectedRows(selected);
        onRowSelect?.(selected);
      },
      onSelectAll: (isSelected, rows) => {
        const selected = isSelected ? rows : [];
        setSelectedRows(selected);
        onRowSelect?.(selected);
      },
      nonSelectable: tableData?.reduce(
        (prev, curr) =>
          curr.fileStatus !== MalwareStatus.CLEAN ? [curr.id, ...prev] : prev,
        []
      ),
    }),
    [tableData, selectedRows, onRowSelect]
  );

  const errorOccurred = isGetFilesError || downloadError;
  const errorHeader = isGetFilesError
    ? 'Error occurred while fetching documents'
    : downloadError
    ? 'Error occurred while downloading documents'
    : '';
  const errorMessage = error?.message ?? downloadError?.message ?? '';

  return (
    <>
      {errorOccurred && (
        <Message
          negative
          icon="cancel"
          header={errorHeader}
          content={errorMessage}
        />
      )}

      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={tableData}
        columns={columns}
        className="bootstrap-iso"
      >
        {({ baseProps }) => (
          <div className="bootstrap-iso">
            <BootstrapTable
              remote
              hover
              bordered={false}
              selectRow={selectRow}
              headerClasses="table-header"
              rowClasses={getRowClasses}
              noDataIndication={
                <>
                  {isLoading ? (
                    <div>{t('Loading data')}...</div>
                  ) : (
                    <div>{t('No forms/documents found')}</div>
                  )}
                </>
              }
              defaultSorted={filters?.orderBy?.map((o) => ({
                dataField: o.field,
                order: o.direction,
              }))}
              onTableChange={handleTableChange}
              {...baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      {hasNextPage && (
        <Button
          positive
          disabled={isFetchingNextPage}
          size="small"
          onClick={fetchNextPage}
        >
          {t('Load more')}
        </Button>
      )}
    </>
  );
};

export default FormsAndDocumentsTable;
