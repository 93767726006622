/**
 * Enum for various user status states
 * - NO_USER: no user found in app
 * - NOT_VERIFIED: user has not verified their email via firebase auth
 * - DISABLED: user has been marked as disabled in firebase auth
 * - SESSION_INVALID: the attached user session is either inactive, not found, or otherwise invalid
 * - AUTHENTICATED: the user has been fully authenticated and has no issues
 */
export const UserStatus = {
  NOT_READY: 'NOT_READY',
  NO_USER: 'NO_USER',
  NOT_VERIFIED: 'NOT_VERIFIED',
  DISABLED: 'DISABLED',
  SESSION_INVALID: 'SESSION_INVALID',
  AUTHENTICATED: 'AUTHENTICATED',
};
