/*****************************/
/* REDUCED from SSO-ACCOUNTS */
/*****************************/
import { writeMergeDoc, getDocument, getDocRef } from './firestore';
import { trackNewSession } from '../../rollbar';
/**
 * 1 user can have one session per browser context (ideally).
 * This is meant to be enforced by how the client looks up sessions and auth.
 * A session can be shared across devices if they can share a session id.
 * Otherwise, the only cross-device functionality supported is to sign out of all devices.
 */
const paths = {
  /**
   * @param {string} userId firebase id
   * @param {string} sessionId uuidv4
   * @returns {string} doc path
   */
  session: (userId, sessionId) => `authSession/${userId}/sessions/${sessionId}`,
};
const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
const FIVE_MINUTES = 1000 * 60 * 5;
/**
 * @returns {number} epoch millis
 */
const getNow = () => Date.now();
/**
 * @param {number?} now epoch millis, default right now
 * @returns {number} epoch millis, 1 week from now (default)
 */
const getNewExpiration = (now = getNow()) => now + ONE_WEEK;
/**
 * ### Async / Promise
 * write session data to firestore
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @param {Object} sessionData
 * @returns {boolean}
 * true if write completed, false if an error was caught
 */
const writeSessionData = async (userId, sessionId, sessionData = {}) => {
  if (await writeMergeDoc(paths.session(userId, sessionId), sessionData)) {
    return true; // some result provided, maybe just batched
  } else {
    return false; // failed to write, error was caught
  }
};
/**
 * ### Async / Promise
 * gets a specific session from firestore
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @returns {DocumentData | undefined}
 * if undefined, either the document was not found or an error occurred.
 * otherwise, returns the document data as json object
 */
const getSession = async (userId, sessionId) =>
  (await getDocument(paths.session(userId, sessionId))) || undefined;
/**
 * Makes a "fresh" session object with existing session id
 * Recommend including the original session data
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @param {string?} optSessionData original session data
 * @returns {Object}
 */
const makeUpdateSessionData = (userId, sessionId, optSessionData = {}) => {
  return {
    ...optSessionData,
    userId,
    sessionId,
    active: true,
    startsAt: getNow(),
    expires: getNewExpiration(),
  };
};
/**
 * Makes an inactive session that has already expired
 * Recommend including the original session data
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @param {string?} optSessionData original session data
 * @returns {Object} updated session
 */
const makeInactiveSessionData = (userId, sessionId, optSessionData = {}) => {
  return {
    ...optSessionData,
    userId,
    sessionId,
    active: false,
    expires: getNow(),
  };
};
/**
 * Active session must be marked active and have an expiration date in the future
 * @param {Object} session session data
 * @returns {boolean}
 */
const isActive = (session) => {
  return session?.active && session?.expires && session.expires > getNow();
};
/**
 * Recent session must active and have started in the last 5 minutes
 * @param {Object} session session data
 * @returns {boolean}
 */
const isRecent = (session) => {
  return isActive(session) && getNow() - session.startsAt < FIVE_MINUTES;
};
/**
 * @param {Object} session session data
 * @returns {number?} epoch millis until expiration, undefined otherwise. may be negative if already expired.
 */
const getRemaining = (session) =>
  session ? session.expires - getNow() : undefined;
/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * Tries to update an existing session, including refreshing the timestamps, and returns the new session data
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @param {Object?} optData
 * @returns { Object } updated session
 * @throws { Error } if userId is not provided
 * @throws { Error } if sessionId is not provided
 * @throws { Error } if session is not found
 * @throws { Error } session fails to write
 */
const updateSession = async (userId, sessionId, optData = {}) => {
  if (!userId) {
    throw new Error('userId required');
  }
  if (!sessionId) {
    throw new Error('userId sessionId');
  }
  const session = await getSession(userId, sessionId);
  if (!session) {
    throw new Error('session not found');
  }

  const refreshedSessionData = makeUpdateSessionData(userId, sessionId, {
    ...session,
    ...optData,
  });
  const didWrite = await writeSessionData(
    userId,
    sessionId,
    refreshedSessionData
  );
  if (didWrite) {
    trackNewSession(refreshedSessionData);
    return refreshedSessionData;
  } else {
    throw new Error('Failed to update session');
  }
};
/**
 * ### Should be wrapped in try/catch ###
 * ### Async / Promise
 * Tries to stop an existing session, including overwriting the expiration
 * and flipping the active flag, and returns the new session data
 * @param {string} userId firebase id
 * @param {string} sessionId uuidv4
 * @returns { Object } stopped session
 * @throws { Error } if userId is not provided
 * @throws { Error } if sessionId is not provided
 * @throws { Error } if session is not found
 * @throws { Error } session fails to write
 */
const stopSession = async (userId, sessionId) => {
  if (!userId) {
    throw new Error('userId required');
  }
  if (!sessionId) {
    throw new Error('userId sessionId');
  }
  const session = await getSession(userId, sessionId);
  if (!session) {
    throw new Error('session not found');
  }

  const inactiveSessionData = makeInactiveSessionData(
    userId,
    sessionId,
    session
  );
  const didWrite = await writeSessionData(
    userId,
    sessionId,
    inactiveSessionData
  );
  if (didWrite) {
    trackNewSession(inactiveSessionData);
    return inactiveSessionData;
  } else {
    throw new Error('Failed to stop session');
  }
};
/**
 * Helper to refresh session timing data
 * @param {Object*} session
 * @returns { Object } updated session
 * @throws { Error } if userId is not provided in session
 * @throws { Error } if sessionId is not provided in session
 * @throws { Error } if session is not found
 * @throws { Error } session fails to write
 */
const refreshSession = async (session) =>
  updateSession(session?.userId, session?.sessionId, session);

/**
 * @param {string?} userId
 * @param {string?} sessionId
 * @returns {DocumentReference<DocumentData?>} either the reference or undefined if sufficient arguments are not provided
 */
const getSessionRef = (userId, sessionId) => {
  if (!userId || !sessionId) {
    return undefined;
  }
  return getDocRef(paths.session(userId, sessionId));
};

export {
  isActive,
  isRecent,
  getRemaining,
  updateSession,
  stopSession,
  refreshSession,
  getSessionRef,
  //
};
