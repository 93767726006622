import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import moment from 'moment';
// import 'moment/locale/es'; - TODO: CCMS-1047 - figure out i18n + moment

import { isLocal } from './config/env';
import { logError } from './rollbar';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: isLocal(),

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    parseMissingKeyHandler: (key) => {
      const str = `Missing translation: ${key}`;
      logError(str, new Error(str), { key });
      return key;
    },
  });

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang);
  // moment.locale(lang); - TODO: CCMS-1047 - figure out i18n + moment
};

export default i18n;
