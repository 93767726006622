import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Segment,
  Grid,
  Header,
  Button,
  Table,
  Modal,
} from 'semantic-ui-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  formatFullName,
  currencyFormatter,
  getFormatter,
} from '../../../../helpers/utils';
import { didStripeOnboardingSucceed } from '../../../../helpers/stripe';
import {
  isEnabled,
  getFastpayTooltip,
  getFastpayColor,
} from '../../../../helpers/fastpay';
import {
  setOrganizationInvoicePlan,
  updateOrganizationInvoice,
} from '../../../../api/firebase/invoices';
import {InvoiceStatus} from '../../../../helpers/invoices';
import { InvoiceStatus as InvoiceStatusComponent } from '../../../../Components/Billing/Invoices';
import { FastpayStatus } from '../../../../Components/Billing/Fastpay';
import InvoiceActionForm from './InvoiceActionForm';

// Import style
import './InvoiceDetail.scss';
import { usePendingParentInvitationsListener } from "../../../../hooks/useInvitations";
import { isEmpty } from 'lodash';
import { useStudent } from '../../../../students/studentsHooks';

function InvoiceDetailContainer({
  invoices,
  invoiceType,
  currentOrganization,
  ...rest
}) {
  const [selectedInvoice, setSelectedInvoice] = useState(
    invoices[invoiceType]?.selected
  );
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [invoiceUpdateData, setInvoiceUpdateData] = useState();

  const { t } = useTranslation();

  const organizationId = currentOrganization.id;

  let responsibleForBilling = null;
  const student = useStudent(selectedInvoice.student && selectedInvoice.student.id);
  if(student && student.responsibleForBilling) responsibleForBilling = student.responsibleForBilling;

  usePendingParentInvitationsListener(
      organizationId,
      selectedInvoice?.student?.id
  );

  useEffect(() => {
    if (!selectedInvoice) setSelectedInvoice(invoices[invoiceType]?.selected);

    const doUpdateInvoice = async () => {

      if (invoiceUpdateData && selectedInvoice) {

        if (!invoiceUpdateData.id) invoiceUpdateData.id = selectedInvoice.id;

        if (invoiceType === 'recurring') {
          invoiceUpdateData.dateDue = Date.now();
          await setOrganizationInvoicePlan(organizationId, invoiceUpdateData);
        } else {
          await updateOrganizationInvoice(organizationId, invoiceUpdateData);
        }
        const updatedInvoice = { ...selectedInvoice, ...invoiceUpdateData };
        setInvoiceUpdateData(null);
        setSelectedInvoice(updatedInvoice);
      }
    };
    doUpdateInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, selectedInvoice, invoiceUpdateData]);

  return (
    <Container fluid className="no-padding no-margin">
      <Grid stackable columns="equal">
        <Grid.Column>
          {renderSummary(organizationId, selectedInvoice)}
          {renderDetails(selectedInvoice)}
          {renderPayments(selectedInvoice)}
        </Grid.Column>
      </Grid>
    </Container>
  );

  function renderSummary(organizationId, selectedInvoice) {
    const invoiceRows =
      selectedInvoice &&
      selectedInvoice.invoiceItemList &&
      selectedInvoice.invoiceItemList.map((item, index) => [
        <Table.Row key={index} verticalAlign="middle">
          <Table.Cell>{item.category}</Table.Cell>
          <Table.Cell>{item.item}</Table.Cell>
          <Table.Cell>{item.notes}</Table.Cell>
          <Table.Cell textAlign="right">
            {currencyFormatter(item.amount, { precision: 2 })}
          </Table.Cell>
        </Table.Row>,
        item.discounts.map((discount, j) => (
          <Table.Row key={`d-${index}-${j}`} disabled verticalAlign="middle">
            <Table.Cell>{discount.discountType}</Table.Cell>
            <Table.Cell />
            <Table.Cell />

            <Table.Cell textAlign="right">
              -{getFormatter(discount.amountType)(discount.amount)}
            </Table.Cell>
          </Table.Row>
        )),
      ]);

    const family = selectedInvoice && selectedInvoice.student && selectedInvoice.student.family;
    let billedTo = t('No parents found :(');

    if(selectedInvoice.billedTo) {
      billedTo = selectedInvoice.billedTo.displayName;
    } else if(responsibleForBilling) {
      billedTo = responsibleForBilling.displayName;
    } else if (!isEmpty(family)) {
      billedTo = Object.keys(family)
        .filter((fam) => !!family[fam].email)
        .map((fam) => {
        // check if fullName exists
        const fullName = formatFullName(family[fam], true);
        return fullName === '' ? null : fullName;
      })[0];
    } 

    const paidAmount = selectedInvoice.paidAmount ?? 0;

    return (
      <Segment clearing>
        <Modal
          size="tiny"
          open={showActionsModal}
          onClose={() => setShowActionsModal(false)}
          header={t('Change invoice status')}
          content={
            <InvoiceActionForm
              invoice={selectedInvoice}
              invoiceType={invoiceType}
              onCancel={() => setShowActionsModal(false)}
              onSave={(data) => {
                setInvoiceUpdateData(data);
                setShowActionsModal(false);
              }}
            />
          }
        />

        {renderPageTitle(currentOrganization, selectedInvoice)}

        <Segment className="no-shadow no-border top-border">
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Table compact basic="very" className="billing-summary">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{t('Billed to')}</Table.Cell>
                      <Table.Cell>{billedTo}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>

              <Grid.Column>
                <Table
                  singleLine
                  compact
                  basic="very"
                  className="billing-summary"
                >
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{t('Invoice number')}</Table.Cell>
                      <Table.Cell>
                        {selectedInvoice && selectedInvoice.id}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{t('Due date')}</Table.Cell>
                      <Table.Cell>
                        {selectedInvoice &&
                          selectedInvoice.dateDue &&
                          moment(selectedInvoice.dateDue).format('MM/DD/YYYY')}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{t('Billing method')}</Table.Cell>
                      <Table.Cell>
                        {selectedInvoice.manuallyPaid
                          ? t('Collected Manually.')
                          : t('Send invoice.')}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{t('Notes')}</Table.Cell>
                      <Table.Cell>
                        {selectedInvoice.notes ||
                          t('Thank you for your business')}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Table stackable compact="very" basic="very">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>{t('Category')}</Table.HeaderCell>
                      <Table.HeaderCell>{t('Item')}</Table.HeaderCell>
                      <Table.HeaderCell>{t('Notes')}</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t('Amount')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>{invoiceRows}</Table.Body>
                </Table>

                <Table
                  stackable
                  compact="very"
                  basic="very"
                  className="billing-summary"
                >
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell textAlign="right">{t('Total')}</Table.Cell>
                      <Table.Cell textAlign="right">
                        {selectedInvoice &&
                          currencyFormatter(selectedInvoice.total, {
                            precision: 2,
                          })}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell textAlign="right">
                        {t('Amount paid')}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        -
                        {selectedInvoice &&
                          currencyFormatter(paidAmount, {
                            precision: 2,
                          })}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell textAlign="right">
                        {t('Amount due')}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {selectedInvoice &&
                          currencyFormatter(
                            selectedInvoice.total - paidAmount,
                            {
                              precision: 2,
                            }
                          )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment>
    );
  }

  function renderDetails(selectedInvoice) {
    const family = selectedInvoice && selectedInvoice.student && selectedInvoice.student.family;
    let email = t('No email found');

    if(selectedInvoice.billedTo) {
      email = selectedInvoice.billedTo.email;
    } else if(responsibleForBilling) {
      email = responsibleForBilling.email;
    } else if (!isEmpty(family)) {
      email = Object.keys(family)
        .filter((fam) => !!family[fam].email)
        .map((fam) => {
        // check if email exists
        if (family[fam] && family[fam].email) {
          return family[fam].email;
        }

        return null;
      })[0];
    } 

    return (
      <Segment clearing>
        <Segment basic clearing className="no-padding">
          <Header as="h2" content={t('Details')} />
        </Segment>

        <Segment className="no-shadow no-border no-padding no-margin top-border">
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell>Id</Table.Cell>
                <Table.Cell>{selectedInvoice && selectedInvoice.id}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('Created')}</Table.Cell>
                <Table.Cell>
                  {selectedInvoice &&
                    selectedInvoice.createdAt &&
                    moment(selectedInvoice.createdAt).format(
                      'MM/DD/YYYY HH:MM A'
                    )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('Finalized')}</Table.Cell>
                <Table.Cell>
                  {selectedInvoice &&
                    selectedInvoice.createdAt &&
                    moment(selectedInvoice.createdAt).format(
                      'MM/DD/YYYY HH:MM A'
                    )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('Email invoice sent to')}</Table.Cell>
                <Table.Cell>{email}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }

  function renderPayments(selectedInvoice) {
    const payments = selectedInvoice?.payments?.map((payment) => (
      <Table.Row key={payment.createdAt}>
        <Table.Cell>
          {payment.paidAmount &&
            currencyFormatter(payment.paidAmount / 100, { precision: 2 })}
        </Table.Cell>
        <Table.Cell>
          {moment(payment.createdAt).format('MM/DD/YYYY hh:mm A')}
        </Table.Cell>
        <Table.Cell>{renderInvoiceStatus(payment)}</Table.Cell>
      </Table.Row>
    ));

    return (
      <Segment clearing>
        <Segment basic clearing className="no-padding">
          <Header as="h2" content={t('Payments')} />
        </Segment>

        <Segment className="no-shadow no-border top-border">
          <Table singleLine basic="very">
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell>{t('Amount')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Process Date')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Status')}</Table.HeaderCell>
              </Table.Row>

              {payments ? (
                payments
              ) : (
                <Table.Row key={'noPayment'} textAlign="center">
                  <Table.Cell colSpan={6}>{t('No payments made')}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }

  function renderPageTitle(organization, selectedInvoice) {
    const showActionsButton =
      selectedInvoice &&
      !selectedInvoice.alreadyPaid &&
      (selectedInvoice.status === InvoiceStatus.OPEN ||
        selectedInvoice.status === InvoiceStatus.DRAFT ||
        selectedInvoice.status === InvoiceStatus.SCHEDULED ||
        selectedInvoice.status === InvoiceStatus.UNCOLLECTIBLE);

    const showSendInvoiceButton =
      didStripeOnboardingSucceed(organization) &&
      (selectedInvoice.status === InvoiceStatus.DRAFT ||
        selectedInvoice.status === InvoiceStatus.SCHEDULED);

    return (
      <Segment basic clearing className="no-padding no-border no-margin">
        {showActionsButton && renderActionsButton()}
        {showSendInvoiceButton && renderSendInvoiceButton()}

        <Header as="h2" floated="left">
          {t('Summary')} {renderInvoiceStatus(selectedInvoice)}
          {renderFastpayStatusMaybe(organization, selectedInvoice)}
        </Header>
      </Segment>
    );
  }

  function renderInvoiceStatus(selectedInvoice) {
    return <InvoiceStatusComponent invoice={selectedInvoice} />;
  }

  function renderFastpayStatusMaybe(organization, selectedInvoice) {
    if (!isEnabled(organization)) return null;

    const { fastpay: fastpayGranted } = selectedInvoice;
    const tooltip = t(getFastpayTooltip(selectedInvoice));
    const color = getFastpayColor(selectedInvoice);

    if (!fastpayGranted) {
      return (
        <div>
          <FastpayStatus
            size="tiny"
            enabled={fastpayGranted}
            hasLabel={false}
            color={color}
          />
          <span className="fastpay-status-size">
            {t('Quick Pay Status')}
            <span className="fastpay-status-font-weight">
              {` ${tooltip}`}
              <a href="mailto:hello@wonderschool.com">
                {' Hello@wonderschool.com'}
              </a>
              .
            </span>
          </span>
        </div>
      );
    }

    return (
      <div>
        <FastpayStatus
          size="tiny"
          enabled={fastpayGranted}
          hasLabel={false}
          color={color}
        />
        <span className="fastpay-status-size">
          {t('Quick Pay Status')}
          <span className="fastpay-status-font-weight">{` ${tooltip}`}</span>
        </span>
      </div>
    );
  }

  function renderSendInvoiceButton() {
    return (
      <Button
        primary
        floated="right"
        loading={!!invoiceUpdateData}
        onClick={async () => {
          setInvoiceUpdateData({ status: InvoiceStatus.OPEN });
        }}
      >
        {t('Send Invoice Now')}
      </Button>
    );
  }

  function renderActionsButton() {
    return (
      <Button
        primary
        icon="ellipsis horizontal"
        floated="right"
        loading={!!invoiceUpdateData}
        content={t('Actions')}
        onClick={() => setShowActionsModal(true)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices,
    currentOrganization: state.organizations.currentOrganization,
  };
};

export default connect(mapStateToProps)(InvoiceDetailContainer);
