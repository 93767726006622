import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import React, { createContext, useMemo } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { Segment } from 'semantic-ui-react';
import { useAuthUser } from '../../../hooks/useUser';
import AllOrganizationList from '../../Organizations/AllOrganizationsList';

const HOST = process.env.REACT_APP_SEARCH_HOST;
const INDEX_NAME = 'organizations';
const PRIMARY_KEY = 'id';

export const OrganizationContext = createContext();

export default function OrganizationProvider({ children }) {
  const { token } = useAuthUser();

  const searchClient = useMemo(() => {
    let client = undefined;

    if (!!HOST && !!token) {
      // Use auth token to search server.
      client = instantMeiliSearch(HOST, token, {
        primaryKey: PRIMARY_KEY,
      });
    }

    return client;
  }, [token]);

  if (!token)
    return (
      <Segment basic textAlign="center">
        <p>Initializing search...</p>
      </Segment>
    );

  return (
    <OrganizationContext.Provider
      value={{
        searchClient,
      }}
    >
      {searchClient ? (
        <InstantSearch indexName={INDEX_NAME} searchClient={searchClient}>
          {children}
        </InstantSearch>
      ) : (
        <AllOrganizationList />
      )}
    </OrganizationContext.Provider>
  );
}
