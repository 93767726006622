import { isEmpty } from 'lodash';
import LocalConfig from './config.local.json';
import DevConfig from './config.dev.json';
import ProdConfig from './config.prod.json';

const LOCAL = 'local';
const DEV = 'dev';
const PROD = 'prod';

/**
 * @type {'local' | 'dev' | 'prod' | undefined} memoized env
 */
let currentEnv;
const getCurrentEnv = () => currentEnv;

/**
 * once provided, the env value will be cached for future use
 * @param {'local' | 'dev' | 'prod'} env
 * @returns {{
 *  _meta: Object,
 *  firebaseConfig: Object,
 *  wsConfig: Object
 * }} Firebase config json object
 * @throws {Error} if env param is invalid
 */
const getConfig = (env = getCurrentEnv()) => {
  if (!env) {
    throw new Error('env is required');
  }
  currentEnv = env; // memoize

  const result = {
    [LOCAL]: LocalConfig,
    [DEV]: DevConfig,
    [PROD]: ProdConfig,
  }[env];

  if (!result) {
    throw new Error('Invalid env and config provided');
  }
  return result;
};

const getFirebaseConfig = (env = getCurrentEnv()) => {
  return getConfig(env)?.firebaseConfig;
};
const getWSConfig = (env = getCurrentEnv()) => {
  return getConfig(env)?.wsConfig;
};

/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isLocal = (env = getCurrentEnv()) => env === LOCAL;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isDev = (env = getCurrentEnv()) => env === DEV;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isProd = (env = getCurrentEnv()) => env === PROD;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isTest = (env = getCurrentEnv()) =>
  (isLocal(env) && isEmpty(process.env.REACT_APP_GIT_SHA)) ||
  !!process.env.REACT_APP_IS_TEST;

/**
 * whether the emulation env variable has been set
 * @returns {boolean}
 */
const shouldEmulateFirebase = () => isLocal() || isTest();

export {
  LOCAL,
  DEV,
  PROD,
  getCurrentEnv,
  getConfig,
  getFirebaseConfig,
  getWSConfig,
  isLocal,
  isDev,
  isProd,
  isTest,
  shouldEmulateFirebase,
};
