import { useEffect, useCallback } from 'react';
import useLocalStorage from 'use-local-storage';

import { isLocal } from '../config/env';
import { trackFlags } from '../rollbar';

export const IS_FEATURE_FLAGS_UI_ENABLED = 'IS_FEATURE_FLAGS_UI_ENABLED';
export const IS_ROLLBAR_ENABLED = 'IS_ROLLBAR_ENABLED';
export const IS_MAIN_MENU_BILLING_PAGES_ENABLED = 'IS_MAIN_MENU_BILLING_PAGES_ENABLED';

const FlagDefaults = {
  [IS_FEATURE_FLAGS_UI_ENABLED]: isLocal(), // whether to show the feature flag control panel
  [IS_ROLLBAR_ENABLED]: true, // whether to use rollbar logging
  [IS_MAIN_MENU_BILLING_PAGES_ENABLED]: true, // wheather to show billing pages on main menu
};

const currentFlags = {
  ...FlagDefaults,
};

export const useFeatureFlag = (flagKey) => {
  const internalName = `ff-${flagKey}`;

  const [flag, setFlag] = useLocalStorage(internalName, currentFlags[flagKey]);
  const toggle = useCallback(() => {
    setFlag(!flag);
    trackFlags(currentFlags);
  }, [flag, setFlag]);

  useEffect(() => {
    currentFlags[flagKey] = flag;
  }, [flag, flagKey]);

  return {
    name: flagKey,
    enabled: !!flag || false,
    toggle,
  };
};

// useful for non-hooks - non-observable
export const isFeatureFlagsUIEnabled = () =>
  !!currentFlags[IS_FEATURE_FLAGS_UI_ENABLED];
export const isRollbarFlagEnabled = () => !!currentFlags[IS_ROLLBAR_ENABLED];
export const isMainMenuBillingPagesEnabled = () => !!currentFlags[IS_MAIN_MENU_BILLING_PAGES_ENABLED];

export const resetFlags = () =>
  Object.assign(currentFlags, { ...FlagDefaults });
