import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Header,
  Modal,
  Segment,
} from 'semantic-ui-react';

import { userHasPermission } from '../../../api/firebase/account';
import {
  CSV,
  DOC,
  DOCX,
  JPEG,
  PDF,
  PNG,
  TXT,
  XLS,
  XLSX,
} from '../../../helpers/mimeTypes';
import { formatFullName } from '../../../helpers/utils';
import { useOrganization } from '../../../hooks/useOrganizations';
import { useUser } from '../../../hooks/useUser';

import { useSelectedStudent } from '../../studentsHooks';
import { organizationUpdateStudent } from '../../studentsRedux';

import DocumentUploader from '../../../Components/Upload/DocumentUploader';
import { computeEnforceAutomaticPayment } from '../../studentsUtils';
import { StudentEnrollment, StudentEnrollmentForm } from '../enrollment';
import { FamilyEditContactModal, FamilyList } from '../family';
import StudentDocumentList from './StudentDocumentList';
import StudentInvoicesTable from './StudentInvoicesTable';
import StudentPersonalInformation from './StudentPersonalInformation';
import StudentPersonalInformationForm from './StudentPersonalInformationForm';

export default function StudentDetail() {
  const { t } = useTranslation();

  const [
    isStudentPersonalInformationFormOpen,
    setIsStudentPersonalInformationFormOpen,
  ] = useState(false);
  const [isStudentEnrollmentFormOpen, setIsStudentEnrollmentFormOpen] =
    useState(false);
  const [isAddFamilyOpen, setIsAddFamilyOpen] = useState(false);

  const user = useUser();
  const dispatch = useDispatch();
  const organization = useOrganization();

  const selectedStudent = useSelectedStudent();

  const allowedMimeTypes = {
    PDF,
    PNG,
    JPEG,
    DOC,
    DOCX,
    XLS,
    XLSX,
    CSV,
    TXT,
  };

  const allowedFileTypes = Object.values(allowedMimeTypes);
  const allowedFileTypeNames = Object.keys(allowedMimeTypes);

  const allowedFileTypeNamesStart = allowedFileTypeNames
    .slice(0, -1)
    .join(', ');
  const allowedFileTypeNamesEnd = allowedFileTypeNames.slice(-1);

  const maxFileSize = '30MB';

  const processUploadedDocuments = (uploads) => {
    if (!uploads?.length) return;

    const { uid, email } = user;

    const documentUploadPath = `organizations/${organization.id}/students/${selectedStudent.id}/documents`;

    let documents = {
      ...selectedStudent.documents,
    };

    uploads.forEach((upload) => {
      const name = upload.name ? upload.name.split('.')[0] : 'Unknown';

      documents[upload.meta.refId] = {
        name,
        documentId: upload.meta.refId,
        path: documentUploadPath,
        type: upload.type,
        size: upload.size,
        extension: upload.extension,
        progress: upload.progress,
        meta: upload.meta,
        downloadUrl: upload.downloadUrl,
        uploadedBy: { uid, email, name: formatFullName(user, true) },
        uploadedDate: Date.now(),
        tags: [],
      };
    });

    dispatch(
      organizationUpdateStudent(organization.id, {
        ...selectedStudent,
        documents,
      })
    );
  };

  const onEnforceAutomaticPaymentChange = (e, { checked }) => {
    if (!selectedStudent?.id || !organization?.id) return;

    if (!selectedStudent.automaticPayment) {
      selectedStudent.automaticPayment = {};
    }

    selectedStudent.automaticPayment.enforce = checked;
    selectedStudent.automaticPayment.modifiedDate = Date.now();

    dispatch(organizationUpdateStudent(organization.id, selectedStudent));
  };

  return (
    <Container fluid className="no-padding no-margin">
      <FamilyEditContactModal
        student={selectedStudent}
        isOpen={isAddFamilyOpen}
        onClose={() => setIsAddFamilyOpen(false)}
      />
      <Grid stackable columns="equal">
        <Grid.Column>
          <StudentPersonalInformationSegment />
        </Grid.Column>
        <Grid.Column>
          <StudentEnrollmentSegment />
        </Grid.Column>
      </Grid>

      <Grid stackable columns="equal">
        <Grid.Column>
          <Segment basic clearing>
            <Segment basic clearing className="no-padding">
              {userHasPermission('can_edit_student') && (
                <Button
                  primary
                  icon="add"
                  floated="right"
                  content={t('Add')}
                  className="round"
                  onClick={() => setIsAddFamilyOpen(true)}
                />
              )}

              <Header as="h2" content={t('Family & Emergency Contact')} />
            </Segment>

            <Segment className="no-shadow no-border top-border">
              <FamilyList />
            </Segment>
          </Segment>
        </Grid.Column>
      </Grid>

      <Grid stackable columns="equal">
        <Grid.Column>
          <Segment basic clearing>
            <Segment basic clearing className="no-padding">
              <Header as="h2" floated="left" content={t('All Invoices')} />
              <Form.Radio
                toggle
                id="enforceAutomaticPayment"
                name="enforceAutomaticPayment"
                label={
                  <label>
                    {t('studentDetailsEnforce')}{' '}
                    <strong>{t('studentDetailsAutomaticPayment')}</strong>
                  </label>
                }
                className="vertical-algin-sub text-right"
                onChange={onEnforceAutomaticPaymentChange}
                checked={computeEnforceAutomaticPayment(
                  selectedStudent,
                  organization
                )}
                control={Checkbox}
              />
            </Segment>

            <StudentInvoicesTable />
          </Segment>
        </Grid.Column>
      </Grid>

      <Grid stackable columns="equal">
        <Grid.Column>
          <Segment basic clearing>
            <Segment basic clearing className="no-padding">
              {userHasPermission('can_edit_student') && (
                <Segment basic floated="right" className="no-padding no-margin">
                  <DocumentUploader
                    uploadPath={`organizations/${organization.id}/students/${selectedStudent.id}/documents`}
                    title={t('Uploading Student Documents')}
                    allowedFileTypes={allowedFileTypes}
                    onUploaded={processUploadedDocuments}
                    note={t(
                      'Only {{allowedFileTypeNamesStart}}, or {{allowedFileTypeNamesEnd}} files up to {{maxFileSize}} are allowed.',
                      {
                        allowedFileTypeNamesStart,
                        allowedFileTypeNamesEnd,
                        maxFileSize,
                      }
                    )}
                  />
                </Segment>
              )}

              <Header as="h2" floated="left" content={t('Files')} />
            </Segment>

            <Segment className="no-shadow no-border top-border">
              <StudentDocumentList />
            </Segment>
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );

  function StudentPersonalInformationSegment() {
    return (
      <Segment basic clearing>
        <Segment basic clearing className="no-padding">
          {userHasPermission('can_edit_student') && (
            <Button
              primary
              floated="right"
              content={t('Edit')}
              size="tiny"
              className="round"
              onClick={(e) => {
                e.preventDefault();
                setIsStudentPersonalInformationFormOpen(true);
              }}
            />
          )}

          <Header as="h2" floated="left" content={t('Personal Info')} />
        </Segment>

        <Segment className="no-shadow no-border top-border">
          <StudentPersonalInformation />
        </Segment>

        <Modal
          closeIcon
          centered={false}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={isStudentPersonalInformationFormOpen}
          onClose={() => setIsStudentPersonalInformationFormOpen(false)}
          header={t('Personal Info')}
          content={
            <StudentPersonalInformationForm
              hideCancel
              clearOnUnmount={false}
              done={() => setIsStudentPersonalInformationFormOpen(false)}
            />
          }
        />
      </Segment>
    );
  }
  function StudentEnrollmentSegment() {
    return (
      <Segment basic clearing>
        <Segment basic clearing className="no-padding">
          {userHasPermission('can_edit_student') && (
            <Button
              primary
              floated="right"
              content={t('Edit')}
              size="tiny"
              className="round"
              onClick={(e) => {
                e.preventDefault();
                setIsStudentEnrollmentFormOpen(true);
              }}
            />
          )}

          <Header as="h2" floated="left" content={t('Enrollment')} />
        </Segment>

        <Segment className="no-shadow no-border top-border">
          <StudentEnrollment />
        </Segment>

        <Modal
          closeIcon
          centered={false}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={isStudentEnrollmentFormOpen}
          onClose={() => setIsStudentEnrollmentFormOpen(false)}
          header={t('Enrollment')}
          content={
            <StudentEnrollmentForm
              hideCancel
              clearOnUnmount={false}
              done={() => setIsStudentEnrollmentFormOpen(false)}
            />
          }
        />
      </Segment>
    );
  }
}
