import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Header, Segment, Icon } from 'semantic-ui-react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { useOrganizationInvoicesListener } from '../../../hooks/useOrganizations';
import { InvoiceStatus as InvoiceStatusComponent } from '../../../Components/Billing/Invoices';
import styles from '../students.module.scss';
import { LoadingIndicator } from '../../../Components/Shared/BusyIndicator';
import { dateFormatter } from '../../../helpers/dates';
import { currencyFormatter } from '../../../helpers/utils';
import { isEnabled, renderFastpayMaybe } from '../../../helpers/fastpay';
import { useOrganization } from '../../../hooks/useOrganizations';
import { useInvoices } from '../../../hooks/useInvoices';
import { useSelectedStudent } from '../../studentsHooks';

const LoadingIndicatorStyle = {
  marginTop: '28px',
};

function StudentInvoicesTable() {
  const { t } = useTranslation();
  const currentOrganization = useOrganization();
  const selectedStudent = useSelectedStudent();
  const invoices = useInvoices();

  useOrganizationInvoicesListener (currentOrganization.id);

  if (!invoices.isLoaded) return renderLoading();

  const filteredInvoices = invoices.all.list.filter(
    (invoice) => invoice.student.id === selectedStudent.id
  );

  return filteredInvoices.length
    ? renderTable({ t, currentOrganization, filteredInvoices })
    : renderNoData({ t });
}

const renderTable = ({ t, currentOrganization, filteredInvoices }) => {
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.preventDefault();
      // do nothing for now
    },
  };

  return (
    <ToolkitProvider
      bootstrap4
      keyField="id"
      data={filteredInvoices}
      columns={getTableColumns(t, currentOrganization)}
    >
      {(props) => (
        <div className="bootstrap-iso">
          <BootstrapTable
            classes="w-auto w-md-100"
            wrapperClasses="table-responsive"
            headerClasses={styles.invoicesListRow}
            bordered={false}
            rowEvents={rowEvents}
            {...props.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

const renderLoading = () => {
  return (
    <Segment
      basic
      clearing
      textAlign="center"
      className="no-shadow no-border top-border"
    >
      <LoadingIndicator style={LoadingIndicatorStyle} />
    </Segment>
  );
};

const renderNoData = ({ t }) => {
  return (
    <Segment
      basic
      clearing
      textAlign="center"
      className="no-shadow no-border top-border"
    >
      <Header as={'h3'}>
        <Icon circular inverted name="info" color="grey" />
      </Header>
      <Header as={'h4'}>{t('No invoices found')}</Header>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organizations.currentOrganization,
    invoices: state.invoices,
  };
};

const getTableColumns = (t, currentOrganization) => {
  const columns = [
    {
      dataField: 'invoiceItemList',
      text: t('Items'),
      formatter: (cell) => cell.map((c) => c.item).join(', '),
    },
    {
      dataField: 'isInvoice',
      text: '',
      width: 2,
      classes: 'text-center',
      formatter: (cell) => (!cell ? <Icon name="sync" /> : ''),
      headerStyle: () => ({ width: '5%' }),
    },
    {
      dataField: 'createdAt',
      text: t('Date Sent'),
      sort: true,
      hidden: true,
      formatter: (cell) => (cell ? dateFormatter(cell) : '-'),
    },
    {
      dataField: 'dateDue',
      text: t('Due Date'),
      sort: true,
      formatter: (cell, row) => {
        return row.isInvoice
          ? dateFormatter(row.dateDue)
          : dateFormatter(row.createdAt);
      },
    },
    {
      dataField: 'total',
      text: t('Amount'),
      sort: true,
      formatter: (cell) => currencyFormatter(cell, { precision: 2 }),
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'paidAmount',
      text: t('Balance Due'),
      sort: true,
      sortValue: (cell, row) => row.total - cell,
      formatter: (cell, row) => (
        <span>{currencyFormatter(row.total - cell, { precision: 2 })}</span>
      ),
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'status',
      text: t('Status'),
      sort: true,
      formatter: (cell, invoice) => (
        <InvoiceStatusComponent invoice={invoice} />
      ),
    },
  ];

  if (isEnabled(currentOrganization)) {
    columns.push({
      dataField: 'fastpay',
      text: t('Quickpay'),
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row) => renderFastpayMaybe(cell, row, t),
    });
  }

  return columns;
};

export default connect(mapStateToProps)(StudentInvoicesTable);
