import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'semantic-ui-react';

import {
  useInvoiceEditDetails,
  useInvoiceEditItems,
  useInvoiceEditStudents,
} from '../../../hooks/useInvoiceEdit';
import { calculateTotalFromInvoiceItems } from '../../../helpers/invoices';

// Import components

import { StudentsListWidget } from './Widgets';
import { InvoicePlanStatus, getUpdatedBillingDates } from '../../../helpers/invoicePlans';

import InvoicePlanDetailsContainer from './InvoiceDetail/InvoicePlanDetailsContainer';
import { StepsContainer } from './Steps';

export default function StepConfirmAndSave({ isInvoice = true, onSave }) {
  const { t } = useTranslation();

  const details = useInvoiceEditDetails();
  const invoiceItems = useInvoiceEditItems();
  const students = useInvoiceEditStudents();
  const [alreadyPaid, setAlreadyPaid] = useState(false);

  const dataToSave = useMemo(
    () => createDataToSave(isInvoice, details, invoiceItems, students),
    [isInvoice, details, invoiceItems, students]
  );

  return (
    <>
      <StepsContainer
        isInvoice={isInvoice}
        isEdit={!!details?.id}
        CurrentForm={ConfirmForm}
        Widgets={Widgets}
        onDone={onSaveLocal}
      />
    </>
  );

  function ConfirmForm() {
    return (
      <>
        {!isInvoice && (
          <InvoicePlanDetailsContainer
            showActionButtons={false}
            invoicePlan={dataToSave}
          />
        )}
      </>
    );
  }

  function Widgets() {
    return (
      <>
        {isInvoice && (
          <Checkbox
            onChange={onChangeAlreadyPaid}
            name={'alreadyPaid'}
            checked={alreadyPaid}
            label={t('Mark invoice as already paid')}
          />
        )}
        {
          <StudentsListWidget
            students={students}
            onRemove={onRemoveStudent}
            allowDelete={false}
          />
        }
      </>
    );
  }
  function onRemoveStudent(student){
    // TODO, will use in future feature
    //  setSelectedStudents (selectedStudents.filter (s => s.id !== student.id));
  }
  function onChangeAlreadyPaid(e, { checked }) {
    setAlreadyPaid(checked);
  }
  function onSaveLocal() {
    if (onSave) onSave(dataToSave, alreadyPaid);
  }
}

function createDataToSave(isInvoice, details, invoiceItems, students) {
  if (isInvoice) {
    return {};
  } else {
    return createInvoicePlan(details, invoiceItems, students);
  }
}
function createInvoicePlan(details, invoiceItems, students) {
  return {
    ...prepareInvoicePlanDetailsForSave(details, invoiceItems),
    invoiceItemList: invoiceItems,
    students,
  };
}
function prepareInvoicePlanDetailsForSave(details, invoiceItems) {
  const data = {
    ...details,
    ...getUpdatedBillingDates(details),
    isInvoice: false,
    status: InvoicePlanStatus.ACTIVE,
    total: calculateTotalFromInvoiceItems(invoiceItems),
  };
  return data;
}
