import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Image, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import helpers.
import { openSidebar } from '../../redux/actions/layoutActions';

// Import components.
import MainNavigation from './MainNavigation';
import UserProfileMenu from './UserProfileMenu';
import ChangeLanguage from '../Shared/ChangeLanguage';
import { FeatureFlagsLink } from '../../feature-flags';

// Import logo.
import wsLogo from '../../styles/img/wonderschool/logo-base.png';

import { routes } from '../../config/routes';

import WSA from '../../WSA';

const TopNavigation = ({
  viewport = 'desktop',
  location: { pathname },
  isSidebarOpen,
  openSidebar,
  handleShowMenu,
  history,
}) => {
  const { t } = useTranslation();
  const { isReady, isAuthenticated } = WSA.components.useWSAuth();

  // Remove/hide navbar on invoice pages.
  if (pathname && pathname.startsWith('/invoice/')) return null;

  // don't show if there's no user
  if (!isReady || !isAuthenticated) return null;

  const nav =
    viewport === 'mobile' ? (
      <Menu id="main-menu" stackable>
        <MainNavigation handleShowMenu={handleShowMenu} />

        <Menu.Item
          onClick={() => {
            handleShowMenu();
            history.push(routes.signout);
          }}
          content={t('Sign out')}
          style={{ cursor: 'pointer' }}
        />

        <Menu.Item>
          <FeatureFlagsLink mobile />
        </Menu.Item>

        <Menu.Item>
          <ChangeLanguage />
        </Menu.Item>

        <Menu.Item>
          <UserProfileMenu viewport="mobile" />
        </Menu.Item>
      </Menu>
    ) : (
      <Menu borderless className="top-nav">
        {!isSidebarOpen && (
          <Menu.Item>
            <Icon
              name="sidebar"
              color="grey"
              style={{ cursor: 'pointer' }}
              onClick={() => openSidebar(true)}
            />
            <Image as={Link} to="/" src={wsLogo} size="small" />
          </Menu.Item>
        )}

        <Menu.Menu position="right">
          <Menu.Item>
            <FeatureFlagsLink />
          </Menu.Item>

          <Menu.Item>
            <ChangeLanguage />
          </Menu.Item>

          <UserProfileMenu />
        </Menu.Menu>
      </Menu>
    );

  return nav;
};

const mapStateToProps = (state) => ({
  isSidebarOpen: state.layout.isSidebarOpen,
});

export default withRouter(
  connect(mapStateToProps, { openSidebar })(TopNavigation)
);
