import { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function DeleteFamilyMemberConfirmationDialog({
  isOpen,
  onYes,
  onClose
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState (false);

  if (!onYes || !onClose)
    throw new Error ('onYes and onClose are required');

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="tiny"
    >
      <Modal.Header>{t('Delete Family Member')}</Modal.Header>
      <Modal.Content>
        <p>{t('Are you sure you want to delete this record? This action is not reversible.')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={isLoading}
          negative
          onClick={onClose}
          content={t('No')}
          />
        <Button
          loading={isLoading}
          positive
          icon="checkmark"
          labelPosition="right"
          content={t('Yes')}
          onClick={onYesLocal}
        />
      </Modal.Actions>
    </Modal>
  );

  async function onYesLocal (e) {
    if (e) e.preventDefault();

    try {
      setIsLoading (true);
      await onYes();
    } catch (error) {
      console.log (error);
    } finally {
      setIsLoading (false);
    }
  }
}
