import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Grid, Input, Modal } from 'semantic-ui-react'

const RenameFileModal = ({
  isModalOpen = false,
  closeModal = () => { },
}) => {
  const [newFilename, setNewFilename] = useState('')
  const { t } = useTranslation()

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setNewFilename('')
        closeModal()
      }}
      closeIcon
    >
      <Modal.Header>
        {t('Rename File')}
      </Modal.Header>
      <Modal.Content>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <div className='margin-bottom'>
                <label htmlFor='newFilenameInput'>{t('New File Name')}</label>
              </div>
              <Input
                id='newFilenameInput'
                placeholder={t('Enter new file name')}
                value={newFilename}
                onChange={e => setNewFilename(e.currentTarget.value)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                basic
                color='blue'
              >
                {t('Cancel')}
              </Button>
              <Button
                primary
                disabled={!newFilename}
              >
                {t('Save Changes')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default RenameFileModal 
