import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Image, Menu, Sidebar, Icon } from 'semantic-ui-react';

// Import helpers.
import { openSidebar } from '../../redux/actions/layoutActions';

// Import Logo
import wsLogo from '../../styles/img/wonderschool/logo-base.png';

// Import components.
import MainNavigation from './MainNavigation';

import WSA from '../../WSA';

const DesktopNavigation = ({
  viewport = 'desktop',
  location: { pathname },
  animation,
  direction,
  visible,
  openSidebar,
  staticContext,
  ...rest
}) => {
  const { isReady, isAuthenticated } = WSA.components.useWSAuth();
  // Remove/hide navbar on invoice pages.
  if (
    (pathname && pathname.startsWith('/invoice/')) ||
    !(isReady && isAuthenticated)
  )
    return null;

  return (
    <Sidebar
      id="main-menu"
      as={Menu}
      vertical
      size="large"
      animation="uncover"
      direction="left"
      visible={visible}
      borderless
      {...rest}
    >
      <Menu.Item style={{ paddingTop: '1.2rem', paddingBottom: '1.2rem' }}>
        <Icon
          name="sidebar"
          color="grey"
          style={{ paddingTop: '0.3rem', cursor: 'pointer' }}
          onClick={() => openSidebar(false)}
        />
        <Image as={Link} to="/" src={wsLogo} style={{ width: '65%' }} />
      </Menu.Item>

      <MainNavigation />
    </Sidebar>
  );
};

const mapStateToProps = (state) => ({
  //
});

export default withRouter(
  connect(mapStateToProps, { openSidebar })(DesktopNavigation)
);
