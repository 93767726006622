import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import SectionCard from '../Shared/SectionCard';
import withPermission from '../Shared/withPermission';
import ActivityFeed from '../Stats/ActivityFeed';

const TodaysActivityFeed = () => {
  const { t } = useTranslation();
  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <SectionCard
          header={t("Today's Activity Feed")}
          component={ActivityFeed}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default withPermission(TodaysActivityFeed, 'can_view_activity_feed');
