import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { InvoiceItem } from '../../../../Shared/InvoiceItem';
import { formatCurrency } from '../../../../../helpers/utils';

import './DetailsWidget.scss';

const DetailsWidget = ({ invoiceSummary }) => {
  const { t } = useTranslation();

  return (
    <Card fluid className={'details-widget'}>
      <Card.Content className={'details-widget-header'}>
        <Card.Header textAlign="center">{t('Invoice Details')}</Card.Header>
      </Card.Content>
      <Card.Content>
        <List>
          {invoiceSummary &&
            invoiceSummary.items.map((item, index) => (
              <InvoiceItem key={`invoiceItemSummary${index}`} item={item} />
            ))}
          <Divider />
          <List.Item>
            <List.Content>
              <List.Header>
                <div>{t('Total')}</div>
                <div>
                  {invoiceSummary && formatCurrency(invoiceSummary.total)}
                </div>
              </List.Header>
            </List.Content>
          </List.Item>
        </List>
      </Card.Content>
    </Card>
  );
};

DetailsWidget.propTypes = {
  invoiceSummary: PropTypes.object,
};

export default DetailsWidget;
