import React, { useState, useEffect } from 'react';
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Input,
  Label,
} from 'semantic-ui-react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { routes } from '../../../config/routes';
import { currencyFormatter } from '../../../helpers/utils';
import { dateFormatter } from '../../../helpers/dates';
import { useInvoicePlans } from '../../../hooks/useInvoicePlans';
import { invoicePlanStatusFormatter } from '../../../helpers/invoicePlans';
import {
  invoicePlanNew,
  invoiceEditSelected,
  invoiceEditSelectionCleared,
} from '../../../redux/actions/billingActions';

// Import components
import withSlidingPanel from '../../Shared/withSlidingPanel';
import NoDataMessage from '../../Shared/NoDataMessage';
import SectionCard from '../../Shared/SectionCard';
import { LoadingIndicator } from '../../Shared/BusyIndicator';

import { InvoicePlanDetailsContainer } from './InvoiceDetail';

import './Billing.scss';
import { InvoicePlanStatus } from '../../../helpers/invoicePlans';
import { BILLING_INTERVAL_OPTIONS } from './dropdownOptions';
import { filterInvoicePlans, isCurrentlyFiltered } from './InvoicePlansFilter';
import { DateRangePicker } from '../../Shared/DatePicker';

const SLIDER_WIDTH = '80%';

const SlidingDetail = withSlidingPanel(InvoicePlanDetailsContainer, {
  width: SLIDER_WIDTH,
});

const PageFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const {
    invoicePlanStatus,
    billingInterval,
    startDateRange,
    nextInvoiceDateRange,
    dueDateRange,
  } = filters;

  const statusOptions = [
    {
      key: InvoicePlanStatus.ACTIVE,
      value: InvoicePlanStatus.ACTIVE,
      text: t('Active'),
    },
    {
      key: InvoicePlanStatus.PAUSED,
      value: InvoicePlanStatus.PAUSED,
      text: t('Paused'),
    },
    {
      key: InvoicePlanStatus.ENDED,
      value: InvoicePlanStatus.ENDED,
      text: t('Ended'),
    },
    {
      key: InvoicePlanStatus.SCHEDULED,
      value: InvoicePlanStatus.SCHEDULED,
      text: t('Scheduled'),
    },
  ];

  const onChange =
    (updateField) =>
    (_, { value }) => {
      setFilters({ ...filters, [updateField]: value });
    };

  const onInputChange = (updateField) => (e) => {
    setFilters({ ...filters, [updateField]: e.currentTarget.value });
  };

  return (
    <>
      <div className="invoice-filter-row">
        <Dropdown
          placeholder={t('All Statuses')}
          selection
          clearable
          value={invoicePlanStatus}
          onChange={onChange('invoicePlanStatus')}
          options={statusOptions}
        />
        <Input
          placeholder={t('Search Description')}
          onChange={onInputChange('descriptionSearch')}
        />
        <DateRangePicker
          placeholder={t('All Due Dates')}
          value={dueDateRange}
          clearable
          allowSameEndDate
          onChange={onChange('dueDateRange')}
          className="date-range-picker"
          closeOnMouseLeave={false}
        />
        <Input
          placeholder={t('Search Amount')}
          onChange={onInputChange('amountSearch')}
          className="filter-input"
        />
        <Input
          placeholder={t('Search Student/Parent')}
          icon="search"
          iconPosition="left"
          onChange={onInputChange('studentSearch')}
        />
        <Dropdown
          placeholder={t('Billing Frequency')}
          selection
          clearable
          value={billingInterval}
          onChange={onChange('billingInterval')}
          options={BILLING_INTERVAL_OPTIONS}
        />
        <DateRangePicker
          placeholder={t('All Start Dates')}
          value={startDateRange}
          clearable
          allowSameEndDate
          onChange={onChange('startDateRange')}
          className="date-range-picker"
          closeOnMouseLeave={false}
        />
        <DateRangePicker
          placeholder={t('All Next Invoice Dates')}
          value={nextInvoiceDateRange}
          clearable
          allowSameEndDate
          onChange={onChange('nextInvoiceDateRange')}
          className="date-range-picker"
          closeOnMouseLeave={false}
        />
      </div>
    </>
  );
};

export default function InvoicePlansList() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [filters, setFilters] = React.useState({
    invoicePlanStatus: '',
    billingInterval: '',
    descriptionSearch: '',
    amountSearch: '',
    startDateRange: '',
    nextInvoiceDateRange: '',
    dueDateRange: '',
    studentSearch: '',
  });

  const [selectedInvoicePlan, setSelectedInvoicePlan] = useState(null);
  const organization = useSelector(
    (state) => state.organizations.currentOrganization
  );
  const { invoicePlans, loading } = useInvoicePlans(organization.id);
  const [editInvoicePlan, setEditInvoicePlan] = useState(false);
  const isEmpty =
    !loading &&
    !!invoicePlans &&
    !invoicePlans.count &&
    !isCurrentlyFiltered(filters);

  // we need to clear edit plan state if we land on this page
  if (!editInvoicePlan) dispatch(invoiceEditSelectionCleared());

  useEffect(() => {
    if (editInvoicePlan) {
      history.push(routes.billing.plans.edit);
    }
  }, [editInvoicePlan, history]);
  return isEmpty ? renderNoDataMessage() : renderContent();

  function renderContent() {
    return (
      <>
        <Header as="h4" floated="right">
          <AddButton />
        </Header>
        <Grid container>
          <PageFilters filters={filters} setFilters={setFilters} />
        </Grid>
        <SectionCard header={t('Recurring Plans')}>
          {loading && <LoadingIndicator />}
          {!loading && renderPlansList()}
        </SectionCard>
      </>
    );
  }

  function renderPlansList() {
    if (!invoicePlans) return null;

    return (
      <>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={filterInvoicePlans(invoicePlans.list, filters)}
          columns={getTableColumns(t)}
        >
          {(props) => (
            <div className="bootstrap-iso">
              <BootstrapTable
                classes="w-auto w-md-100"
                wrapperClasses="table-responsive"
                headerClasses="billing-table-header"
                bordered={false}
                hover={true}
                rowClasses="billing-table-row"
                rowEvents={{ onClick }}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
        {renderInvoiceDetail()}
      </>
    );
  }
  function renderInvoiceDetail() {
    const displayName = getStudentNames(selectedInvoicePlan?.students);
    const invoiceId = selectedInvoicePlan?.id;

    return (
      <SlidingDetail
        title={t('Recurring Plan for {{displayName}}', { displayName })}
        subTitle={t('Recurring Plan # {{invoiceId}}', { invoiceId })}
        isOpen={!editInvoicePlan && !!selectedInvoicePlan}
        onClose={() => setSelectedInvoicePlan(null)}
        invoicePlan={selectedInvoicePlan}
      />
    );
  }
  function renderNoDataMessage() {
    return (
      <NoDataMessage
        message={t(
          'Hey, hey! You are new here. Go ahead and create a recurring invoice...'
        )}
        CallToActionComponent={AddButton}
      />
    );
  }

  function AddButton({ options = {} }) {
    return (
      <Button
        primary
        as={Link}
        to={routes.billing.plans.edit}
        size="large"
        onClick={(e) => {
          e.preventDefault();
          dispatch(invoicePlanNew());
          history.push(routes.billing.plans.edit);
        }}
        {...options}
      >
        {t('Create New Recurring Plan')}
      </Button>
    );
  }
  function onClick(e, row, rowIndex) {
    e.preventDefault();
    setSelectedInvoicePlan(row);
  }

  function getStudentNames(students) {
    return (
      (students && students.map((s) => s?.displayName).join(', ')) ||
      'No students'
    );
  }
  function getTableColumns(t) {
    return [
      {
        dataField: 'student.displayName',
        text: t('Students'),
        sort: true,
        formatter: (cell, row) => {
          const { students } = row;
          return <>{getStudentNames(students)}</>;
        },
      },
      {
        dataField: 'billingInterval',
        text: t('Billing Interal'),
        sort: true,
      },
      {
        dataField: 'invoiceItemList',
        text: t('Description'),
        formatter: (cell) => cell.map((c) => c.item).join(', '),
      },
      {
        dataField: 'total',
        text: t('Amount'),
        formatter: (cell) => currencyFormatter(cell, { precision: 2 }),
        sort: true,
      },
      {
        dataField: 'dateStart',
        text: t('Start Date'),
        formatter: (cell) => (cell ? dateFormatter(cell) : t('Not Started')),
        sort: true,
      },
      {
        dataField: 'dateSend',
        text: t('Next Invoice Date'),
        formatter: (cell) =>
          cell ? dateFormatter(cell) : t('No Invoice Date'),
        sort: true,
      },
      {
        dataField: 'dateDue',
        text: t('Next Due Date'),
        formatter: (cell) => (cell ? dateFormatter(cell) : t('Not Due Yet')),
        sort: true,
      },
      {
        dataField: 'status',
        text: t('Status'),
        sort: true,
        formatter: (cell, row) => {
          const { text, color } = invoicePlanStatusFormatter(row);

          if (!text || !color) return null;

          return <Label color={color} content={t(text)} />;
        },
      },
      {
        dataField: 'actions',
        text: '',
        isDummyField: true,
        csvExport: false,
        formatter: (cell, row) => (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              dispatch(invoiceEditSelected(row));
              setEditInvoicePlan(true);
            }}
            className={'invoice-edit-link'}
          >
            {t('Edit')}
          </Link>
        ),
        headerStyle: { width: '100px' },
      },
    ];
  }
}
