import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Button,
  Header,
  Dropdown,
  Input,
  Grid,
  Popup,
} from 'semantic-ui-react';

// Import actions
import { invoiceSelected } from '../../../redux/actions/invoiceActions';

// Import components
import { InvoiceDetailContainer } from '../../Forms/Billing/InvoiceDetail';
import withSlidingPanel from '../../Shared/withSlidingPanel';
import NoDataMessage from '../../Shared/NoDataMessage';
import { LoadingIndicator } from '../../Shared/BusyIndicator';
import SectionCard from '../../Shared/SectionCard';

import { currencyFormatter } from '../../../helpers/utils';
import { dateFormatter } from '../../../helpers/dates';
import { isEnabled, renderFastpayMaybe } from '../../../helpers/fastpay';
import { resendInvoiceToStripe } from '../../../api/firebase/invoices';
import { InvoiceStatus as InvoiceStatusComponent } from './index';

// Import styles
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import styles from './InvoicesList.module.scss';
import './InvoiceList.scss';
import { routes } from '../../../config/routes';
import AlertMessage from '../../Shared/AppAlert';
import { isLocal } from '../../../config/env';
import { DateRangePicker } from '../../Shared/DatePicker';
import { useInvoices } from '../../../hooks/useInvoices';
import { InvoiceStatus } from '../../../helpers/invoices';

export const INVOICE_TYPE = 'all';
const SLIDER_WIDTH = '80%';

const SlidingInvoiceDetail = withSlidingPanel(InvoiceDetailContainer, {
  width: SLIDER_WIDTH,
});

const PageFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const { invoiceStatus, dueDateRange } = filters;

  const statusOptions = [
    { key: InvoiceStatus.DRAFT, value: InvoiceStatus.DRAFT, text: t('New') },
    {
      key: InvoiceStatus.OPEN,
      value: InvoiceStatus.OPEN,
      text: t('Invoice Sent'),
    },
    { key: InvoiceStatus.PAID, value: InvoiceStatus.PAID, text: t('Paid') },
    {
      key: `${InvoiceStatus.PAID}(O)`,
      value: `${InvoiceStatus.PAID}(O)`,
      text: t('Paid(O)'),
    },
    {
      key: InvoiceStatus.PROCESSING,
      value: InvoiceStatus.PROCESSING,
      text: t('Processing'),
    },
    {
      key: InvoiceStatus.FAILED,
      value: InvoiceStatus.FAILED,
      text: t('Failed'),
    },
    {
      key: InvoiceStatus.UNCOLLECTIBLE,
      value: InvoiceStatus.UNCOLLECTIBLE,
      text: t('Uncollectible'),
    },
    { key: InvoiceStatus.VOID, value: InvoiceStatus.VOID, text: t('Void') },
  ];

  const onChange =
    (updateField) =>
    (_, { value }) => {
      setFilters({ ...filters, [updateField]: value });
    };

  const onInputChange = (updateField) => (e) => {
    setFilters({ ...filters, [updateField]: e.currentTarget.value });
  };

  return (
    <div className="invoice-filter-row">
      <Dropdown
        placeholder={t('All Statuses')}
        selection
        clearable
        value={invoiceStatus}
        onChange={onChange('invoiceStatus')}
        options={statusOptions}
      />
      <Input
        placeholder={t('Search Description')}
        onChange={onInputChange('descriptionSearch')}
      />
      <DateRangePicker
        placeholder={t('All Due Dates')}
        value={dueDateRange}
        clearable
        allowSameEndDate
        onChange={onChange('dueDateRange')}
        className="date-range-picker"
        closeOnMouseLeave={false}
      />
      <Input
        placeholder={t('Search Amount')}
        onChange={onInputChange('amountSearch')}
        className="filter-input"
      />
      <Input
        placeholder={t('Search Balances Due')}
        onChange={onInputChange('balanceDueSearch')}
        className="filter-input"
      />
      <Input
        placeholder={t('Search Student/Parent')}
        icon="search"
        iconPosition="left"
        onChange={onInputChange('studentSearch')}
      />
    </div>
  );
};

function InvoicesList() {
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [filters, setFilters] = React.useState({
    invoiceStatus: '',
    dueDateRange: '',
    descriptionSearch: '',
    amountSearch: '',
    balanceDueSearch: '',
    studentSearch: '',
  });

  const invoices = useInvoices(filters);
  const currentOrganization = useSelector(
    (state) => state.organizations?.currentOrganization
  );
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const invoiceType = invoices.invoiceType || INVOICE_TYPE;
  const invoicesList = invoices[invoiceType];

  const isEmpty = !!invoices?.isLoaded && invoicesList && !invoicesList.count;

  return isEmpty ? renderNoDataMessage() : renderContent();

  function renderContent() {
    return (
      <>
        <Header as="h4" floated="right">
          <AddButton floated="right" />
        </Header>
        <Grid container>
          <PageFilters filters={filters} setFilters={setFilters} />
        </Grid>
        <SectionCard header={t('All Invoices')}>
          {!invoices.isLoaded && <LoadingIndicator />}
          {invoices.isLoaded && renderInvoicesList()}
        </SectionCard>
      </>
    );
  }

  function renderInvoicesList() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        e.preventDefault();
        dispatch(invoiceSelected(row, invoiceType));
      },
    };

    return (
      <>
        <AlertMessage alert={alertMessage} setAlert={setAlertMessage} />
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={invoicesList.list}
          columns={getTableColumns(t, currentOrganization, setAlertMessage)}
        >
          {(props) => (
            <div className="bootstrap-iso">
              <BootstrapTable
                headerClasses={'table-header'}
                classes="w-auto w-md-100"
                wrapperClasses="table-responsive"
                bordered={false}
                hover
                rowClasses={styles.bstRow}
                rowEvents={rowEvents}
                defaultSorted={[
                  {
                    dataField: 'dateDue',
                    order: 'desc',
                  },
                ]}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
        {renderInvoiceDetail()}
      </>
    );
  }

  function renderInvoiceDetail() {
    const selectedInvoice = invoicesList.selected;
    const displayName = selectedInvoice?.student?.displayName || '';
    const invoiceId = selectedInvoice?.id || '';

    return (
      <SlidingInvoiceDetail
        title={t('Invoice for {{displayName}}', { displayName })}
        subTitle={t('Invoice # {{invoiceId}}', { invoiceId })}
        invoiceType={invoiceType}
        isOpen={!!selectedInvoice}
        onClose={(id) => {
          if (id) refreshInvoice(currentOrganization, id);
          dispatch(invoiceSelected(null, invoiceType));
        }}
      />
    );
  }
  function refreshInvoice(currentOrganization, invoiceId) {
    //    refreshOrganizationInvoice(currentOrganization.id, invoiceId, INVOICE_TYPE);
  }
  function renderNoDataMessage() {
    return (
      <NoDataMessage
        message={t(
          'Hey, hey! You are new here. Go ahead and create a one-off invoice...'
        )}
        CallToActionComponent={() => <AddButton />}
      />
    );
  }
  function AddButton({ options = {} }) {
    return (
      <Button
        primary
        as={Link}
        to={routes.billing.invoices.edit}
        size="large"
        {...options}
      >
        {t('Create New Invoice')}
      </Button>
    );
  }
}

function getTableColumns(t, currentOrganization, setAlertMessage) {
  const columns = [
    {
      dataField: 'student.displayName',
      text: t('Students'),
      sort: true,
      formatter: (cell, row) => {
        return <>{cell}</>;
      },
    },
    {
      dataField: 'invoiceItemList',
      text: t('Description'),
      formatter: (cell) => cell.map((c) => c.item).join(', '),
    },
    {
      dataField: 'isInvoice',
      text: '',
      width: 2,
      classes: 'text-center',
      formatter: (cell) =>
        !cell ? (
          <Popup
            trigger={<Icon name="sync" />}
            content={t('Recurring plan')}
            offset={[-15, 0]}
          />
        ) : (
          ''
        ),
      headerStyle: () => ({ width: '5%' }),
    },
    {
      dataField: 'createdAt',
      text: t('Date Sent'),
      sort: true,
      hidden: true,
      formatter: (cell) => (cell ? dateFormatter(cell) : '-'),
    },
    {
      dataField: 'dateDue',
      text: t('Due Date'),
      sort: true,
      formatter: (cell, row) => dateFormatter(row.dateDue),
    },
    {
      dataField: 'total',
      text: t('Amount'),
      sort: true,
      formatter: (cell) => currencyFormatter(cell, { precision: 2 }),
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'paidAmount',
      text: t('Balance Due'),
      sort: true,
      sortValue: (cell, row) => row.total - cell,
      formatter: (cell, row) => {
        const amountDue = cell ?? 0;
        return (
          <span>
            {currencyFormatter(row.total - amountDue, { precision: 2 })}
          </span>
        );
      },
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'status',
      text: t('Status'),
      sort: true,
      formatter: (cell, invoice) => (
        <InvoiceStatusComponent invoice={invoice} withTooltip />
      ),
    },
  ];

  if (isEnabled(currentOrganization)) {
    columns.push({
      dataField: 'fastpay',
      text: t('Quickpay'),
      sort: false,
      classes: 'text-center align-middle',
      formatter: (cell, row) => renderFastpayMaybe(cell, row, t),
    });
  }

  if (isLocal()) {
    columns.push({
      isDummyField: true,
      dataField: '',
      text: '',
      classes: 'text-center align-middle',
      formatter: (cell, row) => {
        return (
          <Button
            basic
            primary
            size="mini"
            content={t('Resend')}
            onClick={(event) => onResendClick(event, row, setAlertMessage)}
          />
        );
      },
    });
  }
  return columns;
}

async function onResendClick(event, invoice, setAlertMessage) {
  try {
    event.stopPropagation();
    await resendInvoiceToStripe(invoice);
    setAlertMessage({
      type: 'success',
      message: 'Invoice successfully resent',
    });
  } catch (error) {
    console.error(error);
    setAlertMessage({
      type: 'error',
      message: 'Something went wrong, please try again',
    });
  }
}

export default InvoicesList;
