import { SLIDER_TOGGLED, SIDEBAR_TOGGLED } from './types';

export const toggleSlider = sliderOpen => ({
  type: SLIDER_TOGGLED,
  sliderOpen,
});

export const sidebarOpen = (isSidebarOpen) => ({
  type: SIDEBAR_TOGGLED,
  isSidebarOpen,
});

export const openSlider = status => dispatch =>
  dispatch(toggleSlider(status));

export const openSidebar = (isSidebarOpen) => (dispatch) =>
  dispatch(sidebarOpen(isSidebarOpen));