import { Component } from 'react';
import { connect } from 'react-redux';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import { Button, Modal } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// Import API.
import { storage } from '../../api/firebase';

// Import helpers.
import FirebaseCloudStorage from './FirebaseCloudStorage';
import { getUppyLocale } from '../../hooks/useUppy';
import UppyCSV from './UppyCSV';

class DocumentUploader extends Component {
  constructor(props) {
    super();
    this.state = {
      modalOpen: false,
      uppyLocale: undefined,
    };
  }

  componentDidMount() {
    this.initUppy(this.props);
  }

  componentDidUpdate() {
    const { i18n } = this.props;
    const { uppyLocale } = this.state;

    if (i18n) {
      const newLocale = getUppyLocale(i18n);
      if (newLocale !== uppyLocale) {
        this.initUppy(this.props);
      }
    }
  }

  initUppy = (props) => {
    const { i18n } = props;
    const uppyLocale = getUppyLocale(i18n);
    this.setState({ uppyLocale });

    this.uppy = new Uppy({
      id: 'uppy1',
      locale: uppyLocale,
      autoProceed: props.autoProceed || false,
      restrictions: {
        maxFileSize: props.maxFileSize || 30000000,
        allowedFileTypes: props.allowedFileTypes || '',
        minNumberOfFiles: 1,
        maxNumberOfFiles: props.maxNumberOfFiles || null,
      },
    })
      // When individual files are added
      .on('file-added', (files) => this.props.onFileSelected(files) ?? {}) 
      // When individual files are added
      .on('upload', (data) => {
        if (this.props.onSubmit) {
          this.handleClose()
          return this.props.onSubmit(data)
        }

        return {}
      })
      // When individual files are uploaded to remote storage.
      .on('upload-success', (file, body, uploadURL) => {}) 
      // When there an error response received from the API.
      .on('error', (error) => this.props.onError(error) ?? {})
      .on('complete', (result) => {
        const { successful } = result;
        if (props.onUploaded && successful) props.onUploaded(successful);

        this.handleClose();
        this.clearFiles();
      });
    
    if (!props.shouldUseExternalStorageApi) {
      const storageRef = storage().ref().child(props.uploadPath);

      this.uppy.use(FirebaseCloudStorage, {
        storageRef,
        refId: props.uniqueId || '',
        rename: true,
      })
    }

    // If CSV template is provided,
    // add UppyCSV plugin to Uppy instance.
    if (props.csvTemplate) {
      this.uppy.use(UppyCSV, {
        translate: this.props.i18n.t,
        csvTemplate: props.csvTemplate,
      });
    }
  };

  clearFiles = () => {
    if (this.uppy) {
      const currentFiles = this.uppy.getFiles();
      currentFiles.forEach((currentFile) =>
        this.uppy.removeFile(currentFile.id)
      );
    }
  };

  handleOpen = (e) => {
    if (e) e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <Modal
          trigger={
            <Button
              primary
              icon="cloud upload"
              content={t('Upload')}
              className="round"
              onClick={this.handleOpen}
            />
          }
          closeIcon
          open={this.state.modalOpen}
          size="small"
          header={this.props.title || t('Uploader')}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={() => {
            if (this.uppy) {
              this.uppy.reset();
            }
            this.handleClose();
          }}
          content={
            this.uppy && (
              <Dashboard
                uppy={this.uppy}
                hideProgressAfterFinish
                width={1000}
                proudlyDisplayPoweredByUppy={false}
                closeModalOnClickOutside
                open={this.state.modalOpen}
                onRequestClose={this.handleClose}
                note={this.props.note || ''}
              />
            )
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
  selectedStudent: state.students.selectedStudent,
});

export default withTranslation()(connect(mapStateToProps)(DocumentUploader));
