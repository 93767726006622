import React from 'react';
import { Segment, Image, Header } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

// Import image
import noMessageImage from '../../styles/img/mirage/mirage-list-is-empty.png';

const NoDataMessage = ({ image, message, sub, CallToActionComponent }) => {
  const { t } = useTranslation()
  
  return (
    <Segment basic textAlign="center">
      <Image
        src={!isEmpty(image) ? image : noMessageImage}
        centered
        size="small"
      />

      <Header as="h2">
        {!isEmpty(message) ? message : t('Hey, hey! You are new here...')}
      </Header>

      {!isEmpty(sub) && (
        <Header as="h3" color="grey" style={{ marginTop: 0 }}>
          {sub}
        </Header>
      )}

      {CallToActionComponent && (
        <>
          <br />
          <br />
          <CallToActionComponent />
        </>
      )}
    </Segment>
  )
};

export default NoDataMessage;
