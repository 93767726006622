import React from 'react';
import { Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function TransactionsStatsBar({count, totalCount}) {
  const { t } = useTranslation();

  if (!totalCount || count === totalCount) return null;

  return <Container style={{textAlign:'left', width:'100%', paddingBottom:'2px'}}>
    {t('Showing') + ' '}<strong>{count}</strong> {t('of')} <strong>{totalCount}</strong> {t('transactions')}
  </Container>
}