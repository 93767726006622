import React from 'react';
import { Card } from 'semantic-ui-react';

const SectionCard = ({
  header,
  noPadding,
  component: PassedComponent,
  extra = false,
  children,
  ...rest
}) => (
  <Card fluid className="no-padding" {...rest}>
    {header ? (
      <Card.Content>
        <Card.Header as="h3">{header}</Card.Header>
      </Card.Content>
    ) : (
      ''
    )}

    <Card.Content className={noPadding ? 'no-padding' : ''}>
      {PassedComponent ? <PassedComponent /> : children}
    </Card.Content>

    {extra && <Card.Content extra>{extra}</Card.Content>}
  </Card>
);

export default SectionCard;
