import { useTranslation } from 'react-i18next';
import { Grid, Image, Header, List } from 'semantic-ui-react';

import { useUser } from '../../hooks/useUser';


const AppStoreLinksContainer = ({ organizationName }) => {
  const { t } = useTranslation()
  const { isParent } = useUser()

  if (!isParent) {
    return null
  }

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={5}>
          <Image
              style={{ width: 'auto' }}
              src='/images/wonderschool-app.png'
              srcSet='/images/wonderschool-app@2x.png 2x'
          />
        </Grid.Column>
        <Grid.Column width={11}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as='h3'>{t('Make sure you download Wonderschool mobile app.')}</Header>
              <div>{t('{{organizationName}} is partnering with Wonderschool to bring an exceptional experience! By downloading the app you are able to see.', { organizationName })}</div>
              <List as='ul'>
                <List.Item as='li'>
                  <strong>{t('Real-time updates')}</strong> - {t('never miss a beat. Providers can keep parents posted on activities and share photos of their child’s day.')}
                </List.Item>
                <List.Item as='li'>
                  <strong>{t('Connect your bank account or credit card')}</strong> - {t('never miss a payment. You can set up automatic payment on the app by connecting your bank account or credit card.')}
                </List.Item>
                <List.Item as='li'>
                  <strong>{t('Mobile check in / check out')}</strong> - {t('enjoy the touchless Check in / Check out process using the QR code. Ready for parents on the go. Parents can securely check their child in and out with just a few taps.')}
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column style={{ marginTop: 20 }}>
              <Image.Group>
                <a 
                  href='https://apps.apple.com/us/app/wonderschool-2-0/id1560074981'
                  target='_blank'
                  rel="noreferrer"
                >
                  <Image
                    style={{ height: '45px', width: 'auto' }}
                    src='/images/download-apple-store-badge.svg' />
                </a>
                <a 
                  href='https://play.google.com/store/apps/details?id=com.moxitinc.wonderschool'
                  target='_blank'
                  rel="noreferrer"
                >
                  <Image
                    style={{ height: '45px', width: 'auto' }}
                    src='/images/download-google-play-badge2.png' />
                </a>
              </Image.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default AppStoreLinksContainer;
