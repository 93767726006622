import { firestore } from '.';

export const getPermission = (organizationId, permission) =>
  firestore()
    .doc(`organizations/${organizationId}/_permissions/${permission}`)
    .get()
    .then(doc => {
      if (doc.exists && doc.data().enabled)
        return { id: doc.id, ...doc.data() };
      return null;
    });

export const organizationPermissionsOnListen = (
  organizationId,
  next,
  error,
  complete
) =>
  firestore()
    .collection(`organizations/${organizationId}/_permissions`)
    .orderBy('name', 'asc')
    .where('enabled', '==', true)
    .onSnapshot(
      querySnapshot =>
        next(querySnapshot ? buildPermissionObjects(querySnapshot.docs) : {}),
      error,
      complete
    );

const buildPermissionObjects = docs => {
  const permissions = {};
  docs.forEach(
    doc =>
      (permissions[doc.id] = {
        ...doc.data(),
        roles:
          Object.keys(doc.data().roles).filter(
            role => doc.data().roles[role] === true
          ) || [],
      })
  );
  return permissions;
};
