import _ from 'lodash';
import {
  STAFF_FETCHED,
  STAFF_SELECTED,
  STAFF_SELECTION_CLEARED,
  STAFF_UPDATED,
} from '../actions/types';

const initialState = {
  selectedStaff: {},
  count: 0,
  list: [],
};

const staff = (state = initialState, action) => {
  switch (action.type) {

    case STAFF_FETCHED:
      return {
        ...state,
        count: action.staff.count,
        list: action.staff.list,
      };

    case STAFF_SELECTED:
      return { ...state, selectedStaff: action.staff };

    // Replace selected with the newly updated data.
    // New data is fetched by the listener as soon as listener receives the update.
    case STAFF_UPDATED: {
      return {
        ...state,
        selectedStaff: _.find(state.list, { uid: state.selectedStaff.uid }),
      };
    }

    case STAFF_SELECTION_CLEARED:
      return { ...state, selectedStaff: {} };

    default:
      return state;
  }
};

export default staff;
