import { useTranslation } from 'react-i18next';

import { Label } from 'semantic-ui-react';

export default function UserStatusLabel ({user}) {
  const { t } = useTranslation();

  if (user.invited) {
    return <Label color="yellow" content={t('Invited')} />

  } else if (user.enabled) {
    return <Label color="green" content={t('Enabled')} />

  } else {
    return <Label color="red" content={t('Disabled')} />
  }
}