/*****************************/
/* REDUCED from SSO-ACCOUNTS */
/*****************************/
import { getDocument } from './firestore';
/**
 * 1-per-user record of data used during signup.
 * Intended to be used to help complete sign up process in different apps.
 */
const paths = {
  /**
   * @param {string} userId firebase id
   * @returns {string} doc path
   */
  signupData: (userId) => `authSession/${userId}/signupData/${userId}`,
};
/**
 * ### Async / Promise
 * gets the saved signup data from firestore
 * @param {string} userId firebase id
 * @returns {DocumentData | undefined}
 * if undefined, either the document was not found or an error occurred.
 * otherwise, returns the document data as json object
 */
const getSignupData = async (userId) => {
  const docPath = paths.signupData(userId);
  return (await getDocument(docPath)) || undefined;
};
export { getSignupData };
