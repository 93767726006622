import { useCallback } from 'react';

import { useSegment } from 'react-segment-hooks';

import { callSegmentTrack } from '../api';

// This returns a function that can be used to track an event.
// The function takes an event name and an optional object of properties.
// Usage:
// const segmentTrack = useSegmentTrack();
// segmentTrack('eventName', { property: 'value' });

export default function useSegmentTrack() {
  const segmentClient = useSegment();

  const segmentTrackFunction = useCallback(
    (eventName, properties = {}) => {
      const data = {
        event: eventName,
        properties: properties,
      }
      callSegmentTrack(segmentClient, data)
    }, [segmentClient]);
  return segmentTrackFunction;
}
