import { useEffect, useState, useCallback } from 'react';
import { Form, Segment, Checkbox, Icon, Popup } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import Validator from 'validator';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Import components
import { DatePicker } from '../Shared/DatePicker';
import InlineError from '../Messages/InlineError';
import ShowErrors from '../Messages/ShowError';
import LocationPicker from '../Locations/LocationPicker';
import RoomPicker from '../Rooms/RoomPicker';
import SalutationPicker from '../Shared/SalutationPicker';
import LanguagePicker from '../Shared/LanguagePicker';
import { Dropdown } from '../Shared/Dropdown';
import StaffPicker from '../Staff/StaffTypePicker';
import withPermission from '../Shared/withPermission';
import { ethnicityOptions, raceOptions, ageOptions } from '../../config';
import { showSuccessToast, showErrorToast } from '../Shared/showToast';

import { useSelectedStaff } from '../../hooks/useStaff';
import { useOrganization } from '../../hooks/useOrganizations';
import {
  formatFullName,
  phoneNumberFormat,
  phoneNumberParse,
} from '../../helpers/utils';

import {
  formatStringAsUtcMillisOrNull,
  formatUtcMillisAsString,
} from '../../helpers/dates';

import { organizationUpdateStaff } from '../../redux/actions/staffActions';

import {
  archiveInvitation,
  createStaffInvitation,
  updateInvitation,
} from '../../api/firebase/invitations';

import { getOrganizationUserByEmail } from '../../api/firebase/users';
import { updateSetupStatus } from '../../api/firebase/setup';
import { useSegmentTrack, SEGMENT_EVENTS } from '../../segment';

import styles from './StaffForm.module.scss';

const SaveEditButton = (props) => <Form.Button {...props} />;

const RestrictedSaveButton = withPermission(SaveEditButton, 'can_create_staff');
const RestrictedEditButton = withPermission(SaveEditButton, 'can_edit_staff');
const EMAIL_IN_USE_MESSAGE = 'This email is already in use by another user. Please use a different email address.';

const initialFormData = {
  uid: '',
  salutation: '',
  firstName: '',
  middleName: '',
  lastName: '',
  race: '',
  ethnicity: '',
  age: '',
  phone: '',
  email: '',
  location: '',
  locationName: '',
  rooms: [],
  hireDate: null,
  language: '',
  allowStaffLogin: true,
  staffType: [],
  enabled: true,
};

export default function StaffForm({ onClose, staffList }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedStaff = useSelectedStaff();
  const organization = useOrganization();
  const segmentTrack = useSegmentTrack();

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { programType } = organization;

  useEffect(() => {
    if (isEmpty(selectedStaff)) return;

    const {
      phone,
      defaultLocation,
      locations,
      currentRoom,
      rooms,
      hireDate,
      ...rest
    } = selectedStaff;

    setFormData({
      staff: true, // for some reason this gets unset
      hireDate: formatUtcMillisAsString(hireDate),
      phone: phoneNumberParse(phone),
      location: defaultLocation,
      rooms: rooms ? Object.keys(rooms).map((key) => key) : [],
      currentRoom: rooms ? Object.keys(rooms)[0] : '',
      ...rest,
    });
  }, [selectedStaff]);

  const removeError = useCallback(
    (name) => {
      const _errors = { ...errors };
      delete _errors[name];
      setErrors(_errors);
    },
    [errors]
  );
  const formatFormDataForSave = useCallback(
    (formData) => {
      const {
        phone,
        location,
        organizations,
        defaultOrganization,
        rooms,
        currentRoom,
        hireDate,
        ...rest
      } = formData;

      // Add rooms object
      const selectedRooms = {};
      if (rooms && rooms.length)
        rooms.forEach((room) => (selectedRooms[room] = true));

      let formattedFormData = {
        currentOrganization: organization.id,
        defaultLocation: location || '',
        locations: location ? { [location]: true } : {},
        rooms: selectedRooms,
        currentRoom: !isEmpty(selectedRooms)
          ? Object.keys(selectedRooms)[0]
          : '',
        ...rest,
      };

      // Format some data
      formattedFormData.phone = phoneNumberFormat(phone);
      formattedFormData.hireDate = formatStringAsUtcMillisOrNull(hireDate);
      formattedFormData.displayName = formatFullName(rest, true);

      // Modify the payload if FCC.
      if (programType && programType === 'familyChildCare') {
        const locationId = `${organization.id}-primaryLocation`;
        const roomId = `${organization.id}-primaryRoom`;

        formattedFormData = {
          ...formattedFormData,
          defaultLocation: locationId,
          locations: {
            [locationId]: true,
          },
          rooms: {
            [roomId]: true,
          },
          currentRoom: roomId,
        };
      }
      return formattedFormData;

    },[organization.id, programType]
  )

  const updateExistingUser = useCallback(
    async (formattedFormData) => {
      try {
        await dispatch(
          organizationUpdateStaff(organization.id, formattedFormData)
        );
        segmentTrack(SEGMENT_EVENTS.staffUpdated, formattedFormData);
        showUpdateSuccessToast(formattedFormData, t);

      } catch (error) {
        console.error ("Failed to update existing user", error);
        showUpdateErrorToast(formattedFormData, error, t);
        throw new Error (error);
      }
    }
    ,[organization, dispatch, segmentTrack, t]
  );

  const updateExistingInvitation = useCallback(
    async (formattedFormData) => {
      try {
        const {uid, ...rest} = formattedFormData;
        await updateInvitation(rest);
        segmentTrack(SEGMENT_EVENTS.staffUpdated, rest);
        showUpdateSuccessToast(rest, t);

      } catch (error) {
        console.error ("Failed to update existing Staff invitation", error);
        showUpdateErrorToast(formattedFormData, error, t);
        throw new Error (error);
      }
    }
    ,[segmentTrack, t]
  );

  const createNewInvitation = useCallback(
    async (formattedFormData) => {
      try {
        await createStaffInvitation(organization, formattedFormData);
        await updateSetupStatus(organization.id, 'firstStaffCreated');
        segmentTrack(SEGMENT_EVENTS.staffCreated, formattedFormData);
        showInvitationSuccessToast(formattedFormData, t);

      } catch (error) {
        console.error ("Failed to create new Staff invitation", error);
        showInvitationErrorToast(formattedFormData, error, t);
        throw new Error (error);
      }
    }
    , [organization, segmentTrack, t]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const _errors = validate(formData, staffList, t);
      setErrors(_errors);

      if (!isEmpty(_errors)) return;

      setIsLoading(true);

      const hasEmailChanged = (selectedStaff && formData.email !== selectedStaff.email);
      const isUserUpdate = !!formData.uid && !formData.invited;
      const isInvitationUpdate = !!formData.id && !!formData.invited && !hasEmailChanged;
      const shouldArchiveInvitation = !!formData.id && !!formData.invited && hasEmailChanged;

      const formattedFormData = formatFormDataForSave (formData);

      try {

        // We're updating a verified user
        if (isUserUpdate) {
          await updateExistingUser(formattedFormData);

        // The email didn't change, so we're updating an invitation
        } else if (isInvitationUpdate) {
          await updateExistingInvitation (formattedFormData);

        } else {
          const existingUser = await getOrganizationUserByEmail (organization.id, formattedFormData.email);
          if (!!existingUser && existingUser.id !== formattedFormData.id) {
            setErrors ({email: t(EMAIL_IN_USE_MESSAGE)});
            return;
          }
          if (shouldArchiveInvitation)
            await archiveInvitation(formattedFormData.id);

          await createNewInvitation (formattedFormData);
        }
        if (onClose) onClose()

      } catch (error) {
        console.log(error);

      } finally {
        setIsLoading(false);
      }
    },
    [formData, staffList, t, selectedStaff, formatFormDataForSave, onClose, updateExistingUser, updateExistingInvitation, organization.id, createNewInvitation]
  );

  const onChange = useCallback(
    (e, { name, value, checked }) => {
      if (name === 'rooms') setFormData({ ...formData, rooms: [value] });
      else if (name === 'location')
        setFormData({ ...formData, location: value, rooms: [] });
      else setFormData({ ...formData, [name]: checked ?? value });

      removeError(name);
    },
    [removeError, formData]
  );

  const onChangePhone = useCallback(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      removeError(e.target.name);
    },
    [removeError, formData]
  );

  const onChangeHireDate = useCallback(
    (e, { name, value, valid }) => {
      onChange(e, { name, value });
      if (!valid) {
        setErrors({ ...errors, [name]: t('Invalid Date') });
      } else {
        removeError(name);
      }
    },
    [errors, removeError, setErrors, onChange, t]
  );

  return (
    <Segment basic textAlign="left">
      <ShowErrors errors={errors} />

      <Form id="staff-form" onSubmit={onSubmit} loading={isLoading} noValidate>
        <Form.Group widths={3}>
          <Form.Field error={!!errors.salutation}>
            <Form.Field
              translator={t}
              id="salutation"
              name="salutation"
              label={t('Salutation')}
              control={SalutationPicker}
              placeholder={'Salutation'}
              value={formData.salutation}
              selection
              search
              onChange={onChange}
            />
            {errors.salutation && <InlineError text={errors.salutation} />}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.firstName}>
            <Form.Input
              required
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={onChange}
              label={t('First Name')}
              placeholder={t('First Name')}
            />
            {errors.firstName && <InlineError text={errors.firstName} />}
          </Form.Field>

          <Form.Field error={!!errors.middleName}>
            <Form.Input
              type="text"
              id="middleName"
              name="middleName"
              value={formData.middleName}
              onChange={onChange}
              label={t('Middle Name')}
              placeholder={t('Middle Name')}
            />
            {errors.middleName && <InlineError text={errors.middleName} />}
          </Form.Field>
        </Form.Group>

        <Form.Field error={!!errors.lastName}>
          <Form.Input
            required
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={onChange}
            label={t('Last Name')}
            placeholder={t('Last Name')}
          />
          {errors.lastName && <InlineError text={errors.lastName} />}
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.race}>
            <Dropdown
              translator={t}
              isForm
              id="race"
              name="race"
              label={t('Race')}
              placeholder="Race"
              required
              selection
              selectOnBlur={false}
              value={formData.race}
              onChange={onChange}
              options={raceOptions}
            />
            {errors.race && <InlineError text={errors.race} />}
          </Form.Field>

          <Form.Field error={!!errors.ethnicity}>
            <Dropdown
              translator={t}
              isForm
              id="ethnicity"
              name="ethnicity"
              label={t('Ethnicity')}
              placeholder={'Ethnicity'}
              required
              selection
              selectOnBlur={false}
              value={formData.ethnicity}
              onChange={onChange}
              options={ethnicityOptions}
            />
            {errors.ethnicity && <InlineError text={errors.ethnicity} />}
          </Form.Field>
        </Form.Group>

        <Form.Field error={!!errors.age}>
          <Dropdown
            translator={t}
            isForm
            id="age"
            name="age"
            label={t('Age')}
            placeholder={'Age'}
            required
            selection
            selectOnBlur={false}
            value={formData.age}
            onChange={onChange}
            options={ageOptions}
          />
          {errors.age && <InlineError text={errors.age} />}
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.email}>
            <Form.Input
              required={formData.allowStaffLogin}
              type="text"
              id="email"
              name="email"
              value={
                formData.email && formData.email.indexOf('moxit_') !== -1
                  ? ''
                  : formData.email
              }
              onChange={onChange}
              label={t('Email')}
              placeholder={t('Email')}
            />
            {errors.email && <InlineError text={errors.email} />}
          </Form.Field>

          <Form.Field error={!!errors.phone}>
            <Form.Input
              type="text"
              id="phone"
              name="phone"
              onChange={onChangePhone}
              label={t('Cell Phone')}
              control={MaskedInput}
              mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              guide={false}
              value={formData.phone}
              placeholder={'(123) 456-7890'}
            />
            {errors.phone && <InlineError text={errors.phone} />}
          </Form.Field>
        </Form.Group>

        {(programType === null ||
          (programType && programType !== 'familyChildCare')) && (
          <>
            <Form.Group widths="equal">
              <LocationPicker
                error={!!errors.location}
                id="location"
                name="location"
                label={t('Location')}
                placeholder={t('Select location')}
                value={formData.location}
                selection
                search
                required
                onChange={onChange}
              >
                {errors.location && <InlineError text={errors.location} />}
              </LocationPicker>

              <Form.Field error={!!errors.rooms}>
                <RoomPicker
                  error={!!errors.rooms}
                  id="rooms"
                  name="rooms"
                  label={t('Rooms')}
                  placeholder={t('Room')}
                  value={formData?.rooms[0] ?? ''}
                  location={formData.location}
                  dependent
                  selection
                  search
                  onChange={onChange}
                  noResultsMessage={t(
                    !formData.location ? 'Select location first' : 'No room found'
                  )}
                />
                {errors.rooms && <InlineError text={errors.rooms} />}
              </Form.Field>
            </Form.Group>
          </>
        )}

        <Form.Group widths="equal">
          <Form.Field error={!!errors.language}>
            <Form.Field
              id="language"
              name="language"
              label={t('Language')}
              control={LanguagePicker}
              placeholder={t('Language')}
              value={formData.language}
              selection
              search
              onChange={onChange}
            />
            {errors.language && <InlineError text={errors.language} />}
          </Form.Field>

          <Form.Field width={16}>
            <DatePicker
              id="hireDate"
              name="hireDate"
              value={formData.hireDate}
              onChange={onChangeHireDate}
              label={t('Hire Date')}
              maxDate={moment()}
              error={!!errors.hireDate}
              closable
            >
              {errors.hireDate && <InlineError text={errors.hireDate} />}
            </DatePicker>
          </Form.Field>
        </Form.Group>

        <StaffPicker
          translator={t}
          isForm
          id="staffType"
          name="staffType"
          label={
            <label htmlFor="staffType">
              {t('Staff Type')}
              <Popup
                className={styles.staffPickerTooltip}
                content={t(
                  "Staff types are: Organization Administrator: A super user who has access to every feature on the platform. Location Administrator: A user who has access to every feature in the assigned location(s). Teacher and Supporting Staff: A user who has access to the student list and attendance reporting in the rooms that they are assigned to. They won't have any access to the financial information."
                )}
                position="right center"
                offset={[4, 0]}
                trigger={<Icon name="info circle" />}
              />
            </label>
          }
          placeholder={'Staff Type'}
          value={formData.staffType}
          required
          selection
          multiple
          search
          onChange={onChange}
          error={!!errors.staffType}
        >
          {errors.staffType && <InlineError text={errors.staffType} />}
        </StaffPicker>

        <Form.Field error={!!errors.allowStaffLogin}>
          <label>{t('Allow user to login using PIN code?')}</label>
          <Form.Radio
            toggle
            id="allowStaffLogin"
            name="allowStaffLogin"
            onChange={onChange}
            checked={formData.allowStaffLogin}
            control={Checkbox}
          />
          {errors.allowStaffLogin && (
            <InlineError text={errors.allowStaffLogin} />
          )}
        </Form.Field>

        {
          // @TODO: Replace the condition with a feature flag
          false && (
            <Form.Field error={!!errors.enabled}>
              <label>
                {t('Account enabled?')}
                <Popup
                  content={t(
                    'Accounts for staff are enabled as default. This is a flag for you to keep track of your staff. Disabling it will results in staff not being able to log in to the app or check in/out their children.'
                  )}
                  position="right center"
                  offset={[4, 0]}
                  trigger={<Icon name="info circle" />}
                />
              </label>
              <Form.Radio
                toggle
                id="enabled"
                name="enabled"
                onChange={onChange}
                checked={formData.enabled}
                control={Checkbox}
              />
              {errors.enabled && <InlineError text={errors.enabled} />}
            </Form.Field>
          )
        }

        {formData?.staffPin && (
          <Form.Field>
            <label>{t('PIN')}</label>
            <code>{formData.staffPin}</code>
          </Form.Field>
        )}

        <br />
        <br />
        <Form.Group>
          {formData.uid && <RestrictedEditButton primary content={t('Update')} />}
          {!formData.uid && <RestrictedSaveButton primary content={t('Save')} />}
          <Form.Button
            basic
            content={t('Cancel')}
            onClick={(e) => {
              if (e) e.preventDefault();
              if (onClose) onClose();
            }}
          />
        </Form.Group>
      </Form>
    </Segment>
  );
}
function validate(formData, staffList, t) {
  const errors = {};

  if (!formData.firstName) errors.firstName = t('First Name is required');
  if (!formData.lastName) errors.lastName = t('Last Name is required');

  if (!formData.race) errors.race = t('Race is required');
  if (!formData.ethnicity) errors.ethnicity = t('Ethnicity is required');
  if (!formData.age) errors.age = t('Age is required');

  if (isEmpty(formData.staffType)) errors.staffType = t('Staff type is required');

  if (formData.phone && !Validator.isMobilePhone(formData.phone, 'en-US')) {
    errors.phone = t('Phone is invalid');
  }

  if (formData.email) {
    if (!Validator.isEmail(formData.email)) {
      errors.email = t('Invalid email');
    } else {
      const existingStaff = staffList.find (staff => staff.id !== formData.id && staff.email === formData.email);
      if (!!existingStaff)
        errors.email = t(EMAIL_IN_USE_MESSAGE)
    };
  } else if (formData.allowStaffLogin) {
    errors.email = t('Email is required');
  }
  return errors;
}

function showInvitationSuccessToast({ displayName, email }, t) {
  const message = t(
    'An invitation was sent to {{displayName}} at ({{email}})',
    { displayName, email }
  );
  showSuccessToast(t('Staff Invitation Sent'), message);
}

function showInvitationErrorToast({ displayName, email }, error, t) {
  const message = t(
    'Failed to send a staff invitation to {{displayName}} at ({{email}})',
    { displayName, email }
  );
  showErrorToast(t('Staff Invitation Failed'), message, error);
}

function showUpdateSuccessToast({ displayName }, t) {
  const message = t('Updated staff member: {{displayName}}', { displayName });
  showSuccessToast(t('Staff Updated'), message);
}

function showUpdateErrorToast({ displayName }, error, t) {
  const message = t('Update staff member failed for {{displayName}}', {
    displayName,
  });
  showErrorToast(t('Staff Update Failed'), message, error);
}
