export const PAYOUT_STATUS = {
  PENDING: 'pending',
  IN_TRANSIT: 'in_transit',
  PAID: 'paid',
  FAILED: 'failed',
  CANCELED: 'canceled',
};

const PAYOUT_STATUS_FORMAT = {
  [PAYOUT_STATUS.PENDING]: {
    text: 'Processing',
    color: 'yellow',
    popupText: 'payoutProcessingPopup',
  },
  [PAYOUT_STATUS.IN_TRANSIT]: {
    text: 'Processing',
    color: 'yellow',
    popupText: 'payoutProcessingPopup',
  },
  [PAYOUT_STATUS.PAID]: {
    text: 'Paid',
    color: 'green',
    popupText: 'payoutPaidPopup',
  },
  [PAYOUT_STATUS.FAILED]: {
    text: 'Failed',
    color: 'red',
    popupText: 'payoutFailedPopup',
  },
  [PAYOUT_STATUS.CANCELED]: { text: 'Canceled', color: 'pink' },
};

export function invoicePayoutStatusFormatter(payout) {
  const format = PAYOUT_STATUS_FORMAT[payout?.status];
  if (!format) return { text: 'Paid', color: 'green' };
  return format;
}
