import { useMemo, useState } from 'react';
import {
  Checkbox,
  Form,
  Header,
  Icon,
  Menu,
  Modal,
  Popup,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Validator from 'validator';
import MaskedInput from 'react-text-mask';
import uuidGenerate from 'uuid/v4';

import { useUsers } from '../../../hooks/useUser';
import { useInvitations } from '../../../hooks/useInvitations';
import { useOrganization } from '../../../hooks/useOrganizations';

import { createMissingFieldsAndMergeLists } from '../../../helpers/invitations';
import { assignPin } from '../../../api/firebase/tabletPins';

import { fetchEmailUsage } from '../../../api/firebase/users';
import {
  archiveInvitation,
  addStudentToInvitation,
  createParentInvitation,
  updateInvitation,
} from '../../../api/firebase/invitations';

import { useSegmentTrack, SEGMENT_EVENTS } from '../../../segment';

import {
  addOrUpdateStudentContact,
  updateFamilyMemberAccount,
  removeStudentContact,
  setResponsibleForBilling,
  ResponsibleForBillingType,
} from '../../studentsAPI';

import {
  formatFullName,
  phoneNumberFormat,
  phoneNumberParse,
} from '../../../helpers/utils';

import InlineError from '../../../Components/Messages/InlineError';
import ShowErrors from '../../../Components/Messages/ShowError';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../Components/Shared/showToast';
import { Dropdown } from '../../../Components/Shared/Dropdown';

import {
  getOrCreateRelationshipNotes,
  transformRelationship,
  RelationshipTypes,
  RelationshipTypeOptions,
  createArrayOfFamilies,
} from '../../studentsUtils';

import { useStudentOrPrimaryLocation } from '../../studentsHooks';

import ExistingUserConfirmationDialog from './ExistingUserConfirmationDialog';
import InvoiceResponsibleConfirmationDialog from '../enrollment/InvoiceResponsibleConfirmationDialog';
import NoInvitationWillBeSentConfirmationDialog from './NoInvitationWillBeSentConfirmationDialog';

const PHONE_NUMBER_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const initialFormState = {
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  email: '',
  relationship: '',
  relationshipNotes: '',
  familyPin: '',
  allowParentLogin: true,
  enabled: true,
};

export default function FamilyEditContactModal({
  student,
  familyMember: selectedFamilyMember,
  isOpen,
  onClose,
}) {
  const { t } = useTranslation();

  const isAdd =
    !selectedFamilyMember || Object.keys(selectedFamilyMember).length === 0;
  const title = isAdd
    ? t('Add Parent or Emergency Contact')
    : t('Edit Parent or Emergency Contact');

  return (
    <Modal
      centered={false}
      open={isOpen}
      closeOnEscape={false}
      closeIcon
      size="small"
      closeOnDimmerClick={false}
      onClose={onClose}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <FamilyEditContactForm
          student={student}
          familyMember={selectedFamilyMember}
          isAdd={isAdd}
          onClose={onClose}
        />
      </Modal.Content>
    </Modal>
  );
}

function FamilyEditContactForm({
  student,
  familyMember: selectedFamilyMember,
  isAdd,
  onClose,
}) {
  const { t } = useTranslation();
  const organization = useOrganization();
  const segmentTrack = useSegmentTrack();
  const location = useStudentOrPrimaryLocation(student);

  const [isSaving, setIsSaving] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  const [errors, setErrors] = useState({});
  const [shouldEditContact, setShouldEditContact] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
  const [existingUser, setExistingUser] = useState(null);
  const [familyMember, setFamilyMember] = useState(selectedFamilyMember ?? {});
  const [
    isOpenInvoiceResponsibleConfirmation,
    setIsOpenInvoiceResponsibleConfirmation,
  ] = useState(false);
  const [
    isOpenNoInvitationWillBeSentConfirmation,
    setIsOpenNoInvitationWillBeSentConfirmation,
  ] = useState(false);

  const { getParents } = useUsers();
  const invitations = useInvitations();

  const invitationsAndUsersList = useMemo(() => {
    return createInvitationsAndUsersList(student, getParents(), invitations);
  }, [getParents, invitations, student]);

  const families = createArrayOfFamilies(student?.family);
  const familiesAndContacts = families.concat(student?.contacts ?? []);
  const familiesAndContactsAndInvitations = createMissingFieldsAndMergeLists(
    familiesAndContacts,
    invitations.list.filter((user) => 
      (user.student && user.student.id === student.id) || 
      (user.studentIds && user.studentIds.includes(student.id))),
  );

  const parentCount = familiesAndContactsAndInvitations
    .filter(user => user.relationship === RelationshipTypes.PARENT).length;

  const selectContactOptions = useMemo(() => {
    const options = invitationsAndUsersList.map((data) => ({
      key: data.key,
      text: data.displayName,
      value: data.key,
    }));
    return options;
  }, [invitationsAndUsersList]);

  if (!isInitialized) initializeFormState();

  return (
    <>
      {isAdd && (
        <Menu pointing secondary>
          <Menu.Item
            name="editContact"
            active={shouldEditContact}
            onClick={() => {
              setShouldEditContact(true);
              setErrors({});
            }}
          >
            {t('New Contact')}
          </Menu.Item>

          <Menu.Item
            name="selectContact"
            active={!shouldEditContact}
            onClick={() => {
              setShouldEditContact(false);
              setErrors({});
            }}
          >
            {t('Existing Contact')}
          </Menu.Item>
        </Menu>
      )}
      <ShowErrors errors={errors} />

      <Form loading={isSaving} noValidate>
        {!shouldEditContact && renderSelectContactForm()}
        {shouldEditContact && renderEditContactForm()}
        <Form.Group>
          <Form.Button
            primary
            content={isAdd ? t('Add Contact') : t('Save Contact')}
            onClick={onSave}
            loading={isSaving}
          />
          <Form.Button content={t('Cancel')} onClick={onCloseLocal} />
        </Form.Group>
      </Form>
      <ExistingUserConfirmationDialog
        existingUser={existingUser}
        onYes={onSaveExistingUser}
        onClose={() => setExistingUser(null)}
      />
      <NoInvitationWillBeSentConfirmationDialog
        familyMember={familyMember}
        isOpen={isOpenNoInvitationWillBeSentConfirmation}
        onYes={() => {
          setFamilyMember({ ...familyMember, allowParentLogin: false, isResponsibleForBilling: Validator.isEmail(familyMember.email) });
          removeError('email'); // email is no longer required
          setIsOpenNoInvitationWillBeSentConfirmation(false);
        }}
        onClose={() => {
          setFamilyMember({ ...familyMember, allowParentLogin: true });
          setIsOpenNoInvitationWillBeSentConfirmation(false);
        }}
      />
      <InvoiceResponsibleConfirmationDialog
        isResponsibleForBilling={familyMember.isResponsibleForBilling}
        parentCount={parentCount}
        open={isOpenInvoiceResponsibleConfirmation}
        onClose={() => {
          setIsOpenInvoiceResponsibleConfirmation(false);
        }}
        onYes={() => {
          setFamilyMember({ ...familyMember, isResponsibleForBilling: true });
          setIsOpenInvoiceResponsibleConfirmation(false);
        }}
      />
    </>
  );

  function renderEditContactForm() {
    return (
      <>
        <Form.Field>
          <Header as="h3">{t('Personal Information')}</Header>
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field error={!!errors?.relationship}>
            <Form.Field
              translator={t}
              control={Dropdown}
              label={t('Relationship')}
              fluid
              search
              selection
              clearable
              required
              id="relationship"
              name="relationship"
              placeholder="Relationship"
              options={RelationshipTypeOptions}
              value={familyMember.relationship}
              onChange={onChange}
            />
            {errors.relationship && <InlineError text={errors.relationship} />}
          </Form.Field>
          <Form.Field>
            <Form.Input
              disabled={familyMember.relationship === 'parent'}
              type="text"
              id="relationshipNotes"
              name="relationshipNotes"
              value={familyMember.relationshipNotes ?? ''}
              onChange={onChange}
              label={t('Type of Relationship')}
              placeholder={t('Tell us about this relationship')}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.firstName}>
            <Form.Input
              required
              type="text"
              id="firstName"
              key="contact-firstName"
              name="firstName"
              value={familyMember.firstName}
              onChange={onChange}
              label={t('First Name')}
              placeholder={t('First Name')}
            />
            {errors.firstName && <InlineError text={errors.firstName} />}
          </Form.Field>
          <Form.Field>
            <Form.Input
              type="text"
              id="middleName"
              name="middleName"
              value={familyMember.middleName}
              onChange={onChange}
              label={t('Middle Name')}
              placeholder={t('Middle Name')}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field error={!!errors.lastName}>
          <Form.Input
            required
            type="text"
            id="lastName"
            name="lastName"
            value={familyMember.lastName}
            onChange={onChange}
            label={t('Last Name')}
            placeholder={t('Last Name')}
          />
          {errors.lastName && <InlineError text={errors.lastName} />}
        </Form.Field>

        <Header as="h3" dividing>
          {t('Contact Information')}
        </Header>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.email}>
            <Form.Input
              required={familyMember.allowParentLogin || familyMember.invited}
              type="text"
              id="email"
              name="email"
              value={
                familyMember.email &&
                familyMember.email.indexOf('moxit_') !== -1
                  ? ''
                  : familyMember.email
              }
              onChange={onChange}
              label={t('Email')}
              placeholder={t('Email')}
            />
            {errors.email && <InlineError text={errors.email} />}
          </Form.Field>

          <Form.Field error={!!errors.phone}>
            <Form.Input
              type="text"
              id="phone"
              name="phone"
              onChange={maskedOnChange}
              label={t('Cell Phone')}
              control={MaskedInput}
              mask={PHONE_NUMBER_MASK}
              guide={false}
              value={familyMember.phone}
              placeholder={'(123) 456-7890'}
            />
            {errors.phone && <InlineError text={errors.phone} />}
          </Form.Field>
        </Form.Group>
        <Header as="h3" dividing>
          {t('Account Information')}
        </Header>
        <Form.Group widths="equal">
          <Form.Field>
            <label>
              {t('Approved Pick Up with a PIN code')}
              <Popup
                content={t(
                  "Parents by default are set to be able to log in to the Wonderschool App to check in and check out their children. If you disable this, email field becomes optional but they won't be able to log in to the app. Use this feature if you want to add emergency contact and you don't want to add emails."
                )}
                position="right center"
                offset={[-1, 0]}
                trigger={<Icon name="info circle" />}
              />
            </label>
            <Form.Radio
              toggle
              id="allowParentLogin"
              name="allowParentLogin"
              label={familyMember.allowParentLogin ? t('Yes') : t('No')}
              onChange={onChangeAllowParentLogin}
              checked={familyMember.allowParentLogin}
              control={Checkbox}
            />
          </Form.Field>
          {familyMember.allowParentLogin && familyMember.uid && (
            <Form.Field>
              <Form.Input
                readOnly
                label={
                  <label>
                    {t('Drop Off/Pick Up PIN Code')}
                    <Popup
                      content={t(
                        "The pin code is used on the shared device where you can have a kiosk for parents to check in/out kids using their designated pin. This pin also shows up on the parent app by clicking on parent info under child's profile."
                      )}
                      position="right center"
                      offset={[-1, 0]}
                      trigger={<Icon name="info circle" />}
                    />
                  </label>
                }
                value={familyMember.familyPin}
                action={{
                  color: 'teal',
                  icon: 'sync',
                  loading: isSaving,
                  onClick: async (e) => {
                    e.preventDefault();
                    const { id } = organization;

                    setIsSaving(true);

                    if (familyMember.uid && id) {
                      try {
                        // Generate PIN.
                        const familyPin = await assignPin(
                          familyMember.uid,
                          id,
                          'familyPin'
                        );

                        // If PIN Generation is successful, save changes.
                        if (familyPin) {
                          setFamilyMember({ ...familyMember, familyPin });
                        }
                      } catch (error) {
                        console.log(error);
                      } finally {
                        setIsSaving(false);
                      }
                    }
                  },
                }}
              />
            </Form.Field>
          )}
        </Form.Group>
        { familyMember.relationship && familyMember.relationship !== RelationshipTypes.CONTACT && (
            <Form.Field>
              <label>{t('Responsible for Billing')}</label>
              <Form.Radio
                toggle
                id="responsible"
                name="responsible"
                label={
                  familyMember.isResponsibleForBilling ? t('Yes') : t('No')
                }
                onChange={onChangeResponsibleForBilling}
                checked={familyMember.isResponsibleForBilling}
                control={Checkbox}
              />
            </Form.Field>
          )}
      </>
    );
  }

  function renderSelectContactForm() {
    return (
      <>
        <Header as="h3">{t('Personal Information')}</Header>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.selectedContact}>
            <Form.Field
              translator={t}
              control={Dropdown}
              label={t('Contact Name')}
              fluid
              search
              selection
              clearable
              required
              id="users"
              name="users"
              placeholder="Select Contact"
              options={selectContactOptions}
              value={selectedContact?.key ?? ''}
              onChange={onChangeSelectedUser}
            />
            {errors.selectedContact && (
              <InlineError text={errors.selectedContact} />
            )}
          </Form.Field>
          <Form.Field>
            <label>{t('Relationship')}</label>
            <div>
              {selectedContact?.relationship
                ? getOrCreateRelationshipNotes(selectedContact, t)
                : '--'}
            </div>
          </Form.Field>
        </Form.Group>

        <Header as="h3" dividing>
          {t('Contact Information')}
        </Header>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('Email')}</label>
            <div>{selectedContact?.email || '--'}</div>
          </Form.Field>
          <Form.Field>
            <label>{t('Phone Number')}</label>
            <div>{phoneNumberParse(selectedContact?.phone) || '--'}</div>
          </Form.Field>
        </Form.Group>
      </>
    );
  }

  function initializeFormState() {
    if (selectedFamilyMember) {
      const { relationship, relationshipNotes, phone, ...rest } =
        transformRelationship(selectedFamilyMember, t);

      let isResponsibleForBilling = false;

      if(relationship === RelationshipTypes.PARENT) {
        if(student.responsibleForBilling &&
          student.responsibleForBilling.id === (selectedFamilyMember.id || selectedFamilyMember.uid)) {
            isResponsibleForBilling = true;
          }
      }

      const _familyMember = {
        ...rest,
        relationship,
        relationshipNotes,
        phone: phoneNumberParse(phone),
        isResponsibleForBilling,
      };
      _familyMember.allowParentLogin =
        selectedFamilyMember.allowParentLogin ??
        relationship === RelationshipTypes.parent;

      setFamilyMember(_familyMember);
    } else {
      const isResponsibleForBilling = false;
      setFamilyMember({ ...initialFormState, isResponsibleForBilling });
    }
    setIsInitialized(true);
  }

  function onCloseLocal(e) {
    if (e) e.preventDefault();
    setSelectedContact(null);
    setFamilyMember({});
    setErrors({});
    setIsSaving(false);
    if (onClose) onClose();
  }

  async function onSaveExistingUser() {
    setErrors({});
    if (!existingUser) return;

    setShouldEditContact(false);
    setSelectedContact(existingUser);
    setExistingUser(null);
  }

  async function onSave(e) {
    if (e) e.preventDefault();

    setIsSaving(true);
    try {
      const _errors = validate(selectedFamilyMember);

      if (Object.keys(_errors).length > 0) {
        setErrors(_errors);
        return;
      }
      let success = false;
      if (shouldEditContact) success = await saveEditContact();
      else success = await saveSelectedContact();

      if (success && onClose) onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  }

  async function saveEditContact() {
    let {
      uid,
      id: invitationId,
      uuid,
      phone,
      isResponsibleForBilling,
      ...rest
    } = familyMember;
    const hasEmailChanged =
      selectedFamilyMember && rest.email !== selectedFamilyMember.email;

    rest.phone = phoneNumberFormat(phone);
    rest.displayName = formatFullName(rest, true);

    // this is not an update of an existing user.
    if (isAdd && familyMember.allowParentLogin) {
      const _existingUser = invitationsAndUsersList.find(
        (user) => user.email === familyMember.email
      );
      if (_existingUser) {
        setExistingUser(_existingUser);
        return false;
      } else {
        const emailUsage = await fetchEmailUsage(
          familyMember.email,
          organization.id
        );
        if (emailUsage?.hasRootUser) {
          setErrors({
            email: t(
              'User already exists, but in ANOTHER organization. Please use a different email address.'
            ),
          });
          return false;

          // There is an AuthUser, but no root user doc. We'll create a new user below by adding to the family
        } else if (emailUsage?.hasAuthUser && emailUsage?.uid) {
          uid = emailUsage.uid;
        }
      }
    }

    if (!student?.id || !organization?.id) {
      setIsSaving(false);
      return false;
    }
    let success = true;
    if (uid && !invitationId) {
      // Update existing family member
      try {
        rest.uid = uid;
        await updateFamilyMemberAccount(organization.id, student.id, rest);

        showUpdateSuccessToast(rest, t);
        segmentTrack(SEGMENT_EVENTS.parentUpdated, rest);
      } catch (error) {
        showUpdateErrorToast(invitationId, uuid, rest);
        console.log('Error updating parent', error);
        success = false;
      }

      // it's an existing invitation, but email hasn't changed, so don't resend an invitation
    } else if (invitationId && !hasEmailChanged) {
      success = await updateExistingInvitation(invitationId, rest);

      // it's a new invitation
    } else if (rest.allowParentLogin) {
      success = await createNewInvitation(invitationId, uuid, rest);

      //it's a contact
    } else {
      rest.uuid = uuid || uuidGenerate();
      addOrUpdateStudentContact(organization.id, student, rest);
    }

    rest.id = rest.id || rest.uuid || invitationId || uid || uuid;
    
    if(familyMember.relationship && 
      familyMember.relationship !== RelationshipTypes.CONTACT &&
      isResponsibleForBilling) {
      setResponsibleForBilling(organization.id, student.id, rest, uid && !invitationId? ResponsibleForBillingType.PARENT : ResponsibleForBillingType.INVITATION);
    }
    
    return success;
  }

  // Archive the old invitation, if it exists, and also delete the contact, if it exists
  async function createNewInvitation(invitationId, uuid, formData) {
    try {
      await archiveInvitation(invitationId);
      await removeStudentContact(organization.id, student, uuid);

      formData.phone = formData.phone.replace('+1', ''); // remove +1 from phone number for Invitation only
      const {id} = await createParentInvitation(student, organization, location, formData);
      formData.id = id;
      showInvitationSuccessToast(formData, t);
      segmentTrack(SEGMENT_EVENTS.parentInvited, formData);
      return true;
    } catch (error) {
      showInvitationErrorToast(formData, error, t);
      console.log('Error creating invitation', error);
      return false;
    }
  }

  async function updateExistingInvitation(invitationId, formData) {
    try {
      formData.phone = formData.phone.replace('+1', ''); // remove +1 from phone number for Invitation only
      await updateInvitation({ id: invitationId, ...formData });
      showInvitationSuccessToast(formData, t, invitationId);
      segmentTrack(SEGMENT_EVENTS.parentInvited, formData);
      return true;
    } catch (error) {
      showInvitationErrorToast(formData, error, t);
      console.log('Error updating invitation', error);
      return false;
    }
  }
  async function saveSelectedContact() {
    if (!selectedContact) return false;

    const isInvitation = selectedContact.invited;
    if (!isInvitation) {
      await updateFamilyMemberAccount(
        organization.id,
        student.id,
        selectedContact
      );
      showAddedSuccessToast(selectedContact, t);
    } else {
      await addStudentToInvitation(organization.id, student, selectedContact);
      showInvitationSuccessToast(selectedContact, t);
    }

    return true;
  }
  function validate() {
    const errors = {};

    if (shouldEditContact) {
      if (!familyMember.relationship)
        errors.relationship = t('Relationship is required');
      if (!familyMember.firstName)
        errors.firstName = t('First Name is required');
      if (!familyMember.lastName) errors.lastName = t('Last Name is required');

      if (familyMember.phone) {
        if (!Validator.isMobilePhone(familyMember.phone, 'en-US'))
          errors.phone = t('Phone is invalid');
      }
      if (familyMember.email && !Validator.isEmail(familyMember.email)) {
        errors.email = t('Email is invalid');
      } else if (familyMember.allowParentLogin) {
        if (!familyMember.email || familyMember.email.indexOf('moxit_') > -1)
          errors.email = t('Email is required');
      }

      if(familyMember.relationship && 
        familyMember.relationship !== RelationshipTypes.CONTACT && 
        familyMember.isResponsibleForBilling && (!familyMember.email || !Validator.isEmail(familyMember.email))) {
        errors.email = t('Email is required to set this parent responsible for billing.');
      }
    } else {
      if (!selectedContact)
        errors.selectedContact = t('Please select a contact from the list');
    }

    return errors;
  }
  function removeError(name) {
    if (errors[name]) {
      const { [name]: _, ..._errors } = errors;
      setErrors(_errors);
    }
  }
  function onChangeSelectedUser(e, { name, value }) {
    e.preventDefault();
    removeError(name);
    const contact = invitationsAndUsersList.find((u) => u.key === value);
    setSelectedContact(contact ?? null);
  }

  function onChange(e, { name, value, checked }) {
    removeError(name);
    let isResponsibleForBilling =  familyMember.isResponsibleForBilling;

    if(name === 'relationship' && value === 'parent' && parentCount <= 0) {
      isResponsibleForBilling = true;
    }

    setFamilyMember({ ...familyMember, [name]: checked ?? value, isResponsibleForBilling });
  }

  function onChangeAllowParentLogin(e, { name, value, checked }) {
    onChange(e, { name, value, checked });
    setIsOpenNoInvitationWillBeSentConfirmation(
      familyMember && !familyMember.uid && !checked
    );
  }

  function maskedOnChange(e) {
    onChange(e, { name: e.target.name, value: e.target.value });
  }

  function onChangeResponsibleForBilling(_event, { checked }) {
    if(familyMember.relationship !== RelationshipTypes.PARENT) return;

    const isCurrentlyResponsibleForBilling = parentCount <= 0 ||
      (student.responsibleForBilling && student.responsibleForBilling.id === (familyMember.id || familyMember.uid));

    if(!checked && !isCurrentlyResponsibleForBilling) {
      setFamilyMember({...familyMember, isResponsibleForBilling: false});
      return;
    }

    if(parentCount <= 0) {
      setFamilyMember({ ...familyMember, isResponsibleForBilling: checked });
    } else {
      setIsOpenInvoiceResponsibleConfirmation(true);
    }
  }
}

function createInvitationsAndUsersList(student, parents, invitations) {
  const enabledParentsList = parents.list.filter((parent) => parent.enabled);

  const mergedList = createMissingFieldsAndMergeLists(
    enabledParentsList,
    invitations.list
  );

  return mergedList.filter((data) => {
    const isAlreadyInvited =
      !!data.invited && !!data.student && data.student.id === student.id;
    const isAlreadyAFamilyMember =
      !!data.uid && !!student.family && !!student.family[data.uid];

    return !isAlreadyInvited && !isAlreadyAFamilyMember;
  });
}

function showInvitationSuccessToast({ displayName, email }, t, invitationId) {
  const message = invitationId
    ? t(
        'Invitation data updated successfully for {{displayName}} at ({{email}})',
        { displayName, email }
      )
    : t('An invitation was sent to {{displayName}} at ({{email}})', {
        displayName,
        email,
      });

  const title = invitationId
    ? t('Parent Invitation Updated')
    : t('Parent Invitation Sent');
  showSuccessToast(title, message);
}
function showInvitationErrorToast({ displayName, email }, error, t) {
  const message = t(
    'Failed to send a parent invitation to {{displayName}} at ({{email}})',
    { displayName, email }
  );
  showErrorToast(t('Parent Invitation Failed'), message, error);
}
function showUpdateSuccessToast({ displayName }, t) {
  const message = t('Updated family member: {{displayName}}', { displayName });
  showSuccessToast(t('Family Member Updated!'), message);
}
function showAddedSuccessToast({ displayName }, t) {
  const message = t('Added a new family member: {{displayName}}', {
    displayName,
  });
  showSuccessToast(t('Family Member Added!'), message);
}
function showUpdateErrorToast({ displayName }, error, t) {
  const message = t('Update family member failed for {{displayName}}', {
    displayName,
  });
  showErrorToast(t('Family Member Update Failed'), message, error);
}
