import React from 'react';
import { Button, Container, Header, Image, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

// Import components
import wsLogo from '../styles/img/wonderschool/logo-base.png';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';
import { routes } from '../config/routes';

const Error = () => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.error);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Oh no!') })}
      </title>
      <body class="error" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        <Image src={wsLogo} size="medium" centered />
      </Segment>

      <Segment basic textAlign="center" padded="very">
        <h1>¯\_(ツ)_/¯</h1>
      </Segment>

      <Header
        textAlign="center"
        as="h1"
        content={t('Oops, something went wrong!')}
      />

      <Segment basic textAlign="center">
        <Button
          color="red"
          icon="sign-out"
          content={t('Try signing out')}
          onClick={() => {
            window.location.replace(routes.signout);
          }}
        />{' '}
        <Button
          color="blue"
          primary
          icon="redo"
          content={t('Try starting over')}
          onClick={() => {
            window.location.replace(routes.home);
          }}
        />
      </Segment>
    </Container>
  );
};

export default Error;
