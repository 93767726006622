import moment from 'moment';

/**
 * Converts Firebase ServerTimestamp to local date.
 * @param {ServerTime} firebaseServerTime - Firebase ServerTimestamp
 * @param {String} format - optional format string
 * @returns {String} Formatted date string
 */
export const firebaseServerTimeToDate = (
  firebaseServerTime,
  format = 'MMMM Do YYYY, h:mm:ss A'
) => {
  if (isNaN(firebaseServerTime?._seconds)) return 'Invalid date';

  // Convert to milliseconds.
  const time = new Date(firebaseServerTime._seconds * 1000);

  // Check if the date is valid
  if (isNaN(time.getTime())) return 'Invalid date';
  return moment(time).format(format);
};

/**
 * Coverts and renders a Firebase ServerTimestamp or timestamp to a local date.
 * @param {number | ServerTime} date - date to convert
 * @param {String} format - optional format string
 * @returns {String} Formatted date string
 */
export function renderDate(date, format = 'MM/DD/YYYY h:mm A') {
  if (!date) return 'Invalid date';
  if (moment(date).isValid()) return moment(date).format(format);
  return firebaseServerTimeToDate(date, format);
}
