import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import LoadingRoute from './LoadingRoute';
import LoadingComponent from '../Shared/LoadingComponent';
import useRouteDebounce from './useRouteDebounce';
import { getOrganizationSetupState } from '../../redux/actions/organizationActions';
import WSA from '../../WSA';
import { routes } from '../../config/routes';

const VerifyRoute = ({
  component: UnverifiedUserComponent,
  needsOrgOnboarding,
  ...rest
}) => {
  const { isAuthenticated, needsVerification } = WSA.components.useWSAuth();
  const { isDebounced } = useRouteDebounce(
    JSON.stringify({
      isAuthenticated,
      needsVerification,
      needsOrgOnboarding,
    })
  );

  return (
    <LoadingRoute
      {...rest}
      component={(props) => {
        if (!isDebounced) {
          return <LoadingComponent />;
        } else if (isAuthenticated) {
          // ready and autheticated
          if (needsVerification) {
            return <UnverifiedUserComponent {...props} />;
          } else if (needsOrgOnboarding) {
            return <Redirect to={routes.completeSignup} />;
          } else {
            return <Redirect to={routes.setup} />;
          }
        } else {
          // not authenticated, go to signin
          return (
            <Redirect
              push={true}
              to={{
                ...props,
                pathname: routes.signin,
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

VerifyRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  needsOrgOnboarding: getOrganizationSetupState(state.organizations)
    .needsOrgOnboarding,
});

export default connect(mapStateToProps)(VerifyRoute);
