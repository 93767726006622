import { firestore, functions } from '../api/firebase';
import { setDocument, startCollectionListener, updateDocument } from '../api/firebase/firestore';
import { removeStudentFromInvitation } from '../api/firebase/invitations';
import moment from 'moment';

import {
  CONTACT_TYPES,
  getContactType
} from './studentsUtils';

const paths = {
  student: (organizationId, studentId) =>
    `organizations/${organizationId}/students/${studentId}`,
  students: (organizationId) => `organizations/${organizationId}/students`,
  user: (organizationId, userId) =>
    `organizations/${organizationId}/users/${userId}`,
};

export const ResponsibleForBillingType = {
  PARENT: 'parent',
  INVITATION: 'invitation',
}

export function organizationStudentsOnListen(
  organizationId,
  next,
  error,
  complete
) {
  return startCollectionListener(
    {
      path: paths.students(organizationId),
      orderBy: [{ field: 'firstName', direction: 'asc' }],
    },
    next,
    error,
    complete
  );
}

export const getOrganizationStudentDocRef = (organizationId) => {
  if (!organizationId) throw new Error('Organization Id is required');
  return firestore().collection(paths.students(organizationId)).doc();
};

export const addOrganizationStudent = (organizationId, studentData) =>
  firestore().collection(paths.students(organizationId)).add(studentData);

export const updateOrganizationStudent = async (
  organizationId,
  // combinedFamily is used on the front end only and should not be stored in the db
  // get the id to complete the path
  { id, combinedFamily, ...studentData }
) => {
  try {
    await setDocument({
      path: paths.student(organizationId, id),
      data: studentData,
    });
  } catch (error) {
    return error;
  }
};

export const createFamilyMemberAccount = (studentId, data) =>
  functions().httpsCallable('callables-students-addFamilyMember')({
    studentId,
    ...data,
  });

export const importStudentsRoster = (studentId, data) =>
  functions().httpsCallable('callables-students-importStudentsRoster')({
    studentId,
    ...data,
  });

export const updateFamilyMemberAccount = async (
  organizationId,
  studentId,
  familyMember
) => {

  if (!familyMember || !organizationId || !familyMember.uid || !studentId)
    throw new Error('Required data is missing');

  const {id, key, ...rest} = familyMember;

  const path = paths.student(organizationId, studentId);
  try {
    // Update student.family map.
    await setDocument({
      path: path,
      data: {
        family: { [familyMember.uid]: rest },
        familyMembers:  firestore.FieldValue.arrayUnion(familyMember.uid)
      },
    });

  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addOrUpdateStudentContact = async (
  organizationId,
  student,
  data
) => {
  if (!organizationId || !student?.id)
    throw new Error('Required data is missing');

  const { id, uid, key, ...rest } = data;

  // combinedFamily is used on the front end only and should not be stored in the db
  const { combinedFamily, ...studentData } = student
  if (!student.contacts) {
    student.contacts = [rest];
  } else {
    const index = student.contacts.findIndex((c) => c.uuid === rest.uuid);
    if (index === -1) {
      student.contacts.push(rest);
    } else {
      student.contacts[index] = rest;
    }
  }
  try {
    // Update student.family map.
    await setDocument({
      path: paths.student(organizationId, student.id),
      data: studentData,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const removeFamilyMember = async (organizationId, studentId, uid) => {
  if (!uid) return null;

  const data = {
    family: {
      [uid]: firestore.FieldValue.delete(),
    },
    familyMembers: firestore.FieldValue.arrayRemove (uid)
  }
  return setDocument({
    path: paths.student(organizationId, studentId),
    data,
  });
};

export const removeStudentContact = async (organizationId, student, uuid) => {
  if (!uuid) return null;

  student.contacts = student.contacts.filter((c) => c.uuid !== uuid);

  return setDocument({
    path: paths.student(organizationId, student.id),
    data: { contacts: student.contacts },
  });
};

export const setResponsibleForBilling = async (organizationId, studentId, familyMember, responsibleForBillingType) => {
  if(!organizationId || !studentId || !familyMember || !responsibleForBillingType) throw new Error(`${moment().format()} setResponsibleForBilling: Required data is missing`);

  try {
    await updateDocument({
      path: paths.student(organizationId, studentId),
      data: {
        responsibleForBilling: {
          id: familyMember.id || familyMember.uid,
          displayName: familyMember.displayName,
          email: familyMember.email,
          type: responsibleForBillingType,
          modifiedDate: Date.now(),
        }
      },
    })
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const clearResponsibleForBilling = async (organizationId, studentId) => {
  if(!organizationId || !studentId) throw new Error(`${moment().format()} clearResponsibleForBilling: Required data is missing`);

  try {
    await updateDocument({
      path: paths.student(organizationId, studentId),
      data: {
        responsibleForBilling: firestore.FieldValue.delete(),
      },
    })
  } catch (error) {
    console.log(error);
    return error;
  }
}

// familyMember can be a user, invitation, or contact
export const removeFamilyMemberFromStudent = async (organizationId, student, familyMember) => {
  const contactType = getContactType (familyMember);
  switch (contactType) {
    case CONTACT_TYPES.user:
      return removeFamilyMember (organizationId, student.id, familyMember.uid);

    case CONTACT_TYPES.invitation:
      return removeStudentFromInvitation (familyMember.id, student.id);

    case CONTACT_TYPES.contact:
      return removeStudentContact (organizationId, student, familyMember.uuid)

    default:
      return null;
  }
}
