import { useSelector } from 'react-redux';

export function useInvoiceEditDetails() {
  return useSelector((state) => {
    return state?.invoiceEdit?.details ?? {};
  });
}
export function useInvoiceEditItems() {
  return useSelector((state) => {
    return state?.invoiceEdit?.invoiceItems ?? [];
  });
}

export function useInvoiceEditStudents() {
  return useSelector((state) => {
    return state?.invoiceEdit?.students ?? [];
  });
}
export function useInvoiceEditSelected() {
  return useSelector((state) => {
    return state?.invoiceEdit?.selected;
  });
}
