import { initFirebase } from './config/init';
import {
  makeWithWSAuth,
  useWSAuth,
  mapAuthContextToProps,
} from './components/WSAuthProvider';
import {
  getSigninUrl,
  getSignupUrl,
  getSignoutUrl,
  getFullSignoutUrl,
  makeInvitedUrl,
  makeReturnData,
  extractReturnData,
} from './config/authUrls';
import { getAuth } from './firebase/';
import { signInWithCustomToken, signOut } from './firebase/auth';
import { getSignupData } from './firebase/signupData';
import { sendVerificationEmail } from './firebase/currentUser';
import { ACTION_TYPES } from './firebase/actionReturn';
import { getHashFragmentDecoded, removeHashFragment } from './utils/HashParams';
import { saveSessionId } from './hooks/useSavedSessionId';
import { logWarning, logError } from '../rollbar';

/* eslint-disable no-unused-vars */
// use for types in comments
import firebase from 'firebase/app';
/* eslint-enable no-unused-vars */
/**
 * @type {WSAuth} memoized instance
 */
let defaultApp;

/**
 * initializes and memoizes the WSAuth instance
 * @param {{
 *  env: 'local' | 'dev' | 'prod' | undefined
 * }?} config
 * @returns {WSAuth}
 */
const getWSA = (config = {}) => {
  if (!defaultApp) {
    defaultApp = new WSAuth(config);
  }
  return defaultApp;
};

/**
 * @param {string} url
 */
const defaultRedirect = (url) => {
  window.location.assign(url);
};

const makeError = ({ errorCode }) => {
  const error = new Error(errorCode);
  error.errorCode = errorCode;
  return error;
};

/**
 * Class to manage initialization of SSO SDK and provides lifecycle methods
 * to sign in, sign out of sdk and client side firebase app instances.
 * - For react components (context providers and connectors), see `components`.
 * - For getting routes to redirect to the SSO app, see `urls`.
 * - For auth lifecycle actions, like signin and signout, see `auth`.
 * - For completing signup/user onboarding, see `signup`.
 * - For user management actions, like verification and forgot password, see `actions`.
 */
class WSAuth {
  /**
   *
   * @param {{
   *  env: 'local' | 'dev' | 'prod',
   *  isMobileMode: boolean,
   *  returnUrl: string
   *  fbAuthV8: firebase.auth.Auth,
   *  performRedirect: (s: string) => {}
   *  getLanguageKey: () => (string | undefined)
   * }}
   * - env: environment
   * - isMobileMode: whether to use mobile returns to support mobile web views instead of desktop web,
   * - returnUrl: web-accessible route to the app's return endpoint
   * - fbAuthV8: initial client firebase app to register
   * - performRedirect: given a string url, visit that url as directed by the sdk
   * - getLanguageKey: returns current language or undefined
   */
  constructor({
    env,
    isMobileMode = false,
    returnUrl,
    fbAuthV8,
    getLanguageKey = () => undefined,
    performRedirect = defaultRedirect,
  } = {}) {
    if (!env) {
      throw new Error('env is required for WSA');
    }
    defaultApp = this;

    /** @type {'local' | 'dev' | 'prod'} */
    this.env = env;
    /** @type {firebase.app.App} */
    this.firebase = initFirebase(env);
    /** @type {boolean} whether to request mobileReturns in redirects */
    this.isMobileMode = isMobileMode;
    this.returnUrl = returnUrl;
    /** @type {Set<firebase.auth.Auth>} */
    this.appRegistry = new Set();
    if (fbAuthV8) {
      this.auth.registerClientFirebaseForSignin(fbAuthV8);
    }
    this.performRedirect = performRedirect;
    this.getLanguageKey = getLanguageKey;
  }
  /**
   * For react components (context providers and connectors)
   * @memberof WSAuth
   */
  components = {
    /**
     * Wraps existing component with WSAuth and Firebase contexts (Function components)
     */
    withWSAuth: makeWithWSAuth(this),
    /**
     * Extends existing component props with auth context (Class components)
     */
    mapAuthContextToProps,
    /**
     * hook that returns the WSAuthContext object
     */
    useWSAuth,
  };
  /**
   * For getting routes to redirect to the SSO app
   * @memberof WSAuth
   */
  urls = {
    /**
     * if mobile mode, sign up on SSO will be blocked
     * @param {{idTokenJwtMode: boolean}} config
     *  - idTokenJwtMode: whether to return an id token jwt instead of the custom token jwt (useful for legacy apps)
     * @returns {string} web resource location in the sso app that will conduct a sign in action
     */
    getSignin: ({ idTokenJwtMode = false, ...restState } = {}) => {
      return getSigninUrl(
        {
          idTokenJwtMode: idTokenJwtMode,
          noSignupMode: this.isMobileMode, // sign up blocking and mobile mode are pinned together
          mobileReturnMode: idTokenJwtMode || this.isMobileMode, // if idTokenMode, then must be mobileReturn
          returnUrl: this.returnUrl,
          lang: this.getLanguageKey(),
        },
        restState
      );
    },
    /**
     * @param {string} returnUrl client web resource location that sso should return to after flow
     * @param {{idTokenJwtMode: boolean}} config
     *  - idTokenJwtMode: whether to return an id token jwt instead of the custom token jwt (useful for legacy apps)
     * @returns {string} web resource location in the sso app that will conduct a sign up action
     */
    getSignup: ({ idTokenJwtMode = false, ...restState } = {}) => {
      return getSignupUrl(
        {
          idTokenJwtMode: idTokenJwtMode,
          mobileReturnMode: idTokenJwtMode || this.isMobileMode, // if idTokenMode, then must be mobileReturn
          returnUrl: this.returnUrl,
          lang: this.getLanguageKey(),
        },
        restState
      );
    },
    /**
     * Make sure to pair this with `auth`.`signoutLocal()`
     * @param {string} returnUrl client web resource location that sso should return to after flow
     * @returns {string} web resource location in the sso app that will conduct a normal (just this browser context) sign out
     */
    getSignout: (restState = {}, instantReturnUrl) => {
      return getSignoutUrl(
        {
          mobileReturnMode: this.isMobileMode,
          returnUrl: instantReturnUrl || this.returnUrl,
          instantReturnMode: !!instantReturnUrl,
          lang: this.getLanguageKey(),
        },
        restState
      );
    },
    /**
     * * Make sure to pair this with `auth`.`signoutLocal()`
     * @param {string} returnUrl client web resource location that sso should return to after flow
     * @returns {string} web resource location in the sso app that will conduct a full sign out action
     */
    getFullSignout: (restState = {}, instantReturnUrl) => {
      return getFullSignoutUrl(
        {
          mobileReturnMode: this.isMobileMode,
          returnUrl: instantReturnUrl || this.returnUrl,
          instantReturnMode: !!instantReturnUrl,
          lang: this.getLanguageKey(),
        },
        restState
      );
    },
  };
  /**
   * For auth lifecycle actions, like signin and signout
   * @memberof WSAuth
   */
  auth = {
    /**
     * Adds a firebase app instance to the app registry. This sdk will manage
     * sign in/out lifecycle events for all registered apps.
     * @param {firebase.auth.Auth} fbAuthV8
     * @returns {() => void} unsub function to remove from registry
     */
    registerClientFirebaseForSignin: (fbAuthV8) => {
      if (!this.appRegistry.has(fbAuthV8)) {
        this.appRegistry.add(fbAuthV8);
      }
      return () => {
        this.appRegistry.delete(fbAuthV8);
      };
    },
    /**
     * ### Async / Promise
     * @param {boolean} removeHashAfter whether to remove the hash after usage
     * @returns {{
     *  jwt: string,
     *  sessionId: string,
     *  isSignin: boolean,
     *  signinData: Object?,
     *  isSignup: boolean,
     *  signupData: Object?
     *  selectedLanguage: string?
     * } | undefined} if undefined, something went wrong and no sign in was completed
     */
    completeSignin: async (removeHashAfter = true) => {
      let decoded;
      try {
        // looks for the decoded hash data
        decoded = getHashFragmentDecoded();
        if (decoded) {
          // then from decoded, extract jwt, session id, mode, signup data
          const {
            jwt,
            sessionId,
            isSignin,
            signinData,
            isSignup,
            signupData,
            selectedLanguage,
            returnState,
          } = extractReturnData(decoded);
          if (!jwt) {
            throw new Error('Missing jwt');
          }
          if (!sessionId) {
            throw new Error('Missing session id');
          }
          // save session id for later
          saveSessionId(sessionId);
          // sign into other registered apps
          await Promise.all(
            [...this.appRegistry].map(
              /** @param {firebase.auth.Auth} appAuth */
              (appAuth) =>
                appAuth.signInWithCustomToken(jwt).then(() => {
                  return new Promise((resolve, reject) => {
                    const unsubscribe = appAuth.onAuthStateChanged((user) => {
                      if (user) {
                        resolve(appAuth.currentUser.uid);
                        unsubscribe();
                      }
                    }, reject);
                  });
                })
            )
          ).catch((err) => {
            throw err;
          });
          // use token to sign in to sdk and client
          const user = await signInWithCustomToken(jwt);
          if (!user) {
            throw new Error('Could not sign in');
          }
          // maybe remove hash
          if (removeHashAfter) {
            removeHashFragment();
          }
          // return misc state for next steps
          return {
            jwt,
            sessionId,
            user,
            isSignin,
            isSignup,
            signinData,
            signupData: { ...signupData, email: user.user.email },
            selectedLanguage,
            returnState,
          };
        }
        // else return nothing
      } catch (err) {
        logError(`completeSignin error: ${err.message}`, err, { err, decoded });
        return; // signals failure
      }
    },
    /**
     * ### Async / Promise
     * @param {string} jwt custom token
     * @returns {boolean} if successful
     */
    manualCustomTokenSignin: async (jwt) => {
      try {
        // use token to sign in to sdk and client
        const result = await signInWithCustomToken(jwt);
        if (!result || result.errorCode) {
          throw new Error(
            `Failed to sign in with custom token to the sdk: ${
              result?.errorCode || '(unhandled)'
            }`
          );
        }
        // sign into other registered apps
        const appsResults = await Promise.allSettled([
          [...this.appRegistry].map(
            /** @param {firebase.auth.Auth} appAuth */
            (appAuth) => appAuth.signInWithCustomToken(jwt)
          ),
        ]);
        const errors = appsResults.filter(
          (result) => result.status === 'rejected'
        );
        const hasErrors = errors.length > 0;
        if (hasErrors) {
          throw new Error(
            `Failed to sign in with custom token to the app: ${errors[0].reason}`
          );
        }

        return true; // signals success
      } catch (err) {
        logError(`manualCustomTokenSignin error: ${err.message}`, err, {
          err,
          jwt,
        });
        return false; // signals failure
      }
    },
    /**
     * There is a weird bug with certain meta data about the user (like email_verified)
     * see https://stackoverflow.com/questions/41541887/firebase-how-to-detect-observe-when-firebase-user-verified-their-email-like-on#comment122566144_41550149
     * and see useAuthUser.js
     * That requires forcing an idToken refresh on all registered app when we suspect the email_verified field has changed.
     * This exposes a method to do that and is passed to where it is needed internally
     * @returns {boolean} if successful
     */
    forceTokenRefreshOnApps: async () => {
      try {
        const promises = [];
        const currentUser = getAuth().currentUser;
        if (currentUser) {
          promises.push(currentUser.getIdToken(true));
        }
        promises.push(
          [...this.appRegistry].map(
            /** @param {firebase.auth.Auth} appAuth */
            async (appAuth) => {
              const appUser = appAuth.currentUser;
              if (appUser) {
                await appUser.getIdToken(true);
              }
            }
          )
        );
        await Promise.allSettled(promises);
        return true;
      } catch (err) {
        logError(`forceTokenRefresh error: ${err.message}`, err);
        return false; // signals failure
      }
    },
    /**
     * ### Async / Promise
     * Attempts to sign out of all firebase instances,
     * first the registered client apps and finally the sdk app.
     * @returns {boolean} whether or not it was successful
     */
    signoutLocal: async () => {
      saveSessionId(undefined);
      try {
        await Promise.allSettled([
          ...[...this.appRegistry].map(
            /** @param {firebase.auth.Auth} appAuth */
            async (appAuth) => {
              await appAuth.signOut();
              return true;
            }
          ),
          (async () => {
            const result = await signOut();
            if (!result) {
              throw new Error('failed to sign out');
            }
            return true;
          })(),
        ]);
        return true;
      } catch (err) {
        logError(`signoutLocal error: ${err.message}`, err);
        return; // unrecoverable, will fix on next signin
      }
    },
    /**
     * ### Async / Promise
     * Redirects to the SSO app to complete a signout event
     * @param {Object} restState additional state
     * @returns {boolean} whether or not it was successful
     */
    signoutRedirect: (restState = {}) => {
      this.performRedirect(this.urls.getSignout(restState));
    },
  };
  /**
   * For completing signup/user onboarding
   * @memberof WSAuth
   */
  signup = {
    /**
     * ### Async / Promise
     * gets the saved signup data from firestore
     * @param {firebase.User} user firebase user with properties uid, email
     * @returns {DocumentData | undefined}
     * Either returns the document data as json object
     * or an empty object if nothing was found or an error occurred.
     * @throws an error is userId is not provided
     */
    getSavedSignupData: async (user) => {
      const userId = user?.uid;
      if (!userId) {
        throw new Error('userId required');
      }
      return Object.assign({}, await getSignupData(userId), {
        email: user.email,
      });
    },
    getInvitePayload: () => {
      const actionType = ACTION_TYPES.RESET_PASSWORD;
      const actionReturnData = makeReturnData(
        {
          mobileReturnMode: this.isMobileMode,
          returnUrl: this.returnUrl,
        },
        { actionType }
      );
      return {
        inviteUrl: makeInvitedUrl(),
        returnData: {
          ...actionReturnData,
          isInvited: true,
        },
      };
    },
  };
  /**
   * For user management actions, like verification and forgot password
   * @memberof WSAuth
   */
  actions = {
    /**
     * ### Async / Promise
     * Saves the return data and sends a verification email to the user.
     * @param {string} userId
     * @returns {boolean} if successful
     * @throws { Error } if user id not provided
     */
    sendVerificationEmail: async (userId) => {
      if (!userId) {
        throw new Error('User id required to send verification email');
      }
      const actionType = ACTION_TYPES.VERIFY_EMAIL;
      const actionReturnData = makeReturnData(
        {
          mobileReturnMode: this.isMobileMode,
          returnUrl: this.returnUrl,
        },
        { actionType }
      );
      try {
        const result = await sendVerificationEmail(actionReturnData);
        if (result?.errorCode) {
          return makeError(result);
        } else if (result === true) {
          return true; // signals success
        } else {
          throw new Error('Failed to send verification email');
        }
      } catch (err) {
        logWarning(`Could not send verification email: ${err.message}`, {
          err,
        });
        return false; // signals failure
      }
    },
  };
}

export { getWSA, WSAuth };
