import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header, Label } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

// Import style
import cardStyles from '../Shared/CardHelpers/CardStyles.module.scss';

const buildAgeRangeStr = ({ from, to }, t) => {
  // resolve legacy data ---------------
  if (!from || !to) {
    return null;
  }
  if (typeof from === 'string' && typeof to === 'string') {
    if (from === to) {
      return t(from);
    }
    return `${t(from)} - ${t(to)}`;
  }

  // resolve current data ---------------
  const toMonths = `${to.months} ${to.months === 1 ? t('Month') : t('Months')}`;
  const toYears = `${to.years < 13 ? to.years : '12+'} ${
    to.years === 1 ? t('Year') : t('Years')
  }`;

  // display none
  if (
    from.months === 0 &&
    from.years === 0 &&
    to.months === 0 &&
    to.years === 0
  ) {
    return null;
  }

  // from.X === 0 && one of to.X is 0
  if (
    from.months === 0 &&
    from.years === 0 &&
    (to.months === 0 || to.years === 0)
  ) {
    if (to.years === 0) {
      return `${from.months} - ${toMonths}`;
    }
    return `${from.years} - ${toYears}`;
  }

  // display only months
  if (from.months === 0 && to.months === 0) {
    return `${from.years} - ${toYears}`;
  }

  // display only years
  if (from.years === 0 && to.years === 0) {
    return `${from.months} - ${toMonths}`;
  }

  // hide 0s
  if (
    from.months === 0 ||
    from.years === 0 ||
    to.months === 0 ||
    to.years === 0
  ) {
    return `
      ${from.years > 0 ? `${from.years} ${t('Yrs')}` : ''}
      ${from.months > 0 ? `${from.months} ${t('Mos')}` : ''} - 
      ${to.years > 0 ? `${to.years} ${t('Yrs')}` : ''}
      ${to.months > 0 ? `${to.months} ${t('Mos')}` : ''}
    `;
  }

  // display all
  return `${from.years} ${t('Yrs')} ${from.months} ${t('Mos')} - ${
    to.years
  } ${t('Yrs')} ${to.months} ${t('Mos')}`;
};

const RoomCard = ({ room, onClick }) => {
  const { t } = useTranslation();

  const ageRange = room.ageRange && buildAgeRangeStr(room.ageRange, t);

  return (
    <Card className="shadow center" key={room.id} onClick={onClick}>
      <Card.Content className="center">
        <Avatar
          name={room.name}
          round
          size={75}
          className="left floated"
          style={{ float: 'unset', fontSize: '2em' }}
        />
      </Card.Content>

      <Card.Content className="no-border" extra>
        <Card.Header>
          <Header as={'h3'} className={cardStyles.textTruncate}>
            {room.name}
          </Header>
        </Card.Header>
        <Card.Meta>{ageRange || <>&nbsp;</>}</Card.Meta>
      </Card.Content>

      <Card.Content extra>
        <Label
          content={room.enabled ? t('Enabled') : t('Disabled')}
          color={room.enabled ? 'green' : 'red'}
        />
      </Card.Content>
    </Card>
  );
};

RoomCard.propTypes = {
  room: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RoomCard;
