import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import LoadingRoute from './LoadingRoute';
import WSA from '../../WSA';
import { routes } from '../../config/routes';

const GuestRoute = ({
  authenticatedRedirect,
  component: GuestComponent,
  ...rest
}) => {
  const { isAuthenticated } = WSA.components.useWSAuth();

  return (
    <LoadingRoute
      {...rest}
      component={(props) => {
        if (!isAuthenticated) {
          // guest route
          return <GuestComponent {...props} />;
        } else {
          // user found
          return <Redirect to={authenticatedRedirect || routes.home} />;
        }
      }}
    />
  );
};

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authenticatedRedirect: PropTypes.string,
};

export default GuestRoute;
