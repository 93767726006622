import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Form, Modal, Radio, Segment } from 'semantic-ui-react';
import { auth, firestore } from '../../../api/firebase';

import { useSegmentTrack, SEGMENT_EVENTS } from '../../../segment';

// Import actions
import { organizationAddStudent } from '../../studentsRedux';

import FormSelector from '../../../Components/FormSelector';
import LocationPicker from '../../../Components/Locations/LocationPicker';
import InlineError from '../../../Components/Messages/InlineError';
import RoomPicker from '../../../Components/Rooms/RoomPicker';
import withPermission from '../../../Components/Shared/withPermission';

import { getRandomStudentPicture } from '../../studentsUtils';
import InvitationConfirmationDialog from '../enrollment/InvitationConfirmationDialog';

const SHOW_STUDENT_ENROLLMENT_FEATURES = false;

const NewStudentButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button primary {...props}>
      {!props.icon ? '+' : ''} {t('Add student')}
    </Button>
  );
};

const StudentForm = ({ currentOrganization, onClose }) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [showInvitationForm, setShowInvitationForm] = useState(false);
  const [studentData, setStudentData] = useState({
    displayName: '',
    firstName: '',
    lastName: '',
    rooms: '',
    enrollmentStatus: false,
  });
  const [invitationData, setInvitationData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    type: 'enrollment',
    formId: '',
    location: '',
    completed: false,
  });
  const [errors, setErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const segmentTrack = useSegmentTrack();

  const handleStudentDataChange = useCallback((_, { name, value }) => {
    setStudentData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleInvitationDataChange = useCallback((_, { name, value }) => {
    setInvitationData((prev) => ({ ...prev, [name]: value }));
  }, []);

  // Validate form data.
  const validate = useCallback(() => {
    const errors = {};

    // Validate student data
    if (!studentData.firstName)
      errors.firstName = t('Student first name is required');
    if (!studentData.lastName)
      errors.lastName = t('Student last name is required');

    // Validate invitation data
    if (showInvitationForm) {
      if (!invitationData.firstName)
        errors.invitationFirstName = t('Parent first name is required');
      if (!invitationData.lastName)
        errors.invitationLastName = t('Parent last name is required');
      if (!invitationData.email)
        errors.invitationEmail = t('Parent email is required');
      if (!invitationData.location)
        errors.invitationLocation = t('Please select location');
      if (!invitationData.formId)
        errors.invitationFormId = t('Please select form');
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, [studentData, invitationData, showInvitationForm, t]);

  const saveData = useCallback(async () => {
    try {
      setErrors({});
      setSaving(true);
      const { rooms } = studentData;

      const createdAt = firestore.FieldValue.serverTimestamp();

      const createdBy = {
        uid: auth().currentUser.uid,
        email: auth().currentUser.email,
      };

      const studentDoc = firestore()
        .collection(`organizations/${currentOrganization.id}/students`)
        .doc();

      studentData.id = studentDoc.id;
      studentData.organization = currentOrganization.id;
      studentData.displayName = `${studentData.firstName} ${studentData.lastName}`;
      studentData.picture = studentData.picture
        ? studentData.picture
        : getRandomStudentPicture();

      // Save student data.
      if (currentOrganization?.id) {
        await studentDoc.set({
          ...studentData,
          rooms: [rooms], // Workaround to work with legacy data structure.
          invitation: {
            ...invitationData,
          },
          createdAt,
          createdBy,
        });

        // Save invitation data.
        if (showInvitationForm) {
          await firestore()
            .collection('invitations')
            .add({
              ...invitationData,
              student: { ...studentData, id: studentDoc.id },
              organization: currentOrganization.id,
              entityPath: `organizations/${currentOrganization.id}/students/${studentData.id}`,
              createdAt,
              createdBy,
            });
        }
      }
      segmentTrack(SEGMENT_EVENTS.studentCreated, invitationData);

      setSaving(false);

      return null;
    } catch (error) {
      console.error(error);
      setSaving(false);
      setErrors({ submit: error?.message || t('Unable to save student data') });
    }
  }, [
    studentData,
    currentOrganization.id,
    segmentTrack,
    invitationData,
    showInvitationForm,
    t,
  ]);

  const handleSubmit = async () => {
    if (validate()) {
      // If send enrollment toggled true, show confirmation dialog first.
      if (showInvitationForm) {
        return setShowConfirmation(true);
      } else {
        await saveData();
        if (onClose) onClose(studentData.id);
      }
    }
  };

  return (
    <Segment basic>
      <InvitationConfirmationDialog
        open={showConfirmation}
        loading={saving}
        onClose={(studentId) => {
          setShowConfirmation(false);
          if (onClose) onClose(studentId);
        }}
        onYes={saveData}
      />
      <Form
        onSubmit={handleSubmit}
        error={!!errors}
        loading={saving}
        noValidate
      >
        <Form.Field error={!!errors.firstName}>
          <Form.Input
            required
            type="text"
            id="firstName"
            name="firstName"
            value={studentData?.firstName}
            onChange={handleStudentDataChange}
            label={t('First Name')}
            placeholder={t('First Name')}
          />
          {errors.firstName && <InlineError text={errors.firstName} />}
        </Form.Field>

        <Form.Field error={!!errors.lastName}>
          <Form.Input
            required
            type="text"
            id="lastName"
            name="lastName"
            value={studentData?.lastName}
            onChange={handleStudentDataChange}
            label={t('Last Name')}
            placeholder={t('Last Name')}
          />
          {errors.lastName && <InlineError text={errors.lastName} />}
        </Form.Field>

        {currentOrganization.programType !== 'familyChildCare' && (
          <Form.Field error={!!errors.rooms}>
            <RoomPicker
              selection
              search
              error={!!errors.rooms}
              id="rooms"
              name="rooms"
              label={t('Room')}
              placeholder={t('Select room')}
              value={studentData?.rooms}
              onChange={handleStudentDataChange}
            />

            {errors.rooms && <InlineError text={errors.rooms} />}
          </Form.Field>
        )}

        {SHOW_STUDENT_ENROLLMENT_FEATURES && (
          <div className="field" style={{ marginTop: '20px' }}>
            <label>
              {t('Send enrollment form to be completed by parents')}
            </label>
            <Radio
              checked={showInvitationForm}
              toggle
              onChange={() => setShowInvitationForm(!showInvitationForm)}
            />

            {showInvitationForm && (
              <div style={{ marginTop: '20px' }}>
                <Form.Field error={!!errors.invitationFirstName}>
                  <Form.Input
                    type="text"
                    required
                    id="invitationFirstName"
                    name="firstName"
                    value={invitationData?.firstName}
                    onChange={handleInvitationDataChange}
                    label={t('Parent First Name')}
                    placeholder={t('First Name')}
                  />
                  {errors.invitationFirstName && (
                    <InlineError text={errors?.invitationFirstName} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.invitationLastName}>
                  <Form.Input
                    type="text"
                    required
                    id="invitationLastName"
                    name="lastName"
                    value={invitationData?.lastName}
                    onChange={handleInvitationDataChange}
                    label={t('Parent Last Name')}
                    placeholder={t('Last Name')}
                  />
                  {errors.invitationLastName && (
                    <InlineError text={errors?.invitationLastName} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.invitationEmail}>
                  <Form.Input
                    type="text"
                    required
                    id="invitationEmail"
                    name="email"
                    value={invitationData?.email}
                    onChange={handleInvitationDataChange}
                    label={t('Parent Email')}
                    placeholder={t('Email')}
                  />
                  {errors.invitationEmail && (
                    <InlineError text={errors?.invitationEmail} />
                  )}
                </Form.Field>

                <Form.Field error={!!errors.invitationLocation}>
                  <LocationPicker
                    id="location"
                    name="location"
                    label={t('Location')}
                    placeholder={t('Select location')}
                    value={invitationData.location}
                    selection
                    search
                    required
                    onChange={handleInvitationDataChange}
                  />
                  {errors.invitationLocation && (
                    <InlineError text={errors.invitationLocation} />
                  )}
                </Form.Field>

                <FormSelector
                  required
                  name="formId"
                  label="Enrollment Form"
                  placeholder={t('Select enrollment form')}
                  error={errors?.invitationFormId}
                  location={invitationData?.location}
                  value={invitationData?.formId}
                  onChange={handleInvitationDataChange}
                />
              </div>
            )}
          </div>
        )}

        <Button
          floated="right"
          primary
          style={{ margin: '20px 0' }}
          loading={saving}
        >
          {t('Add student')}
        </Button>
      </Form>
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
});

const ConnectedStudentForm = connect(mapStateToProps, {
  organizationAddStudent,
})(StudentForm);

const StudentAddForm = ({ setState, icon = false }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={showModal}
        closeOnEscape={false}
        closeIcon
        size="small"
        closeOnDimmerClick={false}
        header={t('Add Student')}
        onClose={() => setShowModal(false)}
        content={
          <ConnectedStudentForm
            onClose={(studentId) => {
              setShowModal(false);
              setState((prev) => ({ ...prev, newStudentId: studentId }));
            }}
          />
        }
      />
      <NewStudentButton icon={icon} onClick={() => setShowModal(true)} />
    </>
  );
};

export default withPermission(StudentAddForm, 'can_create_student');
