import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import { userSaveSignUpData } from '../redux/actions/userActions';
import { useNetwork } from '../networks/networksHooks';
import { NetworkLogo } from '../networks';
import networkStyles from '../networks/components/networks.module.scss';

import CompleteSignupForm from '../Components/Forms/Auth/CompleteSignupForm';

import WSA from '../WSA';
import { getTitlePrefix } from '../config/env';
import { routes } from '../config/routes';

const NetworkSection = ({ network }) => {
  if (!network) {
    return null;
  }
  return (
    <div className={networkStyles.networkLogoWrapper}>
      <Header as="h4" className={networkStyles.networkLogoTitle}>
        {network.name}
      </Header>
      <NetworkLogo network={network} />
    </div>
  );
};

const CompleteSignup = ({
  signupData,
  defaultOrganization,
  currentOrganization,
  userSaveSignUpData,
  history,
}) => {
  const { t } = useTranslation();
  const { authUser, forceUserRefresh } = WSA.components.useWSAuth();

  const { networkId } = signupData;
  const network = useNetwork(networkId);

  useEffect(() => {
    let isStillValid = true;
    if (isEmpty(signupData) && !!authUser) {
      WSA.signup
        .getSavedSignupData(authUser)
        .then((data) => {
          if (isStillValid) {
            userSaveSignUpData(data);
          }
        })
        .catch((err) => {
          console.log(err);
          // something went wrong - let the user fill out the fields again
          // :: TODO :: better error handling, at least email verification flow?
        });
    }
    return () => (isStillValid = false);
  }, [signupData, authUser, userSaveSignUpData]);

  useEffect(() => {
    if (defaultOrganization) {
      forceUserRefresh();
      if (currentOrganization?.setupComplete) {
        history.replace(routes.home);
      } else {
        history.replace(routes.setup);
      }
    }
  }, [
    currentOrganization?.setupComplete,
    defaultOrganization,
    forceUserRefresh,
    history,
  ]);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Sign-up') })}
      </title>
      <body class="auth sign-up" />
    </Helmet>
  );

  const renderMainSignup = (network) => {
    return (
      <>
        {renderPageHead()}

        <Segment className="auth-wrapper">
          <CompleteSignupForm signupData={signupData} networkId={network?.id} />
        </Segment>
      </>
    );
  };

  if (defaultOrganization) {
    return null;
  } else if (networkId) {
    return (
      <div className={networkStyles.signUpPage}>
        <div className={networkStyles.signUpFormWrapper}>
          <Container className={networkStyles.signUpForm}>
            {renderMainSignup(network)}
          </Container>
        </div>
        <NetworkSection network={network} />
      </div>
    );
  } else {
    return <Container style={{ width: 600 }}>{renderMainSignup()}</Container>;
  }
};

const mapStateToProps = (state) => {
  return {
    signupData: state.user?.signupData,
    defaultOrganization: state.user?.defaultOrganization,
    currentOrganization: state.organizations?.currentOrganization,
  };
};
export default connect(mapStateToProps, { userSaveSignUpData })(CompleteSignup);
