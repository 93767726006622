import React from 'react';
import { connectRefinementList, Highlight } from 'react-instantsearch-dom';
import { Checkbox, List } from 'semantic-ui-react';

// This functions returns a label because Checkbox.label expects SemanticShorthandItem<HtmlLabelProps>
const getLabel = (itemFromSearch, item) => {
  return (
    <label>
      {itemFromSearch ? (
        <Highlight attribute="label" hit={item} />
      ) : (
        <span>
          {item.label}
          {/* FIXME: Figure out a better way to style this. */}
          <span
            className="ais-RefinementList-count"
            style={{
              marginLeft: '1em',
              minWidth: '2em',
              minHeight: '2em',
              textAlign: 'center',
              borderRadius: '500rem',
            }}
          >
            {item.count}
          </span>
        </span>
      )}
    </label>
  );
};

export const CustomRefinementList = connectRefinementList(
  ({ items, isFromSearch, refine, title = null }) => {
    if (!items.length) return null;
    return (
      <List size="tiny">
        {title && <List.Header as="h5">{title}</List.Header>}
        {items.map((item, index) => (
          <List.Item key={index}>
            <Checkbox
              checked={item.isRefined}
              label={getLabel(isFromSearch, item)}
              onClick={() => {
                refine(item.value);
              }}
            />
          </List.Item>
        ))}
      </List>
    );
  }
);
