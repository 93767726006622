import React, { useMemo, useState } from 'react';
import { Button, Grid, Label, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { routes } from '../../../config/routes';
import { invoiceEditSelectionCleared } from '../../../redux/actions/billingActions';

import './Billing.scss';

export { StepsContainer };
const StepStatus = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  ACTIVE: 'active',
};

function StepsContainer({
  CurrentForm,
  Widgets,
  isInvoice = true,
  isEdit = false,
  onNext,
  onDone,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const confirmText = useMemo(() => {
    if (isInvoice) {
      return isEdit ? t('Save the Invoice') : t('Create an Invoice');
    } else {
      return isEdit ? t('Save the Plan') : t('Create a Plan');
    }
  }, [isInvoice, isEdit, t]);

  const cancelPath = useMemo(
    () =>
      isInvoice ? routes.billing.invoices.list : routes.billing.plans.list,
    [isInvoice]
  );
  const editPath = useMemo(
    () =>
      isInvoice ? routes.billing.invoices.edit : routes.billing.plans.edit,
    [isInvoice]
  );

  return (
    <Grid stackable reversed={'computer tablet'}>
      <Grid.Row>
        <Grid.Column computer={5} mobile={16} tablet={16}>
          <Steps
            confirmText={confirmText}
            cancelPath={cancelPath}
            editPath={editPath}
            isInvoice={isInvoice}
            onDone={onDone}
            onNext={onNextLocal}
          />
          {Widgets && <Widgets />}
        </Grid.Column>
        <Grid.Column
          computer={11}
          mobile={16}
          tablet={14}
          className={'leftCol'}
        >
          {CurrentForm && <CurrentForm key={'currentForm'} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
  function onNextLocal(hash) {
    if (onNext && onNext(true)) {
      history.push(editPath + hash);
    }
  }
}

function Steps({
  confirmText,
  editPath,
  cancelPath,
  disableNext = false,
  onDone,
  onNext,
  loading = false,
}) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const hash = location.hash ?? routes.billing.hashes.details;

  const { t } = useTranslation();

  const steps = useMemo(() => getSteps(t), [t]);
  const activeStepIndex = useMemo(
    () => getActiveStepIndex(steps, hash),
    [steps, hash]
  );

  const [maxStepIndex, setMaxStepIndex] = useState(0);
  if (activeStepIndex > maxStepIndex) setMaxStepIndex(activeStepIndex);

  const isConfirm = activeStepIndex === steps.length - 1;

  const actionButtonContent = isConfirm ? confirmText : t('Next');

  return (
    <Segment>
      <Grid className={'steps-wrapper'}>
        <Grid.Row columns={4}>
          {steps.map((step, index) => {
            const stepStatus =
              index === activeStepIndex
                ? StepStatus.ACTIVE
                : index < activeStepIndex
                ? StepStatus.ENABLED
                : StepStatus.DISABLED;
            const color = stepStatus === StepStatus.ACTIVE ? 'blue' : 'grey';
            return (
              <Grid.Column key={index} className={`step-wrapper ${stepStatus}`}>
                {stepStatus === StepStatus.DISABLED && (
                  <StepLabel index={index} label={step.label} />
                )}
                {stepStatus !== StepStatus.DISABLED && (
                  <Link to={editPath + step.hash}>
                    <StepLabel index={index} label={step.label} color={color} />
                  </Link>
                )}
              </Grid.Column>
            );
          })}
        </Grid.Row>
        <Grid.Row>
          <Button
            disabled={disableNext}
            loading={loading}
            size="large"
            primary
            fluid
            content={actionButtonContent}
            className={'steps-button'}
            onClick={(e) => {
              e.preventDefault();
              if (isConfirm) {
                if (onDone) onDone();
              } else {
                const hash = steps[activeStepIndex + 1].hash;
                if (onNext) onNext(hash);
              }
            }}
          />
          <Button
            loading={loading}
            size="large"
            basic
            fluid
            content={t('Cancel')}
            className={'steps-button'}
            onClick={(e) => {
              e.preventDefault();
              dispatch(invoiceEditSelectionCleared());
              history.push(cancelPath);
            }}
          />
        </Grid.Row>
      </Grid>
    </Segment>
  );
}
function StepLabel({ index, label, color = 'grey' }) {
  return (
    <div className="step-label">
      <Label circular className={'step-number'} color={color}>
        {index + 1}
      </Label>
      <div className={'step-title'}>{label}</div>
    </div>
  );
}

function getActiveStepIndex(steps, hash) {
  return !hash ? 0 : steps.findIndex((step) => step.hash === hash);
}

function getSteps(t) {
  return [
    {
      hash: routes.billing.hashes.details,
      label: t('Details'),
    },
    {
      hash: routes.billing.hashes.items,
      label: t('Charges'),
    },
    {
      hash: routes.billing.hashes.students,
      label: t('Students'),
    },
    {
      hash: routes.billing.hashes.confirm,
      label: t('Confirm'),
    },
  ];
}
