import { organizationPermissionsOnListen } from '../../api/firebase/_.permissions';
import { PERMISSION_CHANGED, PERMISSIONS_LISTENER_STARTED } from './types';

export const permissionListenerStarted = () => ({
  type: PERMISSIONS_LISTENER_STARTED,
});

export const organizationPermissionChanged = permissions => ({
  type: PERMISSION_CHANGED,
  permissions,
});

export const startOrganizationPermissionsListener = organizationId => dispatch => {
  dispatch(permissionListenerStarted());
  return organizationPermissionsOnListen(
    organizationId,
    data => {
      if (data) dispatch(organizationPermissionChanged(data));
    },
    error => console.log(error.message)
  );
};
