import { omit } from 'lodash';
import {
  INVOICE_PLANS_FETCHED,
  INVOICE_SELECTION_CLEARED,
  INVOICE_SELECTED,
  INVOICE_REFRESHED,
  INVOICE_REMOVE_FROM_LIST,
  INVOICE_UPDATED,
  INVOICES_FETCHED,
} from '../../actions/types';

const initialState = {
  isLoaded: false,
  invoiceType: 'all',
  recurring: {
    list: [],
    last: {},
    count: 0,
    selected: null,
  },
  all: {
    list: {},
    last: {},
    count: 0,
    selected: null,
  },
};

function createNewInvoicesStateFromArray (state, action) {
  const list = action.invoices.list;
  const last = list.length ? list[list.length-1] : null;
  const invoiceType = action.invoiceType || "all";

  return {
    ...state,
    [invoiceType]: {
      ...state[invoiceType],
      list,
      last,
      count: action.invoices.count,
    },
    invoiceType: invoiceType,
    isLoaded: true,
  }

}

function createNewInvoicesStateFromObject (state, action) {
  const list = action.invoices.data;
  const last = action.invoices.lastDocument;
  const invoiceType = action.invoiceType || "all";

  return {
    ...state,
    [invoiceType]: {
      ...state[invoiceType],
      list,
      last,
      count: Object.keys(list).map((k) => k).length,
    },
    invoiceType: invoiceType,
    isLoaded: true,
  };
}
function createNewInvoicesState (state, action) {
  const isArray = Array.isArray (action.invoices.list);
  const isObject = !!action.invoices.data;

  if (isArray) {
    return createNewInvoicesStateFromArray (state, action)

  } else if (isObject) {
    return createNewInvoicesStateFromObject (state, action);

  } else {
    return { ...state }
  }
}

const invoices = (state = initialState, action) => {
  const invoiceType = action.invoiceType || 'all';

  switch (action.type) {
    case INVOICES_FETCHED:
      return createNewInvoicesState (state, action);

    case INVOICE_PLANS_FETCHED: {
      const list = action.invoices.list;
      const last = action.invoices.lastDocument;
      const invoiceType = action.invoiceType;

      return {
        ...state,
        [invoiceType]: {
          ...state[invoiceType],
          list,
          last,
          count: action.invoices.count,
          selected: {},
        },
      };
    }

    case INVOICE_SELECTED: {
      return {
        ...state,
        [invoiceType]: {
          ...state[invoiceType],
          selected: action.invoice,
        },
      };
    }

    case INVOICE_SELECTION_CLEARED: {
      return {
        ...state,
        [invoiceType]: {
          ...state[invoiceType],
          selected: initialState[invoiceType].selected,
        },
      };
    }

    case INVOICE_REFRESHED: {
      const invoice = action.invoice;
      if (invoice && invoice.id && invoice.name)
        return {
          ...state,
          [invoiceType]: {
            ...state[invoiceType],
            list: { ...state.list, [invoice.id]: invoice },
            selected: initialState[invoiceType].selected,
          },
        };
      // No change return the previous state;
      return state;
    }

    case INVOICE_REMOVE_FROM_LIST: {
      return { ...state, list: omit(state.list, [action.id]) };
    }

    case INVOICE_UPDATED: {
      return {
        ...state,
        [action.invoiceType]: {
          ...state[action.invoiceType],
          list: {
            ...state[action.invoiceType].list,
            [action.invoice.id]: action.invoice,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default invoices;
