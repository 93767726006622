import _ from 'lodash';
import { organizationRolesOnListen } from '../../api/firebase/_.roles';
import { ROLES_CHANGED, ROLES_LISTENER_STARTED } from './types';
import store from '../store';

export const roleListenerStarted = () => ({
  type: ROLES_LISTENER_STARTED,
});

export const organizationRoleChanged = roles => ({
  type: ROLES_CHANGED,
  roles,
});

export const getRoleById = id => {
  if (!id) return null;
  const roles = store.getState().roles;
  return roles[id] || null;
};

export const getParentRoles = () => {
  const roles = store.getState().roles;
  if (_.isEmpty(roles)) return null;
  return _.pickBy(roles, { parentRole: true }) || null;
};

export const getStaffRoles = () => {
  const roles = store.getState().roles;
  if (_.isEmpty(roles)) return null;
  return _.pickBy(roles, { staffRole: true }) || null;
};

export const startOrganizationRolesListener = organizationId => dispatch => {
  dispatch(roleListenerStarted());
  return organizationRolesOnListen(
    organizationId,
    data => {
      if (data) dispatch(organizationRoleChanged(data));
    },
    error => console.log(error.message)
  );
};
