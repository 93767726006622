import moment from 'moment';
import { DefaultDateFormat } from './dates';
import { invoicePayoutStatusFormatter, PAYOUT_STATUS } from './payouts';

export const InvoiceStatus = {
  DRAFT: 'draft',
  OPEN: 'open',
  PAID: 'paid',
  UNCOLLECTIBLE: 'uncollectible',
  VOID: 'void',
  SCHEDULED: 'scheduled',
  FAILED: 'failed',
  PROCESSING: 'processing',
};

export const INVOICE_UNSUCCESSFUL_STATUSES = [
  InvoiceStatus.FAILED,
  InvoiceStatus.VOID,
  InvoiceStatus.UNCOLLECTIBLE,
];

export const DiscountAmountType = {
  PERCENT: 'percent',
  CURRENCY: 'currency',
};

const getFailurePopupText = (invoiceId, failureMessage, nextPaymentAttempt) => {
  if (!failureMessage && !nextPaymentAttempt) return `Please contact support (invoice ${invoiceId})`;

  const nextAttemptString = nextPaymentAttempt
    ? `Payment will be retried on ${moment(nextPaymentAttempt).format(DefaultDateFormat)}`
    : '';

  return `${failureMessage || ''} ${nextAttemptString}`.trim();
}

export function invoiceStatusFormatter(invoice, t) {
  const {
    paidAmount,
    status,
    total,
    payout,
    emailNotifications,
    failureMessage,
    nextPaymentAttempt,
    id,
    manuallyPaid,
  } = invoice;

  const hasPayout = Boolean(payout?.status);
  if (hasPayout && !isInvoiceUnsuccessful(invoice)) {
    return invoicePayoutStatusFormatter(payout);
  }

  const hasSentEmailNotification = !!(emailNotifications && emailNotifications.length > 0);

  switch (status) {
    case hasSentEmailNotification && InvoiceStatus.OPEN:
    case hasSentEmailNotification && InvoiceStatus.SCHEDULED:
        return { text: 'Notification sent', color: 'blue', popupText: t('notificationInvoicePopup') };

    case InvoiceStatus.DRAFT:
      return { text: 'New', color: 'grey', popupText: 'newInvoicePopup' };

    case InvoiceStatus.VOID:
      return { text: 'Void', color: 'red' };

    case InvoiceStatus.UNCOLLECTIBLE:
      return { text: 'Uncollectible', color: 'pink', popupText: getFailurePopupText(id, failureMessage, nextPaymentAttempt) };

    case InvoiceStatus.FAILED:
      return { text: 'Failed', color: 'red', popupText: getFailurePopupText(id, failureMessage, nextPaymentAttempt) };

    case InvoiceStatus.OPEN:
      return { text: 'Invoice sent', color: 'blue', popupText: 'sentInvoicePopup' };

    case InvoiceStatus.SCHEDULED:
      return { text: 'New', color: 'yellow', popupText: 'scheduledInvoicePopup' };

    case InvoiceStatus.PAID:
      if (paidAmount > 0 && paidAmount < total)
        return { text: 'Partially paid', color: 'orange', popupText: 'partiallyPaidInvoicePopup' };

      const popupText = manuallyPaid ? 'paidOfflineInvoicePopup' : 'paidOnlineInvoicePopup';

      return { text: `Paid${manuallyPaid ? '(O)' : ''}`, color: 'green', popupText };

    default: {
      return { text: 'Invoice sent', color: 'blue', popupText: 'sentInvoicePopup' };
    }
  }
}

export const calculateTotalFromInvoiceItems = (invoiceItems) => {
  let total = 0;
  invoiceItems.forEach((item) => {
    total += item.amount;
    item.discounts.forEach((discount) => {
      const { currencyAmount } = calculateDiscountAmounts(discount, item.amount);
      total -= currencyAmount;
    });
  });
  return total;
}

// The 'amount' property of a discount can either be an amount, or a percentage, depending
// on the value of the 'amountType' property.
// Return an object shaped like this:{currency, percent}, where we calculate either the
// amount or the percentage based on the value of the 'amountType' property.
export function calculateDiscountAmounts(discount, invoiceItemAmount) {
  const totalAmount = invoiceItemAmount ?? 0;
  if (isDiscountPercent(discount)) {
    if (!discount.amount) return { percentAmount: 0, currencyAmount: 0 };
    else
      return {
        percentAmount: discount.amount,
        currencyAmount: totalAmount * (discount.amount / 100),
      };
  } else {
    // it's currency
    if (!totalAmount) return { percentAmount: 0, currency: discount.amount };
    else
      return {
        percentAmount: (discount.amount / totalAmount) * 100,
        currencyAmount: discount.amount,
      };
  }
}
export function isDiscountPercent(discount) {
  return discount?.amountType === DiscountAmountType.PERCENT;
}
export function isDiscountCurrency(discount) {
  return (
    !discount.amountType || discount.amountType === DiscountAmountType.CURRENCY
  );
}

export function getUpcomingInvoices(invoices) {
  if (!invoices) return [];

  return invoices.filter((invoice) => {
    return (
      invoice?.status !== InvoiceStatus.PAID &&
      invoice?.status !== InvoiceStatus.VOID &&
      invoice?.status !== InvoiceStatus.UNCOLLECTIBLE
    );
  });
}

export function isInvoiceDue(invoice) {
  if (!invoice?.dateDue) return false;

  const now = moment().startOf('day');
  const dueDate = moment(invoice.dateDue).startOf('day');
  return dueDate.isBefore(now);
}

export function getPastDueInvoices(invoices) {
  if (!invoices) return [];

  return invoices.filter((invoice) => isInvoiceDue(invoice));
}

export const isInvoiceUnsuccessful = (invoice) => {
  return INVOICE_UNSUCCESSFUL_STATUSES.some(
    (status) => status === invoice.status
  );
};

export const isInvoicePaid = (invoice) => {
  if (isInvoiceUnsuccessful(invoice)) return false;

  const { payout, manuallyPaid, status } = invoice;
  const hasPayoutStatus = !!payout?.status;
  if (hasPayoutStatus) return payout.status === PAYOUT_STATUS.PAID;
  return !Boolean(manuallyPaid) && status === InvoiceStatus.PAID;
};

export const isInvoiceProcessing = (invoice) => {
  if (isInvoiceUnsuccessful(invoice)) return false;

  const { payout } = invoice;
  const hasPayoutStatus = !!payout?.status;
  return (
    hasPayoutStatus &&
    (payout.status === PAYOUT_STATUS.PENDING ||
      payout.status === PAYOUT_STATUS.IN_TRANSIT)
  );
};
