import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useUser } from './useUser';
// Import actions
import { invoicePlansFetched } from '../redux/actions/invoiceActions';
import { fetchOrganizationInvoicePlans } from '../api/firebase/invoices';

export function useInvoicePlans(organizationId) {
  const dispatch = useDispatch();
  const { isLocationAdminHighestClaim, locations } = useUser();
  const [loading, setLoading] = useState(false);
  const [invoicePlans, setInvoicePlans] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const doFetch = async () => {
      try {
        setLoading(true);

        const conditions = []

        // if highest claim is locationAdmin && locations are assigned
        if (isLocationAdminHighestClaim && locations) {
          conditions.push({
            field: 'location',
            operation: 'in',
            value: Object.keys(locations)
          })
        }

        const _invoicePlans = await fetchOrganizationInvoicePlans(
          organizationId,
          conditions
        )

        setInvoicePlans(_invoicePlans);

        dispatch(invoicePlansFetched(_invoicePlans));
      } catch (err) {
        console.log(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    if (!loading && !invoicePlans) {
      doFetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);
  return { loading, error, invoicePlans };
}
