import React, { useState, useRef } from 'react';
import Fuse from 'fuse.js';
import { Button, Grid, Header, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { addError } from '../../../helpers/errors';
import {
  useInvoiceEditStudents,
  useInvoiceEditDetails,
} from '../../../hooks/useInvoiceEdit';
import { useStudents } from '../../../students/studentsHooks';

// Import components
import SectionCard from '../../Shared/SectionCard';
import RoomPicker from '../../Rooms/RoomPicker';
import ShowError from '../../Messages/ShowError';

import { StepsContainer } from './Steps';
import { InvoiceItemsWidget, StudentsListWidget } from './Widgets';
import './Billing.scss';
import { EmployeesList } from './SelectEmployees/EmployeesList';

export default function StepStudents({ isInvoice, onSave }) {
  const { t } = useTranslation();
  const details = useInvoiceEditDetails();
  const students = useStudents();
  const _selectedStudents = useInvoiceEditStudents();
  const [selectedStudents, setSelectedStudents] = useState(
    _selectedStudents ?? []
  );
  const filteredStudentsRef = useRef(students?.list ?? []);

  const [errors, setErrors] = useState();
  const allowEditStudents = isInvoice || (!details.uid && !details.id);

  return (
    <>
      <StepsContainer
        isInvoice={isInvoice}
        CurrentForm={StudentsSelectForm}
        Widgets={Widgets}
        onNext={onSaveLocal}
      />
    </>
  );

  function StudentsSelectForm() {
    const [filteredStudents, setFilteredStudents] = useState(
      filteredStudentsRef.current ?? []
    );

    return (
      <SectionCard header={t('Select students')}>
        <ShowError errors={errors} />

        <SearchBar
          allStudents={students.list}
          filteredStudents={filteredStudents}
          selectedStudents={selectedStudents}
          onFilterStudents={onFilterStudents}
          onSelectAll={onSelectAll}
          onUnselectAll={onUnselectAll}
          showSelectUnselectAll={allowEditStudents}
        />
        <EmployeesList
          selectedEmployees={selectedStudents}
          onSelect={onToggleSelect}
          employeesList={filteredStudents}
        />
      </SectionCard>
    );
    function onFilterStudents(_filteredStudents) {
      filteredStudentsRef.current = _filteredStudents;
      setFilteredStudents(_filteredStudents);
    }
    function onSelectAll() {
      const validStudents = filteredStudents.filter(
        ({ preventInvoiceReason }) => !preventInvoiceReason
      );
      setSelectedStudents([...validStudents]);
      setErrors(null);
    }
  }

  function Widgets() {
    return (
      <>
        <StudentsListWidget
          students={selectedStudents}
          onRemove={onRemoveStudent}
          allowDelete={allowEditStudents}
        />
        <InvoiceItemsWidget />
      </>
    );
  }

  function onUnselectAll() {
    setSelectedStudents([]);
  }
  function onToggleSelect(student) {
    if (!allowEditStudents) return;

    let index = selectedStudents.findIndex((s) => student.id === s.id);
    if (index === -1) {
      setSelectedStudents([...selectedStudents, student]);
    } else {
      setSelectedStudents(selectedStudents.filter((s) => s.id !== student.id));
    }
    setErrors(null);
  }
  function onRemoveStudent(student) {
    if (!allowEditStudents) return;
    setSelectedStudents(selectedStudents.filter((s) => s.id !== student.id));
  }
  function onSaveLocal(doValidate = true) {
    const errors = doValidate ? validate() : false;

    if (errors) {
      setErrors(errors);
      return false;
    } else if (onSave) {
      onSave(selectedStudents);
      return true;
    }
  }
  function validate() {
    let errors = null;
    if (!selectedStudents?.length)
      errors = addError(
        errors,
        t('Students'),
        t('Please select at least one student')
      );
    return errors;
  }
}

function SearchBar({
  allStudents,
  filteredStudents,
  selectedStudents,
  onFilterStudents,
  onSelectAll,
  onUnselectAll,
  showSelectUnselectAll,
}) {
  const { t } = useTranslation();

  const roomRef = useRef('');
  const [room, setRoom] = useState(roomRef.current);

  const [searchTerm, setSearchTerm] = useState('');
  const headerText = allStudents.length === 1 ? t('Student') : t('Students');
  const validStudentsLength = filteredStudents.filter(
    ({ preventInvoiceReason }) => !preventInvoiceReason
  ).length;
  const isSelectAll = selectedStudents.length !== validStudentsLength;

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={12}>
          <Input
            name={'searchTerm'}
            value={searchTerm}
            onChange={onChangeSearch}
            placeholder={t('Search...')}
            fluid
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <RoomPicker
            selection
            search
            fluid
            onChange={onChangeRoom}
            placeholder={t('Room')}
            name={'room'}
            value={room}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          <Header as="h2">
            {t('{{count}} {{headerText}}', {
              count: filteredStudents.length,
              headerText,
            })}
          </Header>
        </Grid.Column>
        {showSelectUnselectAll ? (
          <Grid.Column width={4}>
            {validStudentsLength > 0 && isSelectAll && (
              <Button floated="right" onClick={onSelectAllLocal}>
                {t('Select All')}
              </Button>
            )}
            {validStudentsLength > 0 && !isSelectAll && (
              <Button floated="right" onClick={onUnselectAllLocal}>
                {t('Unselect All')}
              </Button>
            )}
          </Grid.Column>
        ) : null}
      </Grid.Row>
    </Grid>
  );
  function onSelectAllLocal(e) {
    e.preventDefault();
    onSelectAll(e);
  }
  function onUnselectAllLocal(e) {
    e.preventDefault();
    onUnselectAll(e);
  }
  function onChangeRoom(e, { value }) {
    setRoom(value);
    roomRef.current = value;
    onFilterStudents(filterStudents(allStudents, value, searchTerm));
  }

  function onChangeSearch(e, { value }) {
    setSearchTerm(value);
    onFilterStudents(filterStudents(allStudents, room, value));
  }
}

function filterStudents(studentsList, room, searchTerm) {
  let filteredStudents = studentsList;

  if (room) {
    filteredStudents = studentsList.filter((s) => {
      return (s.rooms || []).includes(room);
    });
  }
  if (searchTerm) {
    const fuse = new Fuse(filteredStudents, {
      includeScore: true,
      threshold: 0.35,
      keys: ['displayName', 'firstName', 'middleName', 'lastName', 'fullName'],
    });
    filteredStudents = fuse.search(searchTerm).map((s) => s.item);
  }
  return filteredStudents;
}
