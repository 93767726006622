export const UNKNOWN_TEXT = 'Unknown';

export const PAGE_NAMES = {
  dashboard: 'Dashboard',
  devices: 'Devices',
  error: 'Error',
  featureFlags: 'Feature Flags',
  importer: 'Importer',
  invoicePlanEdit: 'Invoice Plan Edit',
  invoicePlans: 'Invoice Plans',
  invoiceReports: 'Invoice Reports',
  invoices: 'Invoices',
  locations: 'Locations',
  organizationDetail: 'Organization Detail',
  organizations: 'Organizations',
  profile: 'Profile',
  rooms: 'Rooms',
  staff: 'Staff',
  students: 'Students',
  transactions: 'Transactions',
  home: 'Home',
  finance: 'Finance',
  formsAndDocuments: 'Forms & Documents',
};
export const PAGE_CATEGORIES = {
  default: 'CCMS 2.0 Page',
};

export const SEGMENT_EVENTS = {
  parentInvited: 'Parent Invited',
  parentUpdated: 'Parent Updated',
  staffInvited: 'Staff Invitation Created',
  staffCreated: 'Staff Created',
  staffUpdated: 'Staff Updated',
  studentCreated: 'Student Created',
  transactionCreated: 'Transaction Created',
};
