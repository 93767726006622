import { createContext } from 'react';

const defaultFlagObject = {
  name: '',
  enabled: false,
  toggle: () => {},
};

export const FeatureFlagsContext = createContext({
  /** @type {boolean} */
  FeatureFlagsUIFlag: { ...defaultFlagObject }, // whether to show flags ui
  RollbarFlag: { ...defaultFlagObject }, // whether to use rollbar logging
  MainMenuBillingPages: {...defaultFlagObject}, // wheather to show billing pages on main menu
});
