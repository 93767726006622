import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Input,
  Segment,
} from 'semantic-ui-react';
import BootstrapTable from 'react-bootstrap-table-next';
import Avatar from 'react-avatar';

import RoomPicker from '../../Components/Rooms/RoomPicker';
import withSlidingPanel from '../../Components/Shared/withSlidingPanel';
import StudentDetail from './student/StudentDetail';
import ShowSuccess from '../../Components/Messages/ShowSuccess';

import { formatFullName, getAgeFromBirthday } from '../../helpers/utils';

import useRooms from '../../hooks/useRooms';
import { usePendingParentInvitationsListener } from '../../hooks/useInvitations'
import { useOrganizationUsersListener, useOrganization } from '../../hooks/useOrganizations'

import { useStudents } from '../studentsHooks';
import { studentSelectionCleared } from '../studentsRedux';

import StudentAddForm from './student/StudentAddForm';
import styles from './students.module.scss';

const emptyTableCell = <div className={styles.studentDataPlaceholder}>-</div>;

const SLIDER_WIDTH = '85%';

const SlidingStudentDetail = withSlidingPanel(StudentDetail, {
  width: SLIDER_WIDTH,
});

const PageTitle = ({ state, setState }) => {
  const { t } = useTranslation();
  const { list: students, count } = useStudents(state);

  if (!students?.length && !state.filters) {
    return (
      <Segment basic clearing>
        <Header as="h1" floated="left" content={t('Students')} />
      </Segment>
    );
  }

  return (
    <Segment basic clearing>
      <div className={styles.studentPageTitleWrap}>
        <div>
          <Header as="h1">
            {t('Students ({{studentsCount}})', {
              studentsCount: count,
            })}
          </Header>
        </div>

        <div>
          <Button primary as={Link} to={`/import/students`}>
            {t('Import students')}
          </Button>

          <StudentAddForm setState={setState} />
        </div>
      </div>
    </Segment>
  );
};

const PageFilters = ({ state, setState }) => {
  const { t } = useTranslation();
  const currentOrganization = useOrganization();

  return (
    <div className={styles.studentFilterRow}>
      {/* enrollment status */}
      <Dropdown
        placeholder={t('All Statuses')}
        selection
        clearable
        value={state.filters?.enrollmentStatus}
        onChange={(_, { value }) => {
          setState((prev) => ({
            ...prev,
            filters: { ...prev.filters, enrollmentStatus: value },
          }));
        }}
        options={[
          { key: 'E', value: 'active', text: t('Enrolled') },
          { key: 'U', value: 'unenrolled', text: t('Unenrolled') },
        ]}
      />

      {/* Should not be visible for familyChildCare program types. */}
      {currentOrganization?.programType !== 'familyChildCare' && (
        <RoomPicker
          selection
          search
          clearable
          value={state.filters?.room || ''}
          placeholder={t('All Rooms')}
          onChange={(_, { value }) => {
            setState((prev) => ({
              ...prev,
              filters: { ...prev.filters, room: value },
            }));
          }}
          customOptions={[
            {
              key: 'no-room',
              text: t('No room'),
              value: 'no-room',
            },
          ]}
        />
      )}

      {/* age filter */}
      <Dropdown
        className={styles.studentAgeDropdown}
        placeholder={t('Age')}
        selection
        multiple
        clearable
        onChange={(_, { value }) => {
          setState((prev) => ({
            ...prev,
            filters: { ...prev.filters, age: value },
          }));
        }}
        options={[
          { key: '0', value: '0', text: '0-1' },
          { key: '1', value: '1', text: '1-2' },
          { key: '2', value: '2', text: '2-3' },
          { key: '3', value: '3', text: '3-4' },
          { key: '4', value: '4', text: '4-5' },
          { key: '5', value: '5', text: '5-6' },
          { key: '6', value: '6', text: '6-7' },
          { key: '7', value: '7', text: '7-8' },
          { key: '8', value: '8', text: '8-9' },
          { key: '9', value: '9', text: '9-10' },
          { key: '10', value: '10', text: '10-11' },
          { key: '11', value: '11', text: '11-12' },
          { key: '12', value: '12', text: '12+' },
        ]}
      />

      {/* schedule filter */}
      <Dropdown
        placeholder={t('Schedule')}
        multiple
        selection
        onChange={(_, { value }) => {
          setState((prev) => ({
            ...prev,
            filters: { ...prev.filters, schedule: value },
          }));
        }}
        options={[
          { key: 'M', value: 'M', text: t('Monday') },
          { key: 'T', value: 'T', text: t('Tuesday') },
          { key: 'W', value: 'W', text: t('Wednesday') },
          { key: 'Th', value: 'Th', text: t('Thursday') },
          { key: 'F', value: 'F', text: t('Friday') },
          { key: 'S', value: 'S', text: t('Saturday') },
          { key: 'Su', value: 'Su', text: t('Sunday') },
        ]}
      />
    </div>
  );
};

const StudentSearchInput = ({ state, setState }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Input
        icon="search"
        iconPosition="left"
        fluid
        placeholder={t('Search Students / Contacts')}
        onChange={(e) =>
          setState({
            ...state,
            searchText: e.currentTarget.value,
          })
        }
      />
    </div>
  );
};

const SORT_TYPE_CONSTS = {
  str: 'str',
  bool: 'bool',
  combinedFamily: 'combinedFamily',
  date: 'date',
  room: 'room',
  arr: 'arr',
};

const StudentsTableContainer = ({ students, setState }) => {
  const { t } = useTranslation();
  const currentOrganization = useOrganization();

  usePendingParentInvitationsListener(currentOrganization.id);
  useOrganizationUsersListener (currentOrganization.id);

  const [sortControls, setSortControls] = useState(null);
  const rooms = useRooms();
  const dispatch = useDispatch();

  const dispatchStudentSelected = (studentId) => {
    const student = students.find((student) => student.id === studentId);

    dispatch({ type: 'STUDENT_SELECTED', student });
    setState((prev) => ({ ...prev, isEditOpen: true }));
  };

  const columnNameToPropNameDict = {
    ProfileImage: {
      propKey: 'picture',
      headerTextOverride: '',
      conversionFunc: (url) => {
        return <Avatar round size={44} src={url} />;
      },
      additionalFields: {
        headerStyle: (colum, colIndex) => ({
          width: '70px',
        }),
      },
    },
    Name: {
      propKey: 'displayName',
      sortType: SORT_TYPE_CONSTS.str,
      sortable: true,
    },
    Room: {
      propKey: 'rooms',
      sortType: SORT_TYPE_CONSTS.room,
      sortable: true,
      hideForFcc: true,
      conversionFunc: (studentRoomIds) => {
        const noRoom = t('No room');
        if (!studentRoomIds) {
          return noRoom;
        }
        const room = rooms?.find(
          (room) => studentRoomIds?.indexOf(room.id) > -1
        );
        return room ? room.name : noRoom;
      },
    },
    Status: {
      propKey: 'enrollmentStatus',
      sortType: SORT_TYPE_CONSTS.bool,
      sortable: true,
      conversionFunc: (enrollmentStatus) =>
        enrollmentStatus ? t('Enrolled') : t('Unenrolled'),
    },
    Schedule: {
      propKey: 'schedule',
      conversionFunc: (schedule) => schedule?.join('') || emptyTableCell,
    },
    Age: {
      propKey: 'birthday',
      sortType: SORT_TYPE_CONSTS.date,
      sortable: true,
      conversionFunc: (birthday) => {
        // returns null or object of { years: x, months: x }
        const age = getAgeFromBirthday(birthday);

        if (!age) return emptyTableCell;

        // this covers babies with an assigned birthday that are less than 1 month old
        if (age.years === 0 && age.months === 0 && birthday) {
          return `< 1 ${t('mon')}`;
        }

        const yearSuffix = age.years && age.years > 1 ? t('yrs') : t('yr');
        const monthSuffix = t('mon');
        const { months, years } = age;
        // x months && 0 years
        if (months && !years) {
          return `${months} ${monthSuffix}`;
        }

        // x years && 0 months
        if (years && !months) {
          return `${years} ${yearSuffix}`;
        }

        // everything else
        return `${years} ${yearSuffix} ${months} ${monthSuffix}`;
      },
    },
    Allergies: {
      propKey: 'allergies',
    },
    Contacts: {
      //  note this field is generated by the useUser hook
      propKey: 'combinedFamily',
      sortType: SORT_TYPE_CONSTS.combinedFamily,
      sortable: true,
      conversionFunc: (combinedFamily) => {
        if (!combinedFamily) {
          return emptyTableCell;
        }

        return Object.values(combinedFamily).map((member, i) => (
          <div key={i} className={styles.studentContactCellDiv}>
            {member.displayName
              ? member.displayName
              : formatFullName(member, true)}
          </div>
        ));
      },
    },
  };

  const updateSortControls = (colHeader, order) => {
    setSortControls({
      col: colHeader,
      isReverseOrder: order === 'desc',
    });
  };

  const localeCompareSortHelper = (a, b) => {
    if (!b) {
      return -1;
    }
    if (!a) {
      return 1;
    }
    return a.localeCompare(b);
  };

  const sortStudents = () => {
    if (!sortControls?.col) {
      return [...students];
    }

    let sorted = [...students];
    const { sortType, propKey } = columnNameToPropNameDict[sortControls.col];

    if (sortType === SORT_TYPE_CONSTS.str) {
      sorted.sort((a, b) => localeCompareSortHelper(a[propKey], b[propKey]));
    } else if (sortType === SORT_TYPE_CONSTS.bool) {
      sorted.sort((a, b) =>
        !(a[propKey] ^ b[propKey]) ? 0 : a[propKey] ? -1 : 1
      );
    } else if (sortType === SORT_TYPE_CONSTS.combinedFamily) {
      sorted.sort((a, b) => {
        // family is an object, so we need to get the prop keys array
        const aKeysArr = a[propKey] ? Object.keys(a[propKey]) : [];
        const bKeysArr = b[propKey] ? Object.keys(b[propKey]) : [];
        // make a string out of the student family object displayNames
        const aString = aKeysArr.length
          ? aKeysArr.map((key) => a[propKey][key].displayName).join(' ')
          : '';
        const bString = bKeysArr.length
          ? bKeysArr.map((key) => b[propKey][key].displayName).join(' ')
          : '';

        return localeCompareSortHelper(aString, bString);
      });
    } else if (sortType === SORT_TYPE_CONSTS.room) {
      sorted.sort((a, b) => {
        const roomA = rooms?.find(
          (room) => a.rooms?.indexOf(room.id) > -1
        )?.name;
        const roomB = rooms?.find(
          (room) => b.rooms?.indexOf(room.id) > -1
        )?.name;
        return localeCompareSortHelper(roomA, roomB);
      });
    } else if (sortType === 'date') {
      sorted.sort((a, b) => {
        const parsedA = new Date(a[propKey]);
        const parsedB = new Date(b[propKey]);

        if (isNaN(parsedB)) {
          return -1;
        }
        if (isNaN(parsedA)) {
          return 1;
        }
        return parsedB - parsedA;
      });
    } else if (sortType === SORT_TYPE_CONSTS.arr) {
      sorted.sort(
        (a, b) => (a[propKey]?.length || 0) - (b[propKey]?.length || 0)
      );
    }

    // reverse if clicking same header
    if (sortControls.isReverseOrder) {
      return [...sorted.reverse()];
    }

    return sorted;
  };

  const sortedStudents = sortStudents();

  const data = sortedStudents.map((student) => {
    let baseRowData = {};

    for (const key in columnNameToPropNameDict) {
      const currentColumnObject = columnNameToPropNameDict[key];
      const currentStudentPropValue = student[currentColumnObject.propKey];

      if (currentColumnObject.conversionFunc) {
        baseRowData[key] = currentColumnObject.conversionFunc(
          currentStudentPropValue
        );
      } else if (currentStudentPropValue) {
        baseRowData[key] = currentStudentPropValue;
      } else {
        baseRowData[key] = emptyTableCell;
      }
    }

    return {
      id: student.id,
      edit: (
        <div className={`${styles.studentTableEditCell} pseudo-link`}>
          {t('Edit')}
        </div>
      ),
      ...baseRowData,
    };
  });

  const columns = [
    ...Object.keys(columnNameToPropNameDict)
      .map((colName) => {
        // return null for Rooms if FCC
        if (
          currentOrganization?.programType === 'familyChildCare' &&
          columnNameToPropNameDict[colName].hideForFcc
        ) {
          return null;
        }

        return {
          dataField: colName,
          text: columnNameToPropNameDict[colName].hasOwnProperty(
            'headerTextOverride'
          )
            ? columnNameToPropNameDict[colName].headerTextOverride
            : t(colName),
          sort: columnNameToPropNameDict[colName].sortable,
          onSort: (field, order) => {
            updateSortControls(colName, order);
          },
          // override the library sort functionality as we handle it ourselves above
          sortFunc: () => 0,
          // add any additional custom fields in this prop
          ...columnNameToPropNameDict[colName].additionalFields,
        };
      })
      // remove null if FCC org type
      .filter((x) => x),
    {
      dataField: 'edit',
      text: '',
    },
  ];

  return (
    <Segment basic clearing className="bootstrap-iso">
      <BootstrapTable
        bootstrap4
        keyField="id"
        bordered={false}
        hover
        classes="w-auto w-md-100"
        wrapperClasses="table-responsive"
        headerClasses="table-header"
        defaultSortDirection="asc"
        defaultSorted={[
          {
            dataField: 'Name',
            order: 'asc',
          },
        ]}
        data={data}
        columns={columns}
        rowEvents={{
          onClick: (e, row, rowIndex) => dispatchStudentSelected(row.id),
        }}
      />
    </Segment>
  );
};

const StudentAddedBannerWrapper = ({ lastStudentAdded, setState }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { displayName } = lastStudentAdded;

  const dispatchSelectedStudent = () => {
    dispatch({ type: 'STUDENT_SELECTED', student: lastStudentAdded });
    setState((prev) => ({ ...prev, isEditOpen: true }));
  };

  const closeBanner = () => {
    setState((prev) => ({ ...prev, lastStudentAdded: null }));
  };

  return (
    <Segment basic clearing className={styles.studentAddedBannerWrap}>
      <ShowSuccess
        onDismiss={closeBanner}
        hideHeader
        content={
          <div>
            <Trans
              i18nKey="You have added {{displayName}} as a new student."
              displayName={displayName}
            >
              You have added <strong>{{ displayName }}</strong> as a new
              student.
            </Trans>
            &nbsp;
            <span className="pseudo-link" onClick={dispatchSelectedStudent}>
              {t('View student info')}
            </span>
          </div>
        }
      />
    </Segment>
  );
};

const SlidingForms = ({ state, setState }) => {
  const dispatch = useDispatch();
  const { selectedStudent } = useStudents(state);

  let studentName = selectedStudent?.displayName
    ? selectedStudent.displayName
    : `${selectedStudent?.firstName} ${selectedStudent?.lastName}`;

  // Concatenate nickname if it exists.
  if (selectedStudent?.nickName?.length)
    studentName += ` (${selectedStudent?.nickName})`;

  return (
    <div>
      <SlidingStudentDetail
        title={studentName}
        image={selectedStudent?.picture}
        isOpen={state.isEditOpen}
        onClose={() => {
          dispatch (studentSelectionCleared())
          setState((prev) => ({ ...prev, isEditOpen: false }));
        }}
      />
    </div>
  );
};

const StudentsTable = () => {
  const [state, setState] = useState({
    isAddOpen: false,
    isEditOpen: false,
    searchText: '',
    filters: {
      room: '',
      enrollmentStatus: '',
      age: '',
      schedule: [],
    },
    newStudentId: null,
    lastStudentAdded: null,
  });
  const { list, listFiltered } = useStudents(state);

  useEffect(() => {
    if (state.newStudentId) {
      const student = list.find((student) => student.id === state.newStudentId);
      // wait until the new student is in redux
      if (student) {
        setState((prev) => ({
          ...prev,
          newStudentId: false,
          lastStudentAdded: student,
        }));
      }
    }
  }, [state.newStudentId, list]);

  return (
    <>
      <PageTitle state={state} setState={setState} />
      {state.lastStudentAdded && (
        <StudentAddedBannerWrapper
          setState={setState}
          lastStudentAdded={state.lastStudentAdded}
        />
      )}
       <Segment basic clearing>
        <Grid columns={2} stackable>
          <Grid.Column tablet={11} mobile={16} computer={11}>
            <PageFilters state={state} setState={setState} />
          </Grid.Column>
          <Grid.Column tablet={5} mobile={16} computer={5}>
            <StudentSearchInput state={state} setState={setState} />
          </Grid.Column>
        </Grid>
      </Segment>
      <StudentsTableContainer students={listFiltered} setState={setState} />
      <SlidingForms state={state} setState={setState} />
    </>
  );
};

export default StudentsTable;
