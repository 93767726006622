
export const UPLOAD_STATE_CONSTS = {
  uploading: 'uploadingInProgress',
  error: 'uploadingError',
}

export const FILE_ACTIONS_CONSTS = {
  share: 'share',
  rename: 'rename',
  archive: 'archive',
  download: 'download',
  upload: 'upload',
}

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// there are some additional image types, but i'm not sure whether to include to them. 
export const allowedMimeTypes = [
  // word
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // excel
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // pdf
  'application/pdf',
  // images
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
]
