import React, { Component } from 'react';
import { Container, Dimmer, Image, Loader, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import WSA from '../WSA';
import { getTitlePrefix } from '../config/env';
import { routes } from '../config/routes';
import { changeLanguage } from '../i18n';

// need this when we handle sign up
import { userSaveSignUpData } from '../redux/actions/userActions';

// Import components
import wsLogo from '../styles/img/wonderschool/logo-base.png';

class AuthReturn extends Component {
  state = {
    didSdkSignin: false,
    isSignup: false,
  };
  componentDidMount() {
    this.completeSignIn();
  }
  componentDidUpdate() {
    this.completeSignIn();
  }

  completeSignIn = async () => {
    const {
      // from WSAuth
      isReady,
      isAuthenticated,
      // end WSAuth
      history,
      i18n,
      userSaveSignUpData,
    } = this.props;
    const { didSdkSignin, isSignup } = this.state;

    if (isReady && !didSdkSignin) {
      // only try sign in once
      this.setState({ didSdkSignin: 1 }); // intermediate truthy value
      try {
        // sdk sign in
        const removeHashAfter = true;
        const result = await WSA.auth.completeSignin(removeHashAfter);
        if (!result) {
          throw new Error('CompleteSignin Result was empty');
        }
        const {
          user,
          isSignin,
          signinData,
          isSignup,
          signupData,
          selectedLanguage,
          returnState,
        } = result;
        // possibly inject networkId and other pre-signup state
        let returnPayload = Object.assign({ user }, returnState || {});
        // inject sso-generated state
        if (isSignup) {
          returnPayload = Object.assign(returnPayload, {
            isSignup,
            signupData,
          });
        } else {
          // if SSO performs login, fetch the signup data to be consistent
          // may be needed for onboarding
          const savedSignupData = await WSA.signup.getSavedSignupData(
            user.user
          );
          returnPayload = Object.assign(returnPayload, {
            isSignin,
            signinData,
            signupData: savedSignupData,
          });
        }
        if (selectedLanguage && selectedLanguage !== i18n.language) {
          changeLanguage(selectedLanguage);
        }
        // save signup data for later
        userSaveSignUpData(returnPayload.signupData);
        this.setState({ isSignup, didSdkSignin: true });
      } catch (err) {
        console.log(`%c❌ Signin Completion Failed ❌`, 'color: #d81818');
        console.log(err);
        history.replace(routes.authError);
      }
    }

    // need a separate loop to wait for event triggers to update
    if (didSdkSignin === true && isReady && isAuthenticated) {
      if (isSignup) {
        history.replace(routes.verify);
      } else {
        history.replace(routes.home);
      }
    }
  };

  render() {
    const { t } = this.props;
    const renderPageHead = () => (
      <Helmet>
        <title>
          {getTitlePrefix()}
          {t('{{pageName}} - Wonderschool', { pageName: t('Redirecting') })}
        </title>
        <body class="auth auth-return" />
      </Helmet>
    );

    return (
      <Container style={{ width: 500 }}>
        {renderPageHead()}

        <Segment basic>
          <Image src={wsLogo} size="medium" centered />
        </Segment>

        <Dimmer page active inverted>
          <Loader inline="centered">{t('Redirecting ...')}</Loader>
        </Dimmer>
      </Container>
    );
  }
}

const mapStateToProps = () => ({
  //
});
const mapAuthContextToProps = ({ isReady, isAuthenticated }) => {
  return {
    isReady,
    isAuthenticated,
  };
};
export default WSA.components.mapAuthContextToProps(
  mapAuthContextToProps,
  withTranslation()(
    connect(mapStateToProps, {
      userSaveSignUpData,
    })(AuthReturn)
  )
);
