import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Modal,
  Segment,
  Select,
  Popup,
  Icon,
  Checkbox
} from 'semantic-ui-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

// Import components
import InlineError from '../Messages/InlineError';
import ShowError from '../Messages/ShowError';
import LocationPicker from '../Locations/LocationPicker';

// Import actions
import {
  organizationAddRoom,
  organizationUpdateRoom,
  roomSelectionCleared,
} from '../../redux/actions/roomActions';
import { updateSetupStatus } from '../../api/firebase/setup';
import { ageRangeMonths, ageRangeYears } from '../../config';

class RoomForm extends Component {
  state = {
    data: {
      id: '',
      name: '',
      location: this.props.user?.defaultLocation || '',
      enabledActivities: {},
      ratio: '',
      ageRange: {
        from: {
          months: ageRangeMonths[0].value,
          years: ageRangeYears[0].value,
        },
        to: {
          months: ageRangeMonths[0].value,
          years: ageRangeYears[0].value,
        },
      },
      enabled: true,
      usesExactAgeRange: false,
    },
    loading: false,
    errors: {},
    disableModalOpen: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      rooms: { selectedRoom },
    } = nextProps;

    if (!_.isEmpty(selectedRoom.id) && prevState.data.id !== selectedRoom.id) {
      return {
        data: { ...selectedRoom },
      };
    }

    return prevState;
  }

  componentWillUnmount() {
    // Clear selection from store.
    this.props.roomSelectionCleared();
  }

  onChange = (e, { name, value }) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  onDateRangeChange = (e, { name, value }) => {
    //format: "from-years" ... "to-months"
    const keys = name.split('-');

    this.setState({
      data: {
        ...this.state.data,
        ageRange: {
          ...this.state.data.ageRange,
          [keys[0]]: { ...this.state.data.ageRange[keys[0]], [keys[1]]: value },
        },
      },
      errors: _.omit(this.state.errors, 'ageRange'),
    });
  };

  tryToggleRoomEnabled = (e, { name, checked }) => {
    // if we're disabling the room, check if there are students associated
    if (checked === false) {
      const {
        students: { list },
      } = this.props;
      const {
        data: { id },
      } = this.state;

      const roomHasStudent = list?.find((student) => {
        return student.rooms.find((roomId) => roomId === id);
      });

      if (roomHasStudent) {
        this.setState({
          disableModalOpen: true,
        });
        return;
      }
    }

    this.setState({
      data: {
        ...this.state.data,
        enabled: checked,
      },
      errors: _.omit(this.state.errors, name),
    });
  };

  forceDisableRoom = () => {
    this.setState({
      data: {
        ...this.state.data,
        enabled: false,
      },
      errors: _.omit(this.state.errors, 'enabled'),
      disableModalOpen: false,
    });
  };

  validate = (data) => {
    const { t } = this.props;
    const errors = {};

    const { value: fromYears } = _.find(
      ageRangeYears,
      (o) => o.value === data.ageRange?.from.years
    );
    const { value: fromMonths } = _.find(
      ageRangeMonths,
      (o) => o.value === data.ageRange?.from.months
    );
    const { value: toYears } = _.find(
      ageRangeYears,
      (o) => o.value === data.ageRange?.to.years
    );
    const { value: toMonths } = _.find(
      ageRangeMonths,
      (o) => o.value === data.ageRange?.to.months
    );

    if (!data.name) errors.name = t('Name is required');
    if (!data.location) errors.location = t('Location is required');
    if (data.ratio && data.ratio < 1)
      errors.ratio = t(
        'The student ratio for one teacher can not be less than one student.'
      );

    if (fromYears > toYears) {
      errors.ageRange = t('The age range for this room is not set correctly.');
    } else if (fromYears === toYears && fromMonths > toMonths) {
      errors.ageRange = t('The age range for this room is not set correctly.');
    }

    return errors;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const {
        data: { id, ...rest },
      } = this.state;
      const { currentOrganization } = this.props;

      this.setState({ loading: true });

      if (currentOrganization && currentOrganization.id)
        if (id) {
          // Update.
          this.props
            .organizationUpdateRoom(currentOrganization.id, {
              id,
              organization: currentOrganization.id,
              ...rest,
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('updated');
                return;
              }
              this.setState({ loading: false });
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { 'Unable to Update': error.message },
              })
            );
        } else {
          // New entry.
          this.props
            .organizationAddRoom(currentOrganization.id, {
              organization: currentOrganization.id,
              ...rest,
            })
            .then(() => {
              // Update setup flags.
              updateSetupStatus(currentOrganization.id, 'firstRoomCreated');

              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('added');
                return;
              }
              this.setState({ loading: false });
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { 'Unable to Add': error.message },
              })
            );
        }
    }
  };

  render() {
    const { data, loading, errors, disableModalOpen } = this.state;
    const { t } = this.props;

    const translatedAgeMonthOptions = ageRangeMonths.map((x) => {
      x.text = t(x.text);
      return x;
    });
    const translatedAgeYearOptions = ageRangeYears.map((x) => {
      x.text = t(x.text);
      return x;
    });

    return (
      <Segment basic textAlign="left">
        <Form
          id="room-form"
          onSubmit={this.onSubmit}
          loading={loading}
          noValidate
        >
          <ShowError errors={errors} />
          <Form.Field error={!!errors.name}>
            <Form.Input
              required
              type="text"
              id="name"
              name="name"
              label={t('Name')}
              placeholder={t('Room name')}
              value={data.name}
              onChange={this.onChange}
            />
            {errors.name && <InlineError text={errors.name} />}
          </Form.Field>

          <Form.Field error={!!errors.location}>
            <Form.Field
              required
              id="location"
              name="location"
              label={t('Location')}
              control={LocationPicker}
              placeholder={t('Select location')}
              value={data.location}
              selection
              search
              onChange={this.onChange}
            />
            {errors.location && <InlineError text={errors.location} />}
          </Form.Field>

          <Form.Field error={!!errors.ratio}>
            <Form.Input
              type="text"
              id="ratio"
              name="ratio"
              label={t('Student ratio for one teacher')}
              placeholder={t('Example: 4')}
              value={data.ratio}
              onChange={this.onChange}
            />
            {errors.ratio && <InlineError text={errors.ratio} />}
          </Form.Field>

          <Form.Field
            label={t('This class enrolls students at least age')}
            error={!!errors.ageRange}
            control={() => (
              <>
                <div className="room-age-input-split">
                  <Form.Field
                    name="from-years"
                    className="age-input"
                    clearable
                    value={
                      data.ageRange?.from?.years ||
                      translatedAgeYearOptions[0].value
                    }
                    options={translatedAgeYearOptions}
                    control={Select}
                    onChange={this.onDateRangeChange}
                    error={!!errors.ageRange}
                  />

                  <Form.Field
                    name="from-months"
                    className="age-input"
                    clearable
                    value={
                      data.ageRange?.from?.months ||
                      translatedAgeMonthOptions[0].value
                    }
                    options={translatedAgeMonthOptions}
                    control={Select}
                    onChange={this.onDateRangeChange}
                    error={!!errors.ageRange}
                  />
                </div>
              </>
            )}
          />
          <Form.Field
            label={t('And no older than')}
            error={!!errors.ageRange}
            control={() => (
              <>
                <div className="room-age-input-split">
                  <Form.Field
                    name="to-years"
                    className="age-input"
                    clearable
                    value={
                      data.ageRange.to.years ||
                      translatedAgeYearOptions[0].value
                    }
                    options={translatedAgeYearOptions}
                    control={Select}
                    onChange={this.onDateRangeChange}
                    error={!!errors.ageRange}
                  />

                  <Form.Field
                    name="to-months"
                    className="age-input"
                    value={
                      data.ageRange.to.months ||
                      translatedAgeMonthOptions[0].value
                    }
                    options={translatedAgeMonthOptions}
                    control={Select}
                    onChange={this.onDateRangeChange}
                    error={!!errors.ageRange}
                  />
                </div>
                {errors.ageRange && <InlineError text={errors.ageRange} />}
              </>
            )}
          />

          {
            // @TODO: Replace the condition with a feature flag
            false && (
              <Form.Field error={!!errors.enabled}>
                <label>
                  {t('Room enabled?')}
                  <Popup
                    content={t("Rooms are enabled as default. This is a flag for you to keep track of your rooms. Disabling it won't impact anything.")}
                    position="right center"
                    offset={[4, 0]}
                    trigger={<Icon name="info circle" />}
                  />
                </label>
                <Form.Radio
                  toggle
                  id="enabled"
                  name="enabled"
                  onChange={this.tryToggleRoomEnabled}
                  control={Checkbox}
                  checked={data.enabled}
                />
                {errors.enabled && <InlineError text={errors.enabled} />}
              </Form.Field>
          )}

          <Modal open={disableModalOpen}>
            <Modal.Header>{t('Are you sure?')}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {t(
                  'There are enrolled students in this room. Are you sure you want to disable this room?'
                )}
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="red"
                onClick={() => this.setState({ disableModalOpen: false })}
              >
                No
              </Button>
              <Button
                content="Yes"
                labelPosition="right"
                icon="checkmark"
                onClick={this.forceDisableRoom}
                positive
              />
            </Modal.Actions>
          </Modal>
          <p>&nbsp;</p>

          <Form.Group>
            <Form.Button primary content={data.uid ? t('Update') : t('Save')} />
            <Form.Button
              basic
              content={t('Cancel')}
              onClick={(e) => {
                if (e) e.preventDefault();
                if (this.props.onClose) this.props.onClose();
              }}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

RoomForm.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  rooms: PropTypes.shape({
    selectedRoom: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  students: PropTypes.shape({
    selectedStudent: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        rooms: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
  organizationAddRoom: PropTypes.func.isRequired,
  organizationUpdateRoom: PropTypes.func.isRequired,
  roomSelectionCleared: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms,
  students: state.students,
});

export default withTranslation()(
  connect(mapStateToProps, {
    organizationAddRoom,
    organizationUpdateRoom,
    roomSelectionCleared,
  })(RoomForm)
);
