import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

// Import components
import DevicesList from '../Components/Devices/DevicesList';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';

const Devices = (props) => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.devices);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Shared Devices') })}
      </title>
      <body class="devices" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        <DevicesList />
      </Segment>
    </Container>
  );
};

export default Devices;
