import * as actionTypes from './types';
import {
  chargesOnListen,
  saveCharge as saveChargeAPI
} from '../../api/firebase/charges';

export function updatePaymentDetails(payload) {
  return { type: actionTypes.PAYMENT.UPDATE_DETAILS, payload };
}

export function updateSelectedEmployees(payload) {
  return { type: actionTypes.PAYMENT.UPDATE_SELECTED_EMPLOYEES, payload };
}

export function removeEmployeeSuccess(payload) {
  return { type: actionTypes.PAYMENT.REMOVE_SELECTED_EMPLOYEE, payload };
}

export function resetPaymentData(payload) {
  return { type: actionTypes.PAYMENT.RESET_DATA, payload };
}

export function createInvoice(payload) {
  return { type: actionTypes.INVOICES.CREATE, payload };
}

export function createInvoiceSuccess(payload) {
  return { type: actionTypes.INVOICES.CREATE_SUCCESS, payload };
}

export function loadInvoices() {
  return { type: actionTypes.INVOICES.LOAD };
}

export function loadInvoicesSuccess(payload) {
  return { type: actionTypes.INVOICES.LOAD_SUCCESS, payload };
}

// Edit InvoicePlan actions
export function invoicePlanNew() {
  return { type: actionTypes.INVOICE_NEW, isInvoice: false };
}

export function invoiceNew() {
  return { type: actionTypes.INVOICE_NEW, isInvoice: true };
}
export function invoiceEditSelectionCleared() {
  return { type: actionTypes.INVOICE_EDIT_SELECTION_CLEARED };
}
export function invoiceEditSelected(payload) {
  return { type: actionTypes.INVOICE_EDIT_SELECTED, payload };
}
export function invoiceEditDetails(details) {
  return { type: actionTypes.INVOICE_EDIT_DETAILS, details };
}
export function invoiceEditItems(invoiceItems) {
  return { type: actionTypes.INVOICE_EDIT_ITEMS, invoiceItems };
}
export function invoiceEditStudents(students) {
  return { type: actionTypes.INVOICE_EDIT_STUDENTS, students };
}
export function invoiceReset() {
  return { type: actionTypes.INVOICE_EDIT, invoice: null };
}
// Invoice Item Charges actions
export function chargesFetched(payload) {
  return { type: actionTypes.CHARGES_FETCHED, payload };
}
export function chargeSaved(payload) {
  return { type: actionTypes.CHARGE_SAVED, payload };
}
export const saveCharge = (organizationId, charge) => async (dispatch) => {
  try {
    await saveChargeAPI(organizationId, charge);
    return dispatch(chargeSaved(charge));
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};
export const startChargesListener = (organizationId) => (dispatch) => {
  return chargesOnListen(
    organizationId,
    (data) => {
      if (data) dispatch(chargesFetched(data));
    },
    (error) => console.log(error.message)
  );
};
