import React from 'react';
import {  Dimmer, Loader} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';

export function BusyIndicator ({label='', style={}}) {
  return (
    <Dimmer active inverted>
      <Loader inverted content={label} style={style}/>
    </Dimmer>
  );
}
export function LoadingIndicator({style={}}) {
  const {t} = useTranslation();
  return (
    <BusyIndicator label={t('Loading')} style={style} />
  );
}
