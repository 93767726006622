export const firebaseConfig = {
  // Project: Moxit-Wonderschool-Prod
  // App: Dashboard (Prod)
  // Hosting: moxit-wonderschool-prod.web.app
  apiKey: 'AIzaSyB1mzqYpIWQJs5z4lJKNySdbejMwIXLK04',
  authDomain: 'moxit-wonderschool-prod.firebaseapp.com',
  projectId: 'moxit-wonderschool-prod',
  storageBucket: 'moxit-wonderschool-prod.appspot.com',
  messagingSenderId: '804456175960',
  appId: '1:804456175960:web:45c0ead10f6b6b258f6d2d',
  measurementId: 'G-PM7934FF6K',
};

export const wsConfig = {
  envName: 'prod',
  rollbar: {
    accessToken: '87e02a03ad474af5bba15e3a57a833f7',
    environment: 'production',
  },
};
