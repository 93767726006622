import React, { useState } from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { isEmpty } from 'lodash';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import 'pure-react-carousel/dist/react-carousel.es.css';

import { formatFullName } from '../../helpers/utils';

import CheckedInStudentsModal from './CheckedInStudentsModal';

import styles from './CheckedInStats.module.scss';

const cx = classNames.bind(styles);
const BACKGROUNDS = ['mint', 'rose', 'peach', 'moon', 'champagne'];

const CheckedInStats = ({
  roomsWithStudents,
  rooms = [],
  stats: { students = null, checkedIn = null },
  programType = null,
}) => {
  const { t } = useTranslation();
  const [selectedRoom, setSelectedRoom] = useState(null);

  if (!students) return null;

  const columns = rooms.length > 2 ? 5 : 2;
  return (
    <>
      <CarouselProvider
        naturalSlideWidth={1}
        naturalSlideHeight={1}
        totalSlides={rooms.length}
        visibleSlides={columns}
        touchEnabled={rooms.length > 5}
        dragEnabled={rooms.length > 5}
        isIntrinsicHeight
        className={cx('carousel')}
      >
        <Header as="h3" style={{ padding: '1rem 1rem 0 1rem', marginBottom: 0}}>
          {t('Checked In Students')}
          
          {rooms.length > 5 && (
            <Segment basic floated='right' className={cx('carouselNav')}>
              <ButtonBack className={cx('carouselNavButton')}>
                <Icon name='chevron left' className={cx('carouselNavIcon')} />
              </ButtonBack>
              <ButtonNext className={cx('carouselNavButton')}>
                <Icon name='chevron right' className={cx('carouselNavIcon')} />
              </ButtonNext>
            </Segment>
          )}
        </Header>
        <Slider className={cx('carouselSlider')}>
          {rooms.map((room, i) => {
            if (isEmpty(room)) return null;

            const roomCheckedInCount = checkedIn
              ? checkedIn[`room-${room.id}`] ?? 0
              : 0;
            
            const avatarsPerRoom = columns === 2 ? 6 : 4;
            const students = roomsWithStudents[room.id] ?? [];
            const showMore = students?.length > avatarsPerRoom;
            const showModal = students?.length > 0;
            const numberOfAvatars = avatarsPerRoom - 1;
            const displayAvatars = showMore ? students?.slice(0, numberOfAvatars) : students;
            const moreNum = students?.length - numberOfAvatars;

            return (
              <Slide key={room.id} index={i}>
                <div className={cx('carouselItem')}>
                  <Segment
                    raised
                    className={cx({
                      room: true,
                      clickable: showModal,
                      disabled: !showModal,
                      [BACKGROUNDS[i % BACKGROUNDS.length]]: true
                    })}
                    onClick={showModal ? () => setSelectedRoom(room.id) : null}
                  >
                    <div className={cx('roomHeading')}>
                      <Header as='h4' className={cx('roomTitle')}>
                        {programType !== 'familyChildCare'
                          ? room.name
                          : t("Checked In Students")
                        }
                      </Header>
                      <span className={cx('roomSubtitle')}>{t("Students")}</span>
                    </div>
                    <Header
                      size="huge"
                      className={cx('roomCount')}
                    >
                      {roomCheckedInCount}
                    </Header>
                    <div className={cx('roomAvatars')}>
                      {displayAvatars?.map((student, i) => {
                        return (
                          <Avatar
                            key={i}
                            name={formatFullName(student, true)}
                            round
                            size={28}
                            alt={formatFullName(student, true)}
                            src={student.picture}
                            className={cx('roomAvatar')}
                          />
                        );
                      })}

                      {showMore && (
                        <Avatar
                          key={i}
                          name={`${moreNum > 99 ? '99+' : moreNum}`}
                          color="#919191"
                          round
                          size={28}
                          textSizeRatio={2}
                          className={cx('roomAvatar')}
                          alt={t("Show more")}
                        />
                      )}
                    </div>
                  </Segment>
                </div>
              </Slide>
            );
          })}
        </Slider>
      </CarouselProvider>
      <CheckedInStudentsModal
        roomName={rooms.find(room => room.id === selectedRoom)?.name ?? ''}
        students={Array.from(roomsWithStudents[selectedRoom]?.values() ?? [])}
        onClose={() => setSelectedRoom(null)}
        open={!!selectedRoom}
      />
    </>
  );
};

export default CheckedInStats;
