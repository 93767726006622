import { auth, firestore } from '.';
import { formatFullName } from '../../helpers/utils';
import { updateOrganizationStudent } from '../../students/studentsAPI';
import { startCollectionListener } from './firestore';
import { addDocument, setDocument } from './firestore';

export const InvitationType = {
  ENROLLMENT: 'enrollment',
  PARENT: 'parent',
  STAFF: 'staff',
};

export const InvitationStatus = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
};

const paths = {
  invitation: (invitationId) => `invitations/${invitationId}`,
  invitations: () => `invitations`,
};

export function pendingStaffInvitationsOnListen(
  organizationId,
  next,
  error,
  complete
) {
  return startCollectionListener(
    {
      path: paths.invitations(),
      orderBy: [{ field: 'createdAt', direction: 'desc' }],
      conditions: [
        { field: 'type', operation: '==', value: 'staff' },
        { field: 'organization', operation: '==', value: organizationId },
        { field: 'status', operation: '==', value: InvitationStatus.PENDING },
      ],
    },
    next,
    error,
    complete
  );
}

export function pendingParentInvitationsOnListen(
  { organizationId, studentId },
  next,
  error,
  complete
) {
  const conditions = [
    { field: 'type', operation: '==', value: 'parent' },
    { field: 'organization', operation: '==', value: organizationId },
    { field: 'status', operation: '==', value: InvitationStatus.PENDING },
  ];
  if (studentId)
    conditions.push({
      field: 'studentIds',
      operation: 'array-contains',
      value: studentId,
    });

  return startCollectionListener(
    {
      path: paths.invitations(),
      orderBy: [{ field: 'createdAt', direction: 'desc' }],
      conditions,
    },
    next,
    error,
    complete
  );
}

export const pendingParentInvitationsByStudentIdsOnListen = (
  { organizationId, studentIds },
  next,
  error,
  complete
) => {
  const conditions = [
    { field: 'type', operation: '==', value: 'parent' },
    { field: 'organization', operation: '==', value: organizationId },
    { field: 'status', operation: '==', value: InvitationStatus.PENDING },
    { field: 'student.id', operation: 'in', value: studentIds },
  ];

  if (!studentIds.length) return;

  return startCollectionListener(
    {
      path: paths.invitations(),
      orderBy: [{ field: 'createdAt', direction: 'desc' }],
      conditions,
    },
    next,
    error,
    complete
  );
};

export async function createParentInvitation(
  student,
  organization,
  location,
  invitationData
) {
  const invitation = initializeInvitation(
    invitationData,
    organization.id,
    location?.id,
    InvitationType.PARENT
  );
  invitation.student = initializeStudent(student);
  invitation.studentIds = [student.id];
  invitation.entityPath = `organizations/${organization.id}/students/${student.id}`;
  return createInvitation(invitation);
}

// If an invitation already exists for this parent, just add the student
// to it, don't resend the invitation
export async function addStudentToInvitation(
  organizationId,
  student,
  invitation
) {
  if (
    invitation.type === InvitationType.PARENT &&
    Boolean(invitation.email) &&
    student.preventInvoiceReason
  ) {
    updateOrganizationStudent(organizationId, {
      ...student,
      preventInvoiceReason: null,
    });
  }

  if (!invitation.student)
    // unlikely,but just in case
    invitation.student = initializeStudent(student);

  if (!invitation.studentIds) {
    invitation.studentIds = [student.id];
  } else {
    const index = invitation.studentIds.findIndex(
      (studentId) => studentId === student.id
    );
    if (index === -1) invitation.studentIds.push(student.id);
  }
  return updateInvitation(invitation);
}

export async function createStaffInvitation(organization, staffData) {
  const invitation = initializeInvitation(
    staffData,
    organization.id,
    staffData.defaultLocation,
    InvitationType.STAFF
  );
  invitation.staffClaims = staffData.staffType ?? [];
  return createInvitation(invitation);
}

export async function updateInvitation(invitation) {
  const { id, key, ...rest } = invitation;

  return setDocument({
    path: paths.invitation(id),
    data: rest,
  });
}

export async function createInvitation(invitation) {
  return addDocument({
    path: paths.invitations(),
    data: invitation,
  });
}

export async function archiveInvitation(invitationId) {
  if (!invitationId)
    // don't error out, just return
    return null;

  return setDocument({
    path: paths.invitation(invitationId),
    data: { status: InvitationStatus.ARCHIVED },
  });
}

export async function removeStudentFromInvitation(invitationId, studentId) {
  if (!invitationId || !studentId) return null;

  const data = {
    studentIds: firestore.FieldValue.arrayRemove(studentId),
    student: firestore.FieldValue.delete(),
  };
  return setDocument({
    path: paths.invitation(invitationId),
    data,
  });
}

function initializeInvitation(
  invitationData,
  organizationId,
  locationId,
  invitationType
) {
  const phone = invitationData.phone ?? '';
  const invitation = {
    ...invitationData,
    phone: phone.replace('+1', ''), //HACK ALERT: Firebase doesn't like +1 in the phone number
    displayName:
      invitationData.displayName || formatFullName(invitationData, true),
    type: invitationType,
    organization: organizationId,
    location: locationId || invitationData?.defaultLocation || '',
    status: InvitationStatus.PENDING,
    createdAt: firestore.FieldValue.serverTimestamp(),
    createdBy: {
      uid: auth().currentUser.uid,
      email: auth().currentUser.email,
      name: auth().currentUser.displayName || auth().currentUser.email,
    },
  };
  return invitation;
}
function initializeStudent(studentData) {
  const student = {
    firstName: studentData.firstName ?? '',
    lastName: studentData.lastName ?? '',
    displayName:
      (studentData.displayName || formatFullName(studentData, true)) ?? '',
    id: studentData.id ?? '',
    organization: studentData.organization ?? '',
  };
  return student;
}

export async function getOrganizationInvitedParentsByStudentIds(organizationId, studentIds) {
  const invitationsSnapshot = await firestore().collection(`invitations`)
      .where('type', '==', 'parent')
      .where('organization', '==', organizationId)
      .where('status', '==', InvitationStatus.PENDING)
      .where('student.id', 'in', studentIds)
      .get();

  return invitationsSnapshot?.docs?.filter(doc => doc.exists).map(doc => doc.data()) || [];
}


export async function getInvitation(uid) {
  const invitationSnapshot = await firestore()
      .doc(paths.invitation(uid))
      .get()

  return invitationSnapshot?.exists ? invitationSnapshot.data() : null;
}
