import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Grid, Segment } from 'semantic-ui-react';
import DashboardHeader from '../Components/Dashboard/DashboardHeader';
import Invitations from '../Components/Dashboard/Invitations';
import AppStoreLinksContainer from '../Components/Dashboard/AppStoreLinksContainer';
import RecentPhotos from '../Components/Dashboard/RecentPhotos';
import StatSummary from '../Components/Dashboard/StatSummary';
import TodaysActivityFeed from '../Components/Dashboard/TodaysActivityFeed';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';

import { useUser } from '../hooks/useUser';

import classnames from 'classnames/bind';

import styles from './Dashboard.module.scss';

const cx = classnames.bind(styles);

const Dashboard = ({ currentOrganization }) => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.dashboard);
  const { claims } = useUser();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Dashboard') })}
      </title>
      <body class="dashboard" />
    </Helmet>
  );

  return (
    <div className={cx({ withBackground: claims?.parent })}>
      {renderPageHead()}

      <DashboardHeader isParent={claims?.parent} />

      <Segment basic>
        <Container>
          <Grid container stackable>
            <StatSummary />
            <AppStoreLinksContainer
              organizationName={currentOrganization?.name}
            />
            <Invitations />
            <RecentPhotos />
            <TodaysActivityFeed />
          </Grid>
        </Container>
      </Segment>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps)(Dashboard);
