import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';

import { scheduleDates } from '../../../config';
import { toggleArray } from '../../../helpers/utils';

const SchedulePicker = ({ label, value, onScheduleChange, ...rest }) => {
  const [schedule, setSchedule] = useState(value ?? []);
  const { t } = useTranslation();

  if (!scheduleDates) return null;

  const handleScheduleChange = (e, { value }) => {
    e.preventDefault();

    const newSchedule = toggleArray(schedule, value);
    setSchedule(newSchedule);

    onScheduleChange(newSchedule);
  };

  const scheduleDaysList = scheduleDates.map((s, index) => {
    const basicButton = find(schedule, (v) => v === s.name);
    return (
      <Button
        key={index}
        value={s.name}
        basic={!basicButton}
        color="blue"
        style={{ borderRight: '1px solid #fff' }}
        content={t(`Day-${s.name}`)}
        onClick={handleScheduleChange}
      />
    );
  });

  return (
    <Form.Field {...rest}>
      <label>{label}</label>
      <div>{scheduleDaysList}</div>
    </Form.Field>
  );
};

export default SchedulePicker;
