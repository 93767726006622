import i18n from 'i18next';

import { getEnv, getDomainName, setupLogger } from './config/env';
import { getWSA } from './WSAuth';
import { auth } from './api/firebase';
import { routes } from './config/routes';

const env = getEnv();
setupLogger(`%c🔧 ENV loaded 🔧 ${env.toLocaleUpperCase()}`, 'color: #49fb35');
const WSA = getWSA({
  env,
  isMobileMode: false,
  fbAuthV8: auth(),
  returnUrl: `${getDomainName()}${routes.authReturn}`,
  getLanguageKey: () => i18n.language,
});
setupLogger(
  `%c🔑 WSAuth loaded 🔑 ${WSA.env.toLocaleUpperCase()}`,
  'color: #1e90ff'
);

export default WSA;
