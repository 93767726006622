import find from 'lodash/find';

import {
  STUDENTS_LISTENER_STARTED,
  STUDENTS_FETCHED,
  STUDENT_ADDED,
  STUDENT_SELECTED,
  STUDENT_SELECTION_CLEARED,
  STUDENT_UPDATED,
  STUDENT_FAMILY_SELECTION_CLEARED,
  STUDENT_FAMILY_MEMBER_ADDED,
  USER_EMAIL_RESET_LINK_SENT,
  STUDENT_FAMILY_MEMBER_SELECTED,
} from '../redux/actions/types';

import {
  organizationStudentsOnListen,
  addOrganizationStudent,
  updateOrganizationStudent,
  createFamilyMemberAccount,
  updateFamilyMemberAccount,
} from './studentsAPI';

import store from '../redux/store';
import WSA from '../WSA';

const identifyStudentLocation = (studentData) => {
  let location = '';
  if (!studentData?.rooms?.length) return location;
  const room = studentData.rooms[0];
  if (!room?.length) return location;

  const roomStore = store.getState()?.rooms || [];
  if (roomStore?.list?.length) {
    // Extract current location from room store
    const roomObject = find(roomStore.list, (r) => r.id === room);
    if (roomObject?.location?.length) location = roomObject.location;
  }

  return location;
};

export const studentsListenerStarted = () => ({
  type: STUDENTS_LISTENER_STARTED,
});

export const studentsFetched = (students) => ({
  type: STUDENTS_FETCHED,
  students,
});

export const studentAdded = (student) => ({
  type: STUDENT_ADDED,
  student,
});

export const studentUpdated = () => ({
  type: STUDENT_UPDATED,
});

export const studentSelected = (student) => ({
  type: STUDENT_SELECTED,
  student,
});

export const studentSelectionCleared = () => ({
  type: STUDENT_SELECTION_CLEARED,
});

export const emailResetLinkSent = () => ({
  type: USER_EMAIL_RESET_LINK_SENT,
});

export const familyMemberSelected = (familyMember) => ({
  type: STUDENT_FAMILY_MEMBER_SELECTED,
  familyMember,
});

export const familySelectionCleared = () => ({
  type: STUDENT_FAMILY_SELECTION_CLEARED,
});

export const familyMemberAdded = (studentId, memberData) => ({
  type: STUDENT_FAMILY_MEMBER_ADDED,
  studentId,
  memberData,
});

export const startOrganizationStudentsListener =
  (organizationId) => (dispatch) => {
    dispatch(studentsListenerStarted());

    return organizationStudentsOnListen(
      organizationId,
      (data) => {
        if (data) dispatch(studentsFetched(data));
      },
      (error) => console.log(error.message)
    );
  };

export const organizationAddStudent =
  (organizationId, { combinedFamily, ...studentData }) =>
  (dispatch) => {
    const location = identifyStudentLocation(studentData);
    return addOrganizationStudent(organizationId, {
      ...studentData,
      location,
    }).then((student) => dispatch(studentAdded(student)));
  };

export const organizationUpdateStudent =
  (
    organizationId,
    // combinedFamily is used on the front end only and should not be stored in the db
    { combinedFamily, ...studentData }
  ) =>
  async (dispatch) => {
    const location = identifyStudentLocation(studentData);

    await updateOrganizationStudent(organizationId, {
      ...studentData,
      location,
    });
    return dispatch(studentUpdated());
  };

export const addFamilyMember = (studentId, data) => (dispatch) => {
  return createFamilyMemberAccount(studentId, {
    ...data,
    ...WSA.signup.getInvitePayload(),
  }).then((response) => {
    const { data: userData = null } = response;

    // Skip bogus emails
    if (userData?.uid && userData?.email) {
      if (userData.email.indexOf('moxit_') !== -1)
        dispatch(emailResetLinkSent());

      // Merge form data and response data. This is good for UI optimistic update.
      const familyMember = {
        ...data,
        ...userData,
      };

      return dispatch(familyMemberAdded(studentId, familyMember));
    }

    return null;
  });
};

export const updateFamilyMemberData = (studentId, data) => async (dispatch) => {
  await updateFamilyMemberAccount(studentId, data);
  return dispatch(studentUpdated());
};

const initialState = {
  selectedStudent: null,
  count: 0,
  list: [],
};

export const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENTS_LISTENER_STARTED:
      return { ...state };

    case STUDENTS_FETCHED: {
      let selectedStudent = state?.selectedStudent;
      let selectedFamilyMember = state?.selectedStudent?.selectedFamilyMember;

      if (!!selectedStudent) {
        selectedStudent = action.students.list.find(
          (student) => student.id === selectedStudent.id
        );

        if (!!selectedFamilyMember && selectedStudent?.family) {
          selectedStudent.selectedFamilyMember =
            selectedStudent.family[selectedFamilyMember.uid];
        }
      }

      return {
        ...state,
        count: action.students.count,
        list: action.students.list,
        selectedStudent,
      };
    }

    case STUDENT_SELECTED:
      return { ...state, selectedStudent: action.student };

    // Replace selected with the newly updated data.
    // New data is fetched by the listener as soon as listener receives the update.
    case STUDENT_UPDATED: {
      return {
        ...state,
        selectedStudent: state.list.find(
          (student) => student.id === state.selectedStudent.id
        ),
      };
    }

    case STUDENT_SELECTION_CLEARED:
      return { ...state, selectedStudent: {} };

    case STUDENT_FAMILY_MEMBER_ADDED: {
      // Temporarily add form data until listener receives data.
      if (action.memberData.uid) {
        const studentObject = state.list.find(
          (student) => student.id === action.studentId
        );

        studentObject.family = {
          ...studentObject.family,
          [action.memberData.uid]: { ...action.memberData },
        };

        const studentIndex = state.list.findIndex(
          (student) => student.id === action.studentId
        );

        const list = [
          ...state.list.slice(0, studentIndex),
          studentObject,
          ...state.list.slice(studentIndex + 1),
        ];

        return {
          ...state,
          list,
          selectedStudent: {
            ...state.selectedStudent,
            family: {
              ...state.selectedStudent.family,
              [action.memberData.uid]: {
                uid: action.memberData.uid,
                ...action.memberData,
              },
            },
          },
        };
      }
      return state;
    }

    case STUDENT_FAMILY_MEMBER_SELECTED:
      return {
        ...state,
        selectedStudent: {
          ...state.selectedStudent,
          selectedFamilyMember: action.familyMember,
        },
      };

    case STUDENT_FAMILY_SELECTION_CLEARED:
      return {
        ...state,
        selectedStudent: {
          ...state.selectedStudent,
          selectedFamilyMember: null,
        },
      };

    default:
      return state;
  }
};
