
import { useState } from 'react';
import { Card, Icon, Segment, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Import actions
import {
  staffSelected,
  staffSelectionCleared
} from '../../redux/actions/staffActions';
import { userHasPermission } from '../../api/firebase/account';
import { createMissingFieldsAndMergeLists } from '../../helpers/invitations';

// Import Components
import StaffEditCard from './StaffEditCard';
import StaffForm from '../Forms/StaffForm';
import withSlidingPanel from '../Shared/withSlidingPanel';
import withPermission from '../Shared/withPermission';

import {
  useOrganization,
  useOrganizationStaffListener,
} from '../../hooks/useOrganizations';

import {
  useStaffInvitations,
  usePendingStaffInvitationsListener,
} from '../../hooks/useInvitations';

import { useStaff } from '../../hooks/useStaff';

const RestrictedStaffCreateCard = withPermission(
  StaffCreateCard,
  'can_create_staff'
);

const SlidingStaffFormAdd = withSlidingPanel(StaffForm, {
  title: 'Add Staff',
});

const SlidingStaffFormEdit = withSlidingPanel(StaffForm, {
  title: 'Edit Staff',
});

export default function StaffList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const organization = useOrganization();
  const staff = useStaff();
  const invitations = useStaffInvitations();

  useOrganizationStaffListener(organization.id);
  usePendingStaffInvitationsListener(organization.id);

  const mergedStaffList = createMissingFieldsAndMergeLists(
    staff?.list,
    invitations?.list
  );

  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <div>
      {renderPageTitle()}
      {renderStaffList()}
      {renderSlidingForms()}
    </div>
  );

  function renderStaffList() {
    return (
      <Card.Group stackable itemsPerRow={4}>
        <RestrictedStaffCreateCard onClick={() => setIsAddOpen(true)} />

        {mergedStaffList.map((staff) => (
          <StaffEditCard
            key={staff.id}
            staff={staff}
            onClick={() => onClickStaff(staff)}
            t={t}
          />
        ))}
      </Card.Group>
    );
  }

  function onClickStaff(staff) {
    // can't edit an invitation, or if no permissions
    if (!userHasPermission('can_edit_staff')) return;
    dispatch(staffSelected(staff));
    setIsEditOpen(true);
  }

  function renderSlidingForms() {
    return (
      <>
        <SlidingStaffFormAdd
          isOpen={isAddOpen}
          onClose={() => setIsAddOpen(false)}
          staffList={mergedStaffList}
        />
        <SlidingStaffFormEdit
          isOpen={isEditOpen}
          onClose={() => {
            dispatch (staffSelectionCleared());
            setIsEditOpen(false)
          }}
          staffList={mergedStaffList}
        />
      </>
    );
  }

  function renderPageTitle() {
    if (staff?.count) {
      return (
        <Segment basic clearing>
          <Header
            as="h1"
            floated="left"
            content={t('Staff ({{staffCount}})', {
              staffCount: mergedStaffList.length,
            })}
          ></Header>
        </Segment>
      );
    }

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left" content={t('Staff')} />
      </Segment>
    );
  }
}

function StaffCreateCard({ onClick }) {
  const { t } = useTranslation();
  return (
    <Card className="add-new" onClick={onClick}>
      <Card.Content>
        <div className={'center'}>
          <Card.Header as={'h4'}>
            <Icon name="plus" />
          </Card.Header>
          <Card.Description>{t('Add more staff')}</Card.Description>
        </div>
      </Card.Content>
    </Card>
  );
}