import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import {
  Header,
  Segment,
  Container,
  Tab,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import CreditCard from './CreditCard';
import PaymentDetail from '../PaymentDetail';

import { startDocumentListener } from '../../../api/firebase/firestore';
import BankTransfer from './BankTransfer';
import ShowSuccess from '../../Messages/ShowSuccess';
import ShowErrors from '../../Messages/ShowError';
import InvoiceItems from '../InvoiceItems';
import { formatCurrency } from '../../../helpers/utils';
import InvoicePaidConfirmation from '../InvoicePaidConfirmation';

import WSA from '../../../WSA';
import { routes } from '../../../config/routes';

class PayInvoice extends Component {
  state = {
    loading: false,
    paid: {},
    billToId: '',
    organizationId: '',
    invoiceId: '',
    invoice: {},
    successMessage: '',
    errorMessage: '',
  };

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    const jwtData = params.jwt.length ? jwtDecode(params.jwt) : {};
    const { organizationId, invoiceId, billToId } = jwtData;
    this.setState({ organizationId, invoiceId, billToId });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.invoiceId !== prevState.invoice.invoiceId) {
      this.loadInvoiceData();
    }
  };

  componentWillUnmount = async () => {
    const { isAuthenticated, needsVerification, history } = this.props;
    // Unsubscribe
    if (this.unsubscribeInvoiceListener) this.unsubscribeInvoiceListener();

    // Only kick out Anonymous users.
    if (!isAuthenticated || needsVerification) {
      history.push(routes.signout);
    }
  };

  loadInvoiceData = () => {
    const { organizationId, invoiceId, billToId } = this.state;

    if (!organizationId && !invoiceId) return;

    try {
      // Converted to listener to track changes realtime.
      this.unsubscribeInvoiceListener = startDocumentListener(
        {
          path: `organizations/${organizationId}/invoices/${invoiceId}`,
        },
        (invoice) => {
          this.setState({
            invoice: { organizationId, invoiceId, billToId, ...invoice },
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  render() {
    const { invoice, successMessage, errorMessage, paid } = this.state;

    const panes = [
      {
        menuItem: {
          key: 'credit',
          content: (
            <div className="payment-tab">
              <Icon name="credit card" />
              <div>Card</div>
            </div>
          ),
        },
        render: () => (
          <Tab.Pane style={{ padding: 0 }} basic attached={false}>
            <Elements>
              <CreditCard
                invoice={invoice}
                onSuccess={(paid) => this.setState({ paid })}
                onError={(errorMessage) => this.setState({ errorMessage })}
              />
            </Elements>
          </Tab.Pane>
        ),
      },
      {
        menuItem: {
          key: 'bank',
          content: (
            <div className="payment-tab">
              <Icon name="university" />
              <div>Bank transfer</div>
            </div>
          ),
        },
        render: () => (
          <Tab.Pane style={{ padding: 0 }} basic attached={false}>
            <Elements>
              <BankTransfer invoice={invoice} />
            </Elements>
          </Tab.Pane>
        ),
      },
    ];

    return isEmpty(invoice) ? (
      <Dimmer page active inverted>
        <Loader inline="centered">Loading Invoice...</Loader>
      </Dimmer>
    ) : (
      <Container style={{ width: '60%' }}>
        {errorMessage && (
          <Segment color="red" raised style={{ marginBottom: '40px' }}>
            <ShowErrors content={errorMessage} />
          </Segment>
        )}
        {successMessage && (
          <Segment color="green" raised style={{ marginBottom: '40px' }}>
            <ShowSuccess content={successMessage} />
          </Segment>
        )}
        <Segment.Group raised>
          <Segment basic>
            <PaymentDetail invoice={invoice} />
          </Segment>

          <Segment secondary>
            {isEmpty(paid) && invoice && !invoice.alreadyPaid && (
              <Segment basic textAlign="center">
                <Header as="h3">
                  <Header.Content>{`${formatCurrency(
                    invoice.total
                  )} USD due on ${
                    invoice.dateDue && moment(invoice.dateDue).format('LL')
                  }`}</Header.Content>
                  <Header.Subheader>
                    Choose how you'd like to pay.
                  </Header.Subheader>
                </Header>
              </Segment>
            )}

            <div className="Checkout">
              {invoice.alreadyPaid || (paid && paid.amount) ? (
                <InvoicePaidConfirmation invoice={invoice} paid={paid} />
              ) : (
                invoice &&
                invoice.total > 0 && (
                  <Tab menu={{ secondary: true }} panes={panes} />
                )
              )}
            </div>
          </Segment>

          <Segment basic>
            <InvoiceItems invoice={invoice} />
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  //
});
const mapAuthContextToProps = ({ isAuthenticated, needsVerification }) => {
  return {
    isAuthenticated,
    needsVerification,
  };
};

export default WSA.components.mapAuthContextToProps(
  mapAuthContextToProps,
  withRouter(connect(mapStateToProps)(PayInvoice))
);
