import React from 'react';
import { connect } from 'react-redux';
import { Container, Segment, Header } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

// Import component
import Account from '../Components/Account';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';

const Profile = () => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.profile);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Account Profile') })}
      </title>
      <body class="profile" />
    </Helmet>
  );

  const renderPageTitle = () => (
    <Segment basic clearing>
      <Header as="h1" floated="left" content={t('Account Profile')} />
    </Segment>
  );

  return (
    <Container text className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        {renderPageTitle()}

        <Account />
      </Segment>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(Profile);
