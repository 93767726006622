import { firestore, functions } from '.';
import { updateDocument, startCollectionListener } from './firestore';

const paths = {
  employee: (organizationId, userId) => `organizations/${organizationId}/users/${userId}`,
  staff: (organizationId) => `organizations/${organizationId}/users`,
}

export function organizationStaffOnListen (organizationId, next, error, complete) {
  return startCollectionListener ({
    path: paths.staff(organizationId),
    orderBy: [{field: 'firstName', direction:'asc'}],
    conditions: [{field: 'staff', operation: '==', value: true}]
  },
  next, error, complete);
}

export const addOrganizationStaff = (organizationId, userId, staffData) => {
  if (!organizationId || !userId) {
    console.error('Missing organizationId or userId');
    return null;
  }
  return firestore()
    .doc(paths.employee (organizationId, userId))
    .set(staffData, { merge: true });
};

export const updateOrganizationStaff = (
  organizationId,
  { id, ...staffData }
) => {
  return updateDocument({
    path: paths.employee (organizationId, id),
    data: staffData,
  });
};
export const createStaffAccount = async (staffData) => {
  return functions().httpsCallable('callables-staff-addStaff')(staffData);
}
