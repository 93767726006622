import {
  STAFF_FETCHED,
  STAFF_LISTENER_STARTED,
  STAFF_ADDED,
  STAFF_INVITED,
  STAFF_SELECTED,
  STAFF_SELECTION_CLEARED,
  STAFF_UPDATED,
  USER_EMAIL_RESET_LINK_SENT,
} from './types';

import { createStaffInvitation } from '../../api/firebase/invitations';

import {
  organizationStaffOnListen,
  createStaffAccount,
  updateOrganizationStaff,
} from '../../api/firebase/staff';

import WSA from '../../WSA';

export const staffListenerStarted = () => ({
  type: STAFF_LISTENER_STARTED,
});

export const staffFetched = (staff) => ({
  type: STAFF_FETCHED,
  staff,
});

export const staffAdded = () => ({
  type: STAFF_ADDED,
});

export const staffInvited = () => ({
  type: STAFF_INVITED,
})
export const staffUpdated = () => ({
  type: STAFF_UPDATED,
});

export const staffSelected = (staff) => ({
  type: STAFF_SELECTED,
  staff,
});

export const staffSelectionCleared = () => ({
  type: STAFF_SELECTION_CLEARED,
});

export const emailResetLinkSent = () => ({
  type: USER_EMAIL_RESET_LINK_SENT,
});

export const startOrganizationStaffListener =
  (organizationId) => (dispatch) => {
    return organizationStaffOnListen(
      organizationId,
      (data) => {
        if (data) dispatch(staffFetched(data));
      },
      (error) => console.log(error.message)
    );
  };

export const organizationAddStaff = (staffData) => (dispatch) => {
  return createStaffAccount({
    ...staffData,
    ...WSA.signup.getInvitePayload(),
  }).then((response) => {
    const { data } = response;

    // Skip bogus emails
    if (data && data.uid && data.email)
      if (data.email.indexOf('moxit_') === -1) dispatch(emailResetLinkSent);

    return dispatch(staffAdded());
  });
};

export const inviteStaff = (organization, staffData) => (dispatch) => {
  return createStaffInvitation (organization, staffData)
};

export const organizationUpdateStaff =
  (organizationId, staffData) => (dispatch) =>
    updateOrganizationStaff(organizationId, staffData).then((location) =>
      dispatch(staffUpdated())
    );
