import {
  formatStringAsUtcMillisOrNull,
  DefaultDateFormat,
} from './dates';
import moment from 'moment';

export const InvoicePlanStatus = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  ENDED: 'ended',
  SCHEDULED: 'scheduled'
};

const BILLING_INTERVAL = {
  'daily': moment.duration(1, 'day'),
  'weekly': moment.duration(1, 'week'),
  'biweekly': moment.duration(2, 'weeks'),
  'bi-weekly': moment.duration(2, 'weeks'),
  'twice per month': moment.duration(2, 'weeks'), // TODO Fix - same as biweekly - UI needs to allow user to pick two dates
  'monthly': moment.duration(1, 'month'),
  'quarterly': moment.duration(3, 'months'),
  'yearly': moment.duration(1, 'year'),
}

export function invoicePlanStatusFormatter({status}) {

  switch (status) {
    case InvoicePlanStatus.PAUSED:
      return { text: 'Paused', color: 'grey' };

    case InvoicePlanStatus.ENDED:
      return { text: 'Ended', color: 'red' };

    // case InvoicePlanStatus.SCHEDULED:
    //   return {text: 'Scheduled', color: 'yellow'}

    default: {
      return { text: 'Active', color: 'blue' };

    }
  }
}

export function getBillingDuration(interval) {
  if(!interval) return null;
  return BILLING_INTERVAL[interval.toLowerCase()];
}

export function getUpdatedBillingDates(invoicePlan) {
  const isEdit = !!invoicePlan.updatedAt;
  const momentNow = moment().startOf('day');
  const isSendDateInThePast = moment(invoicePlan.dateSend).startOf('day').isBefore(momentNow);
  const hasBillingIntervalChanged = !!(invoicePlan.oldBillingInterval && invoicePlan.oldBillingInterval !== invoicePlan.billingInterval);

  
  const momentStart = (isNaN(invoicePlan.dateStart) ? moment(invoicePlan.dateStart, DefaultDateFormat) : moment(invoicePlan.dateStart)).startOf('day');
  let momentDue = momentStart.clone();
    
  if(isSendDateInThePast || hasBillingIntervalChanged) {
    while (momentDue.isBefore(momentNow)) {
      momentDue.add(getBillingDuration(invoicePlan.billingInterval));
    }
  } else if(isEdit && invoicePlan.dateDue) {
    momentDue = (isNaN(invoicePlan.dateDue) ? moment(invoicePlan.dateDue, DefaultDateFormat) : moment(invoicePlan.dateDue)).startOf('day');
  }

  const momentSend = momentDue.clone().subtract(parseInt(invoicePlan.notificationDays), 'days');

  return {
    dateStart: momentStart.utc().valueOf(),
    dateEnd: formatStringAsUtcMillisOrNull(invoicePlan.dateEnd),
    dateDue: momentDue.utc().valueOf(),
    dateSend: momentSend.utc().valueOf(),
  };
}
