import moment from 'moment';
import _ from 'lodash';

export const DefaultDateFormat = 'MM/DD/YYYY';
export const UtcFormat = 'x';

/**
 * TODO: Document your code!
 * @constructor
 * @param {date} date - The date to convert.
 */
export const dateFormatter = (date, format = '') => {
  if (!date) return null;
  let dateObj = date.toDate !== undefined ? date.toDate() : date;
  return moment(dateObj).format(format ? format : DefaultDateFormat);
};

export const toDateObject = (date, dateFormat=DefaultDateFormat) => {
  if (typeof date === 'number')
    return new Date(date);
  else if (typeof date === 'string')
    return moment(date, dateFormat).toDate();
  else if (date.toDate !== undefined )
    return date.toDate();
  else if (date instanceof Date)
    return date;
  else
    return null;
}

/**
 * Utility method to format DatePicker string value to UTC millis.
 * Can optionally handle a single argument moment object or
 * a date string with format pattern.
 *
 * @param {string | moment} dateVal Either a moment object or string value
 * @param {string} [dateFmt=DefaultDateFormat] Only used if 1st argument is a date string.
 * @return {*} date as UTC millis
 * @return null if dateVal is null
 */
export const formatStringAsUtcMillisOrNull = (
  dateVal,
  dateFmt = DefaultDateFormat
) => {
  if (_.isEmpty(dateVal)) {
    return null;
  }

  let momentDate = dateVal;
  if (!moment.isMoment(dateVal)) {
    momentDate = moment(dateVal, dateFmt);
  }

  return momentDate.utc().valueOf();
};


/**
 * Converts UTC (ms) timestamp into a date string.
 *
 * @param {Number} utcMillis utc timestamp in milliseconds
 * @param {String} [optFmt=DefaultDateFormat] date format string acceptable to Moment
 * @return {String} Date string formatted according to optFmt
 * @return {null} null if null value provided
 */
export const formatUtcMillisAsString = (
  utcMillis,
  optFmt = DefaultDateFormat
) => {
  if (utcMillis == null) {
    return null;
  }

  return moment(`${utcMillis}`, UtcFormat).format(optFmt);
};
