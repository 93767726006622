/**************************/
/* CLONED in SSO-ACCOUNTS */
/**************************/

// base64url encoding is an extension to normal base64 that makes the characters url safe for
// https://www.base64url.com/
import base64url from 'base64url';
import { isEmpty } from 'lodash';

import removeUrlHash from './remove-url-hash';
import { logWarning } from '../../rollbar';

/**
 * @param {Object} json
 * @returns {string} base64url encoded string
 */
export const encodeBase64UrlJson = (json) => {
  return base64url.encode(JSON.stringify(json));
};
/**
 * @param {string} encoded base64url encoded string
 * @returns {Object | undefined} json
 * if undefined, there was a parsing error
 */
export const decodeBase64UrlJson = (encoded) => {
  try {
    if (isEmpty(encoded)) {
      return; // ignore empty strings
    }
    return JSON.parse(base64url.decode(encoded));
  } catch (err) {
    logWarning(`error decoding json: ${err.message}`, { encoded, err });
    return;
  }
};
/**
 * @param {Location | string} location window.location
 * @returns {string} hash param w/o `#`
 */
export const getHashFragment = (location = window?.location.href) =>
  new URL(location?.href || location).hash?.replace('#', '');
/**
 * @param {Location | string} location window.location
 * @returns {Object | undefined} hash json
 * if undefined,
 */
export const getHashFragmentDecoded = (location = window?.location.href) => {
  const encHashFrag = getHashFragment(location);
  if (encHashFrag === '') {
    return;
  } else {
    return decodeBase64UrlJson(encHashFrag);
  }
};

export const removeHashFragment = () => removeUrlHash();
