import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Form, Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { isLocal } from '../../config/env';

// Import routes.
import routeList from '../Routes';
import {
  hasNoOrgRestriction,
  userHasPermission,
} from '../../api/firebase/account';

// Import components.
import LocationPicker from '../Locations/LocationPicker';

// Import Actions
import { updateUser } from '../../redux/actions/userActions';

const MainNavigation = ({
  location: { pathname },
  locations,
  user,
  updateUser,
  currentOrganization,
  handleShowMenu,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const showMenu = useCallback((menu) => {
    const { enabled, hasMenu, permission } = menu;

    if (menu.menuGroup === 'myWebsite' && !user?.claims?.moxitAdmin) {
      if (!user?.claims?.organizationAdmin && user.uid !== currentOrganization.createdBy) {
        return false;
      }
    }
    
    const { features } = currentOrganization

    if (features?.length) {
      const feature = features.find(f => f.name === menu.name)

      if (feature?.name) {
        return (
          feature.enabled && 
          hasMenu && 
          (permission === '*' || userHasPermission(permission)) && hasNoOrgRestriction(menu.name)
        )
      }
    }

    if (
      enabled &&
      hasMenu &&
      (permission === '*' || userHasPermission(permission)) &&
      hasNoOrgRestriction(menu.name)
    ) {
      return true;
    }

    return false;
  }, [user, currentOrganization]);

  const handleLocationChange = async (location) => {
    try {
      setLoading(true);
      await updateUser(currentOrganization.id, user.uid, {
        defaultLocation: location,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const buildMarketplaceLink = (menu) => {
    if (!menu) return null;

    const root = isLocal()
      ? // only use the default if local
        process.env.REACT_APP_WS_MARKETPLACE_APP_URL || '//lego:4000'
      : process.env.REACT_APP_WS_MARKETPLACE_APP_URL;

    return menu.replace(
      '/marketplace/marketplace-school-id/',
      `${root}/admin/${currentOrganization?.marketplaceSchoolId}/`
    );
  };

  const handleMarketplaceLink = (e, link) => {
    e.preventDefault();
    e.stopPropagation();

    window.location.assign(buildMarketplaceLink(link));
  };

  const menuRoutes = () =>
    routeList?.map((menu, index) => {
      if (showMenu(menu)) {
        // Check if the current menu has children menus.
        const childrenMenus =
          menu?.hasChildren &&
          menu?.childrenMenu?.map((child, index) => {
            if (showMenu(child)) {
              if (child?.to) {
                // If it's a marketplace route, rewrite the URL to
                // include the external school id.
                if (
                  child?.marketplace &&
                  currentOrganization?.marketplaceSchoolId
                ) {
                  return (
                    <Menu.Item
                      key={index}
                      name={t(child?.name)}
                      as={Link}
                      to={{ pathname: buildMarketplaceLink(child.to) }}
                      active={
                        (child?.to && pathname === child?.to) || undefined
                      }
                      onClick={(e) => handleMarketplaceLink(e, child.to)}
                    />
                  );
                } else if (!child?.marketplace) {
                  return (
                    <Menu.Item
                      key={index}
                      name={t(child?.name)}
                      as={Link}
                      to={child?.to || ''}
                      active={
                        (child?.to && pathname === child?.to) || undefined
                      }
                      onClick={handleShowMenu}
                    />
                  );
                }

                return null;
              }
            }

            return null;
          });

        if (menu?.marketplace && currentOrganization?.marketplaceSchoolId) {
          return (
            <Menu.Item
              key={index}
              active={(menu?.to && pathname === menu?.to) || undefined}
              className={childrenMenus ? `nav-item has-children` : `nav-item`}
            >
              {childrenMenus ? (
                <Menu.Header content={t(menu?.name)} />
              ) : (
                <Menu.Header
                  as={Link}
                  to={{ pathname: buildMarketplaceLink(menu?.to) }}
                  content={t(menu?.name)}
                  onClick={(e) => handleMarketplaceLink(e, menu?.to)}
                />
              )}

              {childrenMenus && <Menu.Menu>{childrenMenus}</Menu.Menu>}
            </Menu.Item>
          );
        } else if (!menu?.marketplace) {
          return (
            <Menu.Item
              key={index}
              active={(menu?.to && pathname === menu?.to) || undefined}
              className={childrenMenus ? `nav-item has-children` : `nav-item`}
            >
              {childrenMenus ? (
                <Menu.Header content={t(menu?.name)} />
              ) : (
                <Menu.Header
                  as={Link}
                  to={menu?.to || ''}
                  content={t(menu?.name)}
                  onClick={handleShowMenu}
                />
              )}

              {childrenMenus && <Menu.Menu>{childrenMenus}</Menu.Menu>}
            </Menu.Item>
          );
        }

        return null;
      }

      return null;
    });

  // TODO: The location picker has been disabled for now.
  // It will be enabled on a subsequent release.
  const locationPicker = (disable) => {
    if (!disable) return null;

    return (
      locations?.count > 0 && (
        <Menu.Item>
          <Form id="location-picker">
            <LocationPicker
              id="location"
              name="location"
              value={user?.defaultLocation || ''}
              selection
              clearable={false}
              onChange={(e, { value }) => handleLocationChange(value)}
            />
          </Form>
        </Menu.Item>
      )
    );
  };

  return loading ? (
    <Dimmer active inverted page>
      <Loader>Switching locations</Loader>
    </Dimmer>
  ) : (
    <>
      {locationPicker(false)}

      {menuRoutes()}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  permissions: state.permissions,
  currentOrganization: state.organizations.currentOrganization,
  locations: state.locations,
});

export default withRouter(
  connect(mapStateToProps, { updateUser })(MainNavigation)
);
