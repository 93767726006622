// FIREBASE v8 Syntax
import { getAuth } from './index';
import { getDefaultSettings } from './actionCodeSettings';
import { logError, logWarning } from '../../rollbar';
import { ACTION_TYPES, saveActionReturn } from './actionReturn';

/**
 * ### Async / Promise
 * Gets the fully-hydrated id token. This will contain richer identifying information,
 * but will not be able to re-authenticate in another firebase client.
 * This is useful for legacy apps with their own authentication systems, where the app
 * just needs a way to associate a verified user identity. (ex: lego og mobile app);
 *
 * Will only work if there's a currently signed in user.
 * @param {boolean} forceRefresh
 * @returns {string | undefined} jwt
 * * if undefined, an error occurred.
 * otherwise, returns id token.
 */
export const getIdToken = async (forceRefresh = false) => {
  const authUser = getAuth().currentUser;
  try {
    if (!authUser) {
      throw new Error('Must be logged in');
    }
    const idToken = await authUser.getIdToken(forceRefresh);
    if (idToken) {
      return idToken;
    } else {
      throw new Error('Could not fetch firebase id token');
    }
  } catch (err) {
    logError(`Could not get id token: ${err.message}`, err, {
      err,
      uid: authUser.uid,
      forceRefresh,
    });
    return; // signals failure
  }
};

/**
 * ### Async / Promise
 * Gets the decoded id token object
 *
 * Will only work if there's a currently signed in user.
 * @param {boolean} forceRefresh
 * @returns {{[key: string]: any} | undefined} key/value
 * * if undefined, an error occurred.
 * otherwise, returns id token object.
 */
export const getIdTokenObject = async (forceRefresh = false) => {
  const authUser = getAuth().currentUser;
  try {
    if (!authUser) {
      throw new Error('Must be logged in');
    }
    const idTokenResult = await authUser.getIdTokenResult(forceRefresh);
    if (idTokenResult) {
      return idTokenResult;
    } else {
      throw new Error('Could not get id token result');
    }
  } catch (err) {
    logError(`Could not get id token object: ${err.message}`, err, {
      err,
      uid: authUser.uid,
      forceRefresh,
    });
    return; // signals failure
  }
};

/**
 * ### Async / Promise
 * Gets the decoded user claims, including custom
 *
 * Will only work if there's a currently signed in user.
 * @param {boolean} forceRefresh
 * @returns {{[key: string]: any} | undefined} key/value
 * * if undefined, an error occurred.
 * otherwise, returns claims object.
 */
export const getUserClaims = async (forceRefresh = false) => {
  return (await getIdTokenObject(forceRefresh))?.claims;
};

/**
 * ### Async / Promise
 * Sends a verification email to the user. Saves the return data beforehand so the user will always be able to come back to original app
 * @param {User} authUser firebase user
 * @returns {boolean | { errorCode: string}}
 *  - true: success
 *  - false: generic error
 *  - errorCode object - firebase handled error
 *
 * If payload includes property `errorCode`, then a handleable error has occurred
 * - errorCode: none
 */
export const sendVerificationEmail = async (actionReturnData = {}) => {
  const authUser = getAuth().currentUser;
  try {
    if (!authUser) {
      throw new Error('Must be logged in');
    }
    await saveActionReturn(
      authUser.uid,
      ACTION_TYPES.VERIFY_EMAIL,
      actionReturnData // available for any device that opens the verification email
    );
    await authUser.sendEmailVerification(getDefaultSettings());
    return true;
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/missing-android-pkg-name - An Android package name must be provided if the Android app is required to be installed.
     * auth/missing-continue-uri - A continue URL must be provided in the request.
     * auth/missing-ios-bundle-id - An iOS bundle ID must be provided if an App Store ID is provided.
     * auth/invalid-continue-uri - The continue URL provided in the request is invalid.
     * auth/unauthorized-continue-uri - The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.
     */
    if (err.code) {
      switch (err.code) {
        default:
          logWarning(
            `Could not send verification email (handled): ${err.message}`,
            {
              err,
              code: err.code,
              uid: authUser.uid,
              actionReturnData,
            }
          );
          return false; // signals unhandled error
      }
    } else {
      logError(`Could not send verification email: ${err.message}`, err, {
        err,
        uid: authUser.uid,
        actionReturnData,
      });
      return; // signals failure
    }
  }
};
