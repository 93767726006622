import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { isEmpty, groupBy, keyBy } from 'lodash';
import { Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// Import actions
import { getOrganizationInvoices } from '../../../../redux/actions/invoiceActions';

// Import component
import { routes } from '../../../../config/routes';
import AgingList from './AgingList';
import NoDataMessage from '../../../Shared/NoDataMessage';

// Import utils
import { selectTotalByEmployee } from '../../../../helpers/utils';
import {
  getUpcomingInvoices,
  getPastDueInvoices,
} from '../../../../helpers/invoices';
import SectionCard from '../../../Shared/SectionCard';

class AgingContainer extends Component {
  static propTypes = {
    totalByEmployee: PropTypes.array,
    totalSummary: PropTypes.object,
  };


  render() {
    const {
      invoices: {
        all: { list },
      },
      totalByEmployee,
      totalSummary,
      t,
    } = this.props;

    const loading = !list;

    if (loading === false && isEmpty(list))
      return (
        <NoDataMessage
          message={t(
            'Hey, hey! You are new here. Go ahead and create a new invoice...'
          )}
          CallToActionComponent={() => (
            <Button primary as={Link} to={routes.billing.invoices.edit} size="large">
              {t('Create New Invoice')}
            </Button>
          )}
        />
      );

    return (
      <SectionCard
        header={t('Aging Report')}
        component={() => (
          <AgingList
            totalByEmployee={totalByEmployee}
            totalSummary={totalSummary}
            loading={false}
          />
        )}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    invoices: {
      all: { list },
    },
  } = state;

  const upcomingInvoicesList = Array.isArray(list)
    ? getUpcomingInvoices(list)
    : [];
  const pastDueInvoicesList = getPastDueInvoices(upcomingInvoicesList);

  // Group invoices by student
  const invoiceGroupedByStudent = groupBy(pastDueInvoicesList, 'student.id');

  const studentsObj = {};

  // Find and convert the invoices for each student to an object.
  invoiceGroupedByStudent &&
    Object.keys(invoiceGroupedByStudent).forEach((studentId) => {
      studentsObj[studentId] = keyBy(invoiceGroupedByStudent[studentId], 'id');
    });

  const totalByEmployee = !isEmpty(studentsObj)
    ? selectTotalByEmployee(studentsObj).totalByEmployee
    : [];
  const totalSummary = !isEmpty(studentsObj)
    ? selectTotalByEmployee(studentsObj).totalSummary
    : {};

  return {
    totalByEmployee,
    totalSummary,

    currentOrganization: state.organizations.currentOrganization,
    invoices: state.invoices,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationInvoices,
    },
    dispatch
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AgingContainer)
);
