import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

const FastpayStatus = ({
  enabled,
  size = 'small',
  hasLabel = true,
  color = enabled ? 'green' : 'grey',
}) => {
  const { t } = useTranslation();

  if (!hasLabel) {
    return <Icon name="lightning" color={color} size={size} />;
  }

  const fastpayLabel = t(`Fastpay ${enabled ? 'enabled' : 'disabled'}`);
  return (
    <>
      <Icon name="lightning" color={color} size={size} />
      {fastpayLabel}
    </>
  );
};

export default FastpayStatus;
