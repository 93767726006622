import { InvoiceStatus } from './invoices';
import { PAYOUT_STATUS } from './payouts';
import { FastpayTooltip } from '../Components/Billing/Fastpay';

export const ORGANIZATION_MAX_BALANCE = 30000;
export const USER_MAX_BALANCE = 8000;
export const ORGANIZATION_MAX_TRANSACTION_AMOUNT = 4000;
export const USER_MAX_TRANSACTION_AMOUNT = 4000;

function getEnabled(obj) {
  return obj?.fastpay?.enabled;
}

export function isEnabled(obj) {
  return !!getEnabled(obj);
}

export function getBalance(obj) {
  return obj?.fastpay?.balance ?? 0;
}

export function getMaxBalance(obj) {
  return obj?.fastpay?.maxBalance ?? null;
}

export function getMaxTransactionAmount(obj) {
  return obj?.fastpay?.maxTransactionAmount ?? null;
}

//This will return a new object with the .fastpay property
export function initOrganizationFastpay(organization) {
  const fastpay = {};
  fastpay.enabled = getEnabled(organization) ?? true;
  fastpay.balance = getBalance(organization) ?? 0;
  fastpay.maxBalance = getMaxBalance(organization);
  fastpay.maxTransactionAmount = getMaxTransactionAmount(organization);
  return { fastpay: fastpay };
}

export function getOrSetFastpay(obj) {
  if (!obj.fastpay) {
    obj.fastpay = {
      enabled: false,
      balance: 0,
      maxBalance: getMaxBalance(obj),
      maxTransactionAmount: getMaxTransactionAmount(obj),
    };
  }
  return obj.fastpay;
}

export function getFastpayTooltip(invoice) {
  if (!invoice?.fastpay) {
    return 'Fastpay invoice not eligible';
  }
  if (invoice?.payout?.status === PAYOUT_STATUS.PAID) {
    return 'Fastpay invoice eligible processed';
  }
  return 'Fastpay invoice eligible processing';
}

export function getFastpayColor(invoice) {
  if (!invoice?.fastpay) return 'grey';
  if (invoice?.payout?.status === PAYOUT_STATUS.PAID) return 'green';
  return 'olive';
}

export function renderFastpayMaybe(fastpayGranted, invoice, t) {
  if (
    invoice.status === InvoiceStatus.UNCOLLECTIBLE ||
    invoice.status === InvoiceStatus.VOID
  ) {
    return null;
  }

  const tooltip = t(getFastpayTooltip(invoice));
  const color = getFastpayColor(invoice);
  return (
    <FastpayTooltip
      enabled={fastpayGranted}
      tooltip={!fastpayGranted ? `${tooltip} Hello@wonderschool.com` : tooltip}
      color={color}
    />
  );
}
