import { useEffect, useLayoutEffect, useState } from 'react';

const getContentSidebarPusherDom = (id) => {
  return document.querySelector(`#${id}`);
}

const useContentSidebarPusherScrollPosition = ({sliderOpen, id}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useLayoutEffect(() => {
    const handleOnScrollEvent = () => {
      setScrollPosition(
        getContentSidebarPusherDom(id).scrollTop
      );
    };
    
    const dom = getContentSidebarPusherDom(id);
    dom.addEventListener('scroll', handleOnScrollEvent, { passive: true });
    // for cleanup
    return () => {
      dom.removeEventListener('scroll', handleOnScrollEvent);
    };
  }, [id]); // fire only once per render

  useEffect(() => {
    if (sliderOpen) setLastScrollPosition(scrollPosition);
    else getContentSidebarPusherDom(id).scrollTo(0, lastScrollPosition);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderOpen]); // sliderOpen is the only use state this effect needs to monitor.
};

export default useContentSidebarPusherScrollPosition;
