import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Confirm, Dropdown, Segment } from 'semantic-ui-react';

import { userHasPermission } from '../../../api/firebase/account';
import useAdminActions from '../../../hooks/useAdminActions';
import { showSuccessToast, showErrorToast } from '../../Shared/showToast';

const initialState = {
  loading: false,
  open: false,
  confirmation: { header: '', content: '' },
  selectedOption: null,
};

const UserManage = ({ user }) => {
  const [state, setState] = useState(initialState);
  const { resetUserPassword } = useAdminActions();
  const { t } = useTranslation();

  const userOptions = [
    {
      key: 'reset_password',
      value: 'reset_password',
      text: t('Reset password'),
      icon: 'key',
      permission: 'can_manage_users', // It's possible to define option level permissions.
      confirmation: {
        header: t('Reset password'),
        content: (
          <Segment basic className="no-adding">
            {t('Are you sure you want to send a password reset link to user?')}
          </Segment>
        ),
      },
    },
  ];

  const handleConfirmation = async ({ value, text }) => {
    setState({
      ...initialState,
      loading: true,
    });

    try {
      switch (value) {
        case 'reset_password':
          await resetUserPassword(user);
          // Password reset link sent successfully.
          showSuccessToast(
            `${text} ${t('Link Sent Successfully')}`,
            <>
              {t('Password reset link sent to')} <b>{user.email}</b>
            </>
          );
          break;

        default:
          // Unknown option abort
          return;
      }
    } catch (error) {
      console.log(error);
      showErrorToast(`${text} Failed`, t('Unable to reset user password'));
    }
    // Reset state.
    setState(initialState);
  };

  return (
    <>
      <Confirm
        header={state.confirmation.header}
        content={state.confirmation.content}
        open={state.open}
        onCancel={() => setState(initialState)}
        onClose={() => setState(initialState)}
        onConfirm={() => handleConfirmation(state.selectedOption)}
        confirmButton={t('Yes')}
        size="small"
      />
      <Dropdown icon="cog" direction="left" loading={state.loading}>
        <Dropdown.Menu>
          <Dropdown.Menu scrolling>
            {userOptions.map((option) => {
              // If no permission is defined, it defaults to 'can_manage_users'.
              const permission = option.permission || 'can_manage_users';

              // If the user doesn't have permission, don't show the option
              if (!userHasPermission(permission)) return null;

              return (
                <Dropdown.Item
                  key={option.key}
                  {...option}
                  onClick={() =>
                    setState({
                      ...initialState,
                      selectedOption: option,
                      confirmation: option.confirmation,
                      open: true,
                    })
                  }
                />
              );
            })}
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default UserManage;
