import LS from 'local-storage';

/**
 * Class to simplify local storage usage for one particular key
 */
class LocalStorageKey {
  /**
   * saves initialization values and internal references
   * if no init value is provided, it relies on pre-existing value, if any.
   * @param {*} key unique key used to access
   * @returns {void}
   */
  constructor(key) {
    this.key = key;
    this.store = LS;
  }
  /**
   * @returns {*} saved value
   */
  get = () => {
    try {
      return this.store.get(this.key);
    } catch {
      this.remove();
      return undefined;
    }
  };
  /**
   * @param {*} val value to save
   * @returns {boolean} whether successful
   */
  set = (val) => {
    // json.stringify(undefined) is a special case https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#description
    if (val === undefined) {
      this.remove();
      return true;
    } else {
      return this.store.set(this.key, val);
    }
  };
  /**
   * removes key from local storage
   * @returns {boolean} whether successful
   */
  remove = () => {
    return this.store.remove(this.key);
  };
}

export default LocalStorageKey;
