import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { Checkbox, Form, Image, Segment } from 'semantic-ui-react';
import Validator from 'validator';

import { userHasPermission } from '../../../api/firebase/account';
import { updateSetupStatus } from '../../../api/firebase/setup';
import { ethnicityOptions, raceOptions } from '../../../config';
import {
  formatStringAsUtcMillisOrNull,
  formatUtcMillisAsString,
} from '../../../helpers/dates';
import { getRandomStudentPicture } from '../../studentsUtils';
// Import utils
import {
  formatFullName,
  phoneNumberFormat,
  phoneNumberParse,
} from '../../../helpers/utils';
import { getRooms } from '../../../redux/actions/roomActions';
// Import actions
import {
  organizationAddStudent,
  organizationUpdateStudent,
  studentSelected,
  studentSelectionCleared,
} from '../../studentsRedux';

// Import components
import { InlineError, ShowErrors } from '../../../Components/Messages';
import RoomPicker from '../../../Components/Rooms/RoomPicker';
import { DatePicker } from '../../../Components/Shared/DatePicker';
import { Dropdown } from '../../../Components/Shared/Dropdown';
import { SchedulePicker } from '../../../Components/Shared/SchedulePicker';
import StatePicker from '../../../Components/Shared/StatePicker';
import ProfilePictureUploader from '../../../Components/Upload/ProfilePictureUploader';

class StudentPersonalInformationForm extends Component {
  state = {
    data: {
      id: '',
      firstName: '',
      middleName: '',
      lastName: '',
      nickName: '',
      birthday: null,
      gender: '',
      race: '',
      ethnicity: '',
      allergies: '',
      medications: '',
      doctorName: '',
      doctorPhone: '',
      notes: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      organization: '',
      picture: '',
      rooms: [],
      schedule: ['M', 'T', 'W', 'Th', 'F'],
      enrollmentStatus: false,
      photosAllowed: true,
    },
    loading: false,
    errors: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      students: { selectedStudent },
    } = nextProps;

    if (
      !_.isEmpty(selectedStudent.id) &&
      prevState.data.id !== selectedStudent.id
    ) {
      const { doctorPhone, birthday, ...rest } = selectedStudent;
      return {
        data: {
          ...prevState.data,
          doctorPhone: phoneNumberParse(doctorPhone),
          birthday: formatUtcMillisAsString(birthday),
          ...rest,
        },
      };
    }
    return prevState;
  }

  componentWillUnmount() {
    if (!this.props.clearOnUnmount) return;
    // Clear selection from store.
    this.props.studentSelectionCleared();
  }

  onChange = (e, { name, value, checked }) => {
    // FIXME: TEMPORARY SOLUTION TO SET SINGLE STUDENT ROOM
    if (name === 'rooms') value = [value];
    this.setState({
      data: {
        ...this.state.data,
        [name]: !_.isUndefined(checked) ? checked : value,
        picture:
          !_.isUndefined(checked) && !checked ? '' : this.state.data.picture, // Clear student photo when allow photo is switched off.
      },
      errors: _.omit(this.state.errors, name),
    });
  };

  handleScheduleChange = (schedule) => {
    this.setState({
      data: {
        ...this.state.data,
        schedule,
      },
    });
  };

  maskedOnChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: _.omit(this.state.errors, e.target.name),
    });
  };

  handleBirthdayChange = (e, { name, value, valid }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
      errors: valid ? _.omit(this.state.errors, name) : this.state.errors,
    });
  };

  validate = (data) => {
    const {
      t,
      currentOrganization: { programType },
    } = this.props;
    const errors = {};

    if (!data.firstName) errors.firstName = t('First Name is required');
    if (!data.lastName) errors.lastName = t('Last Name is required');

    if (!data.race) errors.race = t('Race is required');
    if (!data.ethnicity) errors.ethnicity = t('Ethnicity is required');

    if (
      programType !== 'familyChildCare' &&
      (!data.rooms || !data.rooms.length)
    )
      errors.rooms = t('Homeroom is required');
    if (data.doctorPhone)
      if (!Validator.isMobilePhone(data.doctorPhone, 'en-US'))
        errors.doctorPhone = t('Phone is invalid');

    return errors;
  };

  isSetupRoute = () => {
    const { match } = this.props;
    return match.path === '/setup';
  };

  saveChanges = async () => {
    const {
      data: { id, doctorPhone, picture, birthday, displayName, ...rest },
    } = this.state;
    const { currentOrganization } = this.props;

    if (currentOrganization && currentOrganization.id) {
      this.setState({ loading: true });

      const formData = {
        id,
        birthday: formatStringAsUtcMillisOrNull(birthday),
        displayName: formatFullName(rest),
        doctorPhone: phoneNumberFormat(doctorPhone),
        picture: picture ? picture : getRandomStudentPicture(),
        ...rest,
      };

      try {
        await this.props.organizationUpdateStudent(
          currentOrganization.id,
          formData
        );
        this.setState({ loading: false }, () => {
          // Used to close wrapper HOC. e.g Sliding panel, modal...
          this.props.onClose('saved');
        });
      } catch (error) {
        this.setState({
          loading: false,
          errors: { 'Unable save data': error.message },
        });
      }
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const {
        data: { id, doctorPhone, picture, birthday, displayName, ...rest },
      } = this.state;
      const {
        currentOrganization,
        currentOrganization: { programType },
      } = this.props;

      this.setState({ loading: true });

      let formData = {
        birthday: formatStringAsUtcMillisOrNull(birthday),
        displayName: formatFullName(rest),
        doctorPhone: phoneNumberFormat(doctorPhone),
        picture: picture ? picture : getRandomStudentPicture(),
        ...rest,
      };

      if (programType && programType === 'familyChildCare') {
        const roomId = `${currentOrganization.id}-primaryRoom`;

        formData = {
          ...formData,
          rooms: [roomId],
        };
      }

      if (currentOrganization && currentOrganization.id)
        if (id) {
          // Update.
          this.props
            .organizationUpdateStudent(currentOrganization.id, {
              id,
              ...formData,
              organization: currentOrganization.id,
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              this.props.onClose('updated');
              // return;

              this.setState({ loading: false });
              if (this.props.done) this.props.done();
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { 'Unable to Update': error.message },
              })
            );
        } else {
          // New entry.
          this.props
            .organizationAddStudent(currentOrganization.id, {
              ...formData,
              organization: currentOrganization.id,
            })
            .then(() => {
              // Update setup flags.
              updateSetupStatus(currentOrganization.id, 'firstStudentCreated');
              this.setState({ loading: false }, () => {
                // Used to close wrapper HOC. e.g Sliding panel, modal...
                this.props.onClose('added');
              });
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { 'Unable to Add': error.message },
              })
            );
        }
    }
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  setFCCroom = () => {
    const {
      currentOrganization: { programType },
    } = this.props;

    // Select default location and default room for FCC
    if (
      programType &&
      programType === 'familyChildCare' &&
      _.isEmpty(this.state.data.rooms)
    ) {
      const rooms = getRooms();

      this.setState({
        data: {
          ...this.state.data,
          rooms: [rooms[0]?.id],
        },
      });
    }
  };

  render() {
    const { data, loading, errors } = this.state;
    const {
      currentOrganization: { programType },
      t,
    } = this.props;

    return (
      <>
        <Segment basic textAlign="left">
          <ShowErrors errors={errors} />
          <Form
            id="student-personal-information-form"
            onSubmit={this.onSubmit}
            onKeyDown={this.onKeyDown}
            loading={loading}
            noValidate
          >
            <Form.Group widths="equal">
              <Form.Field error={!!errors.firstName}>
                <Form.Input
                  required
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={data.firstName}
                  onChange={this.onChange}
                  label={t('First Name')}
                  placeholder={t('First Name')}
                />
                {errors.firstName && <InlineError text={errors.firstName} />}
              </Form.Field>

              <Form.Field error={!!errors.middleName} width={6}>
                <Form.Input
                  type="text"
                  id="middleName"
                  name="middleName"
                  value={data.middleName}
                  onChange={this.onChange}
                  label={t('Middle Name')}
                  placeholder={t('Middle Name')}
                />
                {errors.middleName && <InlineError text={errors.middleName} />}
              </Form.Field>

              <Form.Field error={!!errors.lastName}>
                <Form.Input
                  required
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={data.lastName}
                  onChange={this.onChange}
                  label={t('Last Name')}
                  placeholder={t('Last Name')}
                />
                {errors.lastName && <InlineError text={errors.lastName} />}
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field error={!!errors.nickName}>
                <Form.Input
                  type="text"
                  id="nickName"
                  name="nickName"
                  value={data.nickName}
                  onChange={this.onChange}
                  label={t('Nickname')}
                  placeholder={t('Nickname')}
                />
                {errors.nickName && <InlineError text={errors.nickName} />}
              </Form.Field>
            </Form.Group>

            {(programType === null ||
              (programType && programType !== 'familyChildCare')) && (
              <Form.Group widths="equal">
                <Form.Field error={!!errors.rooms}>
                  <RoomPicker
                    error={!!errors.rooms}
                    id="rooms"
                    name="rooms"
                    label={t('Homeroom')}
                    placeholder={t('Homeroom')}
                    value={data?.rooms[0] ?? ''}
                    selection
                    search
                    required
                    onChange={this.onChange}
                  />
                  {errors.rooms && <InlineError text={errors.rooms} />}
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group widths="equal">
              <SchedulePicker
                error={!!errors.schedule}
                id="schedule"
                name="schedule"
                label={t('Schedule')}
                value={data?.schedule ?? []}
                onScheduleChange={this.handleScheduleChange}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field width={16}>
                <DatePicker
                  id="birthday"
                  name="birthday"
                  value={data.birthday}
                  onChange={this.handleBirthdayChange}
                  label={t('Birthday')}
                  placeholder={t('Select Birthday')}
                  maxDate={moment()}
                  error={!!errors.birthday}
                  closable
                >
                  {errors.birthday && <InlineError text={errors.birthday} />}
                </DatePicker>
              </Form.Field>

              <Form.Field error={!!errors.gender}>
                <Dropdown
                  translator={t}
                  id="gender"
                  name="gender"
                  label={t('Gender')}
                  placeholder="Gender"
                  selectOnBlur={false}
                  value={data.gender}
                  onChange={this.onChange}
                  isForm
                  options={[
                    { text: 'Male', value: 'Male' },
                    { text: 'Female', value: 'Female' },
                    { text: 'Other', value: 'Other' },
                  ]}
                />
                {errors.gender && <InlineError text={errors.gender} />}
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field error={!!errors.race}>
                <Dropdown
                  translator={t}
                  isForm
                  id="race"
                  name="race"
                  label={t('Race')}
                  placeholder="Race"
                  required
                  selectOnBlur={false}
                  value={data.race}
                  onChange={this.onChange}
                  options={raceOptions}
                />
                {errors.race && <InlineError text={errors.race} />}
              </Form.Field>

              <Form.Field error={!!errors.ethnicity}>
                <Dropdown
                  translator={t}
                  isForm
                  id="ethnicity"
                  name="ethnicity"
                  label={t('Ethnicity')}
                  placeholder="Ethnicity"
                  required
                  selectOnBlur={false}
                  value={data.ethnicity}
                  onChange={this.onChange}
                  options={ethnicityOptions}
                />
                {errors.ethnicity && <InlineError text={errors.ethnicity} />}
              </Form.Field>
            </Form.Group>

            <Form.Field error={!!errors.photosAllowed}>
              <Form.Radio
                toggle
                id="photosAllowed"
                name="photosAllowed"
                label={t('Photos Allowed')}
                onChange={this.onChange}
                checked={data.photosAllowed}
                control={Checkbox}
              />
              {errors.photosAllowed && (
                <InlineError text={errors.photosAllowed} />
              )}
            </Form.Field>

            <Form.Group widths="equal">
              {userHasPermission('can_edit_student') &&
                data.id &&
                this.props.currentOrganization.id && (
                  <Form.Field
                    error={!!errors.picture}
                    label={t('Student Photo')}
                    control={ProfilePictureUploader}
                    uploadPath={`organizations/${this.props.currentOrganization.id}/students/${data.id}`}
                    title="Uploading Student Picture"
                    onUploaded={(imageUrl) => {
                      this.setState(
                        {
                          data: { ...this.state.data, picture: imageUrl },
                        },
                        () => {
                          if (!this.isSetupRoute()) this.saveChanges();
                        }
                      );
                    }}
                  >
                    {errors.picture && <InlineError text={errors.picture} />}
                  </Form.Field>
                )}
              {data && data.picture && (
                <Segment basic>
                  <Image src={data.picture} size="medium" rounded />
                </Segment>
              )}
            </Form.Group>

            <br />
            <br />

            <Form.Field error={!!errors.address1}>
              <Form.Input
                type="text"
                id="address1"
                name="address1"
                value={data.address1}
                onChange={this.onChange}
                label={t('Address 1')}
                placeholder={t('Address 1')}
              />
              {errors.address1 && <InlineError text={errors.address1} />}
            </Form.Field>

            <Form.Field error={!!errors.address2}>
              <Form.Input
                type="text"
                id="address2"
                name="address2"
                value={data.address2}
                onChange={this.onChange}
                label={t('Address 2')}
                placeholder={t('Address 2')}
              />
              {errors.address2 && <InlineError text={errors.address2} />}
            </Form.Field>

            <Form.Field error={!!errors.city}>
              <Form.Input
                type="text"
                id="city"
                name="city"
                value={data.city}
                onChange={this.onChange}
                label={t('City')}
                placeholder={t('City')}
              />
              {errors.city && <InlineError text={errors.city} />}
            </Form.Field>

            <Form.Group widths="equal">
              <Form.Field error={!!errors.state}>
                <Form.Field
                  id="state"
                  name="state"
                  label={t('State')}
                  control={StatePicker}
                  placeholder={t('State')}
                  value={data.state}
                  selection
                  search
                  onChange={this.onChange}
                />
                {errors.state && <InlineError text={errors.state} />}
              </Form.Field>

              <Form.Field error={!!errors.zipcode}>
                <Form.Input
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  value={data.zipcode}
                  onChange={this.onChange}
                  label={t('Zip Code')}
                  placeholder={t('Zip Code')}
                />
                {errors.zipcode && <InlineError text={errors.zipcode} />}
              </Form.Field>
            </Form.Group>

            <br />
            <br />

            <Form.Field error={!!errors.allergies}>
              <Form.Input
                type="text"
                id="allergies"
                name="allergies"
                value={data.allergies}
                onChange={this.onChange}
                label={t('Allergies')}
                placeholder={t('If there are any allergies, add it here')}
              />
              {errors.allergies && <InlineError text={errors.allergies} />}
            </Form.Field>

            <Form.Field error={!!errors.medications}>
              <Form.Input
                type="text"
                id="medications"
                name="medications"
                value={data.medications}
                onChange={this.onChange}
                label={t('Medications')}
                placeholder={t('If there are any medications, add it here')}
              />
              {errors.medications && <InlineError text={errors.medications} />}
            </Form.Field>

            <Form.Group widths="equal">
              <Form.Field error={!!errors.doctorName}>
                <Form.Input
                  type="text"
                  id="doctorName"
                  name="doctorName"
                  value={data.doctorName}
                  onChange={this.onChange}
                  label={t('Doctor Name')}
                  placeholder={t('Dr. Jane Doe')}
                />
                {errors.doctorName && <InlineError text={errors.doctorName} />}
              </Form.Field>

              <Form.Field error={!!errors.doctorPhone}>
                <Form.Input
                  type="text"
                  id="doctorPhone"
                  name="doctorPhone"
                  onChange={this.maskedOnChange}
                  label={t('Phone Number')}
                  control={MaskedInput}
                  mask={[
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  value={data.doctorPhone}
                  guide={false}
                  placeholder={'(123) 456-7890'}
                />
                {errors.doctorPhone && (
                  <InlineError text={errors.doctorPhone} />
                )}
              </Form.Field>
            </Form.Group>

            <Form.Field error={!!errors.notes}>
              <Form.TextArea
                type="text"
                id="notes"
                name="notes"
                value={data.notes}
                onChange={this.onChange}
                label={t('Notes')}
                placeholder={t('Add any relevant notes')}
              />
              {errors.notes && <InlineError text={errors.notes} />}
            </Form.Field>

            <br />
            <br />

            <Form.Group>
              <Form.Button primary content={t('Save')} />
              {!this.props.hideCancel && (
                <Form.Button
                  basic
                  content={t('Cancel')}
                  onClick={(e) => {
                    if (e) e.preventDefault();
                    this.props.onClose();
                  }}
                />
              )}
            </Form.Group>
          </Form>
        </Segment>
      </>
    );
  }
}

StudentPersonalInformationForm.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  students: PropTypes.shape({
    selectedStudent: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  organizationAddStudent: PropTypes.func,
  organizationUpdateStudent: PropTypes.func,
  studentSelectionCleared: PropTypes.func,
  studentSelected: PropTypes.func,
  onClose: PropTypes.func,
};

StudentPersonalInformationForm.defaultProps = {
  onClose: () => {},
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  students: state.students,
});

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, {
      organizationAddStudent,
      organizationUpdateStudent,
      studentSelectionCleared,
      studentSelected,
    })(StudentPersonalInformationForm)
  )
);
