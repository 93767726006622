import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getAgeFromBirthday } from '../helpers/utils';
import { useInvitations } from '../hooks/useInvitations';

import { getPrimaryLocation, searchStudents } from './studentsUtils';

/**
 * Grabs student data from redux store.
 * @returns {Object}
 */
export const useStudents = (
  { filters, searchText } = { filters: {}, searchText: '' }
) => {
  const students = useSelector((state) => state?.students ?? {});

  const invitations = useInvitations();

  // modify and return a cloned object of students rather than mutating the source
  let studentsListFiltered = [ ...students.list ];

  // attach pendingParentInvites to the student
  let studentInvitationsDictionary = {}

  const addToStudentInvitationsDictionary = (studentId, invitation) => {
    const { firstName, lastName, displayName, id: invitationId } = invitation;

    if (studentInvitationsDictionary[studentId]) {
      studentInvitationsDictionary[studentId][invitationId] = {
        firstName,
        lastName,
        displayName,
      }
    } else {
      studentInvitationsDictionary[studentId] = {
        [invitationId]: {
          firstName,
          lastName,
          displayName,
        }
      }
    }
  }
  invitations.list.forEach(invitation => {
    const { student, studentIds } = invitation
    if (studentIds) {
      studentIds.forEach (studentId => addToStudentInvitationsDictionary (studentId, invitation))
    } else if (student) {
      addToStudentInvitationsDictionary (student.id, invitation)
    }
  })

  studentsListFiltered.forEach(student => {
    let combinedFamily = { ...student.family, ...student.contacts }
    const studentInviteKeys = studentInvitationsDictionary[student.id] ? Object.keys(studentInvitationsDictionary[student.id]) : []

    for (const inviteKey of studentInviteKeys) {
      const familyMemberInvite = studentInvitationsDictionary[student.id][inviteKey]
      combinedFamily = {
        [inviteKey]: familyMemberInvite,
        ...combinedFamily,
      }
    }

    student.combinedFamily = combinedFamily
    return student;
  });

  const rooms = useSelector((state) => state?.rooms?.list ?? []);
  const locations = useSelector((state) => state?.locations?.list ?? []);

  // Returns the location object of the selected student.
  const getStudentLocation = useCallback(() => {
    const studentRoomId = students?.selectedStudent?.rooms?.length
      ? students.selectedStudent.rooms[0]
      : null;
    if (!studentRoomId) return null;
    const studentRoom = rooms.find((r) => r.id === studentRoomId);

    if (studentRoom?.location) {
      const studentLocation = locations.find(
        (l) => l.id === studentRoom.location
      );
      return studentLocation ?? null;
    }

    return null;
  }, [students, locations, rooms]);

  if (searchText) {
    studentsListFiltered = searchStudents(searchText, studentsListFiltered)
  }

  // Filter students by room.
  if (filters.room) {
    // Filter students without rooms.
    if (filters.room === 'no-room') {
      studentsListFiltered = studentsListFiltered.filter((student) => (
        !student.hasOwnProperty('rooms') ||
        !student?.rooms?.length ||
        (student.rooms.length === 1 && student.rooms[0] === '')
      ));
    } else {
      studentsListFiltered = studentsListFiltered.filter((student) =>
        student?.rooms?.includes(filters.room)
      );
    }
  }

  // Filter students by enrollment status.
  if (filters.enrollmentStatus) {
    studentsListFiltered = studentsListFiltered.filter((student) => {
      if (filters.enrollmentStatus === 'active')
        return student?.enrollmentStatus === true;
      else if (filters.enrollmentStatus === 'unenrolled')
        return student?.enrollmentStatus === false || !student.enrollmentStatus;

      return true;
    });
  }

  // Filter students by age. (filters.age is an array of years)
  if (filters.age?.length) {
    studentsListFiltered = studentsListFiltered.filter(student => {
      // returns null if !student.birthday
      const age = getAgeFromBirthday(student.birthday)

      for (const filterAgeOption of filters.age) {
        if (!age) {
          return false
        }
        if (filterAgeOption === age.years.toString()) {
          return true
        }
        if (filterAgeOption === '12' && age.years > 11) {
          return true
        }
      }

      return false
    })
  }

  if (filters.schedule?.length) {
    studentsListFiltered = studentsListFiltered.filter(student => {
      for (const day of filters.schedule) {
        if (!student.schedule?.includes(day)) {
          return false
        }
      }

      return true
    })
  }

  return {
    ...students,
    listFiltered: studentsListFiltered,
    count: students.list.length,
    getStudentLocation,
  };
};
export function useSelectedStudent() {
  return useSelector(state => state?.students?.selectedStudent);
}

export function useStudent(studentId) {
  return useSelector(state => state?.students?.list.find(student => student.id === studentId));
}

export function useLoadedStudents(studentIds) {
  return useSelector(state => state?.students?.list.filter(student => studentIds.includes(student.id)));
}

export function useSelectedFamilyMember() {
  return useSelector(state => state?.students?.selectedStudent?.selectedFamilyMember);
}

export function useSelectedStudentOrPrimaryLocation() {
  let location = useSelectedStudentLocation()
  const locations = useSelector(state => state?.locations?.list);

  return location ?? getPrimaryLocation(locations);
}

export function useSelectedStudentLocation() {
  const student = useSelectedStudent()
  return useStudentLocation(student);
}

export function useStudentOrPrimaryLocation(student) {
  const location = useStudentLocation(student);
  const locations = useSelector(state => state?.locations?.list);
  return location ?? getPrimaryLocation(locations);
}
export function useStudentLocation(student) {
  const rooms = useSelector((state) => state?.rooms?.list ?? []);
  const locations = useSelector((state) => state?.locations?.list ?? []);

  return useMemo(() => {
    return getStudentLocation(student, rooms, locations);
  }, [student, rooms, locations]);
}

function getStudentLocation(student, rooms, locations) {
  if (!student) return null;

  const studentRoomId = student.rooms?.length
    ? student.rooms[0]
    : null;

  if (!studentRoomId) return null;

  const studentRoom = rooms.find((r) => r.id === studentRoomId);

  if (studentRoom?.location) {
    const studentLocation = locations.find(
      (location) => location.id === studentRoom.location
    );
    return studentLocation ?? null;
  }
  return null;
}
