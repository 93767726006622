import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// API
import { functions } from '../../../api/firebase';

export default function UserRolesDropdown ({ user, userRoles, onUserRolesChange }) {
  const { email } = user;
  const [loading, setLoading] = useState(false);
  const [defaultRoles, setDefaultRoles] = useState([]);

  const { t } = useTranslation();

  useEffect (() => {
    async function fetchData() {
      setLoading(true);
      try {
        setDefaultRoles (await fetchDefaultRoles());
        onUserRolesChange ( await fetchUserRoles(email));

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false)
      }

    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Field>
      <Form.Dropdown
        fluid
        multiple
        search
        selection
        id="roles"
        name="roles"
        label={t('User roles')}
        placeholder={t('Select user roles')}
        options={defaultRoles}
        value={userRoles}
        onChange={onChange}
        loading={loading}
      />
    </Form.Field>

  )
  function onChange (e, { name, value }) {
    onUserRolesChange(value);
  }
}

async function fetchDefaultRoles() {
  const roles = await functions().httpsCallable(
    'callables-users-getDefaultRoles'
  )();

  const options = [];

  Object.keys(roles.data).map((key) =>
    options.push({
      key,
      text: roles.data[key].name,
      value: key,
    })
  );
  return options;
}

async function fetchUserRoles (email) {
  if (!email) return null;

  const roles = await functions().httpsCallable(
    'callables-users-getUserRoles'
  )({ email });

  const allUserRoles = [];

  Object.keys(roles.data?.customClaims).map((claim) =>
    allUserRoles.push(claim)
  );
  return allUserRoles;

}
