import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form, Checkbox, Button, Card, Header, Modal, Segment } from 'semantic-ui-react';
// API
import { auth, functions } from '../../../api/firebase';
import { firebaseConfig } from '../../../api/firebase/config';
import { getEnv } from '../../../config/env';

import {
  sendInvoicesIfDue,
  sendInvoicePlansIfDue,
  backfillInvoicePayoutStatus,
  syncRootUserWithOrgUser,
  backfillStudentPreventInvoiceReason,
  backfillTransactionsForPaidInvoices,
  sendSinceFeature,
  setFastpayForOrganizations,
  setFastpayForUsers,
  resetFastpayBalanceForOrganizations,
  resetFastpayBalanceForUsers,
} from '../../../api/firebase/pubsub';
import { removeDuplicates } from '../../../api/firebase/locations';
import { fetchInvoicePlansWithMultipleStudents } from '../../../api/firebase/invoices';

const Area51Container = ({ currentOrganization, user }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isFastpayEnabled, setIsFastpayEnabled] = useState(false);
  const { t } = useTranslation();

  const FUNCTIONS_PATH =
    getEnv() === 'local'
      ? `http://localhost:5001/moxit-wonderschool-dev/us-central1`
      : `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`;

  const requestConsent = async () => {
    try {
      const userToken = await auth().currentUser.getIdToken();

      const response = await fetch(
        `${FUNCTIONS_PATH}/endpoints-forms/ds-get-consent-url`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.ok) {
        const consentURL = await response.text();
        window.open(consentURL, '_blank');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderPageTitle = () => {
    return (
      <Segment basic clearing>
        <Header as="h1">
          {t('Area 51 👽🛸')}
          <Header.Subheader>
            {t('We really hope you know what you are doing here')}
          </Header.Subheader>
        </Header>
      </Segment>
    );
  };

  const renderUserRolesDetail = () => {
    return (
      <Modal
        closeIcon
        centered={false}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{t('Update user roles')}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {t('More useful stuff to do here...')}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  const renderActionCards = () => (
    <Segment>
      <Card.Group>
        <Card>
          <Card.Content>
            <Card.Header>{t('Counters')}</Card.Header>
            <Card.Meta>{t('Recalculate all the counters')}</Card.Meta>
            <Card.Description>
              {t(
                'Recalculates all the counters like number of students, staff, checked-in students, rooms, etc... within this organization.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Refresh Counts')}
              loading={loading}
              onClick={async () => {
                if (currentOrganization?.id) {
                  try {
                    setLoading(true);
                    await functions().httpsCallable(
                      'callables-stats-refreshAllOrganizationStats'
                    )({});
                    setLoading(false);
                  } catch (error) {
                    setLoading(false);
                    console.log(error);
                  }
                }
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Permissions')}</Card.Header>
            <Card.Meta>{t('Reset all the permissions')}</Card.Meta>
            <Card.Description>
              {t(
                'This resets ALL the permissions for ALL organizations to the default values. Cuidate!'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="lock"
              primary
              content={t('Reset All Permissions')}
              loading={loading}
              onClick={async () => {
                setLoading(true);

                try {
                  await functions().httpsCallable(
                    'callables-organizations-resetAllPermissions'
                  )();
                } catch (error) {
                  console.log(error);
                }

                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('DocuSign')}</Card.Header>
            <Card.Meta>{t('Obtain DocuSign user consent')}</Card.Meta>
            <Card.Description>
              {t(
                'Connect DocuSign admin user, that will be asked to grant consent for the set of scopes that the application has requested.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="lock"
              primary
              content={t('Request Consent')}
              loading={loading}
              onClick={requestConsent}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Fix Rooms/Locations')}</Card.Header>
            <Card.Meta>{t('Fixes legacy room and location objects')}</Card.Meta>
            <Card.Description>
              {t(
                `This action will fix legacy room and location objects, by adding 'enabled' properties to each object.`
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Fix Rooms & Locations')}
              loading={loading}
              onClick={async () => {
                setLoading(true);
                try {
                  const res = await functions().httpsCallable(
                    'callables-fixes-roomsLocationsEnabled'
                  )();
                  console.log(res.data);
                } catch (error) {
                  console.log(error.details);
                  console.log('fix-rooms', error);
                }
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Card.Header>{t('Scheduled Invoices')}</Card.Header>
            <Card.Meta>
              {t('Initiate scheduled invoices and invoice plans')}
            </Card.Meta>
            <Card.Description>
              {t('This will send any pending invoices. Cuidate!')}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="lock"
              primary
              content={t('Send Scheduled Invoices')}
              loading={loading}
              style={{ marginBottom: '1em' }}
              onClick={async () => {
                setLoading(true);
                await sendInvoicesIfDue();
                setLoading(false);
              }}
            />
            <Button
              basic
              fluid
              color="blue"
              icon="lock"
              primary
              content={t('Send Invoice Plans')}
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await sendInvoicePlansIfDue();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Duplicated Locations')}</Card.Header>
            <Card.Meta>{t('Removes duplicated Locations')}</Card.Meta>
            <Card.Description>
              {t(
                'Removes all locations that were duplicated when DataSync had a bug'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Remove duplicated Locations')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await removeDuplicates();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Card.Header>{t('Invoice Payout Status')}</Card.Header>
            <Card.Meta>
              {t('Backfill all invoices with Payout status')}
            </Card.Meta>
            <Card.Description>
              {t(
                'Will process all payouts to ensure invoice has latest payout status.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Backfill Invoices')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await backfillInvoicePayoutStatus();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Sync Root/User with Org/User')}</Card.Header>
            <Card.Description>
              {t(
                'Adds the "locations" field present for staff in /organizations/.../users/ to the root /users/ document.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Sync Users')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                try {
                  const res = await syncRootUserWithOrgUser();
                  console.log(res);
                } catch (error) {
                  console.log('user sync error: ', error);
                }
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              {t('Create transactions for paid invoices')}
            </Card.Header>
            <Card.Description>
              {t('Creates transactions for paid invoices.')}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Create transactions')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await backfillTransactionsForPaidInvoices();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              {t('Temp Student Prevent Invoice Reason Backfill')}
            </Card.Header>
            <Card.Description>
              {t(
                'Will process all students to set their prevent invoice creation reason. This script will be deleted after it has been run on production.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Backfill Students')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await backfillStudentPreventInvoiceReason();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Fix Room Age Ranges')}</Card.Header>
            <Card.Meta>{t('Fixes legacy room objects')}</Card.Meta>
            <Card.Description>
              {t(
                `This action will fix legacy room objects by updating age ranges to current format.`
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Fix Room Age Ranges')}
              loading={loading}
              onClick={async () => {
                setLoading(true);
                try {
                  const res = await functions().httpsCallable(
                    'callables-fixes-roomsAgeRangeUpdates'
                  )();
                  console.log(res.data);
                } catch (error) {
                  console.log(error.details);
                  console.log('fix-rooms', error);
                }
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              {t('Count active invoice plans with multiple students')}
            </Card.Header>
            <Card.Description>
              {t(
                'Temp util to log active invoice plans that have multiple students.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Count plans')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                const invoicePlans =
                  await fetchInvoicePlansWithMultipleStudents();
                const invoicePlanIds = invoicePlans.map(({ id }) => id);
                console.log(
                  `Invoice plan ids: ${invoicePlanIds}\nCount: ${invoicePlans.length}`
                );
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              {t('Reprocess Invoice since feature release')}
            </Card.Header>
            <Card.Description>
              {t(
                'This will process all invoices that are in status of open or draft that do not have a stripe id. These invoices where not processed due to a bug in a new feature released on July 14 2022.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Reprocess Invoices')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await sendSinceFeature();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              {t('Update user payment methods')}
            </Card.Header>
            <Card.Description>
              {t(
                'Tries to update invalid payment sources for users with the Stripe payment source.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Update payment methods')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await functions().httpsCallable(
                  'callables-backfill-updateUserPaymentMethods'
                )();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Turn on/off fastpay feature')}</Card.Header>
            <Card.Description>
              {t('setFastpayEnabledToolDescription')}
            </Card.Description>
            <Card.Description>
              <Form.Radio
                toggle
                id="setFastpayToggle"
                name="setFastpayToggle"
                label={t(
                  isFastpayEnabled ? 'Turn on fastpay' : 'Turn off fastpay'
                )}
                style={{ marginTop: '1em' }}
                checked={isFastpayEnabled}
                onChange={(_event, { checked }) => setIsFastpayEnabled(checked)}
                control={Checkbox}
              />
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              style={{ marginBottom: '1em' }}
              content={t('Set to organizations')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await setFastpayForOrganizations(isFastpayEnabled);
                setLoading(false);
              }}
            />
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Set to users')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await setFastpayForUsers(isFastpayEnabled);
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>


        <Card>
          <Card.Content>
            <Card.Header>{t('Reset fastpay balance')}</Card.Header>
            <Card.Description>
              {t('resetFastpayBalanceToolDescription')}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              style={{ marginBottom: '1em' }}
              content={t('Reset organizations balance')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await resetFastpayBalanceForOrganizations();
                setLoading(false);
              }}
            />
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Reset users balance')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await resetFastpayBalanceForUsers();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              {t('Update invoice locations')}
            </Card.Header>
            <Card.Description>
              {t(
                'Updates invoice and invoice plans with missing location.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Update locations')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await functions().httpsCallable(
                  'callables-backfill-getInvoiceLocations'
                )();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Sync invoice plan students')}</Card.Header>
            <Card.Description>
              {t(
                'Updates active and paused invoice plans with up to date students'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Update invoice plans')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                await functions().httpsCallable(
                  'callables-backfill-updateInvoicePlansStudents'
                )();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>
      </Card.Group>
    </Segment>
  );

  return (
    <>
      <div>{renderPageTitle()}</div>
      <div>{renderActionCards()}</div>
      <div>{renderUserRolesDetail()}</div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps)(Area51Container);
