import BootstrapTable from 'react-bootstrap-table-next';
import { Segment } from 'semantic-ui-react';
import {  useTranslation } from 'react-i18next';

import { dateFormatter } from '../../helpers/dates';

export default function NetworksTable ({networks, onSelect}) {
  const { t } = useTranslation();

  return (
    <Segment basic clearing className='bootstrap-iso'>
      {networks && (
        <BootstrapTable
          bootstrap4
          keyField='id'
          bordered={false}
          hover
          headerClasses='table-header'
          defaultSortDirection='asc'
          defaultSorted={[{
            dataField: 'name',
            order: 'asc'
          }]}
          data={networks}
          columns={getTableColumns(t)}
          rowEvents={{
            onClick: (e, row, rowIndex) => { if (onSelect) onSelect(row)}
          }}
        />
      )}
    </Segment>
  )
}

function getTableColumns(t) {
  return [
    {
      dataField: 'name',
      text: t('Name'),
      sort: true,
    },
    {
      dataField: 'id',
      text: t('ID'),
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: t('Created At'),
      sort: true,
      formatter: (cell) => {
        return dateFormatter(cell, 'MM/DD/YYYY h:mm:ss A');
      },
      sortValue: (cell) => {
        return cell.toDate !== undefined ? cell.toDate().getTime() : cell;
      }

    },
  ];
}
