import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function NoInvitationWillBeSentConfirmationDialog({
  isOpen,
  onYes,
  onClose
}) {
  const { t } = useTranslation();

  if (!onYes || !onClose)
    throw new Error ('onYes and onClose are required');

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="tiny"
    >
      <Modal.Header>{t('No Invitation')}</Modal.Header>
      <Modal.Content>
        <p>{t('No invitation email will be sent to this user. They will not be able to join Wonderschool.')}</p>
        <p>{t('Continue?')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          {t('No')}
        </Button>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content={t('Yes')}
          onClick={onYes}
        />
      </Modal.Actions>
    </Modal>
  );
}
