import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Header, Segment } from 'semantic-ui-react';

const PageTitle = ({ currentOrganization, user }) => {
  const { t } = useTranslation();
  const today = moment().format('dddd');

  return (
    <Segment basic clearing>
      <Header
        sub
        style={{ marginTop: '1em' }}
        content={currentOrganization && currentOrganization.name}
      />

      <Header
        as="h1"
        style={{ marginTop: 0 }}
        content={t('Happy {{today}}, {{name}}', {
          today: t(today),
          name: user && user.firstName,
        })}
      />
    </Segment>
  );
};

const DashboardHeader = ({ currentOrganization, user }) => {
  return (
    <Container>
      <PageTitle currentOrganization={currentOrganization} user={user} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

// TODO: Add permission trimming.
export default connect(mapStateToProps)(DashboardHeader);
