import { Route, Switch } from 'react-router-dom';

// Import routes
import { routes } from './config/routes';
import GuestRoute from './Components/Routes/GuestRoute';
import RestrictedRoute from './Components/Routes/RestrictedRoute';
import DefaultRoute from './Components/Routes/DefaultRoute';
import VerifyRoute from './Components/Routes/VerifyRoute';

// Import layouts
import ResponsiveContainer from './Components/Layout/ResponsiveContainer';
import { useSegmentIdentify, useSegmentTrackInitializeAction } from './segment';

// Import pages

// Transition Pages
import AuthReturnPage from './Pages/AuthReturn';
import SignoutPage from './Pages/Signout';
import CompleteSignupPage from './Pages/CompleteSignup';
import Pay from './Pages/Pay';
import TokenAuth from './Pages/TokenAuth';

// Guest Pages
import SigninPage from './Pages/Signin';
import SignUpPage from './Pages/Signup';

// User Pages
import routeList from './Components/Routes';
import EmailSentPage from './Pages/EmailSent';

// Misc Pages
import ErrorPage from './Pages/Error';
import UnauthorizedPage from './Pages/Unauthorized';
import { FeatureFlagsPage, useFeatureFlags } from './feature-flags';

// Import API
import { hasNoOrgRestriction } from './api/firebase/account';

// Hooks
import { useOrganization } from './hooks/useOrganizations';
import { FileServiceProvider } from '@wonderschool/file-service-client';
import { auth } from './api/firebase';

const getAuthenticatedRoutes = (features, featureFlags) => {
  const verifyRouteIsEnabled = (route) => {
    const relatedFeature =
      features?.find((f) => f.name === route.name) || false;
    if (relatedFeature?.name) {
      return relatedFeature.enabled;
    }

    if(featureFlags.MainMenuBillingPages.enabled) {
      if(route.name === 'Billing') {
        route.enabled = false;
      }
    } else {
      if(route.name === 'Finances') {
        route.enabled = false;
        route.name += ' disbaled';
  
        if(route.hasChildren){
          route.childrenMenu = route.childrenMenu.map((menuItem) => ({...menuItem, enabled: false}));
        }
      } else if(route.name === 'Settings') {
        if(route.hasChildren) {
          route.childrenMenu = route.childrenMenu.filter((menuItem) => menuItem.name !== 'Finances');
        }
      }
    }

    return route?.enabled;
  };

  const makeRestrictedRoute = (route, index) =>
    route?.to && (
      <RestrictedRoute
        key={index}
        path={route.to}
        component={route.component}
        exact={route.exact}
        routePermission={route.permission}
      />
    );

  return routeList?.map((route, index) => {
    // render children restricted routes instead
    // parent routes don't have their own route but are just a menu group
    const routeHasChildren = route?.hasChildren && route?.childrenMenu;
    if (routeHasChildren) {
      return route.childrenMenu.map((childRoute, childIndex) => {
        const canRenderChild =
          verifyRouteIsEnabled(route) && hasNoOrgRestriction(route?.name);
        if (canRenderChild) {
          return makeRestrictedRoute(childRoute, `${index}-${childIndex}`);
        } else {
          return undefined;
        }
      });
    } else {
      const canViewRoute =
        verifyRouteIsEnabled(route) && hasNoOrgRestriction(route?.name);
      if (canViewRoute) {
        return makeRestrictedRoute(route, index);
      } else {
        return undefined;
      }
    }
  });
};

const AppRouter = () => {
  useSegmentTrackInitializeAction();
  useSegmentIdentify();
  const { FeatureFlagsUIFlag, MainMenuBillingPages } = useFeatureFlags();
  // some features can be toggled for specific orgs, which means we need
  // to check the organization document, not just the routeList
  const currentOrganization = useOrganization();
  const { features } = currentOrganization;

  return (
    <FileServiceProvider
      configuration={{
        auth: auth().currentUser,
        fileServiceURL: process.env.REACT_APP_FILE_SERVICE_HOST,
        organizationId: currentOrganization?.id,
      }}
    >
      <ResponsiveContainer>
        <Switch>
          {/* Transition Routes */}
          <Route path={routes.authReturn} component={AuthReturnPage} />
          <Route path={routes.signout} component={SignoutPage} />
          <Route
            path={routes.signoutFull}
            component={(props) => (
              <SignoutPage fullSignoutMode={true} {...props} />
            )}
          />
          <RestrictedRoute
            path={routes.completeSignup}
            component={CompleteSignupPage}
          />
          <Route path="/token-auth/:jwt" component={TokenAuth} />
          <Route path="/invoice/:jwt" component={Pay} />

          {/* Guest Routes */}
          <GuestRoute
            path={routes.signin}
            component={SigninPage}
            authenticatedRedirect={routes.home}
          />
          <GuestRoute
            path={`${routes.signup}/:networkId?`}
            component={SignUpPage}
            authenticatedRedirect={routes.home}
          />

          {/* User Routes */}
          {getAuthenticatedRoutes(features, {MainMenuBillingPages})}
          <VerifyRoute path={routes.verify} component={EmailSentPage} exact />

          {/* Misc Routes */}
          <Route path={routes.r401} component={UnauthorizedPage} />
          <Route path={routes.r404} component={ErrorPage} />
          <Route path={routes.authError} component={ErrorPage} />
          {FeatureFlagsUIFlag.enabled && (
            <Route path={routes.featureFlags} component={FeatureFlagsPage} />
          )}
          <Route path={routes.error} component={ErrorPage} />
          <DefaultRoute defaultPath={routes.home} />
        </Switch>
      </ResponsiveContainer>
    </FileServiceProvider>
  );
};

export default AppRouter;
