import React from 'react';
import { Dropdown } from '../Shared/Dropdown';

const options = [
  {
    text: 'Child care center',
    value: 'childCareCenter',
    key: 'childCareCenter',
  },
  {
    text: 'Family child care',
    value: 'familyChildCare',
    key: 'familyChildCare',
  },
  {
    text: 'Micro school',
    value: 'microSchool',
    key: 'microSchool',
  },
  {
    text: 'Forest school',
    value: 'forestSchool',
    key: 'forestSchool',
  },
  {
    text: 'Nanny',
    value: 'nanny',
    key: 'nanny',
  },
];

const LocationTypePicker = ({ staticContext, control, children, ...rest }) => {
  return <Dropdown {...rest} options={options} selectOnBlur={false} />;
};

export default LocationTypePicker;
