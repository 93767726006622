import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Sidebar } from 'semantic-ui-react';
import { withRouter } from 'react-router';

// Import hooks.
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useContentSidebarPusherScrollPosition from '../../hooks/useContentSidebarPusherScrollPosition';
// Import helpers.
import { openSidebar } from '../../redux/actions/layoutActions';
import WSA from '../../WSA';
import { ImpersonateBanner } from '../Admin/Impersonation';
import DesktopNavigation from './../Navigation/DesktopNavigation';
import FooterNavigation from './../Navigation/FooterNavigation';
// Import components.
import TopNavigation from './../Navigation/TopNavigation';
import SetupRibbon from './../Setup/SetupRibbon';

const DesktopContainer = ({
  location,
  isSidebarOpen,
  sliderOpen,
  user,
  currentOrganization: { setupComplete },
  openSidebar,
  children,
}) => {
  const { width } = useWindowDimensions();
  const { isReady, isAuthenticated } = WSA.components.useWSAuth();

  useContentSidebarPusherScrollPosition({sliderOpen, id:'content-sidebar-pusher'});

  useEffect(() => {
    if (isReady && isAuthenticated && setupComplete) {
      location.pathname !== '/setup' && openSidebar(true) 
    }
  }, [isAuthenticated, isReady, openSidebar, setupComplete, location]);

  // Calculate the sidebar size be no larger than 275px or 20%
  // of the screen width, whichever is larger.
  const sidebarSize = isSidebarOpen ? width * 0.2 : 0;
  const sidebarWidthSize =
    width > 768 && sidebarSize <= 275 ? sidebarSize : 275;
  const mainContentWidthSize = width - sidebarWidthSize;

  return (
    <Sidebar.Pushable>
      <DesktopNavigation
        visible={isSidebarOpen}
        style={{ width: sidebarWidthSize }}
      />
      <Sidebar.Pusher
        id="content-sidebar-pusher"
        style={{
          height: '100vh',
          width: mainContentWidthSize,
          transform: `translate3d(${sidebarWidthSize}px,0,0)`,
        }}
        dimmed={sliderOpen}
      >
        <div id="content" className={`${sliderOpen? 'no-scroll' : ''}`}>
          <TopNavigation />

          <div id="main-content">
            <ImpersonateBanner />
            <SetupRibbon />

            {children}
          </div>
        </div>

        <FooterNavigation />
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
  isSidebarOpen: state.layout.isSidebarOpen,
  sliderOpen: state.layout.sliderOpen,
});

export default connect(mapStateToProps, { openSidebar })(withRouter(DesktopContainer));
