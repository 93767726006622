import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import LoadingComponent from '../Shared/LoadingComponent';

import WSA from '../../WSA';
import { routes } from '../../config/routes';

const TIMEOUT = 10000;

const LoadingRoute = ({ staticContext, component: Component, ...rest }) => {
  const { isLoading, isUserLoading, isFailed, isReady } =
    WSA.components.useWSAuth();
  const [isTimeout, setIsTimeout] = useState(false);
  const isFinished = !isLoading && isReady && !isUserLoading;
  const isError = isFailed || isTimeout;

  // taking too long, give up and show error
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isFinished) {
        setIsTimeout(true);
      }
    }, TIMEOUT);
    return () => clearTimeout(timer);
  });

  return (
    <Route
      {...rest}
      render={({ staticContext, ...props }) => {
        if (isError) {
          // error state
          return <Redirect to={routes.authError || routes.r404} />;
        } else if (isFinished) {
          return <Component {...props} />;
        } else {
          // loading
          return <LoadingComponent />;
        }
      }}
    />
  );
};

LoadingRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default LoadingRoute;
