import { useState } from 'react';
import CN from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Segment, Header, Icon } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

import { formatFullName, phoneNumberParse } from '../../../helpers/utils';
import { createMissingFieldsAndMergeLists } from '../../../helpers/invitations';
import { removeFamilyMemberFromStudent, clearResponsibleForBilling } from '../../studentsAPI';
import {
  createArrayOfFamilies,
  getOrCreateRelationshipNotes,
} from '../../studentsUtils';

import { useOrganization } from '../../../hooks/useOrganizations';
import { useSelectedStudent } from '../../studentsHooks';
import { useParentInvitations } from '../../../hooks/useInvitations';

import UserStatusLabel from '../../../Components/Shared/UserStatusLabel';

import FamilyEditContactModal from './FamilyEditContactModal';
import DeleteFamilyMemberConfirmationDialog from './DeleteFamilyMemberConfirmationDialog';

export default function FamilyList() {
  const { t } = useTranslation();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState(null);

  const organization = useOrganization();
  const student = useSelectedStudent();
  const invitations = useParentInvitations(student?.id);

  const families = createArrayOfFamilies(student?.family);
  const familiesAndContacts = families.concat(student?.contacts ?? []);
  const familiesAndContactsAndInvitations = createMissingFieldsAndMergeLists(
    familiesAndContacts,
    invitations.list
  );

  return (
    <>
      {familiesAndContactsAndInvitations.length
        ? renderTable()
        : renderNoData()}

      <FamilyEditContactModal
        student={student}
        familyMember={selectedFamilyMember}
        isOpen={isEditModalOpen}
        onClose={onCloseEditModal}
      />
      <DeleteFamilyMemberConfirmationDialog
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={()=>setIsDeleteConfirmationDialogOpen(false)}
        onYes={async ()=> {
          await removeFamilyMemberFromStudent (organization.id, student, selectedFamilyMember)
          
          // update student responsible for billing
          if(student.responsibleForBilling && student.responsibleForBilling.id === (selectedFamilyMember.id || selectedFamilyMember.uid)) {
            clearResponsibleForBilling(organization.id, student.id);
          }

          setIsDeleteConfirmationDialogOpen(false)
        }}
      />
    </>
  );

  function renderTable() {
    return (
      <div className="bootstrap-iso">
        <BootstrapTable
          classes="w-auto w-md-100"
          wrapperClasses="table-responsive"
          noDataIndication={t('no results to display')}
          keyField="key"
          bordered={false}
          data={familiesAndContactsAndInvitations}
          columns={getTableColumns(onClickEdit, onClickDelete, t)}
        />
      </div>
    );
  }


  function renderNoData() {
    return (
      <Segment basic clearing textAlign="center">
        <Header as={'h3'}>
          <Icon circular inverted name="info" color="grey" />
        </Header>
        <Header as={'h4'}>{t('No family or emergency contact added.')}</Header>
        <p className="text-muted">
          {t(
            'You can add a parent, relative, guardian or emergency contact by clicking the add button.'
          )}
        </p>
      </Segment>
    );
  }
  function onClickEdit(familyMember) {
    setSelectedFamilyMember(familyMember);
    setIsEditModalOpen(true);
  }
  function onCloseEditModal() {
    setSelectedFamilyMember (null);
    setIsEditModalOpen(false);
  }
  function onClickDelete(familyMember) {
    setSelectedFamilyMember(familyMember);
    setIsDeleteConfirmationDialogOpen(true);
  }
}

function getTableColumns(onClickEdit, onClickDelete, t) {
  return [
    {
      dataField: 'id',
      text: 'id',
      hidden: true,
    },
    {
      text: '',
      dataField: 'avatar',
      formatter: (cell, row) => (
        <Avatar
          email={!cell ? row.email || '' : ''}
          name={row.firstName || ''}
          round
          size={48}
          className="left floated"
          src={cell}
        />
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'displayName',
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div>
          <div
            className={CN('textTruncate', 'family-member-name')}
            style={{ fontSize: '14px', fontWeight: 'bolder' }}
          >
            {cell || formatFullName(row)}
          </div>
          <div className={CN('textTruncate', 'family-member-email')}>
            {/* Hide bogus emails because they confuse users. */}
            {row.email && !row.email.startsWith('moxit_') ? row.email : ''}
          </div>
        </div>
      ),
      text: t('Name'),
      sort: true,
      headerAlign: 'left',
      style: { verticalAlign: 'middle' },
    },
    {
      dataField: 'phone',
      text: t('Phone'),
      headerAlign: 'left',
      style: { verticalAlign: 'middle' },
      formatter: (cell) => phoneNumberParse(cell),
    },
    {
      dataField: 'relationshipNotes',
      text: t('Relationship'),
      headerAlign: 'left',
      style: { verticalAlign: 'middle' },
      formatter: (cell, row) => getOrCreateRelationshipNotes(row, t),
    },
    {
      dataField: 'familyPin',
      text: t('PIN'),
      headerAlign: 'left',
      style: { verticalAlign: 'middle' },
    },
    {
      dataField: 'enabled',
      text: t('Account status'),
      headerAlign: 'left',
      style: { verticalAlign: 'middle' },
      formatter: (cell, row) => <UserStatusLabel user={row} />,
    },
    {
      text: '',
      dataField: '',
      formatter: (cell, row) => {
        return (
          <>
                      <Button
              circular
              icon="edit outline"
              color="green"
              onClick={() => onClickEdit(row)}
            />
            <Button
              circular
              icon="trash alternate outline"
              color="red"
              onClick={() => onClickDelete(row)}
            />
          </>
        );
      },
      style: { verticalAlign: 'middle' },
      align: 'right',
    },
  ];
}
