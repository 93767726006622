import React from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import components
import { DatePicker } from '../../../../Shared/DatePicker';

// Import style.
import './Invoice.scss';

const Invoice = ({
  dateDue,
  dateStart,
  dateEnd,
  datesRange,
  validationError,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Group>
        <Form.Field width={4}>
          <DatePicker
            required
            placeholder={t('Due Date')}
            name={'dateDue'}
            value={dateDue}
            onChange={onChange}
            label={t('Due Date')}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group className={'row-from-to'}>
        <Form.Field width={4}>
          <label>Service Dates (Optional)</label>
          <DatePicker
            placeholder={t('From')}
            name={'dateStart'}
            value={dateStart}
            onChange={onChange}
            disabled={dateDue ? false : true}
          />
        </Form.Field>
        <Form.Field width={4}>
          <DatePicker
            placeholder={t('To')}
            name={'dateEnd'}
            value={dateEnd}
            onChange={onChange}
            disabled={dateStart ? false : true}
            minDate={dateStart}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

// Invoice.propTypes = {
//   dateDue: PropTypes.string.isRequired,
//   dateStart: PropTypes.string.isRequired,
//   dateEnd: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
// };

export default Invoice;
