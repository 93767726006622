import CN from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from './useFeatureFlags';
import { routes } from '../config/routes';
import styles from './FeatureFlagsLink.module.scss';
// load css substyles
import './FeatureFlagsLink.module.scss';

export const FeatureFlagsLink = ({ mobile }) => {
  const { t } = useTranslation();
  const { FeatureFlagsUIFlag } = useFeatureFlags();

  if (!FeatureFlagsUIFlag.enabled) {
    return null;
  }
  return (
    <Link
      className={CN({
        [styles.featureFlagsLink]: !mobile,
      })}
      to={routes.featureFlags}
    >
      {mobile && '❇️ '}
      {t('Feature Flags')}
    </Link>
  );
};
