import React, { useEffect, useState, useCallback } from 'react';
import { Button, Checkbox, Form, Loader, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { saveNetwork } from '../networksAPI';
import { addError } from '../../helpers/errors';

import { ShowErrors, InlineError } from '../../Components/Messages';
import GeoDataEditForm from './GeoDataEditForm';

export default function NetworkDetails({ network, onClose, onSave, open }) {
  const { t } = useTranslation();

  const [networkData, setNetworkData] = useState({
    id: '',
    name: '',
    logoUrl: '',
    active: true,
  });
  const [geoData, setGeoData] = useState(network?.geoData ?? []);

  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (network) {
      setNetworkData({...network});
      setGeoData(network?.geoData ?? []);
    }
  }, [network]);

  const editTitle = t('Edit Network');
  const addTitle = t('Add Network');

  const onChange = useCallback((_, { name, value, checked }) => {
    setNetworkData((prev) => ({
      ...prev,
      [name]: value !== undefined ? value : checked,
    }));
  }, []);

  if (loading) return <Loader active />;

  return (
    <Modal
      closeIcon
      onClose={onCancel}
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header
        content={network?.id ? `${editTitle}: ${networkData?.name}` : addTitle}
      />

      <Modal.Content>
        {errors && <ShowErrors errors={errors} />}
        {renderInputForm()}
      </Modal.Content>
    </Modal>
  );

  function renderInputForm() {
    return (
      <Form onSubmit={onSubmit} noValidate loading={loading}>
        <Form.Field error={!!errors?.id}>
          <Form.Input
            disabled={!!network?.id}
            required
            type="text"
            id="id"
            name="id"
            value={networkData?.id}
            onChange={onChange}
            label={t('Network ID')}
            placeholder={t('Network ID')}
          />
          {errors?.id && <InlineError text={errors.id} />}
        </Form.Field>

        <Form.Field error={!!errors?.name}>
          <Form.Input
            required
            type="text"
            id="name"
            name="name"
            value={networkData?.name}
            onChange={onChange}
            label={t('Network Name')}
            placeholder={t('Network Name')}
          />
          {errors?.name && <InlineError text={errors.name} />}
        </Form.Field>

        <Form.Field error={!!errors?.active}>
          <label>{t('Network active?')}</label>
          <Form.Radio
            toggle
            id="active"
            name="active"
            checked={networkData?.active}
            onChange={onChange}
            control={Checkbox}
          />
          {errors?.active && <InlineError text={errors.active} />}
        </Form.Field>


        <GeoDataEditForm
          geoData={geoData}
          onChange={onGeoDataChange}
          onDelete={onGeoDataDelete}
          onAdd={onGeoDataAdd}
        />

        <Form.Group widths="equal">
          <Form.Field>
            <Button
              primary
              type="submit"
              loading={loading}
              content={t('Submit')}
            />
            <Button
              basic
              loading={loading}
              content={t('Cancel')}
              onClick={onCancel}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
  function onGeoDataChange (index, value) {
    setGeoData(geoData.map((data, i) => (index === i ? value : data)));
  }
  function onGeoDataDelete (index) {
    setGeoData(geoData.filter((data, i) => (index !== i)));
  }
  function onGeoDataAdd () {
    setGeoData([...geoData, "" ]);
  }

  function onCancel() {
    setErrors(null);
    onClose();
  }

  async function onSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const errors = validate(t);
      if (errors) {
        setLoading(false);
        setErrors(errors);
      }
      networkData.geoData = geoData.filter (geo => geo.length > 0);
      await saveNetwork(networkData, network?.id);
      onSave();
    } catch (error) {
      setErrors(addError(errors, 'exception', error.message));
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function validate(t) {
    let errors = null;

    if (!networkData.id)
      errors = addError(errors, 'id', t('Network ID is required'));

    if (!networkData.name)
      errors = addError(errors, 'name', t('Network Name is required'));

    return errors;
  }
}
