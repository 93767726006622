import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import InlineError from '../Messages/InlineError';
import { currencyFormatter } from '../../helpers/utils';
import {
  isEnabled,
  getMaxBalance,
  getMaxTransactionAmount,
} from '../../helpers/fastpay';

const fastpayRowStyle = {
  minHeight: '5em',
};

//fastpayData is an object with the following shape: { fastpay: fastpay }
export default function FastpaySetup({
  onFastpayDataChange,
  fastpayData,
  maxBalanceDefault = null,
  maxTransactionAmountDefault = null,
  errors = null,
}) {
  const { t } = useTranslation();

  const { fastpay } = fastpayData;
  const isFastpayEnabled = isEnabled(fastpayData);
  const maxBalance = getMaxBalance(fastpayData);
  const maxTransactionAmount = getMaxTransactionAmount(fastpayData);

  const onTextChange = (e, { name, value }) => {
    const intValue = parseInt(value);
    onFastpayDataChange({
      fastpay: {
        ...fastpay,
        [name]: !isNaN(intValue) ? intValue : null,
      },
    });
  };

  const onFastpayEnabledChange = (e, { checked }) => {
    onFastpayDataChange({
      fastpay: {
        ...fastpay,
        enabled: checked,
      },
    });
  };

  return (
    <Form.Group widths="equal" style={fastpayRowStyle}>
      <Form.Radio
        toggle
        id="fastpayEnabled"
        name="fastpayEnabled"
        label={isFastpayEnabled ? t('Disable fastpay') : t('Enable fastpay')}
        checked={isFastpayEnabled}
        onChange={onFastpayEnabledChange}
        control={Checkbox}
      />
      {isFastpayEnabled && (
        <>
          {maxBalanceDefault && (
            <Form.Field error={!!errors?.maxBalance}>
              <Form.Input
                type="text"
                id="maxBalance"
                name="maxBalance"
                value={maxBalance ?? ''}
                onChange={onTextChange}
                label={t('Max Balance')}
                placeholder={currencyFormatter(maxBalanceDefault, {
                  precision: 0,
                })}
              />
              {errors?.maxBalance && <InlineError text={errors.maxBalance} />}
            </Form.Field>
          )}

          {maxTransactionAmountDefault && (
            <Form.Field error={!!errors?.maxTransactionAmount}>
              <Form.Input
                type="text"
                id="maxTransactionAmount"
                name="maxTransactionAmount"
                value={maxTransactionAmount ?? ''}
                onChange={onTextChange}
                label={t('Max Transaction Amount')}
                placeholder={currencyFormatter(maxTransactionAmountDefault, {
                  precision: 0,
                })}
              />
              {errors?.maxTransactionAmount && (
                <InlineError text={errors.maxTransactionAmount} />
              )}
            </Form.Field>
          )}
        </>
      )}
    </Form.Group>
  );
}
