import React from 'react';
import { useTranslation } from 'react-i18next';

// Import component.
import LanguagePicker from './LanguagePicker';

import { changeLanguage } from '../../i18n';

export default function ChangeLanguage() {
  const { i18n } = useTranslation();
  const defaultLanguage = i18n.language || 'en';

  const onChange = (e, { value }) => {
    e.preventDefault();
    changeLanguage(value);
  };

  return (
    <LanguagePicker
      inline
      defaultValue={defaultLanguage}
      clearable={false}
      onChange={onChange}
    />
  );
}
