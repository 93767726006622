import React, { useState, useEffect } from 'react';
import { Card, Divider, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { isEmpty, find } from 'lodash';
import { useTranslation } from 'react-i18next';

import { centerOverviewConfig } from '../../config';

import { organizationStatsOnListen } from '../../api/firebase/stats';

import CheckedInStats from './CheckedInStats'

const orderedKeys = ['checkedIn', 'students', 'rooms', 'staffs', 'transactions'];


const CenterOverviewTable = ({ stats = {} }) => {
  const { t } = useTranslation();
  
  if (!stats) return null;

  const statsArr = orderedKeys.map(
    key => stats[key]
        ? { ...stats[key], name: key}
        : undefined
    )
    .filter(stat => !!stat)


  const statsList = statsArr.map((stat, index) => {
    if (isEmpty(stat)) return null;

    const centerConfig = find(centerOverviewConfig, ['name', stat.name]);

    if (!centerConfig)
      return null;
    
    return (
      <Grid.Column key={index} width={4}>
        {`${t(centerConfig.description)}: `}<strong>{stat.total}</strong>
      </Grid.Column>
    );
  });

  return (
    <Grid stackable>
      <Grid.Row>
        {statsList}
      </Grid.Row>
    </Grid>
  );
};

const CenterOverview = ({ rooms, students, currentOrganization: { id = null, programType = null } }) => {
  const [stats, setStats] = useState();
  const [roomsWithStudents, setRoomsWithStudents] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!id) return null;


    const unsubscribeOrganizations = organizationStatsOnListen(id, (stats) => {
      const allStats = {};
      stats.list.map(stat => allStats[stat.id] = stat);
      setStats(allStats)
    });

    const presentStudents = students.filter(student => student.isPresent);
    const _roomsWithStudents = {};
    
    rooms.forEach(room => {
      const studentsInRoom = presentStudents.filter(student => student.rooms.includes(room.id));
      _roomsWithStudents[room.id] = studentsInRoom;
    })

    setRoomsWithStudents(_roomsWithStudents);

    return () => {
      unsubscribeOrganizations();
    };
  }, [id, students, rooms]);

  if (isEmpty(stats)) return null;

  return (
    <Card fluid className="no-padding">
      <Card.Content>
        <Card.Header as="h3">{t('Center Overview')}</Card.Header>
        <CenterOverviewTable stats={stats} />

        <Divider />
        
        <CheckedInStats
          rooms={rooms}
          stats={stats}
          programType={programType}
          roomsWithStudents={roomsWithStudents}
        />
      </Card.Content>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms.list,
  students: state.students.list
});

export default connect(mapStateToProps)(CenterOverview);
