import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Container, Segment } from 'semantic-ui-react';

// Import component.
import StudentImporter from '../Components/Upload/StudentsImporter';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';

const TYPE = {
  students: 'Import your students list',
};

const Importer = ({
  match: {
    params: { type },
  },
}) => {
  useSegmentPage(PAGE_NAMES.importer);
  const { t } = useTranslation();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', {
          pageName: t(TYPE[type] || 'Importer'),
        })}
      </title>
      <body class="importer" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        <StudentImporter type={type} />
      </Segment>
    </Container>
  );
};

export default Importer;
