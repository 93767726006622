import { useEffect } from 'react';

import { useSegment } from 'react-segment-hooks';

import { useAuthUser } from '../../hooks/useUser';
import { useOrganization } from '../../hooks/useOrganizations';

import { callSegmentPage } from '../api';
import { PAGE_CATEGORIES } from '../types';

export default function useSegmentPage(pageName, properties = {}) {
  const { currentUser } = useAuthUser();
  const organization = useOrganization();
  const segmentClient = useSegment();

  useEffect(() => {
    if (organization?.id && currentUser?.uid) {
      const data ={
        name: pageName,
        category: PAGE_CATEGORIES.default,
        properties: {
          userId: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName,
          organizationId: organization.id,
          organizationName: organization.name,
          ...properties,
        },
      }
      callSegmentPage(segmentClient, data)
    }
  }, [organization, pageName, properties, segmentClient, currentUser]);
}
