import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';

// Import utils
import { formatFullName } from '../../helpers/utils';

class PaymentDetail extends Component {
  renderInvoiceDetail = () => {
    const {
      invoice: { id, billToId, student },
    } = this.props;

    const billTo = billToId && student ? student.family[billToId] : {};
    return (
      <div>
        <Header as="h1">
          <Header.Content>Invoice from</Header.Content>
          <Header.Subheader>
            {id && <div style={{ marginTop: '20px' }}>Invoice #: {id} </div>}
            {billTo && (
              <div style={{ marginTop: '20px' }}>
                Bill to: {formatFullName(billTo, true)}
              </div>
            )}
          </Header.Subheader>
        </Header>
      </div>
    );
  };

  render() {
    return (
      <Segment basic textAlign="center">
        {this.renderInvoiceDetail()}
      </Segment>
    );
  }
}

export default PaymentDetail;
