import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import useSegmentTrack from './useSegmentTrack';
import { segmentTrackInitializeAction } from '../segmentRedux';

export default function useSegmentTrackInitializeAction() {
  const dispatch = useDispatch ();
  const segmentTrackFunction = useSegmentTrack();

  useEffect(() => {
    dispatch (segmentTrackInitializeAction (segmentTrackFunction));
  }, [dispatch, segmentTrackFunction]);
}
