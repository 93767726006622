import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

// Import styles
import './LocationPicker.module.scss'; // for globals/children overrides
import styles from './LocationPicker.module.scss';

const LocationPicker = ({ className, ...rest }) => {
  const locations = useSelector((state) => state.locations?.list);

  const options = useMemo(
    () =>
      locations?.map((location) => ({
        text: location.name,
        value: location.id,
      })),
    [locations]
  );

  return (
    <Form.Dropdown
      clearable
      options={options}
      selectOnBlur={false}
      renderLabel={(label) => ({
        color: 'yellow',
        content: label.text,
      })}
      className={[styles.overflowFix, className].join(' ')}
      {...rest}
    />
  );
};

export default LocationPicker;
