// https://github.com/abell123456/remove-url-hash/blob/master/index.js
// switched from pushState to replaceState
export default function removeUrlHash() {
  const loc = window.location;

  if ('pushState' in window.history) {
    window.history.replaceState('', document.title, loc.pathname + loc.search);
  } else {
    // Prevent scrolling by storing the page's current scroll offset
    const scrollV = document.body.scrollTop;
    const scrollH = document.body.scrollLeft;

    loc.hash = '';

    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
}
