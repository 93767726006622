import React, { Component } from 'react';
import { Header, Segment, Table } from 'semantic-ui-react';
import accounting from 'accounting';

const CURRENCY_OPTIONS = {
  symbol: '$',
  decimal: '.',
  thousand: ',',
  precision: 2,
};

class InvoiceItems extends Component {
  renderInvoiceItems = () => {
    const {
      invoice: { total, invoiceItemList },
    } = this.props;

    return (
      <div>
        <div style={{ paddingBottom: '30px' }}>
          <Header
            as="h4"
            icon="quote left"
            color="grey"
            content="Thanks for your business"
          />
        </div>
        <Table basic="very" size="large" padded="very">
          <Table.Header>
            <Table.Row verticalAlign="middle">
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Note</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {invoiceItemList &&
              invoiceItemList.map((item, index) => [
                <Table.Row key={index} verticalAlign="middle">
                  <Table.Cell>{item.item}</Table.Cell>
                  <Table.Cell>{item.category}</Table.Cell>
                  <Table.Cell>{item.notes}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {accounting.formatMoney(item.amount, CURRENCY_OPTIONS)}
                  </Table.Cell>
                </Table.Row>,
                item.discounts.map((discount, j) => (
                  <Table.Row
                    key={`d-${index}-${j}`}
                    disabled
                    verticalAlign="middle"
                  >
                    <Table.Cell>{discount.discountType}</Table.Cell>
                    <Table.Cell />
                    <Table.Cell />

                    <Table.Cell textAlign="right">
                      -
                      {accounting.formatMoney(
                        discount.amount,
                        CURRENCY_OPTIONS
                      )}
                    </Table.Cell>
                  </Table.Row>
                )),
              ])}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell textAlign="right">
                {accounting.formatMoney(total, CURRENCY_OPTIONS)}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <div>
          If you have any questions, contact at
          <a href="mailto:billing@moxit.io"> Billing Support</a>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Segment basic textAlign="center">
        {this.renderInvoiceItems()}
      </Segment>
    );
  }
}

export default InvoiceItems;
