import * as actionTypes from '../../actions/types';

const initialState = {
  paymentDetails: null,
};
const paymentDetailsReducer = (state = initialState.paymentDetails, action) => {
  switch (action.type) {
    case actionTypes.PAYMENT.UPDATE_DETAILS:
      let details = action.payload;
      return {
        ...details,
        ...{
          invoiceItemList: [
            ...details.invoiceItemList.map((item) => {
              return {
                ...item,
                ...{
                  discounts: [
                    ...item.discounts.map((discount) => {
                      return { ...discount };
                    }),
                  ],
                },
              };
            }),
          ],
        },
      };
    case actionTypes.PAYMENT.RESET_DATA:
      return null;
    default:
      return state;
  }
};

export default paymentDetailsReducer;
