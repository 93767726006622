// Import API.
import {
  STUDENT_INVITATIONS_FETCHED,
  ALL_STUDENT_INVITATIONS_FETCHED,
  STAFF_INVITATIONS_FETCHED,
  INVITED_PARENTS_BY_IDS_FETCHED,
} from './types';

import {
  pendingParentInvitationsByStudentIdsOnListen,
  pendingParentInvitationsOnListen,
  pendingStaffInvitationsOnListen,
} from '../../api/firebase/invitations';

export const invitationsFetched = (invitations, type) => ({
  type: type,
  invitations,
});

export const startPendingStaffInvitationsListener =
  (organizationId) => (dispatch) => {
    return pendingStaffInvitationsOnListen(
      organizationId,
      (invitations) => {
        if (invitations) dispatch(invitationsFetched(invitations, STAFF_INVITATIONS_FETCHED));
      },
      (error) => console.log(error.message)
    );
  };

export const startPendingParentInvitationsListener =
  (organizationId, studentId=null) => (dispatch) => {
    return pendingParentInvitationsOnListen(
      {
        organizationId,
        studentId,
      },
      (invitations) => {
        if (invitations) {
          if (!!studentId) {
            dispatch(invitationsFetched(invitations, STUDENT_INVITATIONS_FETCHED));
          } else {
            dispatch(invitationsFetched(invitations, ALL_STUDENT_INVITATIONS_FETCHED));
          }
        }
      },
      (error) => console.log(error.message)
    );
  };

export const startPendingParentInvitationsByStudentIdsListener =
  (organizationId, studentIds) => (dispatch) => {
    return pendingParentInvitationsByStudentIdsOnListen(
      {
        organizationId,
        studentIds,
      },
      (invitations) => {
        if (invitations) dispatch(invitationsFetched(invitations, INVITED_PARENTS_BY_IDS_FETCHED));
      },
      (error) => console.log(error.message)
    )
  };
