import React from 'react';

import { Popup } from 'semantic-ui-react';
import FastpayStatus from './FastpayStatus';

const FastpayTooltip = ({ enabled, tooltip, size = 'large', color }) => (
  <Popup
    content={tooltip}
    trigger={
      <div>
        <FastpayStatus
          enabled={enabled}
          hasLabel={false}
          size={size}
          color={color}
        />
      </div>
    }
  />
);

export default FastpayTooltip;
