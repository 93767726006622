import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Validator from 'validator';
import MaskedInput from 'react-text-mask';
import Avatar from 'react-avatar';
import { useTranslation, Trans } from 'react-i18next';

// Import components
import InlineError from '../Messages/InlineError';
import LanguagePicker from '../Shared/LanguagePicker';
import ProfilePictureUploader from '../Upload/ProfilePictureUploader';

// Import Actions
import { updateUser } from '../../redux/actions/userActions';
import { phoneNumberParse, phoneNumberFormat } from '../../helpers/utils';

// Import style
import styles from './UserProfileForm.module.scss'; // imports base class styles
import './UserProfileForm.module.scss'; // imports global nested styles as global <style> element

import { changeLanguage } from '../../i18n';

const UserProfileForm = ({
  user: {
    uid,
    firstName,
    middleName,
    lastName,
    phone,
    email,
    photoURL,
    language,
  },
  currentOrganization,
  updateUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    uid: uid || '',
    firstName: firstName || '',
    middleName: middleName || '',
    lastName: lastName || '',
    phone: phoneNumberParse(phone || ''),
    photoURL: photoURL || '',
    language: language || '',
  });

  const { t } = useTranslation();
  const history = useHistory();

  const showEmail = !!email && email.indexOf('moxit_') === -1;

  const validate = (data) => {
    const errors = {};

    // if (!data.salutation) errors.salutation = 'Salutation is required';
    if (!data.firstName) errors.firstName = t('First Name is required');
    if (!data.lastName) errors.lastName = t('Last Name is required');

    if (data.phone)
      if (!Validator.isMobilePhone(data.phone, 'en-US'))
        errors.phone = t('Phone is invalid');

    return errors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errors = validate(data);
    setErrors(errors);

    if (isEmpty(errors)) {
      try {
        await updateUser(currentOrganization.id, uid, {
          ...data,
          phone: phoneNumberFormat(data.phone),
        });

        // Change language
        if (data.language) changeLanguage(data.language);

        setLoading(false);
        history.goBack();
      } catch (error) {
        console.log('UserProfileForm error', error);
        setLoading(false);
      }
    }
  };

  const onChange = (e, _data) => {
    if (_data?.name) {
      setData({ ...data, [_data.name]: _data.value ?? '' });
    } else if (e.target?.name && e.target?.value) {
      setData({ ...data, [e.target.name]: e.target.value ?? '' });
    }
  };

  const onUploaded = (imageUrl) => setData({ ...data, photoURL: imageUrl });

  return (
    <Form onSubmit={onSubmit} noValidate loading={loading}>
      <Form.Group widths="equal">
        <Form.Field error={!!errors.firstName}>
          <Form.Input
            required
            type="text"
            id="firstName"
            name="firstName"
            value={data.firstName}
            onChange={onChange}
            label={t('First Name')}
            placeholder={t('First Name')}
          />
          {errors.firstName && <InlineError text={errors.firstName} />}
        </Form.Field>

        <Form.Field error={!!errors.middleName}>
          <Form.Input
            type="text"
            id="middleName"
            name="middleName"
            value={data.middleName}
            onChange={onChange}
            label={t('Middle Name')}
            placeholder={t('Middle Name')}
          />
          {errors.middleName && <InlineError text={errors.middleName} />}
        </Form.Field>
      </Form.Group>

      <Form.Field error={!!errors.lastName}>
        <Form.Input
          required
          type="text"
          id="lastName"
          name="lastName"
          value={data.lastName}
          onChange={onChange}
          label={t('Last Name')}
          placeholder={t('Last Name')}
        />
        {errors.lastName && <InlineError text={errors.lastName} />}
      </Form.Field>

      {showEmail && (
        <Form.Field className={styles.emailInput}>
          <span className={styles.editLink}>
            <Trans i18nKey="Contact support to change your email">
              Contact{' '}
              <a
                href="mailto:support@wonderschool.com"
                target="_blank"
                rel="noreferrer"
              >
                support@wonderschool.com
              </a>{' '}
              to change your email
            </Trans>
          </span>
          <Form.Input
            type="text"
            disabled
            value={email}
            label={t('Email')}
            placeholder={t('Email')}
          />
        </Form.Field>
      )}

      <Form.Field error={!!errors.phone}>
        <Form.Input
          type="text"
          id="phone"
          name="phone"
          onChange={onChange}
          label={t('Cell Phone')}
          control={MaskedInput}
          mask={[
            '(',
            /[1-9]/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          guide={false}
          value={data.phone}
          placeholder={'(123) 456-7890'}
        />
        {errors.phone && <InlineError text={errors.phone} />}
      </Form.Field>

      <Form.Field error={!!errors.language}>
        <Form.Field
          id="language"
          name="language"
          label={t('Language')}
          control={LanguagePicker}
          placeholder={t('Language')}
          value={data.language}
          selection
          search
          onChange={onChange}
        />
        {errors.language && <InlineError text={errors.language} />}
      </Form.Field>

      <Form.Group widths="equal">
        <Form.Field>
          <label>{t('Profile Photo')}</label>

          <Grid columns="equal" verticalAlign="middle">
            <Grid.Column>
              <Avatar
                round
                name={email || ''}
                src={data.photoURL}
                size={140}
                className="left floated"
                style={{ float: 'unset', fontSize: '2em', marginRight: '20px' }}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <Form.Field
                error={!!errors.photoURL}
                control={ProfilePictureUploader}
                uploadPath={`organizations/${currentOrganization.id}/users/${data.uid}`}
                title={t('Uploading Profile Photo')}
                onUploaded={onUploaded}
              >
                {errors.photoURL && <InlineError text={errors.photoURL} />}
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form.Field>
      </Form.Group>

      <br />
      <br />
      <Form.Group>
        <Form.Button primary content={t('Update')} loading={loading} />
        <Form.Button
          basic
          content={t('Cancel')}
          loading={loading}
          onClick={(e) => {
            if (e) e.preventDefault();
            history.goBack();
          }}
        />
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps, { updateUser })(UserProfileForm);
