import { Container, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

// Import components
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';
import PageTitle from '../Components/Shared/PageTitle';
import EnforceAutomaticPayment from '../Components/Billing/Settings/EnforceAutomaticPayment';

const Finances = (props) => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.finance);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('pageSettingsFinanceTitle')}
      </title>
      <body class="finance" />
    </Helmet>
  );

  const renderPageTitle = () => (
    <PageTitle title={t('pageSettingsFinanceHeaderTitle')} />
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        {renderPageTitle()}
        <EnforceAutomaticPayment />
      </Segment>
    </Container>
  );
};

export default Finances;
