import { firestore } from '.';
import { getDocument, startCollectionListener} from './firestore';

const paths = {
  room: (organizationId, roomId) => `organizations/${organizationId}/rooms/${roomId}`,
  rooms: (organizationId) => `organizations/${organizationId}/rooms`,
}

export function organizationRoomsOnListen (organizationId, next, error, complete) {
  return startCollectionListener ({
    path: paths.rooms(organizationId),
    orderBy: [{field: 'name', direction:'asc'}],

  },
  next, error, complete);
}

export function addOrganizationRoom (organizationId, roomData, programType = null) {

  if (programType && programType === 'familyChildCare') {
    const roomId = `${organizationId}-primaryRoom`;

    return firestore()
      .collection(paths.rooms (organizationId))
      .doc(roomId)
      .set(roomData);
  }

  return firestore()
    .collection(`organizations/${organizationId}/rooms`)
    .add(roomData);
};

export const updateOrganizationRoom = (organizationId, { id, ...roomData }) =>
  firestore()
    .doc(paths.room (organizationId, id))
    .update(roomData);

export const getRoom = (organizationId, roomId) => {
  if (!organizationId || !roomId) return null;
  return getDocument({
    path: paths.room (organizationId, roomId),
  });
};
