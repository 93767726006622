import { firestore } from '.';
import {
  getDocuments,
  getDocumentsAsArray,
  setDocument,
  getDocument,
  deleteDocument,
  updateDocument,
  startCollectionListener,
} from './firestore';

import {InvoiceStatus} from '../../helpers/invoices';
import { findLocationIdForStudent } from '../../students/studentsUtils';

const paths = {
  invoice: (organizationId, invoiceId) => `organizations/${organizationId}/invoices/${invoiceId}`,
  invoices: (organizationId) => `organizations/${organizationId}/invoices`,
  invoicePlans: (organizationId) => `organizations/${organizationId}/invoicePlans`,
}

export async function fetchOrganizationInvoices (
  organizationId,
  conditions,
  orderBy = [],
  limit = null,
  startAfter = null
) {
  return getDocuments({
    path: paths.invoices (organizationId),
    conditions,
    // orderBy: [{ field: 'dateDue', direction: 'asc' }, ...orderBy],
    limit,
    startAfter,
  });
}

export async function fetchOrganizationInvoicePlans (
  organizationId,
  conditions,
  orderBy = [],
  limit = null,
  startAfter = null
) {
  return getDocumentsAsArray({
    path: paths.invoicePlans(organizationId),
    conditions,
    orderBy: [{ field: 'dateStart', direction: 'desc' }, ...orderBy],
    limit,
    startAfter,
  });
}

export const fetchInvoicePlansWithMultipleStudents = async () => {
  const { list: invoicePlans } = await getDocumentsAsArray({
    path: 'invoicePlans',
    conditions: [{
      field: 'status',
      operation: '==',
      value: 'active',
    }],
  });

  return invoicePlans.filter(({ students }) => students?.length > 1);
}

export async function fetchOrganizationInvoice (organizationId, invoiceId) {
  getDocument({
    path: paths.invoice (organizationId, invoiceId),
  });
}

export function organizationInvoicesOnListen ({organizationId, locations}, next, error, complete) {

  const conditions = locations ? [{
    field: 'location',
    operation: 'in',
    value: Object.keys(locations)
  }] : []

  return startCollectionListener ({
    path: paths.invoices(organizationId),
    conditions,
    orderBy:[{field: 'dateDue', direction:'asc'}]
  },
  next, error, complete);
}

export async function createOrganizationInvoice(organizationId, payload) {
  const batch = firestore().batch();
  const batchResult = {};

  if (payload && payload.students && payload.students.length) {
    for (const student of payload.students) {
      if (!student) continue;

      const invoiceDocRef = firestore()
        .collection('organizations')
        .doc(organizationId)
        .collection('invoices')
        .doc();

      const locationId = findLocationIdForStudent(student);

      const invoiceData = {
        ...payload.details,
        organization: organizationId,
        location: locationId,
        student,
        createdAt: Date.now()
      };

      batch.set(invoiceDocRef, invoiceData);

      // Need this object to pass into redux flow on create
      batchResult[invoiceDocRef.id] = invoiceData;
    }
  }

  await batch.commit();
  return batchResult;
}

export async function createOrganizationInvoicePlan (organizationId, payload) {
  const batch = firestore().batch();
  const batchResult = {};

  if (payload && payload.students && payload.students.length) {
    for (const student of payload.students) {
      if (!student) continue;

      const invoiceDocRef = firestore()
      .collection('organizations')
      .doc(organizationId)
      .collection('invoicePlans')
      .doc();

      const invoicePlan = prepareInvoicePlanForWrite({...payload, students:[student]});

      batch.set(invoiceDocRef, invoicePlan);

      // Need this object to pass into redux flow on create
      batchResult[invoiceDocRef.id] = invoicePlan;
    }
  }

  await batch.commit();
  return batchResult;
};
export const setOrganizationInvoicePlan = (organizationId, payload) => {
  const data = prepareInvoicePlanForWrite(payload);

  setDocument({
    path: `organizations/${organizationId}/invoicePlans/${payload.id}`,
    data,
  });
}

function prepareInvoicePlanForWrite(invoicePlan) {
  const {id, uid, ...strippedInvoicePlan} = invoicePlan;
  const locationId = invoicePlan?.students?.length ? findLocationIdForStudent(invoicePlan.students[0]) : '';
  const data = {
    ...strippedInvoicePlan,
    isInvoice: false,
    location: locationId,
    students: invoicePlan?.students ?? [],
    invoiceItemList: invoicePlan?.invoiceItems ?? invoicePlan.invoiceItemList ?? [],
    updatedAt: Date.now()
  };

  if (!data.createdAt)
    data.createdAt = data.updatedAt;
  return data;
}

export async function updateOrganizationInvoice(organizationId, invoice) {
  if (!invoice?.id)
    throw Error('Invoice ID is required');

  const {id, ...data} = invoice;

  await setDocument({
    path: paths.invoice (organizationId, id),
    data,
  });
}
export async function removeOrganizationInvoice (organizationId, invoiceId) {
  await deleteDocument({
    path: paths.invoice (organizationId, invoiceId),
  });
}

export async function resendInvoiceToStripe (invoice) {

  const data = {
    status: InvoiceStatus.DRAFT,
    paidAmount: 0,
    //payments: [],
    stripe: 'null',
  }
  const path = paths.invoice(invoice.organization, invoice.id);
  await updateDocument({path, data});
  data.status = InvoiceStatus.OPEN;
  await updateDocument({path, data});
}
