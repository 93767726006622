// FIREBASE v8 Syntax
import { getAuth } from './index';
import { getIdToken } from './currentUser';
import {
  logWarning,
  logError,
  trackUser,
  trackUserSignout,
} from '../../rollbar';

/* eslint-disable no-unused-vars */
// use for types in comments
import firebase from 'firebase/app';
/* eslint-enable no-unused-vars */
/**
 * @typedef {{
 *  credential: UserCredential,
 *  user: firebase.User,
 *  idToken: string,
 *  userData: {
 *    email: string,
 *    ...rest
 *  }
 * }} UserPayload
 */

/**
 * ### Async / Promise
 * Sign in a user that was authenticated via SSO, as represented by
 * the custom token jwt.
 * @param {string} jwt Created from admin SDK, NOT id token
 * @returns {UserPayload | undefined}
 * If undefined, an unrecoverable error occurred.
 */
export const signInWithCustomToken = async (jwt, authCtx = getAuth()) => {
  try {
    if (!jwt) {
      throw new Error('jwt required');
    }
    const credential = await authCtx.signInWithCustomToken(jwt);
    const user = credential.user;
    const idToken = await getIdToken(true);
    trackUser(user);
    return {
      credential,
      user,
      idToken,
    };
  } catch (err) {
    /**
     * Possible Codes (If undefined, it is custom, runtime error)
     *
     * auth/custom-token-mismatch - Thrown if the custom token is for a different Firebase App.
     * auth/user-disabled - Thrown if the custom token format is incorrect.
     */
    if (err.code) {
      switch (err.code) {
        case 'auth/custom-token-mismatch':
          return {
            errorCode: 'token',
          };
        case 'auth/user-disabled':
          return {
            errorCode: 'user',
          };
        default:
          logWarning(
            `sign in with custom token error (handled): ${err.message}`,
            {
              err,
              code: err.code,
              jwt,
            }
          );
          return; // signals unhandled error
      }
    } else {
      logError(`sign in with custom token error: ${err.message}`, err, {
        err,
        jwt,
      });
      return; // signals unexpected runtime error
    }
  }
};

/**
 * ### Async / Promise
 * signs the user out of JUST this firebase app instance.
 * the client should either sign out of the session before calling this
 * @returns { true | undefined }
 * if undefined, an error occurred.
 * otherwise, returns true.
 */
export const signOut = async (authCtx = getAuth()) => {
  try {
    await authCtx.signOut();
    trackUserSignout();
    return true;
  } catch (err) {
    // no error codes generated by firebase for this function
    logError(`sign out error: ${err.message}`, err);
    return; // signals failure
  }
};

/**
 * listens for auth updates, callback triggers at least once
 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#onauthstatechanged
 * if no user is signed in, `next` is called with a nullish object
 * @param {firebase.Observer<firebase.User>} nextFn
 * @param {(a: firebase.auth.Error) => any} errorFn
 * @param {firebase.Unsubscribe} completeFn
 * @returns {firebase.Unsubscribe} unsubscribe function
 */
export const authOnListen = (
  nextFn,
  errorFn,
  completeFn,
  authCtx = getAuth()
) => {
  return authCtx.onAuthStateChanged(
    (user) => {
      trackUser(user);
      return nextFn(user);
    },
    errorFn,
    completeFn
  );
};
